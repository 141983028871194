import React from 'react';
import styles from './avatar.module.scss';
import { Avatar } from 'antd';

export type AvatarProps = {
  iconSize?: number | 'small' | 'large' | 'default';
  iconSrc?: string;
  shape?: 'circle' | 'square';
  fallBackImgSrc?: string;
  customTextForFallback?: string;
  fontsize?: string;
  isHighlight?: boolean;
};

const AmplifyAvatar = (props: AvatarProps) => {
  let fontsize = props.fontsize;
  if (typeof props.iconSize === 'number') {
    fontsize = props.fontsize || `${props.iconSize * 0.6}px`;
  }

  const { iconSize = 'small', iconSrc, shape = 'circle', fallBackImgSrc, customTextForFallback, isHighlight = false } = props;

  return (
    <Avatar
      size={iconSize}
      src={iconSrc}
      style={{ fontSize: fontsize }}
      className={isHighlight ? styles.active : ''}
      gap={1}
      shape={shape}
      icon={fallBackImgSrc && <img style={{ padding: '10%' }} src={fallBackImgSrc} alt={'No Pic'} />}
    >
      {(customTextForFallback || '@').toUpperCase()}
    </Avatar>
  );
};
export default AmplifyAvatar;
