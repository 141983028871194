import React from 'react';
import Ribbon from '.';
import { shouldShowUsingProdWarning } from '../../utils/prod-warning';
import AmplifyPopover from '../ant-popover';
import Styles from './index.module.scss';

// interface Props {}
const config = {
  title: <b className='has-text-danger'>Using Prod API!</b>,
  content: 'Be cautious, you are connected to production API',
};

const ProdWaringRibbon = () => {
  const showWarning = shouldShowUsingProdWarning();

  return showWarning ? (
    <Ribbon
      text={
        <AmplifyPopover
          title={config.title}
          placement='rightBottom'
          content={config.content}
          arrowContent={null}
          overlayClassName={Styles.warningPopover}
          align={{ offset: ['-10px', '30px'] as any }}
        >
          <span>{'PROD api'}</span>
        </AmplifyPopover>
      }
    />
  ) : null;
};

export default ProdWaringRibbon;
