import { AttemptLoginApiResponse, GetUserDetailsApiResponse } from '../../../types';
import { AuthToken, User, GetUserPermissionsApiResponse, UserPermissions } from '../login.types';

const attemptLoginApiToUi = (apiResponse: AttemptLoginApiResponse): AuthToken => {
  const token = { authToken: apiResponse.access_token, expiresIn: apiResponse.expiresIn };
  return token;
};
const transformMfaSettings = (apiResponse: AttemptLoginApiResponse) => {
  return apiResponse.mfa || null;
};
const getUserDetailsApiToUi = (apiResponse: GetUserDetailsApiResponse): User => {
  const user = {
    name: `${apiResponse.firstName || ''} ${apiResponse.lastName || ''}`,
    firstName: apiResponse.firstName,
    lastName: apiResponse.lastName,
    email: apiResponse.email,
    id: apiResponse.id,
    roles: apiResponse.roles,
    photoUrl: apiResponse.photo_url,
    mfa: apiResponse.mfa ? { enabled: apiResponse.mfa.enabled, required: apiResponse.mfa.required, requiredFrom: apiResponse.mfa.requiredFrom } : undefined,
  };
  return user;
};
const getUserPermissionsApiToUi = (apiResponse: GetUserPermissionsApiResponse): UserPermissions => {
  const permissions = apiResponse;
  return permissions;
};

const transformations = { attemptLoginApiToUi, getUserDetailsApiToUi, getUserPermissionsApiToUi, transformMfaSettings };
export default transformations;
