import { TemplatesTabs } from './templates.types';

export const templatesTabs: TemplatesTabs = {
  amplifyStandard: 'Amplify Standard',
  custom: 'Custom',
};
export const headerNames = {
  NAME: 'name',
  DATE_CREATED: 'dateCreated',
};
export const templatesTabsRoutes = {
  [templatesTabs.amplifyStandard]: 'standard',
  [templatesTabs.custom]: 'custom',
};
const TagsConstants: TemplatesConstantsType = {
  SWITCH_TEMPLATES_TAB: 'SWITCH_TEMPLATES_TAB',
  SHOW_CREATE_TEMPLATE_BUTTON: 'SHOW_CREATE_TEMPLATE_BUTTON',
  CHANGE_TITLE_TEMPLATES: 'CHANGE_TITLE_TEMPLATES',
  CHANGE_CURRENT_TAB_TEMPLATES: 'CHANGE_CURRENT_TAB_TEMPLATES',
  STORE_TEMPLATES_BASE_PATH: 'STORE_TEMPLATES_BASE_PATH',
  SET_TEMPLATES_LOADED: 'SET_TEMPLATES_LOADED',
  STORE_TEMPLATES_STRING_MAP: 'STORE_TEMPLATES_STRING_MAP',
  CREATE_TEMPLATE_MESSAGE_MAP: 'CREATE_TEMPLATE_MESSAGE_MAP',
  DELETE_TEMPLATE_MESSAGE_MAP: 'DELETE_TEMPLATE_MESSAGE_MAP',
  EDIT_TEMPLATE_MESSAGE_MAP: 'EDIT_TEMPLATE_MESSAGE_MAP',
  TEMPLATES_PREV_MODE: 'TEMPLATES_PREV_MODE',
};
type TemplatesConstantsType = {
  SWITCH_TEMPLATES_TAB: string;
  SHOW_CREATE_TEMPLATE_BUTTON: string;
  CHANGE_TITLE_TEMPLATES: string;
  CHANGE_CURRENT_TAB_TEMPLATES: string;
  STORE_TEMPLATES_BASE_PATH: string;
  SET_TEMPLATES_LOADED: string;
  STORE_TEMPLATES_STRING_MAP: string;
  CREATE_TEMPLATE_MESSAGE_MAP: string;
  DELETE_TEMPLATE_MESSAGE_MAP: string;
  EDIT_TEMPLATE_MESSAGE_MAP: string;
  TEMPLATES_PREV_MODE: string;
};
export default TagsConstants;
