import { CommentsMode, CommentsModeConstants, dashboardTabConstants, dashboardTabs } from '../../pages/dashboard/constants';
import { broadcastConstants, broadcastsTabsRoutes } from '../../pages/broadcasts/constants';
import { AudienceTabs } from '../../pages/audience/constants';

const PageUIStateConstants: PageUIStateConstantsType = {
  SET_PAGE_UI_STATE: 'SET_PAGE_UI_STATE',
  SET_SELECTED_PLATFORM_BOT: 'SET_SELECTED_PLATFORM_BOT',
  SET_ROUTE_PARAMS: 'SET_ROUTE_PARAMS',
  SET_SELECTED_TIME_FILTER: 'SET_SELECTED_TIME_FILTER',
  SET_ENGAGEMENTS_TIME_FILTER: 'SET_ENGAGEMENTS_TIME_FILTER',
  SET_CONNECTED_TO_FB: 'SET_CONNECTED_TO_FB',
  SET_SIDEBAR: 'SET_SIDEBAR',
  SET_FILTER: 'SET_FILTER',
  SET_INITIAL_DATA_FETCH_ATTEMPTED: 'SET_INITIAL_DATA_FETCH_ATTEMPTED',
  SET_INITIAL_FILTER: 'SET_INITIAL_FILTER',
  STORE_OVERRIDE_NLP: 'STORE_OVERRIDE_NLP',
  IS_ACCOUNTS_FETCHED: 'IS_ACCOUNTS_FETCHED',
  ROUTE_PARAMS: 'ROUTE_PARAMS',
  APPLICABLE_TABS_SUB_TABS: 'APPLICABLE_TABS_SUB_TABS',
  FILTER_COMMENTS_FROM_API: 'FILTER_COMMENTS_FROM_API',
  SET_INITIAL_PAGE_FILTER: 'SET_INITIAL_PAGE_FILTER',
  CLEAR_COMMENTS_FILTER: 'CLEAR_COMMENTS_FILTER',
  SET_SELECTEDTAG_RANGE: 'SET_SELECTEDTAG_RANGE',
  SET_MOBILESIDEBAR_VISIBILITY: 'SET_MOBILESIDEBAR_VISIBILITY',
};
type PageUIStateConstantsType = {
  SET_PAGE_UI_STATE: string;
  SET_SELECTED_PLATFORM_BOT: string;
  SET_ROUTE_PARAMS: string;
  SET_SELECTED_TIME_FILTER: string;
  SET_ENGAGEMENTS_TIME_FILTER: string;
  SET_CONNECTED_TO_FB: string;
  SET_SIDEBAR: string;
  SET_FILTER: string;
  SET_INITIAL_DATA_FETCH_ATTEMPTED: string;
  SET_INITIAL_FILTER: string;
  SET_INITIAL_PAGE_FILTER: string;
  CLEAR_COMMENTS_FILTER: string;
  STORE_OVERRIDE_NLP: string;
  IS_ACCOUNTS_FETCHED: string;
  ROUTE_PARAMS: string;
  APPLICABLE_TABS_SUB_TABS: string;
  FILTER_COMMENTS_FROM_API: string;
  SET_SELECTEDTAG_RANGE: string;
  SET_MOBILESIDEBAR_VISIBILITY: string;
};

//TODO: convert into route as key
const dashboardBaseURL = '/:accountId/:platformBotId/dashboard';
const broadcastsBaseURL = '/:accountId/:platformBotId/broadcasts';
const templatesBaseURL = '/:accountId/:platformBotId/templates';
const audienceBaseURL = '/:accountId/:platformBotId/audience';
const adminToolBaseURL = '/adminTools';

export const pathsDontNeedAccountAccountSelection = {
  adminTools: adminToolBaseURL,
  smsDeployment: `${adminToolBaseURL}/smsDeployment`,
  accountCreation: `${adminToolBaseURL}/accountCreation`,
  vanityNumber: `${adminToolBaseURL}/vanityNumber`,
  chooseAccount: '/chooseAccount',
  twitterCallBack: '/twitterCallback',
  requestPageAccess: `/requestPageAccess`,
};

export const pathsDontNeedAccountAccountSelectionAsArray = Object.values(pathsDontNeedAccountAccountSelection);

export const deeplyLinkedRoutes = {
  ...pathsDontNeedAccountAccountSelection,
  landing: '/:accountId',
  dashboard: dashboardBaseURL,
  dashboardCommentsMentions: `${dashboardBaseURL}/:moderationMode(${dashboardTabs[dashboardTabConstants.COMMENTS].subURL}|${dashboardTabs[dashboardTabConstants.MENTIONS].subURL})`,
  dashboardCommentsMentionsModeration: `${dashboardBaseURL}/:moderationMode(${dashboardTabs[dashboardTabConstants.COMMENTS].subURL}|${
    dashboardTabs[dashboardTabConstants.MENTIONS].subURL
  })/moderation`,
  dashboardCommentsMentionsEngagement: `${dashboardBaseURL}/:moderationMode(${dashboardTabs[dashboardTabConstants.COMMENTS].subURL}|${
    dashboardTabs[dashboardTabConstants.MENTIONS].subURL
  })/engagements`,
  comments: `/:accountId/:platformBotId/messages/comments`,
  commentsModeration: `/:accountId/:platformBotId/messages/comments/:commentsMode(${CommentsMode[CommentsModeConstants.MODERATION].subURL}|${
    CommentsMode[CommentsModeConstants.ENGAGEMENTS].subURL
  })`,
  mentions: `/:accountId/:platformBotId/messages/mentions`,
  mentionsModeration: `/:accountId/:platformBotId/messages/mentions/:commentsMode(${CommentsMode[CommentsModeConstants.MODERATION].subURL}|${
    CommentsMode[CommentsModeConstants.ENGAGEMENTS].subURL
  })`,
  inbox: `/:accountId/:platformBotId/inbox`,
  keywordGroupsStandard: '/:accountId/:platformBotId/keywordGroups/standard',
  keywordGroupsCustom: '/:accountId/:platformBotId/keywordGroups/custom',
  editKeywordGroup: '/:accountId/:platformBotId/keywordGroups/editKeywordGroup/:keywordGroupId',
  createKeywordGroup: '/:accountId/:platformBotId/keywordGroups/createKeywordGroup',
  tagsStandard: '/:accountId/:platformBotId/tags/standard',
  tagsCustom: '/:accountId/:platformBotId/tags/custom',
  editTag: '/:accountId/:platformBotId/tags/editTag/:taggingCampaignId',
  createTag: '/:accountId/:platformBotId/tags/createTag',
  // templatesStandard: '/:accountId/:platformBotId/templates/standard',
  // templatesCustom: '/:accountId/:platformBotId/templates/custom',
  templates: templatesBaseURL,
  templatesActive: `${templatesBaseURL}/active`,
  templatesArchived: `${templatesBaseURL}/archived`,
  editTemplate: `${templatesBaseURL}/editTemplate/:templateId`,
  cloneTemplate: `${templatesBaseURL}/cloneTemplate/:templateId`,
  viewTemplate: `${templatesBaseURL}/viewTemplate/:templateId`,
  createTemplate: `${templatesBaseURL}/createTemplate`,
  automationStandard: '/:accountId/:platformBotId/(commentModeration|smartReplies|brandLift)/standard',
  automationCustom: '/:accountId/:platformBotId/(commentModeration|smartReplies|brandLift)/custom',
  userProfilePages: '/:accountId/:platformBotId/(profile|accountSettings|manageUsers|socialMediaAccounts|pageHealth)',
  createUser: '/:accountId/:platformBotId/manageUsers/createUser',
  editUser: '/:accountId/:platformBotId/manageUsers/editUser/:userId',
  campaignStandard: '/:accountId/:platformBotId/(smartReplies|commentModeration|brandLift)/standard',
  campaignCustom: '/:accountId/:platformBotId/(smartReplies|commentModeration|brandLift)/custom',
  createCampaign: '/:accountId/:platformBotId/(smartReplies|commentModeration|brandLift)/createCampaign',
  editCampaign: '/:accountId/:platformBotId/(smartReplies|commentModeration|brandLift)/editCampaign/:campaignId',
  reports: '/:accountId/:platformBotId/reports',
  reportViewer: '/:accountId/:platformBotId/report-viewer',
  broadcasts: broadcastsBaseURL,
  broadcastsRecent: '/:accountId/:platformBotId/broadcasts/recent',
  broadcastsDraft: `${broadcastsBaseURL}/${broadcastsTabsRoutes[broadcastConstants.DRAFT]}`,
  broadcastsScheduled: `${broadcastsBaseURL}/${broadcastsTabsRoutes[broadcastConstants.SCHEDULED]}`,
  broadcastsRepeat: `${broadcastsBaseURL}/${broadcastsTabsRoutes[broadcastConstants.REPEAT]}`,
  broadcastsCompleted: `${broadcastsBaseURL}/${broadcastsTabsRoutes[broadcastConstants.COMPLETED]}`,
  broadcastsTemplates: `${broadcastsBaseURL}/${broadcastsTabsRoutes[broadcastConstants.TEMPLATES]}`,
  editBroadcast: `${broadcastsBaseURL}/editBroadcast/:broadcastId`,
  viewBroadcast: `${broadcastsBaseURL}/viewBroadcast/:broadcastId`,
  cloneBroadcast: `${broadcastsBaseURL}/cloneBroadcast/:broadcastId`,
  viewBroadcastDetails: `${broadcastsBaseURL}/viewBroadcast/:broadcastId/details`,
  viewBroadcastEngagement: `${broadcastsBaseURL}/viewBroadcast/:broadcastId/engagement`,
  viewBroadcastAuditDetails: `${broadcastsBaseURL}/viewBroadcast/:broadcastId/auditDetails`,
  createBroadcast: `${broadcastsBaseURL}/createBroadcast`,
  segments: '/:accountId/:platformBotId/segments',
  segmentsTags: '/:accountId/:platformBotId/segments/cohorts',
  manageLabels: '/:accountId/:platformBotId/manageLabels',
  allSegmentsTab: '/:accountId/:platformBotId/segments/all',
  segmentsTab: '/:accountId/:platformBotId/segments/segments',
  segmentsIngestedTab: '/:accountId/:platformBotId/segments/ingested',
  segmentsUploadedTab: '/:accountId/:platformBotId/segments/uploaded',
  segmentsIngestList: '/:accountId/:platformBotId/segments/ingestList',
  createSegment: '/:accountId/:platformBotId/segments/createSegment',
  editSegment: `/:accountId/:platformBotId/segments/editSegment/:segmentId`,
  cloneSegment: `/:accountId/:platformBotId/segments/cloneSegment/:segmentId`,
  settings: '/:accountId/:platformBotId/settings',
  settingsUserDetails: '/:accountId/:platformBotId/settings/accountInfo',
  settingsSignUpMessages: '/:accountId/:platformBotId/settings/signUpMessages',
  settingsAdmin: '/:accountId/:platformBotId/settings/admin',
  settingsBotConfig: '/:accountId/:platformBotId/settings/botConfig',
  settingsAccountConfig: '/:accountId/:platformBotId/settings/accountConfig',
  home: '/:accountId/:platformBotId/home',
  chooseDeployPlatform: '/:accountId/chooseDeployPlatform',
  utility: '/:accountId/:platformBotId/utility',
  qrCode: '/:accountId/:platformBotId/utility/qrCode',
  shortURL: '/:accountId/:platformBotId/utility/shortUrl',
  mediaViewURL: '/:accountId/:platformBotId/utility/mediaViewUrl',
  mobileProfileSettings: '/:accountId/:platformBotId/profileSettings',
  resources: '/:accountId/:platformBotId/resources',
  audience: audienceBaseURL,
  audienceAnalysis: `${audienceBaseURL}/${AudienceTabs.ANALYSIS}`,
  audienceNewMembers: `${audienceBaseURL}/${AudienceTabs.NEWMEMBERS}`,
  audienceSegmentUsers: `${audienceBaseURL}/${AudienceTabs.SEGMENTUSERS}`,
  ingestListing: '/:accountId/:platformBotId/ingestList',
  ingestList: '/:accountId/:platformBotId/ingestList/ingest',
  sentimentAnalysis: '/:accountId/:platformBotId/messages/sentimentAnalysis',
};
export const routes = {
  ...deeplyLinkedRoutes,
};
export default PageUIStateConstants;
