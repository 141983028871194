export const clearLocalStorage = () => {
  localStorage.clear();
};

export const logOut = () => {
  clearLocalStorage();
  window.location.href = '/login';

  // window.setTimeout(() => {
  //   window.location.reload();
  // }, 300);
};
