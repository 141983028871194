import { platformLabels, platforms } from '../../common/entities/entities.constants';
import { actions } from '../../config/platform-action-map';
import { dashboardTabConstants } from '../dashboard/constants';
import { actionsInBrandLift, actionsInCommentModeration, actionsInSmartReplies } from './config';
export const logicalOperatorTypes = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
];
export const automationSubTabs = {
  COMMENT_MODERATION: 'commentModeration',
  BRAND_LIFT: 'brandLift',
  SMART_REPLIES: 'smartReplies',
};
export const automationTabPanes = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
};
export const automationTabRoutes = { [automationTabPanes.STANDARD]: automationTabPanes.STANDARD, [automationTabPanes.CUSTOM]: automationTabPanes.CUSTOM };
export const automationSubtabHeading = {
  [automationSubTabs.COMMENT_MODERATION]: 'Moderation',
  [automationSubTabs.BRAND_LIFT]: 'Brand Lift',
  [automationSubTabs.SMART_REPLIES]: 'Smart Replies',
};
export const conditionOperators = [
  { value: 'equal', label: 'equal to' },
  { value: 'not equal', label: 'not equal to' },
  { value: 'greater than', label: 'greater than' },
  { value: 'less than', label: 'less than' },
  { value: 'substring', label: 'contains' },
  { value: 'not substring', label: 'not contains' },
  { value: 'exist', label: 'exists' },
  { value: 'not exist', label: 'not exists' },
  { value: 'regex', label: 'matches' },
  { value: 'not regex', label: 'not matches' },
  { value: 'word', label: 'has word' },
  { value: 'not word', label: 'not has word' },
  { value: 'expression', label: 'expression' },
  { value: 'not expression', label: 'not expression' },
];
export const campaignTypes = {
  STANDARD: 'standard',
  ADMIN: 'admin',
  TAGGING: 'tagging',
};
export const botPlatforms = {
  MESSENGER: platforms.FACEBOOK,
  TWILIO_SMS: platforms.TWILIO_SMS,
  INSTAGRAM: platforms.INSTAGRAM,
  AMPLIFY_CHAT: 'amplify_chat',
  INTERNAL: 'internal',
  SKYPE: 'skype',
  TELEGRAM: 'telegram',
  KIKA: 'kika',
  JIO: 'jio',
  RBM: 'rbm',
  MESSENGER_PEOPLE: 'messenger_people',
  ADLINGO: 'adlingo',
  GBM: 'gbm',
  ACP: 'internal',
  TWITTER: platforms.TWITTER,
  TWILIO_WA: platforms.TWILIO_WA,
  WHATSAPP: platforms.WHATSAPP,
  KARIX_WA: 'karix_wa',
  CM_WHATSAPP: platforms.CM_WHATSAPP,
};
// Private Reply Instagram actions.
export const PrivateReplyActionTypeInstagram = {
  PUBLIC: 'public',
  DELETE: 'delete',
  HIDE: 'hide',
};
export const PrivateReplyActionTypeFacebook = {
  PRIVATE: 'privateReply',
};

// Private reply actions
export const PrivateReplyActionType = {
  TEXT: 'text',
  MODULE: 'rich',
  LIKE: 'like',
  BLOCK: 'block',
  TAG: 'tag',
  MARKASREVIEWED: 'review',
  ASSIGN: 'assign',
  ...PrivateReplyActionTypeInstagram,
  ...PrivateReplyActionTypeFacebook,
};
export const LabelValuePair = {
  [PrivateReplyActionType.LIKE]: 'Like',
  [PrivateReplyActionType.BLOCK]: 'Ban User',
  [PrivateReplyActionType.PUBLIC]: 'Public Reply',
  [PrivateReplyActionType.DELETE]: 'Delete',
  [PrivateReplyActionType.HIDE]: 'Hide',
  [PrivateReplyActionType.PRIVATE]: 'Private Reply',
  [PrivateReplyActionType.MARKASREVIEWED]: 'Review',
  [PrivateReplyActionType.ASSIGN]: 'Assign',
};
export const ActionLabelValuePair = {
  [actions.LIKE]: 'Like',
  [actions.BLOCK]: 'Ban User',
  [actions.HIDE]: 'Hide',
  [actions.PRIVATE_REPLY]: 'Private Reply',
  [actions.PUBLIC_REPLY]: 'Public Reply',
  [actions.REVIEW]: 'Review',
  [actions.DELETE]: 'Delete',
  [actions.ASSIGN]: 'Assign',
};

export const ActionOptionsAsPerPlatform = {
  [platforms.FACEBOOK]: {
    actions: [
      ...actionsInBrandLift.map((action: string) => ({
        label: LabelValuePair[action],
        value: action,
      })),
      ...actionsInSmartReplies.map((action: string) => ({
        label: LabelValuePair[action],
        value: action,
      })),
      ...actionsInCommentModeration.map((action: string) => ({
        label: LabelValuePair[action],
        value: action,
      })),
      { label: 'No Action', value: 'noaction' },
    ],
  },
  [platforms.INSTAGRAM]: {
    actions: [
      ...[
        { label: LabelValuePair[PrivateReplyActionType.PUBLIC], value: PrivateReplyActionType.PUBLIC },
        { label: LabelValuePair[PrivateReplyActionType.PRIVATE], value: PrivateReplyActionType.PRIVATE },
      ],
      ...[
        { label: LabelValuePair[PrivateReplyActionType.HIDE], value: PrivateReplyActionType.HIDE },
        { label: LabelValuePair[PrivateReplyActionType.DELETE], value: PrivateReplyActionType.DELETE },
      ],
      { label: 'No Action', value: 'noaction' },
    ],
  },
  [platforms.TWITTER]: {
    actions: [
      ...[
        { label: LabelValuePair[PrivateReplyActionType.LIKE], value: PrivateReplyActionType.LIKE },
        { label: LabelValuePair[PrivateReplyActionType.PUBLIC], value: PrivateReplyActionType.PUBLIC },
        { label: LabelValuePair[PrivateReplyActionType.HIDE], value: PrivateReplyActionType.HIDE },
        { label: LabelValuePair[PrivateReplyActionType.BLOCK], value: PrivateReplyActionType.BLOCK },
      ],
      { label: 'No Action', value: 'noaction' },
    ],
  },
};
export const ActionOptionsAsPerTypeAndPlatform = {
  [campaignTypes.STANDARD]: {
    [automationSubTabs.BRAND_LIFT]: actionsInBrandLift.map((action: string) => {
      return {
        label: LabelValuePair[action],
        value: action,
      };
    }),
    [automationSubTabs.SMART_REPLIES]: actionsInSmartReplies.map((action: string) => {
      return {
        label: LabelValuePair[action],
        value: action,
      };
    }),

    [automationSubTabs.COMMENT_MODERATION]: actionsInCommentModeration.map((action: string) => {
      return {
        label: LabelValuePair[action],
        value: action,
      };
    }),
  },
};

export const GROWCONST = {
  DEFAULTACTIVETAB: 'active',
  DEFAULTPOSTTYPE: 'all',
  MAXTAGSFORINPUT: 34,
  MAXTAGSFOROVERLAY: 600,
  POSTIDVALIDATIONERROR: `Post id must be in numeric format. It shouldn't be combined with page id.`,
};
export const filterTypeValues = {
  NONE: 'none',
};

export const subTabToHeader = {
  [automationSubTabs.BRAND_LIFT]: 'BRAND LIFT',
  [automationSubTabs.COMMENT_MODERATION]: ' COMMENT MODERATION',
  [automationSubTabs.SMART_REPLIES]: 'SMART REPLIES',
};

export const analyticsKeys = {
  COMMENTS_HIDDEN: 'commentHidden',
  USER_BLOCKED: 'userBlocked',
  COMMENTS_RECEIVED: 'commentsReceived',
  COMMENTS_REPLIED: 'commentPublicReplied',
  COMMENTS_REPLIED_ENGAGED: 'commentsRepliesEngaged',
  COMMENTS_LIKED: 'commentLiked',
  COMMENTS_DELETED: 'commentDeleted',
};

export const actionToAnalyticsMap = {
  [PrivateReplyActionType.BLOCK]: { key: analyticsKeys.USER_BLOCKED, label: 'Users Banned' },
  [PrivateReplyActionType.DELETE]: { key: analyticsKeys.COMMENTS_DELETED, label: 'Comments Deleted' },
  [PrivateReplyActionType.LIKE]: { key: analyticsKeys.COMMENTS_LIKED, label: 'Comments Liked' },
  [PrivateReplyActionType.PUBLIC]: { key: analyticsKeys.COMMENTS_REPLIED, label: 'Comments Replied' },
  [PrivateReplyActionType.HIDE]: { key: analyticsKeys.COMMENTS_HIDDEN, label: 'Comments Hidden' },
};
export const TagsPriorityAsPerAction = {
  [PrivateReplyActionType.LIKE]: 6,
  [PrivateReplyActionType.BLOCK]: 2,
  [PrivateReplyActionType.PUBLIC]: 8,
  [PrivateReplyActionType.DELETE]: 2,
  [PrivateReplyActionType.HIDE]: 3,
};
export const ALLCONSTANT = 'all';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ALL_SEGMENTS = 'all-segments';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ALL_TAGS = 'all-tags';

export const createForOptions = [
  { label: 'Comments', value: dashboardTabConstants.COMMENTS },
  { label: 'Comment Mentions', value: dashboardTabConstants.MENTIONS },
  { label: 'Post Mentions', value: dashboardTabConstants.POST_MENTIONS },
];

export const commentTypeAPI = {
  COMMENTS: 'comments',
  MENTIONS: 'mentions',
  ALL: 'all',
};
export const ConfirmationPopovertitle = 'Are you sure you like to mark this comment as reviewed?';

export const botPlatformApiToUIMapper = {
  ...platformLabels,
  [botPlatforms.INTERNAL]: 'Amplify Chat Plugin',
  [botPlatforms.INSTAGRAM]: 'Instagram',
  [botPlatforms.MESSENGER]: 'Messenger',
  [botPlatforms.TELEGRAM]: 'Telegram',
  [botPlatforms.SKYPE]: 'Skype',
  [botPlatforms.KIKA]: 'Kika',
  [botPlatforms.JIO]: 'Jio',
  [botPlatforms.RBM]: 'RBM',
  [botPlatforms.MESSENGER_PEOPLE]: 'WhatsApp - MessengerPeople',
  [botPlatforms.ADLINGO]: 'Adlingo',
  [botPlatforms.GBM]: 'GBM',
  [botPlatforms.TWILIO_WA]: 'WhatsApp - Twilio',
  [botPlatforms.TWILIO_SMS]: 'SMS',
  [botPlatforms.AMPLIFY_CHAT]: 'Amplify Chat',
  [botPlatforms.TWITTER]: 'Twitter',
};

export const actionTypeToText = {
  [PrivateReplyActionType.MODULE]: 'Rich Private Reply',
};
