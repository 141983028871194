import { Search, LocationState } from 'history';
import * as queryString from 'query-string';
import { pageFilterKeys, pageKeysForFilter } from '../../pages/dashboard/constants';
import { History } from '../../types';

export const setTimeFilterToStorage = (pageName: string, filterFor: string, filterValue: string) => {
  const retrievedValue = localStorage.getItem(pageName);
  const currentObj = retrievedValue ? JSON.parse(retrievedValue) : {};

  localStorage.setItem(pageName, JSON.stringify({ ...currentObj, [filterFor]: filterValue }));
};

export const getTimeFilterFromStorage = (filterFor: string) => {
  const retrievedValue = localStorage.getItem(filterFor);
  const currentObj = retrievedValue ? JSON.parse(retrievedValue) : {};
  return currentObj;
};

const pageToFilterMap: { [key: string]: { [key: string]: any } } = {
  dashboard: pageFilterKeys,
  [pageKeysForFilter.COMMENTS]: pageFilterKeys,
  [pageKeysForFilter.INBOX]: pageFilterKeys,
  [pageKeysForFilter.BROADCASTS]: pageFilterKeys,
  [pageKeysForFilter.PROFILE_SETTINGS]: pageFilterKeys,
  [pageKeysForFilter.ACCOUNT_SETTINGS]: pageFilterKeys,
  [pageKeysForFilter.SOCIAL_MEDIA_ACCOUNTS]: pageFilterKeys,
  [pageKeysForFilter.HOME]: pageFilterKeys,
  [pageKeysForFilter.RESOURCES]: pageFilterKeys,
  [pageKeysForFilter.AUDIENCE]: pageFilterKeys,
  [pageKeysForFilter.SENTIMENT_ANALYSIS]: pageFilterKeys,
  [pageKeysForFilter.REPORTS]: pageFilterKeys,
};

export const getFiltersForPageFromURL = (pageName: string) => {
  const filters: { [key: string]: any } = {};
  const pageFilterKeys = pageToFilterMap[pageName];

  const location = window.location;
  const params = queryString.parse(location.search);
  Object.values(pageFilterKeys).forEach((filterKey: string) => {
    if (params[filterKey]) filters[filterKey] = params[filterKey];
  });
  return filters;
};

// export const setQueryParams = (searchParam: string, value: string | number, location: Location, history: History) => {
//   const queryParam = queryString.parse(location.search);
//   const newQueryParam = {
//     ...queryParam,
//     [searchParam]: value,
//   };

//   history.push({ pathname: location.pathname, search: queryString.stringify(newQueryParam) });
// };

export const getUpdatedSearchParamFromFiltersInObject = (object: { [key: string]: any }, search: Search) => {
  const queryParam = queryString.parse(search);
  const newQueryParam = {
    ...queryParam,
    ...object,
  };
  return queryString.stringify(newQueryParam);
};

export const setQueryParamsFromObject = (object: { [key: string]: any }, pathName: string, search: Search, history: History, state?: LocationState) => {
  const updatedSearchParam = getUpdatedSearchParamFromFiltersInObject(object, search);

  history.replace({ pathname: pathName, search: updatedSearchParam, state });
};

export const decodeString = (str: any) => {
  try {
    return JSON.parse(decodeURIComponent(atob(str)));
  } catch {
    return {};
  }
};

export const encodeValue = (value: any) => {
  try {
    return btoa(encodeURIComponent(JSON.stringify(value)));
  } catch {
    return '';
  }
};
