import { createAction } from '@reduxjs/toolkit';
import constants from './constants';
import { UsersUiType, UsersSegregated, botUserUIType } from '../type';
import { PageHealthDetails } from '../components/page-health/page-health.types';
type UsersPayloadType = {
  usersToUI: UsersUiType;
  usersSegregated: UsersSegregated;
};

export const storeUsers = createAction<UsersPayloadType>(constants.STORE_USERS);
export const storeBotUsers = createAction(constants.STORE_BOT_USERS, (botId: string, botUsers: botUserUIType[]) => ({
  payload: { botId, botUsers },
}));
export const deleteUser = createAction<string>(constants.DELETE_USER);
// export const setRoles = createAction<string>(constants.DELETE_USER);
// export const deleteUser = createAction<string>(constants.DELETE_USER);
export const setUserActiveTabPane = createAction<string>(constants.SET_USERS_ACTIVE_TAB_PANE);
export const setPlatformBotPermissions = createAction(constants.SET_PLATFORMBOT_PERMISSIONS, (platformBotId: string, permissions: string[] | undefined) => ({
  payload: { platformBotId, permissions },
}));
export const setPageHealthDetails = createAction<PageHealthDetails | undefined>(constants.SET_PAGE_HEALTH_DETAILS);
export const setPageHealthDetailsLoading = createAction<boolean>(constants.SET_PAGE_HEALTH_DETAILS_LOADING);
