import { pathToRegexp } from 'path-to-regexp';
import { capitalize } from 'lodash';

export const getRouterTitle = (pathName: string, routeToPageTitle: any) => {
  let [routerTitle] = pathName.split('/').slice(-1);
  routerTitle = capitalize(routerTitle);
  const routePaths = Object.keys(routeToPageTitle);
  routePaths.some((path: string) => {
    const regexp = pathToRegexp(path);
    const matches = regexp.exec(pathName);
    if (matches) {
      routerTitle = routeToPageTitle[path];
      return true;
    }
    return false;
  });
  return routerTitle;
};

export const setDocumentTitle = (routerTitle: string) => {
  document.title = routerTitle;
};
