
export const templateTaggingGC = {
  // name: 'Tag Disgust',
  type: 'tagging',
  respondToIndirectComments: true,
  ignoreOtherUserCaps: true,
  replyToUserType: 'all',
  // platformBotId: '100549038442280',
  sentiments: ['any'],
  sendDelay: 0,
  postType: 'all',
  paused: true,
  // actions: [{ type: 'tag', replyMessage: 'Hide: Disgust' }],
  // platform: 'facebook',
  priority: 9,
  // conditions: {
  //   logicalOperator: 'and',
  //   childConditions: [
  //     {
  //       logicalOperator: 'and',
  //       ifStatements: [{ variable: { name: 'Disgust', type: 'entity', macro: '{{message.nlp.entities.Disgust.0.value}}' }, condition: 'exist', value: '' }],
  //     },
  //   ],
  // },
};
