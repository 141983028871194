import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetStateOnPageChange } from '../../../../common/entities/actions';
import { setSelectedPlatformBot } from '../../../../common/page-ui-state/actions';
import { userLevelPermissions } from '../../../../common/role-level-permission/hooks';
import { useCurrentAccountId, useCurrentPlatformBotObj, useResetFilters } from '../../../../hooks';
import { RootState } from '../../../../types';
import { storeBotProfile } from '../../../settings/actions';
export { useAccount } from './account-selector-hooks';
export const useSelectedPage = () => {
  const selectedPage = useSelector((state: RootState) => state.pageUIState?.selectedPlatformBot) || '';
  const permissionsRes = useSelector((state: RootState) => state.userPermissions.RoleLevelPermissions);
  const accountId = useCurrentAccountId();
  const bot = useCurrentPlatformBotObj();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { resetFilters } = useResetFilters();
  const setSelectedPage = (platformBotId: string) => {
    resetFilters();
    if (pathname.includes('inbox')) {
      history.replace({ search: undefined }); //for now  we are just clearing search for inbox page
    }
    dispatch(setSelectedPlatformBot(platformBotId));
    dispatch(storeBotProfile({}));
    userLevelPermissions(dispatch, accountId || '', bot?.botId || '', permissionsRes);
    // dispatch(setTemplatesLoaded(false));
    dispatch(resetStateOnPageChange());
  };

  return { selectedPage, setSelectedPage };
};
