// import { defaultSMSdeployStatus } from './../data';
import { storeAdminToolStatus, updateIdsForBackLink } from './../actions/index';
import { AdminToolState } from './../type';
import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
// import { automationSubTabs } from '../constants';


export const initialState: AdminToolState = {
  adminToolStatus: {},
  currentAccountIdAndPlatformBotId: {}
};

const adminToolReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeAdminToolStatus, (state: typeof initialState, action: ReturnType<typeof storeAdminToolStatus>) => {
      const { payload } = action;
      const { featureName, step, ...rest } = payload;

      if (step) {
        state.adminToolStatus = {
          ...state.adminToolStatus,
          [featureName]: {
            ...state.adminToolStatus[featureName],
            [step]: rest
          }
        };
      } else {
        state.adminToolStatus = {
          ...state.adminToolStatus,
          [featureName]: rest
        };
      }
    })
    .addCase(updateIdsForBackLink, (state: typeof initialState, action: ReturnType<typeof updateIdsForBackLink>) => {
      const { payload } = action;

      state.currentAccountIdAndPlatformBotId = {
        ...state.currentAccountIdAndPlatformBotId,
        ...payload
      };

    });
});

export default adminToolReducer;
