import { AxiosError } from 'axios';
import { post } from '../../../common/api-calls/api-client';
import { checkBlackListError, errorReportMailBody, showErrorModal } from '../../../common/utils';
import { showMessage } from '../../../pages/common/toast-notification';
import { SuccessText } from '../../../pages/common/toast-notification/toast-notification.constants';
import { EmailType, slackChannels } from '../../../pages/constants';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { awsLogUrl } from '../../api-calls/config';
import { getStartDateEndDateWithDayInterval } from '../../utils/time-zones';
// import { setPageUIState } from '../../page-ui-state/actions';

export const sendReportErrorEmail = (accountId: string, errorMessage: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const payload = {
      subject: `ACM Support: Error occured on ${accountId}`,
      body: errorMessage,
      toEmailType: EmailType.CLIQZ_ALERTS,
      accountId,
    };
    try {
      response = await post(`dashboardUsers/sendEmail`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      // dispatch(setPageUIState({ key: 'error', value: null }));
      showMessage(`Error report sent ${SuccessText}`);
    }
  };
};

export const sendApiErrorEmailAndSlack = (errorMessage: string, error: AxiosError, useEmail: boolean = false): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const user = state.auth?.user;
    const accountId = state.entities.account?.id;
    let errorMessageNew = errorMessage;
    const blockedErrorList = state.platformActionConfig?.genericSettings?.blackListErrors;
    const isBlackListedError = blockedErrorList && blockedErrorList.length > 0 && checkBlackListError(errorMessageNew, blockedErrorList);
    if (isBlackListedError) {
      return;
    }
    if (!errorMessage) {
      errorMessageNew = `Status Code: ${error.response?.status}`;
    }
    const errorSlackBody = user && errorReportSlackBody(user.id, user.email, errorMessageNew, error?.config.url || '', error?.response?.headers?.requestid);
    const subject = `ACM Support: Error occurred on account: ${accountId}`;
    const payload: any = {
      slack: {
        subject,
        body: errorSlackBody,
        slackChannel: slackChannels.CLIQZ_ALERTS,
      },
    };

    if (useEmail) {
      payload.mail = {
        subject,
        body: user && errorReportMailBody(user.id, user.email, error?.config.url || '', errorMessageNew, error?.response?.headers?.requestid, error?.response?.config?.data),
        accountId,
      };
    }

    const url = `dashboardUsers/sendNotification`;
    let response;
    try {
      response = accountId && (await post(url, payload, state.auth?.authToken as string));
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      // dispatch(setPageUIState({ key: 'error', value: null }));
      showMessage(`Error report sent ${SuccessText}`);
    }
  };
};

export const errorReportSlackBody = (userId: string, Email: string, errorMessage: string, endPoint: string, requestId?: string) => {
  const slackBody = `*Error Message :* ${errorMessage} \n * Logged In User :* ${Email} (${userId}  \n *End Point :* ${endPoint} ${
    requestId ? ` \n * Request Id :* ${requestId}\n * Log URL :* ${getAwsLogUrl(requestId.replaceAll('-', '').trim())}` : ''
  }`;

  return slackBody;
};

export const getAwsLogUrl = (requestId?: string) => {
  const datetimeDuration = getStartDateEndDateWithDayInterval();
  return `${awsLogUrl}${requestId}$26start$3D${datetimeDuration.startDate}$26end$3D${datetimeDuration.endDate}`;
};
