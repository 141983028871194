import { combineReducers } from '@reduxjs/toolkit';
import commentModerationReducer, { engagementsReducer } from '../components/comment-moderation/reducer';
import sentimentReducer from '../components/sentiments/reducer';
import TopicTrendsReducer from '../components/top-trends/reducer';
import { intentsReducer, entitiesReducer } from '../components/intents/reducer';

export default combineReducers({
  commentModeration: commentModerationReducer,
  engagements: engagementsReducer,
  sentiments: sentimentReducer,
  topicTrends: TopicTrendsReducer,
  intents: intentsReducer,
  entities: entitiesReducer,
});
