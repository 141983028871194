import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { deleteReportFromState, storeReports, updateReports } from '../actions';
import { ReportPageStateType } from '../types';

export const initialState: ReportPageStateType = {
  reports: {},
};

const TopicTrendsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeReports, (state: typeof initialState, action: ReturnType<typeof storeReports>) => {
      state.reports = action.payload;
    })
    .addCase(deleteReportFromState, (state: typeof initialState, action: ReturnType<typeof deleteReportFromState>) => {
      const reports = { ...state.reports };
      delete reports[action.payload];
      state.reports = reports;
    })
    .addCase(updateReports, (state: typeof initialState, action: ReturnType<typeof updateReports>) => {
      const reports = { ...action.payload, ...state.reports };
      state.reports = reports;
    });
});

export default TopicTrendsReducer;
