import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { setCurrentPermissions, setRoleLevelPermissionsResponse, setSystemLevelPermissions } from '../actions';
import { RoleLevelPermissions } from '../permission.types';

export const initialState: RoleLevelPermissions = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RoleLevelPermissions: {
    admin: { role: '' },
    account: {},
    rolePermissions: {},
  },
  currentPermissions: {},
  systemLevelPermissions: {},
};

const roleLevelPermissionReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(setRoleLevelPermissionsResponse, (state: typeof initialState, action: ReturnType<typeof setRoleLevelPermissionsResponse>) => {
      state.RoleLevelPermissions = action.payload;
    })
    .addCase(setCurrentPermissions, (state: typeof initialState, action: ReturnType<typeof setCurrentPermissions>) => {
      state.currentPermissions = action.payload;
    })
    .addCase(setSystemLevelPermissions, (state: typeof initialState, action: ReturnType<typeof setSystemLevelPermissions>) => {
      state.systemLevelPermissions = action.payload;
    });
});

export default roleLevelPermissionReducer;
