export const RoleLevelPermissionConstants: RoleLevelPermissionType = {
  PERMISSIONS_RESPONSE: 'PERMISSIONS_RESPONSE',
  ALL_PERMISSIONS: 'ALL_PERMISSIONS',
  SYSTEM_LEVEL_PERMISSION: 'SYSTEM_LEVEL_PERMISSION',
};
type RoleLevelPermissionType = {
  PERMISSIONS_RESPONSE: string;
  ALL_PERMISSIONS: string;
  SYSTEM_LEVEL_PERMISSION: string;
};
export const permissionEntities = {
  ACCOUNT: 'account',
  ADMINTOOLJOBS: 'adminToolJobs',
  BOT: 'bot',
  USER: 'user',
  BOTNLPDATA: 'botNlpData',
  PRIVATEREPLY: 'privateReply',
  PRIVATEREPLYTAG: 'privateReplyTag',
  PUBLICREPLYTEMPLATE: 'publicReplyTemplate',
  USERCOMMENT: 'userComment',
  INBOX_MESSAGE: 'messageInbox',
  BROADCAST: 'broadcast',
  SEGMENTS: 'segment',
  FLOW: 'flow',
  SESSION: 'session',
  UI_ACCESS: 'uiAccess',
  SYSTEM: 'system',
  USER_INGEST: 'userIngest',
};
export const permissionType = {
  CREATE_BOT: 'createBot',
  UPDATE_BOT: 'editBot',
  DEPLOY_BOT: 'deployBot',
  DEPLOY_BOT_TWITTER: 'deployBotTwitter',
  EDIT_ACCOUNT: 'editAccount',
  CREATE_BOT_NLP_DATA: 'createBotNlpData',
  EDIT_BOT_NLP_DATA: 'editBotNlpData',
  DELETE_BOT_NLP_DATA: 'deleteBotNlpData',
  CREATE_PRIVATE_REPLY: 'createPrivateReply',
  EDIT_PRIVATE_REPLY: 'editPrivateReply',
  DELETE_PRIVATE_REPLY: 'deletePrivateReply',
  VIEW_BOT_NLP_DATA: 'viewBotNlpData',
  VIEW_PRIVATE_REPLIES: 'viewPrivateReplies',
  VIEW_PUBLIC_REPLY_TEMPLATES: 'viewPublicReplyTemplates',
  CREATE_PUBLIC_REPLY_TEMPLATE: 'createPublicReplyTemplate',
  DELETE_PUBLIC_REPLY_TEMPLATE: 'deletePublicReplyTemplate',
  EDIT_PUBLIC_REPLY_TEMPLATE: 'editPublicReplyTemplate',
  VIEW_BROADCAST: 'viewBroadcasts',
  CREATE_BROADCAST: 'createBroadcast',
  DELETE_BROADCAST: 'deleteBroadcast',
  EDIT_BROADCAST: 'editBroadcast',
  VIEW_USER_COMMENTS: 'viewUserComments',
  TAKE_ACTION_ON_USER_COMMENT: 'takeActionOnUserComment',
  VIEW_ACM_REPORTS: 'viewAcmReports',
  CREATE_ADMIN_TOOL_REPORTS: 'createAdminToolReport',
  DELETE_ADMIN_TOOL_REPORT: 'deleteAdminToolReport',
  EDIT_PRIVATE_REPLY_TAG: 'editPrivateReplyTag',
  DELETE_PRIVATE_REPLY_TAG: 'deletePrivateReplyTag',
  READ_ADMIN_TOOL_JOBS: 'readAdminToolJobs',
  VIEW_MESSAGE_INBOX: 'viewMessageInboxes',
  EDIT_MESSAGE_INBOX: 'editMessageInbox',
  TAKE_ACTION_ON_MESSAGE_INBOX: 'takeActionOnMessageInbox',
  CREATE_SEGMENTS: 'createSegment',
  EDIT_SEGMENTS: 'editSegment',
  DELETE_SEGMENTS: 'deleteSegment',
  VIEW_SEGMENTS: 'viewSegments',
  READ_FLOW: 'readFlow',
  EDIT_LIVE_FLOW: 'editLiveFlow',
  READ_SESSION: 'readSession',
  SHOW_SESSION: 'showSession',
  VIEW_BM_PAGES: 'viewBMPages',
  REQUEST_BM_PAGE_ACCESS: 'requestBMPageAccess',
  ASSIGN_BM_SYSTEM_USER: 'assignBMSystemUser',
  removeBMSystemUser: 'removeBMSystemUser',
  CREATE_USER_INGEST: 'createUserIngest',
  VIEW_USER_INGESTS: 'viewUserIngests',
};
