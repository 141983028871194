import React from 'react';
import BotInfoPopover from '../../../user-profile-pages/components/social-media-accounts/components/bot-info-popover';
import PageSelectorAvatar from './page-selector-avatar';
import styles from './page-selector.module.scss';
import { Popover } from 'antd';
import socialStyles from '../../../user-profile-pages/components/social-media-accounts/social-media-accounts.module.scss';
import LabelWithHelpText from '../../../../common/components/label-with-helptext';
import { getInfoIcon } from '../../../../config/theme-config';

type FormatOptionLabelProps = {
  label: string;
  iconSize?: number | 'small' | 'large' | 'default';
  iconSrc?: string;
  shape?: 'circle' | 'square';
  fallBackImgSrc?: string;
  optionIsBot?: boolean;
  platform?: string;
  overlayIcon?: string;
  customTextForFallback?: string;
  isPopoverShow?: boolean;
  value?: string;
  botSelectorePage?: string;
};
/* eslint-disable max-len*/
export const FormatOptionLabel = (props: FormatOptionLabelProps) => {
  const { label, value, iconSize = 'small', iconSrc, shape = 'circle', fallBackImgSrc, customTextForFallback, isPopoverShow = false, botSelectorePage } = props;
  return (
    <div className={styles.optionsFormatter}>
      <PageSelectorAvatar
        {...{
          iconSize,
          iconSrc,
          shape,
          customTextForFallback,
          fallBackImgSrc,
          optionIsBot: props.optionIsBot,
          platform: props.platform,
          overlayIcon: props.overlayIcon,
          platformBotId: value,
          botSelectorePage: botSelectorePage ? botSelectorePage : '',
        }}
      >
        <div className={props.optionIsBot ? styles.labelContainer : styles.labelContainerPlatform}>
          <span className={styles.label}>{label}</span>
          {props.optionIsBot && (
            <Popover
              placement='bottomLeft'
              content={() => <BotInfoPopover platformBotId={value} />}
              trigger={isPopoverShow ? 'hover' : ''}
              overlayClassName={socialStyles.botActionsPopover}
            >
              <span>
                <LabelWithHelpText containerClassName='mr-6' placement='right' iconSrc={getInfoIcon()} />
              </span>
            </Popover>
          )}
        </div>
      </PageSelectorAvatar>
    </div>
  );
};
