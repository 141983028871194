export const tagsTabs: TagsTabs = {
  amplifyStandard: 'Amplify Standard',
  custom: 'Custom',
};
export const tagsTabsRoutes = {
  [tagsTabs.amplifyStandard]: 'standard',
  [tagsTabs.custom]: 'custom',
};
type TagsTabs = { amplifyStandard: string; custom: string };

const TagsDrawerConstants: TagsDrawerConstantsType = {
  SHOW_CREATE_RECOMMENDATION_BUTTON: 'SHOW_CREATE_RECOMMENDATION_BUTTON',
  CHANGE_DRAWER_TITLE: 'CHANGE_DRAWER_TITLE',
  CHANGE_CURRENT_TAB: 'CHANGE_CURRENT_TAB',
  SET_ROUTE: 'SET_ROUTE',
  STORE_BASE_PATH: 'STORE_BASE_PATH',
};
type TagsDrawerConstantsType = {
  SHOW_CREATE_RECOMMENDATION_BUTTON: string;
  CHANGE_DRAWER_TITLE: string;
  CHANGE_CURRENT_TAB: string;
  STORE_BASE_PATH: string;
  SET_ROUTE: string;
};
export default TagsDrawerConstants;
