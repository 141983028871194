const AuthConstants: AuthConstantsType = {
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_MFA_SETTINGS: 'SET_MFA_SETTINGS',
  SET_TEMP_AUTHDETAILS: 'SET_TEMP_AUTHDETAILS',
  CLEAR_TEMP_AUTHDETAILS: 'CLEAR_TEMP_AUTHDETAILS',
  STORE_USER_DETAILS: 'STORE_USER_DETAILS',
  STORE_USER_PERMISSIONS: 'STORE_USER_PERMISSIONS',
  STORE_USER_ROLES: 'STORE_USER_ROLES',
  LOG_OUT: 'LOG_OUT',
  SET_LOG_ACTIVITY_FLAG: 'SET_LOG_ACTIVITY_FLAG',
};
type AuthConstantsType = {
  SET_AUTH_TOKEN: string;
  SET_MFA_SETTINGS: string;
  SET_TEMP_AUTHDETAILS: string;
  CLEAR_TEMP_AUTHDETAILS: string;
  STORE_USER_DETAILS: string;
  STORE_USER_PERMISSIONS: string;
  STORE_USER_ROLES: string;
  LOG_OUT: string;
  SET_LOG_ACTIVITY_FLAG: string;
};
export default AuthConstants;

export const maxAttemptsAllowedForOtp = 3;
