import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  setCreateBroadcastName,
  setCurrentActiveTab,
  setDeletedBroadcastFetched,
  setFetchingMessageUsage,
  setNotificationLabels,
  setReplyContent,
  setTestBroadcastSent,
  setTestLink,
  storeAudiencedata,
  storeMessageUsage,
} from '../actions';
import { Audiencedata, MessageUsageUI } from '../types';

export const initialState: {
  messageUsage: MessageUsageUI;
  createBroadcastName: string;
  replyMessageContent: string;
  audiencedata: Audiencedata;
  currentActiveTab: string;
  deletedBroadcastFetched: boolean;
  fetchingMessageUsage: boolean;
  testBroadcastSent: boolean;
  testLink: string;
  notificationLabels?: { [key: string]: any };
} = {
  messageUsage: {
    usageArray: [],
    carrierFeesCount: 0,
  },
  createBroadcastName: '',
  replyMessageContent: '',
  currentActiveTab: '',
  audiencedata: {},
  deletedBroadcastFetched: false,
  fetchingMessageUsage: false,
  testBroadcastSent: false,
  testLink: '',
  notificationLabels: {},
};

const broadcastsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeMessageUsage, (state: typeof initialState, action: ReturnType<typeof storeMessageUsage>) => {
      state.messageUsage = action.payload;
    })
    .addCase(setFetchingMessageUsage, (state: typeof initialState, action: ReturnType<typeof setFetchingMessageUsage>) => {
      state.fetchingMessageUsage = action.payload;
    })
    .addCase(setTestBroadcastSent, (state: typeof initialState, action: ReturnType<typeof setTestBroadcastSent>) => {
      state.testBroadcastSent = action.payload;
    })
    .addCase(storeAudiencedata, (state: typeof initialState, action: ReturnType<typeof storeAudiencedata>) => {
      state.audiencedata = action.payload;
    })
    .addCase(setCurrentActiveTab, (state: typeof initialState, action: ReturnType<typeof setCurrentActiveTab>) => {
      state.currentActiveTab = action.payload;
    })
    .addCase(setDeletedBroadcastFetched, (state: typeof initialState, action: ReturnType<typeof setDeletedBroadcastFetched>) => {
      state.deletedBroadcastFetched = action.payload;
    })
    .addCase(setCreateBroadcastName, (state: typeof initialState, action: ReturnType<typeof setCreateBroadcastName>) => {
      state.createBroadcastName = action.payload;
    })
    .addCase(setReplyContent, (state: typeof initialState, action: ReturnType<typeof setReplyContent>) => {
      state.replyMessageContent = action.payload;
    })
    .addCase(setTestLink, (state: typeof initialState, action: ReturnType<typeof setTestLink>) => {
      state.testLink = action.payload;
    })
    .addCase(setNotificationLabels, (state: typeof initialState, action: ReturnType<typeof setNotificationLabels>) => {
      state.notificationLabels = action.payload;
    });
});

export default broadcastsReducer;
