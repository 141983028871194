import { deeplyLinkedRoutes } from '../common/page-ui-state/constants';
import { AmpLiteNavTabs, AmpLitePages } from '../pages/common/sidebar/constants';
import { ReactComponent as Inbox } from '../assets/svg/dashboard/inbox.svg';
import { ReactComponent as Broadcast } from '../assets/svg/broadcast/broadcast.svg';
import { mobileSubPages } from './types';
// import { Unionize } from '../types';

export const broadcastSubPagesForMobile = {
  HOME: {
    title: 'Broadcast',
    value: 'main',
  },
  CREATE: {
    title: 'Create Broadcast',
    value: 'createBroadcast',
  },
  VIEW_EDIT: {
    title: 'Broadcast title',
    value: 'viewEditBroadcast',
  },
};
export const profileSettingsPageForMobile = {
  HOME: {
    title: 'Profile Settings',
    value: 'main',
  },
};
export const accountSettingsPageForMobile = {
  HOME: {
    title: 'Account Settings',
    value: 'main',
  },
};
export const socialMediaAccountPageForMobile = {
  HOME: {
    title: 'Social Media Accounts',
    value: 'main',
  },
  LINK: {
    title: 'Link Social Profile',
    value: 'linkSoicalProfile',
  },
};
export const homePageForMobile = {
  HOME: {
    title: 'Home',
    value: 'main',
  },
};
export const inboxSubPagesForMobile = {
  HOME: {
    title: 'Inbox',
    value: 'main',
  },
  CONVERSATION: {
    title: 'Conversation',
    value: 'conversation',
  },
  USERDETAIL: {
    parentPage: 'index',
    title: 'User Details',
    value: 'userdetail',
  },
};

export const resourcePageForMobile = {
  HOME: {
    title: 'Resources',
    value: 'main',
  },
};

export const subPagesForMobile: { [key: string]: mobileSubPages } = {
  [AmpLitePages.BROADCASTS]: broadcastSubPagesForMobile,
  [AmpLitePages.INBOX]: inboxSubPagesForMobile,
};

export const mobileWidth = 600;

export const applicableTabsForMobile = [
  {
    route: deeplyLinkedRoutes.broadcasts,
    title: AmpLiteNavTabs.BROADCASTS,
    page: AmpLitePages.BROADCASTS,
    icon: Broadcast,
    subTabs: [],
    additionalInfo: {
      isBeta: true,
    },
  },
  {
    title: AmpLiteNavTabs.INBOX,
    route: `/:accountId/:platformBotId/${AmpLitePages.INBOX}`,
    page: AmpLitePages.INBOX,
    subTabs: [],
    icon: Inbox,
  },
];
