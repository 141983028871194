import { UserUIType } from '../type';
import { UserListingTabs, userRolesLite } from '../components/manage-users/constants';

export const getDefaultUser = (userRole: string, accountId: string, overriddenRoles: any) => {
  const defaultUser: UserUIType = {
    firstName: '',
    lastName: '',
    email: '',
    userRoles: [],
    liteRoleLabel: userRole,
  };

  if (userRole === getAdminRole(overriddenRoles)) {
    defaultUser.userRoles = [{ role: userRole, account: accountId }];
  }
  return defaultUser;
};

export const getRoleByTab = (tab: string) => {
  let role = userRolesLite.BOT_OWNER;
  let pageLabel = 'Team Member';
  switch (tab) {
    case UserListingTabs.accountOwners.value:
      role = userRolesLite.ACCOUNT_OWNER;
      pageLabel = 'Admin';
      break;
  }
  return { role, pageLabel };
};

export const getTabByRole = (role: string, overriddenRoles: any) => {
  let tab = UserListingTabs.accountOwners.value;
  switch (role) {
    case getTeamMemberRole(overriddenRoles):
      tab = UserListingTabs.pageOwners.value;
      break;
  }
  return tab;
};

export const getAccountOwnerRoleArray = (accountId: string, overriddenRoles: any) => {
  return [{ role: getAdminRole(overriddenRoles), account: accountId }];
};
export const getAdminRole = (overriddenRoles: any) => {
  return overriddenRoles?.admin?.role ? overriddenRoles?.admin?.role : userRolesLite.ACCOUNT_OWNER;
};
export const getTeamMemberRole = (overriddenRoles: any) => {
  return overriddenRoles?.teamMember?.role ? overriddenRoles?.teamMember?.role : userRolesLite.BOT_OWNER;
};
