const inboxActionConstants: InboxActionConstants = {
  STORE_INBOX_MESSAGES: 'STORE_INBOX_MESSAGES',
  STORE_CONVERSATION: 'STORE_CONVERSATION',
  UPDATE_CONVERSATION_MESSAGE: 'UPDATE_CONVERSATION_MESSAGE',
  STORE_SESSION_DATA: 'STORE_SESSION_DATA',
  RESET_INBOX_MESSAGES: 'RESET_INBOX_MESSAGES',
  SET_SELECTED_INBOX_MESSAGE: 'SET_SELECTED_INBOX_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  SET_FILTER_CHANGED: 'SET_FILTER_CHANGED',
  STORE_INBOX_AGGREGATION: 'STORE_INBOX_AGGREGATION',
  DECREMENT_AGGREAGTION: 'DECREMENT_AGGREAGTION',
  STORE_LABEL_TO_COLOR: 'STORE_LABEL_TO_COLOR',
  UPDATE_LABEL_TO_COLOR: 'UPDATE_LABEL_TO_COLOR',
  STORE_NLP_TAGS_FOR_FILTER: 'STORE_NLP_TAGS_FOR_FILTER',
  SELECT_FOR_BULK_ACTION: 'SELECT_FOR_BULK_ACTION',
  SET_INBOX_TAB: 'SET_INBOX_TAB',
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  RESET_MESSAGE_ERROR: 'RESET_MESSAGE_ERROR',
  SET_NEW_MESSAGE_ARRIVED_STATUS: 'SET_NEW_MESSAGE_ARRIVED_STATUS',
  STORE_BUFFER_CONVERSATION: 'STORE_BUFFER_CONVERSATION',
  SET_SUBSCRIBE_STATUS: 'SET_SUBSCRIBE_STATUS',
  REMOVE_INBOX_USER: 'REMOVE_INBOX_USER',
};
type InboxActionConstants = {
  STORE_INBOX_MESSAGES: string;
  STORE_CONVERSATION: string;
  UPDATE_CONVERSATION_MESSAGE: string;
  STORE_SESSION_DATA: string;
  RESET_INBOX_MESSAGES: string;
  SET_SELECTED_INBOX_MESSAGE: string;
  UPDATE_MESSAGE: string;
  SET_FILTER_CHANGED: string;
  STORE_INBOX_AGGREGATION: string;
  DECREMENT_AGGREAGTION: string;
  STORE_LABEL_TO_COLOR: string;
  UPDATE_LABEL_TO_COLOR: string;
  STORE_NLP_TAGS_FOR_FILTER: string;
  SELECT_FOR_BULK_ACTION: string;
  SET_INBOX_TAB: string;
  UPDATE_MESSAGES: string;
  RESET_MESSAGE_ERROR: string;
  SET_NEW_MESSAGE_ARRIVED_STATUS: string;
  STORE_BUFFER_CONVERSATION: string;
  SET_SUBSCRIBE_STATUS: string;
  REMOVE_INBOX_USER: string;
};
export default inboxActionConstants;
