import React, { ReactNode } from 'react';
import { Tooltip as TooltipAntd } from 'antd';
import styles from './tooltip.module.scss';
import { TooltipPlacement } from 'antd/lib/tooltip';
type TooltipProps = { text: ReactNode; placement?: TooltipPlacement; toolTipClass?: string; children: ReactNode; type?: 'error'; [key: string]: any; showToolTip?: boolean };

const AmplifyTooltip = ({ text, toolTipClass, type, children, placement = 'top', showToolTip = true, ...rest }: TooltipProps) => {
  return showToolTip ? (
    <TooltipAntd overlayClassName={toolTipClass || (type === 'error' ? styles.tooltipDanger : styles.tooltip)} placement={placement} title={text} color={'#fff'} {...rest}>
      {children}
    </TooltipAntd>
  ) : (
    <>{children}</>
  );
};

export default AmplifyTooltip;
