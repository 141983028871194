import { createAction } from '@reduxjs/toolkit';
import constants from './constants';
import { CampaignType, CampaignsSegregated } from '../types';
type StoreCampaignPayload = {
  campaigns: {
    [key: string]: CampaignType;
  };
  campaignSegregated?: CampaignsSegregated;
};

export const storeCampaigns = createAction<StoreCampaignPayload>(constants.STORE_CAMPAIGNS);
export const setActiveTabPane = createAction<string>(constants.SET_ACTIVE_TAB_PANE);
export const storeBasePath = createAction<string>(constants.STORE_BASE_PATH);
