import { notification } from 'antd';
import React from 'react';
import CloseIcon from '../icons/close';
import styles from './toast-notification.module.scss';
import { v4 as uuid } from 'uuid';

export const NotificationTypes = {
  NORMAL: 'normal',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const showMessage = (
  title: string,
  message: string = '',
  type: string = NotificationTypes.NORMAL,
  autoclose: boolean = true,
  onClose?: () => void,
  icon?: React.ReactNode,
  style?: React.CSSProperties | undefined,
) => {
  const key = uuid();
  notification.open({
    message: title,
    description: message,
    className: getClassName(type),
    placement: 'bottomLeft',
    closeIcon: <CloseIcon color={'#fff'} />,
    duration: autoclose ? 3 : 10,
    onClose,
    style,
    key,
    icon,
  });
  return key;
};
export const hideMessage = (key: string) => {
  notification.close(key);
};

const getClassName = (type: string) => {
  switch (type) {
    case NotificationTypes.ERROR:
      return styles.error;
    case NotificationTypes.WARNING:
      return styles.warning;
    case NotificationTypes.SUCCESS:
      return styles.success;
    default:
      return styles.normal;
  }
};
