const reportConstants: ReportConstantsType = {
  STORE_REPORTS: 'STORE_REPORTS',
  DELETE_REPORT: 'DELETE_REPORT',
  UPDATE_REPORTS: 'UPDATE_REPORTS',
};
type ReportConstantsType = {
  STORE_REPORTS: string;
  DELETE_REPORT: string;
  UPDATE_REPORTS: string;
};
export default reportConstants;
