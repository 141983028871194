/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Styles from './confirmation.modal.module.scss';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { useAccount } from '../../../pages/common/header/hooks';
import { useCurrentBot, useCurrentPlatformBotObj, useTimeZone } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { checkForConfirmationDialogBox, fetchBroadcastDateLocally, getBroadcastSegementDetails, getBroadcastTestDetails, getUpdatedBroadcastStartDate } from '../../utils';
import AmplifyTooltip from '../../../pages/common/tooltip';
import { ReactComponent as InfoIcon } from '../../../assets/svg/information.svg';
import { broadcastScheduleConst, notifBroadcastTestWarning, timezoneTypes, urlClickTrackWarning } from '../../../pages/broadcasts/constants';
import { useBroadcastPageContext, useSubscribedUserCountForSegment } from '../../../pages/broadcasts/hooks';
import { humanizeUserCount } from '../../../pages/broadcasts/utils';
import { platforms } from '../../entities/entities.constants';
import { getBroadcastMessagePreview } from '../../entities/actions/broadcasts.async-actions';
import { useIsMobile } from '../../../mobile/utils';
import { fetchTargetingUsers } from '../../entities/actions/broadcast';
import { broadcastMsgPreviewType } from '../../../pages/broadcasts/types';
import { renderMessagePreview } from '../../../pages/common/confirmation-modal/components';

const ConfirmationModalContext = createContext({
  showConfirmation: (_displayDetails: boolean = false, _currentBroadcastData?: any, _disabledClickTrackForUrlBroadcast?: boolean) => {
    return new Promise(() => {});
  },
});
type ConfirmationModalProps = {
  children?: any;
  value?: { warningMessage: string; childComponents?: React.ReactNode; broadcastDetails?: any; isBroadcastListing?: boolean };
};

/* eslint-disable max-lines-per-function */
const ConfirmationModalContextProvider = (props: ConfirmationModalProps) => {
  const { children, value } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showBroadcastDetails, setShowBroadcastDetails] = useState(false);
  const [currentBroadcast, setCurrentBroadcast] = useState<any>(null);
  const [targetUser, setTargetUser] = useState<any>(null);
  const [displayClickTrackWarning, setDisplayClickTrackWarning] = useState<boolean>(false);
  const [broadcastDateUpdatedAgain, setBroadcastDateUpdatedAgain] = useState(false);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const isConfirmationDialogBoxSupported = currentUser && (value?.broadcastDetails || checkForConfirmationDialogBox(currentUser));
  const { selectedAccount } = useAccount();
  const currPlatformObj = useCurrentPlatformBotObj();
  const currentBot: any = useCurrentBot();
  const showTestWarningFbIG = useMemo(
    () => [platforms.FACEBOOK, platforms.INSTAGRAM].includes(currPlatformObj?.platformAsInSystemSettings || '') && (showBroadcastDetails || value?.isBroadcastListing),
    [showBroadcastDetails, value?.isBroadcastListing, currPlatformObj],
  );

  const resolver = useRef<any>(null);
  const timeZone = useTimeZone();
  const dispatch = useDispatch();
  const [broadcastMsgPreview, setBroadcastMsgPreview] = useState<broadcastMsgPreviewType>();
  const isMobile = useIsMobile();
  const { setStartDate, startDate: startDateContext, scheduleType, usersCount: userCountContext, currentStep } = useBroadcastPageContext();
  const { advancedSegmentConditions, selectedSegmentIds }: any = useMemo(() => {
    return currentBroadcast && value?.isBroadcastListing ? getBroadcastSegementDetails(currentBroadcast) : {};
  }, [currentBroadcast]);
  const { broadcastTestDate, broadcastTestTime, relativeTestTime, testDateInBotTimeZone, broadcastTestedBy }: any = useMemo(
    () => (currentBroadcast?.id ? getBroadcastTestDetails(currentBroadcast, timeZone) : {}),
    [currentBroadcast?.id],
  );
  const notificationLabels = useSelector((state: RootState) => state.broadcastPage.notificationLabels);
  const subscribedUserCount = useSubscribedUserCountForSegment(
    selectedSegmentIds,
    currentBroadcast,
    !currentBroadcast || !value?.isBroadcastListing || (advancedSegmentConditions === undefined && selectedSegmentIds === undefined),
    advancedSegmentConditions,
    currPlatformObj?.platformAsInSystemSettings === platforms.TWILIO_SMS && selectedAccount?.config?.uiConfig?.[platforms.TWILIO_SMS]?.enableDefaultGlobalTargeting,
    notificationLabels,
  );

  const usersCount: any = useMemo(() => {
    if (value?.isBroadcastListing && currentBroadcast && subscribedUserCount?.isUpdated) {
      return subscribedUserCount;
    }
    return userCountContext;
  }, [userCountContext, subscribedUserCount?.count]);

  const startDate = useMemo(() => {
    if (value?.isBroadcastListing && currentBroadcast) {
      const { startDateFinal: startDateVal } = getUpdatedBroadcastStartDate(currentBroadcast.startDate, currentBroadcast?.timeZone, timeZone);
      return startDateVal;
    }
    return startDateContext;
  }, [currentBroadcast?.id, startDateContext]);
  const callbacks = useMemo(
    () => ({
      setStartDate,
    }),
    [setStartDate],
  );
  const broadcastDetails = useMemo(
    () =>
      currentBroadcast?.id && value?.isBroadcastListing ? currentBroadcast : { ...value?.broadcastDetails, scheduleType: scheduleType || value?.broadcastDetails?.scheduleType },
    [value?.broadcastDetails, currentBroadcast],
  );
  const isScheduledForLater = broadcastDetails?.scheduleType === broadcastScheduleConst.LATER.value;
  const broadcastScheduledInUserTimeZone = broadcastDetails?.timeZone === timezoneTypes.USER;

  const setStartDateFunc = (startDateVal: number, timezoneType: string = '') => {
    const currentDate = moment.tz(timeZone);
    const accountOffset = moment.tz(timeZone).utcOffset();
    let startDateFinal = timezoneType === timezoneTypes.USER ? moment(startDateVal).utcOffset(accountOffset, true) : moment(startDateVal).tz(timeZone);
    const broadcastDate = startDateFinal.utcOffset(accountOffset, true);

    if (broadcastDate <= currentDate) {
      startDateFinal = currentDate.add(2, 'minutes');
      callbacks.setStartDate?.(startDateFinal);
      if (isScheduledForLater) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (broadcastDetails && showBroadcastDetails) {
      if (showConfirmationModal) {
        const { timeZone: timezoneType } = broadcastDetails;
        const toSetStartDate = isScheduledForLater ? startDate : moment().tz(timeZone);
        const broadcastDateNeedsUpdation = setStartDateFunc(toSetStartDate.valueOf(), timezoneType);
        setBroadcastDateUpdatedAgain(broadcastDateNeedsUpdation);
      } else if (broadcastDateUpdatedAgain) {
        setBroadcastDateUpdatedAgain(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastDetails, showConfirmationModal, showBroadcastDetails]);

  useEffect(() => {
    if (
      showBroadcastDetails &&
      showConfirmationModal &&
      (!broadcastMsgPreview || broadcastDetails?.id !== broadcastMsgPreview?.broadcastId) &&
      currentBot?.targetedUsers?.[0] &&
      (currentStep === 2 || value?.isBroadcastListing)
    ) {
      const targetedUserForSorting: any[] = [...(currentBot?.targetedUsers || [])];
      const sortedTargetedUsers = targetedUserForSorting?.sort((a: { lastUpdatedDate: string }, b: { lastUpdatedDate: string }) =>
        moment(b.lastUpdatedDate).isAfter(a.lastUpdatedDate) ? 1 : -1,
      );
      const targetedTestUser = sortedTargetedUsers?.[0]?.targeting?.[0];
      dispatch(
        getBroadcastMessagePreview(targetedTestUser, broadcastDetails, (val: any) => {
          setTargetUser(targetedTestUser);
          setBroadcastMsgPreview({ ...val, broadcastId: broadcastDetails?.id });
        }),
      );
    }
  }, [showConfirmationModal, broadcastMsgPreview, currentBot?.targetedUsers, currentStep, showBroadcastDetails]);

  useEffect(() => {
    if (!broadcastDetails?.testBroadcastInfo?.testDate && broadcastMsgPreview) {
      setBroadcastMsgPreview(undefined);
    }
  }, [broadcastDetails?.testBroadcastInfo?.testDate]);

  useEffect(() => {
    if (showConfirmationModal && currentBot?.id && !currentBot?.targetedUsers && value?.isBroadcastListing) {
      // !targetingUserExists && fetchTargetingUserById(currentBot?.id, recentTargetingId);
      dispatch(fetchTargetingUsers(currentBot?.id));
    }
  }, [dispatch, showConfirmationModal, currentBot?.id]);

  const handleShow = (displayBroadcastDetails: boolean = false, currentBroadcastData?: any, disabledClickTrackForbroadcastHasUrl: boolean = false) => {
    if (!isConfirmationDialogBoxSupported && (!value?.isBroadcastListing || !currentBroadcastData)) {
      setShowConfirmationModal(false);
      return Promise.resolve(true);
    } else if (currentBroadcastData) {
      setCurrentBroadcast(currentBroadcastData);
    }
    setDisplayClickTrackWarning(disabledClickTrackForbroadcastHasUrl);
    setShowBroadcastDetails(displayBroadcastDetails);
    setShowConfirmationModal(true);
    return new Promise((resolve: any) => {
      resolver.current = resolve;
    });
  };
  const handleOk = () => {
    setCurrentBroadcast(null);
    resolver.current(true);
    setShowConfirmationModal(false);
  };
  const handleCancel = () => {
    setCurrentBroadcast(null);
    resolver.current(false);
    setShowConfirmationModal(false);
  };

  const getModalHeader = () => {
    const fansSent = !usersCount?.isUpdated ? '' : humanizeUserCount(usersCount?.count || 0);

    return (
      <div className={Styles.customModalHeader}>
        {`${isScheduledForLater ? `Scheduling Broadcast ${fansSent && 'for '}` : `Sending Broadcast ${fansSent && 'to '}`} ${fansSent}`}
        <Divider className={`mt-2 mb-3 ${Styles.lineDivider}`} />
        <div className={Styles.modalSubHeader}> You are viewing this broadcast from </div>
      </div>
    );
  };

  const renderDateDetails = (dateLocally: string, timeLocally: string, botTimeInTooltip: string | undefined, relativeTime: string) => {
    const dateDisplayed = (
      <div className='is-flex' style={botTimeInTooltip ? { cursor: 'pointer' } : {}}>
        <div> {dateLocally}</div>
        <div className={`${Styles['detail-value-seperator']} mx-4 `}>| </div>
        <div> {timeLocally}</div>
        <div className={`${Styles['detail-value-seperator']} mx-4 `}>| </div>
        <div> {relativeTime}</div>
      </div>
    );
    return <>{botTimeInTooltip ? <AmplifyTooltip text={botTimeInTooltip}>{dateDisplayed}</AmplifyTooltip> : dateDisplayed}</>;
  };

  const renderScheduleDate = () => {
    const { scheduledDateLocally, scheduledTimeLocally, localAndAccountTimesDiffer: addBotTimeTooltip = true, relativeTime }: any = fetchBroadcastDateLocally(startDate, timeZone);
    const startDateFormatted = startDate.tz(timeZone).format('DD MMM YYYY hh:mmA (z)');

    return renderDateDetails(scheduledDateLocally, scheduledTimeLocally, addBotTimeTooltip ? startDateFormatted : undefined, relativeTime);
  };

  const renderBroadcastDetails = () => {
    return (
      <>
        {broadcastDetails?.broadcastName && showConfirmationModal && showBroadcastDetails && (
          <div className={` px-2 ${Styles.confirmModalDetails}`}>
            <Divider className='my-5' />
            {showBroadcastDetails && (displayClickTrackWarning || showTestWarningFbIG) && (
              <div className={`${Styles.complexTargetInfo} mx-3`} style={{ color: '#FAAD14', textAlign: 'left' }}>
                {displayClickTrackWarning ? `${urlClickTrackWarning}` : `${notifBroadcastTestWarning}`}
              </div>
            )}
            <Row className={`${Styles.broadcastDetailRow}`}>
              <Col className={Styles['left-audit-detail']}>
                <div className={Styles['detail-label']}>Broadcast Name</div>
                <div className={`${Styles['detail-value']}`}> {broadcastDetails?.broadcastName}</div>
              </Col>
            </Row>
            <Row className={`${Styles.broadcastDetailRow}`}>
              <Col className={Styles['left-audit-detail']}>
                <div className={Styles['detail-label']}>
                  Scheduled For (Local)
                  {broadcastScheduledInUserTimeZone && (
                    <AmplifyTooltip text='scheduled in user timezone'>
                      <InfoIcon width={11} height={11} style={{ marginLeft: '2px', cursor: 'pointer' }} />
                    </AmplifyTooltip>
                  )}
                </div>
                <div className={`${Styles['detail-value']}`}>{renderScheduleDate()} </div>
              </Col>
            </Row>
            {broadcastDetails?.createdBy?.name && (
              <Row className={`${Styles.broadcastDetailRow}`}>
                <Col className={Styles['left-audit-detail']}>
                  <div className={Styles['detail-label']}>Created By</div>
                  <div className={`${Styles['detail-value']}`}> {broadcastDetails?.createdBy?.name}</div>
                </Col>
              </Row>
            )}
            {broadcastTestDate && (
              <Row className={`${Styles.broadcastDetailRow}`}>
                <Col className={Styles['left-audit-detail']}>
                  <div className={Styles['detail-label']}>Tested On (Local)</div>
                  <div className={`${Styles['detail-value']} is-flex`}>
                    {renderDateDetails(broadcastTestDate, broadcastTestTime, testDateInBotTimeZone ? testDateInBotTimeZone : undefined, relativeTestTime)}
                  </div>
                </Col>
              </Row>
            )}
            {broadcastTestedBy && (
              <Row className={`${Styles.broadcastDetailRow}`}>
                <Col className={Styles['left-audit-detail']}>
                  <div className={Styles['detail-label']}>Tested By</div>
                  <div className={`${Styles['detail-value']}`}> {broadcastTestedBy}</div>
                </Col>
              </Row>
            )}
            {broadcastMsgPreview && renderMessagePreview(broadcastMsgPreview, targetUser)}
            <div className={`${Styles.complexTargetInfo} pb-2`}>{`Note: ${
              !isScheduledForLater
                ? `The broadcast will be sent out in the next 2 minutes. It can be paused before then`
                : broadcastDateUpdatedAgain
                ? `The broadcast date and time are in the past. It's now adjusted to current time + 2 minutes. The broadcast can be paused before then`
                : 'The broadcast can be paused before the scheduled date'
            } to prevent it from being sent out.`}</div>
          </div>
        )}

        <div className={`is-justify-content-center ${broadcastDetails ? 'mt-0' : 'mt-6'}`}>{value && value.childComponents}</div>
      </>
    );
  };

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmation: handleShow }}>
      {children}
      <Modal
        width={broadcastMsgPreview && showBroadcastDetails ? (isMobile ? '95%' : '60%') : undefined}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={showConfirmationModal}
        centered
        footer={[
          <Button className={` button is-primary is-medium`} key='Proceed' onClick={handleOk}>
            Proceed
          </Button>,
          <Button className={` button is-light is-medium`} key='Cancel' type='primary' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        closable={false}
      >
        <div className={`is-flex is-justify-content-center ${broadcastDetails ? 'mt-0' : 'mt-6'}`}>
          <img alt='trash' src='/images/icons/warning.svg' style={{ width: '40px' }} />
        </div>
        <div style={{ marginTop: '5px' }}>
          <div className={Styles.warningMessage}>{value && (broadcastDetails?.broadcastName && showBroadcastDetails ? getModalHeader() : value.warningMessage)}</div>
          <div className={Styles.accountName}>
            <span>{selectedAccount && selectedAccount.name}</span>
            <span className='dot'></span>
            <span>{currentBot && currentBot.name}</span>
          </div>
          {(displayClickTrackWarning || showTestWarningFbIG) && !showBroadcastDetails && (
            <div className={`${Styles.complexTargetInfo} mx-3`} style={{ color: '#FAAD14' }}>
              {displayClickTrackWarning ? `Note: ${urlClickTrackWarning}` : `Note: ${notifBroadcastTestWarning}`}
            </div>
          )}
          {renderBroadcastDetails()}
          <div className='is-justify-content-center mt-6'>{value && value.childComponents}</div>
        </div>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};
export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);
export default ConfirmationModalContextProvider;
