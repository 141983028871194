import { MentionData } from '@draft-js-plugins/mention';
import { RawDraftContentState } from 'draft-js';
import _, { capitalize, escapeRegExp, toLower, uniq } from 'lodash';
import { mentionLinkConstant, mergeTagConstant } from '../../../pages/broadcasts/constants';
export const convertFromRawJson = (rawJson: RawDraftContentState) => {
  const delimeter = '\n';
  const blocks = rawJson.blocks;
  const entityMap = rawJson.entityMap;
  const convertedStrings: any[] = [];
  const replaceNameWithMacro = (blockStr: any, entityRanges: any) => {
    if (!entityRanges || !entityRanges.length) return blockStr;
    let diff = 0;
    let modifiedStr = blockStr;
    entityRanges.forEach((entity: any) => {
      const offset = entity.offset;
      const strLength = entity.length;
      const entityData = entityMap[entity.key];
      const macro = getEntityDataByKey(entityData.data, 'macro'); //entityData && entityData.data && entityData.data.mention.macro;
      const name = getEntityDataByKey(entityData.data, 'name'); //entityData && entityData.data && entityData.data.mention.name;
      // When emojis are included in text then substring function was not working as expected.
      // Hence using spread separator operator to tackle unicode issue of emojis in js.
      const firstPart = [...modifiedStr].slice(0, offset + diff).join(''); //modifiedStr.substring(0, (offset + diff));
      const secondPart = [...modifiedStr].slice(offset + diff + strLength).join(''); //modifiedStr.substring(offset + diff+ strLength, modifiedStr.length);
      modifiedStr = `${firstPart}${macro}${secondPart}`;
      if (macro && name) {
        diff = diff + (macro.length - name.length);
      }
    });
    return modifiedStr;
  };

  const getEntityDataByKey = (mentionProps: any, key: any) => {
    let prop = '';
    mentionProps &&
      mentionProps.mention &&
      // eslint-disable-next-line no-underscore-dangle
      mentionProps.mention._root &&
      // eslint-disable-next-line no-underscore-dangle
      mentionProps.mention._root.entries &&
      // eslint-disable-next-line no-underscore-dangle
      mentionProps.mention._root.entries.forEach((arr: any) => {
        if (arr[0] === key) {
          prop = arr[1];
        }
      });
    prop = prop || (mentionProps && mentionProps.mention && mentionProps.mention[key]);
    return prop;
  };
  const createStringFromJson = () => {
    if (!blocks || !blocks.length) {
      return '';
    }
    blocks.forEach((block: any) => {
      const convertedBlockString = replaceNameWithMacro(block.text, block.entityRanges);
      convertedStrings.push(convertedBlockString);
    });
    return convertedStrings.join(delimeter);
  };

  return createStringFromJson();
};

export const suggestionFilter = (value: string, suggestions: any) => {
  return suggestions.filter((suggest: any) => {
    const name: string = toLower(suggest.name);
    const key: string = toLower(value);
    return name.includes(key) || suggest.macro.includes(key);
  }) as MentionData[];
};

export const sortVariables = (mentionData: MentionData[]) => {
  return mentionData.sort((dataA: MentionData, dataB: MentionData) => dataA.name.localeCompare(dataB.name));
};

export const variabletypeColormap: { [key: string]: string } = {
  system: '#666666', // modifying ant's actual color, just taking advantage of their generated class
  entity: 'red', //#eb5637 red
  intent: 'green', //#417505 green,
  custom: 'default', //modifying
  user: 'orange', //modifying
  profile: 'blue',
};

export const variableTypeColorMapForMentions: { [key: string]: string } = {
  user: '#f5a623',
  system: '#515a73',
  entity: '#eb5637',
  profile: '#5a9aef',
  intent: '#417505',
  local: '#EE85C0',
  custom: '#fcffe6',
  default: '#000',
  [mergeTagConstant]: '#f7db86',
  [mentionLinkConstant]: 'transparent',
};

export const FINDMACROREGEXPATTERN = /{{\s*[\w @/#&%\\+-\\!\\(\\)\\*\\$\\.]+\s*}}/g;
export const getVariableByMacro = (macro: any, variables: any[any]) => {
  const result = variables.filter((vars: any) => vars.macro === macro)[0];
  return result;
};
/* eslint-disable-next-line max-lines-per-function */
export const getVariableNameByMacro = (macro: any, variables: any[any]) => {
  let varName = macro;
  // const result = variables.filter((vars) => vars.macro == macro)[0];
  const result = getVariableByMacro(macro, variables);
  if (result) {
    varName = result.name;
  }
  return varName;
};
/* eslint-disable-next-line max-lines-per-function */
export const convertToRawJson = (rawString: string, variables: any[any], regExToFindMentions: RegExp = FINDMACROREGEXPATTERN, delimeter: string = '\n') => {
  const blockStrings = rawString.split(delimeter);
  const blocks: any = [];
  const entityMap: any = {};
  const blockKeys = ['key', 'text', 'type', 'depth', 'inlineStyleRanges', 'entityRanges', 'data'];
  const entityKeys = ['type', 'mutability', 'data'];
  const getEntityData = (macro: any) => {
    const data: any = {};
    const variable = getVariableByMacro(macro, variables);
    data['mention'] = JSON.parse(JSON.stringify(variable));
    return data;
    // Don't use unique here.
  };
  const getEntityMutability = () => {
    return 'IMMUTABLE';
  };
  const getEntityType = () => {
    return '{mention';
  };
  const getEntityDataByKey = (key: any, macro: any) => {
    switch (key) {
      case 'type':
        return getEntityType();
      case 'mutability':
        return getEntityMutability();
      case 'data':
        return getEntityData(macro);
    }
  };
  const getEntityKey = () => {
    return Object.keys(entityMap).length;
  };
  const createEntityMaps = (blockStr: any) => {
    // Don't use unique here.
    const matches: any = getBlockStrMatches(blockStr);
    for (let i = 0, len = matches.length; i < len; i++) {
      const match = matches[i];
      const varname = getVariableNameByMacro(match, variables);
      if (varname !== match) {
        const entity: any = {};
        const mapKey = getEntityKey();
        for (const key of entityKeys) {
          entity[key] = getEntityDataByKey(key, match);
        }
        entityMap[mapKey] = entity;
      }
    }
  };
  const getBlockText = (blockString: any) => {
    if (!blockString) return blockString;
    let retString = blockString;
    const matches: any = uniq(getBlockStrMatches(blockString));

    //console.log("transformToDisplay matches", matches);

    for (const macro of matches) {
      const varName = getVariableNameByMacro(macro, variables);
      if (varName !== macro) {
        const transformStr = `${varName}`;
        const replaceRegex = new RegExp(escapeRegExp(macro), 'g');
        retString = retString.replace(replaceRegex, transformStr);
      }
    }
    //console.log("transformToDisplay final",retString);
    return retString;
  };
  const getBlockStrMatches = (blockStr: any) => {
    return blockStr.match(regExToFindMentions) || [];
  };
  const getEntityRanges = (blockString: any) => {
    const entityRanges = [];
    const originalIndex = getEntityKey();
    let modifiedBlockString = blockString;
    // Don't use unique here.
    const matches = getBlockStrMatches(blockString);
    let blockKeyIndex = 0;
    for (let i = 0, len = matches.length; i < len; i++) {
      const match = matches[i];
      const varName = getVariableNameByMacro(match, variables);
      if (varName !== match) {
        const entityRange: any = {};
        // When emojis are included in text then indexOf function was not working as expected.It does not consider unicode.Hence there is difference in offset provided by draft js and this function.
        // Hence using spread separator operator to tackle this issue.
        const offset1 = modifiedBlockString.indexOf(match);
        const offset = [...modifiedBlockString.substring(0, offset1)].length;
        entityRange.offset = offset; //modifiedBlockString.indexOf(match);
        entityRange.length = varName.length;
        entityRange.key = originalIndex + blockKeyIndex++;
        entityRanges.push(entityRange);
        modifiedBlockString = modifiedBlockString.replace(match, varName);
      }
    }
    return entityRanges;
  };
  const getBlockData = (key: any, blockStr: any) => {
    switch (key) {
      case 'key':
        // Do not define key here. Draft will generate key. If we define key then suggestions dropdown won't work.
        return ''; //uuid.v4();
      case 'text':
        return getBlockText(blockStr);
      case 'type':
        return 'unstyled';
      case 'depth':
        return 0;
      case 'inlineStyleRanges':
        return [];
      case 'entityRanges':
        return getEntityRanges(blockStr);
      case 'data':
        return {};
    }
  };
  const createBlocks = (blockStr: any) => {
    const block: any = {};
    for (const key of blockKeys) {
      block[key] = getBlockData(key, blockStr);
    }
    blocks.push(block);
  };
  const convertStringToBlockAndEntity = (blockStr: any) => {
    // if (!blockStr) return blockStr;
    createBlocks(blockStr);
    createEntityMaps(blockStr);
  };
  const convertToJson = () => {
    for (const blockStr of blockStrings) {
      convertStringToBlockAndEntity(blockStr);
    }
    const rawJson: any = {};
    rawJson['blocks'] = blocks;
    rawJson['entityMap'] = entityMap;
    return rawJson;
  };
  return convertToJson();
};

//to maintain order of variables
export const variableTypes = ['user', 'system', 'entity', 'intent', 'profile'];

export const creteOptionsForSuggestions = (optionsForVariableDropdown: MentionData[], varTypes: any = null) => {
  const allVarTypesExtracted =
    varTypes ||
    Object.keys(
      optionsForVariableDropdown?.reduce((acc: any, curr: any) => {
        acc[curr.type] = curr.type;
        return acc;
      }, {}),
    );
  let orderedVarTypes: any = [];
  const defaultVariableTypes = variableTypes;
  defaultVariableTypes?.forEach((varType: any) => allVarTypesExtracted.includes(varType) && orderedVarTypes.push(varType));
  orderedVarTypes = [...orderedVarTypes, ...(_.difference(allVarTypesExtracted, defaultVariableTypes) || [])];
  const result: MentionData[] = [];

  orderedVarTypes.forEach((type: string) => {
    let variables = optionsForVariableDropdown.filter((option: MentionData) => option.type === type);
    if (variables.length) {
      variables = sortVariables(variables);
      variables[0].displayType = capitalize(variables[0].type);
      result.push(...variables);
    }
  });
  return result;
};
