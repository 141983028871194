import { twilioSmartEncoderMap } from './twilo-char-encoder-map';
export type ReplacedChars = {
  [Key: string]: {
    replacedChar: string;
    count: number;
  };
};

export type SmartEncodingFunctionOutput = { specialCharsFound: boolean; encodedString: string; replacedChars: ReplacedChars };

export const getTwilioSmartEncodedString = (() => {
  const translateRegex = new RegExp(`[${Object.keys(twilioSmartEncoderMap).join('')}]`, 'g');
  return (messageString: string, mapper?: (match: string, mappedChar: string) => string) => {
    let specialCharsFound = false;
    let encodedString;
    const replacedChars: ReplacedChars = {};

    if (!messageString) return (encodedString = { specialCharsFound: false, encodedString: '', replacedChars: {} });
    encodedString = messageString.replace(translateRegex, (match: string) => {
      const mappedChar = twilioSmartEncoderMap[match];
      const replaceCharDetails = replacedChars[match];

      if (replaceCharDetails) {
        const count = replaceCharDetails.count;
        replacedChars[match] = { ...replaceCharDetails, count: count + 1 };
      } else {
        replacedChars[match] = { replacedChar: mappedChar, count: 1 };
      }

      if (mapper) {
        return mapper(match, mappedChar);
      }
      return mappedChar;
    });

    specialCharsFound = Boolean(Object.keys(replacedChars).length);

    return { specialCharsFound, encodedString, replacedChars }; //typeof replacedChars={messageString: {replacedChar: messageString, count: number}}
  };
})();
