import { engagementTypes } from '../../dashboard/components/comments-viewer/components/comment/comment.constants';
import { CommentTags, TagGroup, Tags } from './tag.types';

export const AmplifyGeneratedEngagement = 'Automated';

export const amplifyEngagedTagGroup: TagGroup = {
  external: {
    color: '#dfdeff',
    id: engagementTypes.EXTERNAL,
    label: 'External',
    groupType: 'amplifyEngaged',
  },
  manual: {
    color: '#eecb5799',
    id: engagementTypes.MANUAL,
    label: 'Manual',
    groupType: 'amplifyEngaged',
  },
  automated: {
    id: AmplifyGeneratedEngagement,
    label: AmplifyGeneratedEngagement,
    color: '#1297E019',
    groupType: 'amplifyEngaged',
  },
};
export const defaultCommentTags = (addEngagementTypeTags?: boolean): CommentTags => ({
  postTypes: {},
  keywords: {},
  intents: {},
  sentiments: {},
  sentiment: {},
  recommendations: {},
  amplifyEngaged: addEngagementTypeTags ? amplifyEngagedTagGroup : {},
  isPostMention: {},
  isIndirectComment: {},
});

export const defaultTags = (): Tags => {
  return {
    ...defaultCommentTags(),
    customLabels: {},
    messageStatus: {},
    assignedStatus: {},
    sessionStartType: {},
  };
};
