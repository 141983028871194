import { platforms, showCommentsEnabledForPlatforms, showInboxEnabledForPlatforms, showPermissionsStatusForPlatforms } from '../../../../common/entities/entities.constants';
import { Bot, PlatformBot } from '../../../../common/entities/entities.types';
import { getFBPageProfilePicURL } from '../../../../common/utils/platforms';
import { SelectOption } from '../../../../types';
import { PageHealthDetails } from '../../../user-profile-pages/components/page-health/page-health.types';
import { PermissionStatus } from '../../../user-profile-pages/type';

export const getProfilePic = (option: SelectOption, platform: string, dontUseDefaultDp: boolean = true) => {
  const defaultDp = !dontUseDefaultDp ? '/images/icons/stock-insta-dp.svg' : undefined;

  switch (platform) {
    case platforms.FACEBOOK:
      return getFBPageProfilePicURL(option.value);
    case platforms.INSTAGRAM:
    case platforms.TWITTER:
    case platforms.TWILIO_SMS:
    case platforms.TIKTOK:
    case platforms.THREADS:
    case platforms.TIKTOK_ORGANIC:
      return option.profilePictureUrl || defaultDp;
    default:
      return defaultDp;
  }
};
export const platfromOverlayIcon = {
  [platforms.INSTAGRAM]: '/images/icons/platforms/instagramround.svg',
  [platforms.FACEBOOK]: '/images/icons/platforms/facebookround.svg',
  [platforms.TWITTER]: '/images/icons/platforms/twitterround.svg',
};

export const getSelectOptionFromPlatformBot: SelectOption | any = (currentPlatformBot?: PlatformBot | undefined) => {
  return {
    label: currentPlatformBot?.name || '',
    platform: currentPlatformBot?.platformAsInSystemSettings,
    profilePictureUrl: currentPlatformBot?.profilePictureUrl,
    value: currentPlatformBot?.platformBotId || '',
  };
};

export const getPageHealthWarningStatus = (
  permissionStatuses: PermissionStatus[],
  currentBot: Bot,
  platform?: string,
  pageHealthDetails?: PageHealthDetails,
  hasCreateBotPermission?: boolean,
) => {
  if (!platform) return false;
  if (!currentBot) return false;
  if (platform === platforms.FACEBOOK || platform === platforms.INSTAGRAM) {
    if (pageHealthDetails) {
      if (platform === platforms.FACEBOOK) {
        if (!pageHealthDetails.isAdminPermission || pageHealthDetails.isEUPrivacyRule) return true;
      }
      if (platform === platforms.INSTAGRAM) {
        if (!pageHealthDetails.igMessagingEnabled) return true;
      }
    }
  }
  if (showInboxEnabledForPlatforms.includes(platform)) {
    if (!currentBot.config?.indexMessage?.enabled) return true;
  }
  if (showCommentsEnabledForPlatforms.includes(platform)) {
    if (!currentBot?.config?.indexComment) return true;
  }
  if (showPermissionsStatusForPlatforms.includes(platform) && hasCreateBotPermission) {
    let isPermissionMissing = false;
    for (const permission of permissionStatuses) {
      if (permission.isRequired && !permission.isGiven) isPermissionMissing = true;
    }
    return isPermissionMissing;
  }
  return false;
};
