/* eslint-disable max-lines */
import { extractFirstLastName, identifyCliqzPopups } from './../utils/index';
import { storeBotMessageDefaultValue, storeBotMessageSettings, storeBotProfile, storeSMSText } from '.';
import { get, post, put } from '../../../common/api-calls/api-client';
import { getBotConfig, updatePlatformBotProfilePictureUrl } from '../../../common/entities/actions/async-actions';
import { Account, accountConfig, Bot, BotConfig, BotProfile, PlatformBot } from '../../../common/entities/entities.types';
import { showErrorModal } from '../../../common/utils';
import { systemSettingsTypes } from '../../../config';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { VcardRes } from '../../admin/sms-deployment/bot-deploy/types';
import { showMessage } from '../../common/toast-notification';
import { cliqzLogoUrl, CLIQZ_SIGNUP_POSTBACK } from '../constant';
import {
  BotMacroCreateRequest,
  BotMacroResponse,
  BotMacros,
  BotMediaRequest,
  BotMediaResponse,
  BotMediaTypes,
  FetchBotMacroResponse,
  PopupAPI,
  PopupAPIRequest,
  PopupsAPIResonse,
  ProvisionPhoneNumber,
  ProvisionPhoneNumbersResponse,
  SystemSettingsBotAPIResponse,
  UserFormAPIRequest,
  VCardRequest,
} from '../settings.types';
import { BotMacrosAPItoUI, systemSettingsBotAPItoUI, transformeBotProfileUItoBotMacroAPI } from '../transformer';
import { Crop } from 'react-image-crop';
import { platforms } from '../../../common/entities/entities.constants';
import { updateAccount } from '../../user-profile-pages/actions/account-async-actions';
import { storeUserAccount } from '../../../common/entities/actions';
// import { SystemSettingsBotAPItoUI } from '../transformer';

// Base  API calls

export const fetchSystmSettings = (accountId: string, doNotShowLoader?: boolean | undefined): Thunk<SystemSettingsBotAPIResponse | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<SystemSettingsBotAPIResponse | null> => {
    let response: { data: SystemSettingsBotAPIResponse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`/utils/getSystemSettings?type=${systemSettingsTypes.ACM_BOT_TYPE_SETTINGS}&accountId=${accountId}`, authToken as string, doNotShowLoader);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const fetchbotMacro = (botId: string, doNotShowLoader?: boolean | undefined): Thunk<FetchBotMacroResponse | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<FetchBotMacroResponse | null> => {
    let response: { data: FetchBotMacroResponse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`/botMacros?botId=${botId}`, authToken as string, doNotShowLoader);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const uploadBotMedia = (payload: BotMediaRequest, botId: string, mediaType: BotMediaTypes, doNotShowLoader?: boolean): Thunk<BotMediaResponse | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<BotMediaResponse | null> => {
    let response: { data: BotMediaResponse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await post(`/bots/${botId}/media/${mediaType}`, payload, authToken as string, doNotShowLoader);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const fetchImageByCroppedImageUrl = (croppedImageUrl: string, botId: string, onDone?: () => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const {
      auth: { authToken },
    } = getState();
    let response: any = null;
    const preppedUrl = encodeURIComponent(croppedImageUrl.split('?')[0]);
    const url = `images/lookupByCroppedURL/?croppedImageURL=${preppedUrl}&botId=${botId}`;
    try {
      response = await get(url, authToken as string);
      if (response && response.status === 200) {
        onDone && onDone();
        return response.data;
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }

    return null;
  };
};

export const cropImage = (imageId: string, crop: Crop, onDone?: () => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const {
      auth: { authToken },
    } = getState();
    try {
      const response = await post(`images/${imageId}/crop`, crop, authToken as string);
      if (response && response.status === 200) {
        onDone && onDone();
        return response.data;
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    return null;
  };
};

export const createUpdatebotMacro = (botId: string, payload: BotMacroCreateRequest, doNotShowSuccessAlert?: boolean): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: { data: any; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      let method = post;
      if (payload.id) {
        method = put;
      }
      response = await method(`/botMacros?botId=${botId}`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && (response.status === 200 || response.status === 204)) {
      !doNotShowSuccessAlert && showMessage(`Settings updated.`);
      return true;
    }
    return false;
  };
};

export const fetchShopifyPopups = (botId: string): Thunk<PopupsAPIResonse | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<PopupsAPIResonse | null> => {
    let response: { data: PopupsAPIResonse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`shopifyPopups?botId=${botId}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const updateShopifyPopup = (payload: PopupAPIRequest, onDone?: () => void): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: { data: PopupsAPIResonse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await put(`shopifyPopups`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 204) {
      onDone?.();
      return true;
    }
    return false;
  };
};

export const deployShopifyConfigPopup = (accountId: string): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: { data: ProvisionPhoneNumbersResponse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`shopifyConfigs/deploy/${accountId}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return true;
    }
    return false;
  };
};

export const ferchProvisionPhoneNumbers = (botId: string, platformBotId: string): Thunk<ProvisionPhoneNumbersResponse | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<ProvisionPhoneNumbersResponse | null> => {
    let response: { data: ProvisionPhoneNumbersResponse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`platformBots/provisionPhoneNumbers?botId=${botId}&platformBotId=${platformBotId}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const updateUserForm = (payload: UserFormAPIRequest): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: { data: PopupsAPIResonse; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await put(`userForm`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 204) {
      return true;
    }
    return false;
  };
};

export const updateAdminCliqzAccountSettings = (
  originalAccount: Account,
  updateAdminCliqzAccountSettingsKey: string,
  updateAdminCliqzAccountSettingsValue: string | boolean,
  currentPlatform: string = platforms.TWILIO_SMS,
  updateAdminCliqzAccountSettingsKeySecond?: string,
  updateAdminCliqzAccountSettingsValueSecond?: string | boolean,
): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch): Promise<boolean> => {
    let account: Account = originalAccount;

    try {
      if (originalAccount) {
        if (updateAdminCliqzAccountSettingsKeySecond && updateAdminCliqzAccountSettingsValueSecond) {
          account = {
            ...originalAccount,
            config: {
              ...originalAccount.config,
              uiConfig: {
                ...originalAccount.config?.uiConfig,
                [currentPlatform]: {
                  ...originalAccount.config?.uiConfig?.[currentPlatform],
                  [updateAdminCliqzAccountSettingsKey]: updateAdminCliqzAccountSettingsValue,
                  [updateAdminCliqzAccountSettingsKeySecond]: updateAdminCliqzAccountSettingsValueSecond,
                },
              },
            },
          };
        } else {
          account = {
            ...originalAccount,
            config: {
              ...originalAccount.config,
              uiConfig: {
                ...originalAccount.config?.uiConfig,
                [currentPlatform]: {
                  ...originalAccount.config?.uiConfig?.[currentPlatform],
                  [updateAdminCliqzAccountSettingsKey]: updateAdminCliqzAccountSettingsValue,
                },
              },
            },
          };
        }
        dispatch(updateAccount(account));
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    return false;
  };
};

// Inherited Above API calls

export const fecthSystemSettingsAndBotMacros = (accountId: string, botId: string, doNotShowLoader?: boolean | undefined): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    dispatch(fetchSystmSettings(accountId, doNotShowLoader)).then((data: SystemSettingsBotAPIResponse | null) => {
      if (data) {
        dispatch(fetchbotMacro(botId, doNotShowLoader)).then((botMacroData: FetchBotMacroResponse | null) => {
          if (botMacroData) {
            let botMacros: BotMacros | null = {};
            botMacroData.length &&
              botMacroData.forEach((data: BotMacroResponse) => {
                botMacros = {
                  ...data.defaults,
                };
              });
            const transformedSettings = systemSettingsBotAPItoUI(data, botMacros);
            dispatch(storeBotMessageSettings(transformedSettings.settings.cliqz));
          }
        });
      }
    });
  };
};

export const updateCliqzVcard = (payload: VCardRequest): Thunk<VcardRes | undefined> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<VcardRes | undefined> => {
    let response;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await post(`vcards/cliqz`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return undefined;
  };
};

export const updateSystemSettingbotMacro = (settings: BotMacros, bot: Bot, onDone?: () => void, storeInRedux: boolean = true, doNotShowSuccessAlert?: boolean): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    const handleDone = () => {
      storeInRedux && dispatch(storeBotMessageDefaultValue(settings));
      onDone && onDone();
    };
    dispatch(fetchbotMacro(bot.id)).then((data: FetchBotMacroResponse | null) => {
      if (data) {
        if (data.length) {
          //update into existing botMacro
          const dataAt0Index = data[0];
          const payload: BotMacroCreateRequest = {
            ...dataAt0Index,
            defaults: {
              ...dataAt0Index.defaults,
              ...settings,
            },
          };
          dispatch(createUpdatebotMacro(bot.id, payload, doNotShowSuccessAlert)).then((created: boolean) => {
            created && handleDone();
          });
        } else {
          //create new botMacro
          const payload: BotMacroCreateRequest = {
            botId: bot.id,
            botName: bot.name,
            defaults: {
              ...settings,
            },
          };
          dispatch(createUpdatebotMacro(bot.id, payload, doNotShowSuccessAlert)).then((updated: boolean) => {
            updated && handleDone();
          });
        }
      }
    });
  };
};

export const fetchBotProfileDetails = (botId: string): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    dispatch(getBotConfig(botId)).then((data: BotConfig | null) => {
      let botProfile: BotProfile = {};
      if (data) {
        botProfile.popupUrl = data.profile?.popupUrl;
        botProfile.userName = data.profile?.userName;
      }
      dispatch(fetchbotMacro(botId)).then((botMacroData: FetchBotMacroResponse | null) => {
        if (botMacroData) {
          let botMacros: BotMacros | null = {};
          botMacroData.length &&
            botMacroData.forEach((data: BotMacroResponse) => {
              botMacros = {
                ...data.defaults,
              };
            });
          const transformedSettings = BotMacrosAPItoUI(botMacros);
          botProfile = { ...botProfile, ...transformedSettings };
          dispatch(storeBotProfile(botProfile));
        }
      });
    });
  };
};

export const fetchBotMacroDetailsForNonCliqzBots = (botId: string): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    dispatch(fetchbotMacro(botId)).then((botMacroData: FetchBotMacroResponse | null) => {
      if (botMacroData) {
        let botMacros: BotMacros | null = {};
        botMacroData.length &&
          botMacroData.forEach((data: BotMacroResponse) => {
            botMacros = {
              ...data.defaults,
            };
          });
        const transformedSettings = BotMacrosAPItoUI(botMacros);
        const botProfile: BotProfile = { ...transformedSettings };
        dispatch(storeBotProfile(botProfile));
      }
    });
  };
};

export const updateBotSettingsForProfileDetails = (botProfile: BotProfile, bot: Bot, platformBot: PlatformBot, onDone?: () => void): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const {
      settings: { profileDetails },
    } = getState();
    const isNameUpdated = profileDetails.name !== botProfile.name || profileDetails.completeName?.firstName !== botProfile.completeName?.firstName;
    if (isNameUpdated || profileDetails.profilePic !== botProfile.profilePic) {
      // if name or pofile pic has chnaged then updating macros, Vscard, popup and userForm
      const boMacropayload = transformeBotProfileUItoBotMacroAPI(botProfile);
      const handleDone = () => {
        dispatch(updatePopupVCardUserFromDetails(bot, platformBot, botProfile));
        dispatch(storeBotProfile({ ...profileDetails, ...BotMacrosAPItoUI(boMacropayload) }));
        onDone && onDone();
      };

      dispatch(updateSystemSettingbotMacro(boMacropayload, bot, handleDone, true));
      if (profileDetails.profilePic !== botProfile.profilePic || isNameUpdated) {
        botProfile.profilePic && dispatch(updatePlatformBotProfilePictureUrl(botProfile.profilePic, isNameUpdated && botProfile.name));
      }
    }
  };
};

export const updateSMSSettingForSignUpMessages = (smsTextPayload: string, bot: Bot, onDone?: () => void, doNotShowSuccessAlert?: boolean): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const {
      settings: { profileDetails, smsText },
    } = getState();
    if (smsText !== smsTextPayload) {
      // if only smstext has chnaged then updating popup only
      dispatch(fetchShopifyPopups(bot.id)).then((data: PopupsAPIResonse | null) => {
        if (data) {
          // const popUpWithBotName = data.filter((popup: PopupAPI) => popup.popUpName === profileDetails?.name || popup.subType === 'cliqz');
          const popUpWithBotName = identifyCliqzPopups(data, { popupId: profileDetails.popupId, popupNameFromBot: profileDetails?.name });

          if (!popUpWithBotName || !popUpWithBotName.length) {
            return showErrorModal('', dispatch, false, 'Cliqz type popup is not configured for this account.');
          }
          if (popUpWithBotName.length > 1) {
            return showErrorModal('', dispatch, false, `There are ${popUpWithBotName.length} Cliqz type popups in an account. There must be only one Cliqz type popup.`);
          }
          popUpWithBotName.forEach((popup: PopupAPI) => {
            const payloadPopup: PopupAPIRequest = {
              ...popup,
            };
            payloadPopup.popupStateConfig.expand.sms_body = smsTextPayload;
            dispatch(updateShopifyPopup(payloadPopup)).then((updated: boolean) => {
              if (updated) {
                dispatch(deployShopifyConfigPopup(bot.accountId));
                dispatch(storeSMSText(smsTextPayload));
                !doNotShowSuccessAlert && showMessage(`Settings updated.`);
                onDone && onDone();
              }
            });
          });
        }
      });
    }
  };
};

export const updatePopupVCardUserFromDetails = (bot: Bot, platformBot: PlatformBot, payload: BotProfile): Thunk<void> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const {
      settings: { profileDetails },
    } = getState();
    //update name and profic pic in popups
    dispatch(fetchShopifyPopups(bot.id)).then((data: PopupsAPIResonse | null) => {
      if (data) {
        // const popUpWithBotName = data.filter((popup: PopupAPI) => popup.popUpName === profileDetails?.name);
        const popUpWithBotName = identifyCliqzPopups(data, { popupId: profileDetails.popupId, popupNameFromBot: profileDetails?.name });

        popUpWithBotName?.forEach((popup: PopupAPI) => {
          const payloadPopup: PopupAPIRequest = {
            ...popup,
            popUpName: payload.name || popup.popUpName,
            logo: payload.profilePic || cliqzLogoUrl,
          };
          dispatch(updateShopifyPopup(payloadPopup)).then((updated: boolean) => {
            if (updated) {
              dispatch(deployShopifyConfigPopup(bot.accountId));
            }
          });
        });
      }
    });
    //update name and profic pic in Vcard
    dispatch(ferchProvisionPhoneNumbers(bot.id, platformBot.platformBotId)).then((data: ProvisionPhoneNumbersResponse | null) => {
      if (data) {
        const phoneNumbersArray = data.map((phoneNumbers: ProvisionPhoneNumber) => phoneNumbers.phoneNumber);
        const { firstName, lastName } = extractFirstLastName(payload.name);
        const payloadVCard: VCardRequest = {
          botId: bot.id,
          vcardOptions: {
            firstName: payload.completeName?.firstName || firstName,
            lastName: payload.completeName?.firstName ? payload.completeName?.lastName : lastName,
          },
          imageUrl: payload.profilePic || cliqzLogoUrl,
          phoneNumbersArray,
        };
        dispatch(updateCliqzVcard(payloadVCard));
      }
    });
    //update name and profic pic in Vcard  demographic form
    const userFormPayload: UserFormAPIRequest = {
      botId: bot.id,
      platform: platformBot.platform,
      platformBotId: platformBot.platformBotId,
      botProfileName: payload.name || '',
      botProfilePic: payload.profilePic || '',
      nextNodes: [CLIQZ_SIGNUP_POSTBACK],
    };
    dispatch(updateUserForm(userFormPayload));
  };
};

export const fetchSMSTextfromPopup = (botId: string, { name, popupId }: { name?: string; popupId?: string }): Thunk<PopupsAPIResonse | null | undefined> => {
  return async (dispatch: AsyncThunkDispatch): Promise<PopupsAPIResonse | null | undefined> => {
    return dispatch(fetchShopifyPopups(botId)).then((data: PopupsAPIResonse | null) => {
      if (data && data.length) {
        const cliqzPopup = identifyCliqzPopups(data, { popupNameFromBot: name, popupId });
        //data.filter((popup: PopupAPI) => popup.popUpName === popupName);

        if (cliqzPopup && cliqzPopup.length > 0) {
          const popup = cliqzPopup?.[0];
          popup?.popupStateConfig?.expand?.sms_body && dispatch(storeSMSText(popup.popupStateConfig.expand.sms_body));
          return cliqzPopup;
        }
      }
    });
  };
};

export const updateAccountConfig = (accountConfig: accountConfig): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = 'accounts';
    const state = getState();
    const originalAccount = state.entities?.account!;
    const accountToUpdate = { ...originalAccount, config: { ...accountConfig } };
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await put(url, accountToUpdate, authToken as string);
      if (response) {
        accountToUpdate.lastUpdatedDate = response.headers['x-response-time'];
        dispatch(storeUserAccount(accountToUpdate));
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
