import { StoreCreator, Reducer, Action, StoreEnhancer, Store } from 'redux';
import { RootState } from '../types';

const round = (number: number): number => Math.round(number * 100) / 100;

const monitorReducerEnhancer: any = (createStore: StoreCreator) => (
  reducer: Reducer<RootState, Action>,
  initialState?: RootState,
  enhancer?: StoreEnhancer<RootState>,
): Store<RootState> => {
  const monitoredReducer = (state: RootState, action: Action): RootState => {
    const start = performance.now();
    const newState = reducer(state, action);
    const end = performance.now();
    const diff = round(end - start);
    // eslint-disable-next-line no-console
    console.log('reducer process time:', diff);

    return newState;
  };

  return createStore(monitoredReducer as any, initialState, enhancer);
};

export default monitorReducerEnhancer;
