import { SelectOption } from '../../../types';

export const customRange = 'customRange';

export const timeFilterOptions: SelectOption[] = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 days',
    value: 'last7Days',
  },
  {
    label: 'Last 14 days',
    value: 'last14Days',
  },
  {
    label: 'Last 30 days',
    value: 'last30Days',
  },
  {
    label: 'Custom Range',
    value: customRange,
  },
];
export const customDateRange: any = {
  NINETY: 90,
};
