import { createAction } from '@reduxjs/toolkit';
import constants from '../keyword-groups.constants';
import { BotTrainingInfo } from '../keyword-groups.types';

export const showCreateKeywordGroupButton = createAction<boolean>(constants.SHOW_CREATE_KEYWORD_GROUP_BUTTON);
export const changeCurrentTab = createAction<string>(constants.CHANGE_CURRENT_TAB);
export const storePropertiesForUpdatingKeywordGroups = createAction<{ entitiesId: string; lastUpdatedDate: string }>(constants.STORE_ENTITIES_ID);
export const updateBotTrainingStatus = createAction<BotTrainingInfo>(constants.UPDATE_BOT_TRAINING_STATUS);
export const trainingStarted = createAction(constants.TRAINING_STARTED);
export const didKeywordGroupsChange = createAction<boolean>(constants.DID_KEYWORD_GROUPS_CHANGE);
export const storeBasePath = createAction<string>(constants.STORE_BASE_PATH_KEYWORDS);
