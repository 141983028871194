import React from 'react';
import styles from '../broadcasts/scss/broadcasts-mobile.module.scss';

export const dateRenderer = (dateObj?: any, dateSeperatorStyle: any = {}) => {
  return (
    dateObj?.date && (
      <>
        <div> {dateObj.date} </div>
        <div className={`${styles['detail-value-seperator']}`} style={dateSeperatorStyle}>
          |{' '}
        </div>
        <div> {dateObj.time || ``} </div>
      </>
    )
  );
};

export const rowValueRenderer = (val?: string) => <> {val} </>;
