import { storeSegmentTags } from '.';
import { AsyncThunkDispatch, RootState } from '../../../types';
import { get } from '../../api-calls/api-client';
import { showErrorModal } from '../../utils';
import { SegmentTagAPI } from '../../entities/entities.types';
import { transformSegmentTagsAPItoUI } from '../transformer/segmentTags';

export const fetchSegmentTags = (botId: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<SegmentTagAPI[] | null> => {
    let response: { data: SegmentTagAPI[]; status: number } | null = null;

    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`/tags/?botId=${botId}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const transformedSegmentTags = transformSegmentTagsAPItoUI(response.data);
      dispatch(storeSegmentTags(transformedSegmentTags));
      return response.data;
    }
    return null;
  };
};
