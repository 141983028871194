/* eslint-disable max-lines-per-function */
import { v4 as uuid } from 'uuid';
import { actions } from '../../../config/platform-action-map';
import { actionsInBrandLift, actionsInCommentModeration, actionsInSmartReplies } from '../config';
import { automationSubTabs, campaignTypes, PrivateReplyActionType } from '../constants';
import { AnalyticsResponseType, CampaignsSegregated, CampaignsType, CampaignsWithIds, CampaignType } from '../types';
import { isStandardCampaign } from '../utils';
import { transformCampaignAPItoUI } from './api-to-ui';
export { transformCampaignUIToApi } from './ui-to-api';

export type analyticsDataALL = {
  comments: { [key: string]: AnalyticsResponseType };
  mentions: { [key: string]: AnalyticsResponseType };
};

export const segregateGC = (campaigns: CampaignsType, analytics: analyticsDataALL) => {
  const mentionAnalytics = analytics.mentions;
  const commentAnalytics = analytics.comments;

  const campaignsSegregated: CampaignsSegregated = {
    [automationSubTabs.BRAND_LIFT]: {
      custom: [],
      recommended: [],
    },
    [automationSubTabs.COMMENT_MODERATION]: {
      custom: [],
      recommended: [],
    },
    [automationSubTabs.SMART_REPLIES]: {
      custom: [],
      recommended: [],
    },
  };

  const allActions = {
    [automationSubTabs.BRAND_LIFT]: actionsInBrandLift,
    [automationSubTabs.COMMENT_MODERATION]: actionsInCommentModeration,
    [automationSubTabs.SMART_REPLIES]: actionsInSmartReplies,
  };
  if (allActions[automationSubTabs.SMART_REPLIES].includes(actions.PRIVATE_REPLY)) {
    allActions[automationSubTabs.SMART_REPLIES].pop();
    allActions[automationSubTabs.SMART_REPLIES].push(PrivateReplyActionType.TEXT);
  }

  campaigns
    .filter((campaign: CampaignType) => (campaign.actions && campaign.actions.length) === 1)
    .forEach((campaign: CampaignType) => {
      const transformedGC = transformCampaignAPItoUI(campaign, commentAnalytics[campaign.id], mentionAnalytics[campaign.id]);
      //TODO; How will you segregate into recommended

      let subTab = '';

      if (campaign.type === campaignTypes.STANDARD) {
        const actions = campaign.actions || [];

        actions.find((action: { type?: string }) => {
          return Object.keys(allActions).find((actionKey: string) => {
            if (action.type) {
              if (allActions[actionKey].includes(action.type)) {
                subTab = actionKey;

                return true;
              }
            }
            return false;
          });
        });

        if (subTab) {
          // campaignsSegregated[subTab].custom.push(campaign);
          if (isStandardCampaign(transformedGC.uiMetadata)) {
            campaignsSegregated[subTab].recommended.push(transformedGC);
          } else {
            campaignsSegregated[subTab].custom.push(transformedGC);
          }
        }
      }
      subTab = '';
    });
  return { campaigns: transformCampaignsApiToUi(campaigns, commentAnalytics, mentionAnalytics), campaignSegregated: campaignsSegregated };
};
export const transformCampaignsApiToUi = (
  campaigns: CampaignsType,
  analytics?: { [key: string]: AnalyticsResponseType },
  mentionAnalytics?: { [key: string]: AnalyticsResponseType },
) => {
  const campaignsObj: CampaignsWithIds = {};

  campaigns
    .filter((campaign: CampaignType) => (campaign.actions && campaign.actions.length) === 1)
    .forEach((campaign: CampaignType) => {
      const transformedGC = transformCampaignAPItoUI(campaign, analytics?.[campaign.id], mentionAnalytics?.[campaign.id]);
      campaignsObj[campaign.id] = transformedGC;
    });
  return campaignsObj;
};
export function transformGcResponseActionAPIToUI(transformedCampaign: CampaignType) {
  const actions = transformedCampaign.actions || [];
  return actions.map((a: any) => {
    return {
      ...a,
      id: uuid(),
    };
  });
}
