import { createAction } from '@reduxjs/toolkit';
import FBDeployConstants from '../constants';
import { FbPageListAction, ToggleFBWarningModalAction, ShowFbPageListAction, FbPageDeploymentSuccessAction, InstagramPageList, FbPageResponseAction } from '../types';

export const toggleFbWarningModalDisplay = createAction<ToggleFBWarningModalAction>(FBDeployConstants.TOGGLE_FB_LOGIN_MODAL_DISPLAY);
export const storeFbPages = createAction<FbPageListAction>(FBDeployConstants.STORE_FB_PAGES);
export const showFbPageList = createAction<ShowFbPageListAction>(FBDeployConstants.SHOW_FB_PAGE_LIST);
export const fbPageDeploymentSuccess = createAction<FbPageDeploymentSuccessAction>(FBDeployConstants.FB_PAGE_DEPLOY_SUCCESS);
export const storeInstagramPages = createAction<InstagramPageList>(FBDeployConstants.STORE_INSTAGRAM_PAGES);
export const storeInstaAccessToken = createAction<string>(FBDeployConstants.STORE_INSTA_ACCESS_TOKEN);
export const setFbPageResponse = createAction<FbPageResponseAction>(FBDeployConstants.SET_FB_PAGE_RESPONSE);

export { checkFbApprovedPermissions } from './async-actions';
