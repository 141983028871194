import React from 'react';
import styles from './media-close.module.scss';
import { ReactComponent as EditIcon } from '../../../../src/assets/svg/Pen.svg';
import { ReactComponent as HeadphonesIcon } from '../../../../src/assets/svg/headphones.svg';
import { ReactComponent as VideoIcon } from '../../../../src/assets/svg/video.svg';
import { ReactComponent as FileIcon } from '../../../../src/assets/svg/file.svg';
import { mediaFormatTypes } from './config';
import { prettifySize } from '../../imageCompression/utils';
import ImageWithDownload from '../../../common/components/image-with-download';

type MediaWithCloseIconTypes = {
  fileName?: string;
  fileURL: string;
  fileSize?: string;
  onClose: () => void;
  readOnly?: boolean;
  containerClassName?: string;
  setCropper?: (booleanValue: boolean) => void;
  cropperNeeded?: boolean;
  mediaFormat: string;
  containerStyle?: any;
  imageStyle?: any;
  fileNameStyle?: any;
};

/*eslint-disable max-lines-per-function*/
export const MediaWithCloseIcon = (props: MediaWithCloseIconTypes) => {
  const {
    onClose,
    fileURL,
    fileName,
    readOnly,
    setCropper,
    mediaFormat,
    cropperNeeded,
    fileSize,
    containerClassName = '',
    containerStyle,
    fileNameStyle = {},
    imageStyle = {},
  } = props;

  const getIconUsingMediaType = (mediaType: string) => {
    switch (mediaType) {
      case mediaFormatTypes.VIDEO: {
        return <VideoIcon />;
      }
      case mediaFormatTypes.AUDIO: {
        return <HeadphonesIcon />;
      }
      default:
        return <FileIcon />;
    }
  };
  return (
    <div
      className={styles.attachmentContainer + ' ' + containerClassName}
      style={{ display: mediaFormat !== mediaFormatTypes.IMAGE ? 'flex' : '', width: mediaFormat !== mediaFormatTypes.IMAGE ? '150px' : '120px', ...containerStyle }}
    >
      {mediaFormat === mediaFormatTypes.IMAGE ? (
        <>
          <ImageWithDownload alt='Preview' src={fileURL} imageStyle={imageStyle} />
          {/* <img alt='Preview' src={fileURL} style={imageStyle} /> */}
          <div className={styles.imageFileNameSpace} style={fileNameStyle}>
            <div className={styles.fileNameNew} title={fileName}>
              {fileName}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.iconSpace}>
            <div className={styles.iconStyle}>{getIconUsingMediaType(mediaFormat)}</div>
          </div>
          <div className={styles.fileNameSpace}>
            <div className={styles.fileName} title={fileName} style={fileNameStyle ? { width: fileNameStyle } : {}}>
              {fileName}
            </div>
            {fileSize !== null && <div className={styles.fileSize}>{prettifySize(fileSize)}</div>}
          </div>
        </>
      )}
      {!readOnly && cropperNeeded && (
        <>
          <div className={styles.overlay} onClick={() => setCropper && setCropper(true)}>
            <EditIcon width={25} height={25} />
          </div>
        </>
      )}
      <div className={styles.attachmentFooter}>
        {!readOnly && (
          <div onClick={() => onClose()} className={styles.closeIcon} data-testid={'cross-media-icon'}>
            <img alt='close' src='/images/icons/close.svg' />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaWithCloseIcon;
