import GraphemeSplitter from 'grapheme-splitter';
import { CapitalLetterTransform, DigitTransform, SmallLetterTransform } from './characterTransformInterfaces';

const formatTransforms = {
  boldenTransforms: [new CapitalLetterTransform('𝗔'), new SmallLetterTransform('𝗮'), new DigitTransform('𝟬')],
  italicizeTransform: [new CapitalLetterTransform('𝘈'), new SmallLetterTransform('𝘢')],
  boldenAndItalicizeTransform: [
    new CapitalLetterTransform('𝘼'),
    new SmallLetterTransform('𝙖'),
    new DigitTransform('𝟬'), // There are no bold italics digits, use simple bold
  ],
  // monospaceTransform:[new CapitalLetterTransform('𝙰'), new SmallLetterTransform('𝚊'), new DigitTransform('𝟶')],
  // scriptizeTransform: [new CapitalLetterTransform('𝓐'), new SmallLetterTransform('𝓪')],
  // subscriptTransform: [
  //   new DigitTransform('₀'),
  //   new SingleCharTransform('a', 'ₐ'),
  //   new SingleCharTransform('e', 'ₑ'),
  //   new SingleCharTransform('h', 'ₕ'),
  //   new SingleCharTransform('i', 'ᵢ'),
  //   new SingleCharTransform('j', 'ⱼ'),
  //   new CharTransform('k', 'n', 'ₖ'),
  //   new SingleCharTransform('o', 'ₒ'),
  //   new SingleCharTransform('p', 'ₚ'),
  //   new SingleCharTransform('r', 'ᵣ'),
  //   new CharTransform('s', 't', 'ₛ'),
  //   new SingleCharTransform('u', 'ᵤ'),
  //   new SingleCharTransform('v', 'ᵥ'),
  //   new SingleCharTransform('x', 'ₓ'),
  //   new CharTransform('a', 'z', 'ₐ'),
  // ],
  //   superscriptTransform : [
  //   new SingleCharTransform('1', '¹'),
  //   new CharTransform('2', '3', '²'),
  //   new DigitTransform('⁰'),
  //   new CharTransform('(', ')', '⁽'),
  //   new SingleCharTransform('+', '⁺'),
  //   new SingleCharTransform('-', '⁻'),
  //   new SingleCharTransform('=', '⁼'),
  //   new SingleCharTransform('n', 'ⁿ'),
  //   new SingleCharTransform('i', 'ⁱ'),
  // ]
};

function transformator(transforms: any) {
  return function transform(text: string) {
    const codesBuffer = [];
    for (let i = 0; i < text.length; i++) {
      const code = text.charCodeAt(i);
      const transform = transforms.find((t: any) => t.matches(code));
      if (transform) transform.transform(code, codesBuffer);
      else codesBuffer.push(code);
    }
    return String.fromCharCode(...codesBuffer);
  };
}

const htmlToUnicodeTransformers = {
  bolden: transformator(formatTransforms.boldenTransforms),
  italicize: transformator(formatTransforms.italicizeTransform),
  boldenAndItalicize: transformator(formatTransforms.boldenAndItalicizeTransform),
  // monospace:transformator(formatTransforms.monospaceTransform),
  // scriptize: transformator(formatTransforms.scriptizeTransform),
  // subscript: transformator(formatTransforms.subscriptTransform),
  // superscript: transformator(formatTransforms.superscriptTransform),
  strikeThrough(text: string) {
    const splitter = new GraphemeSplitter();
    const textArray = splitter.splitGraphemes(text);
    return textArray.map((val: string) => (val.split('').length < 2 ? (val + `\u0335`).normalize('NFKD') : val)).join('');
  },
  underline(text: string) {
    const splitter = new GraphemeSplitter();
    const textArray = splitter.splitGraphemes(text);
    return textArray.map((val: string) => (val.split('').length < 2 ? (val + `\u035F`).normalize('NFKD') : val)).join('');
  },
};

export default htmlToUnicodeTransformers;
