import moment from 'moment';
import { getTimeZone } from '../../../common/utils/time-zones';
import { History } from '../../../types';

export const authenticateUser = (history: History, route?: string) => {
  history.push(route || '/');
};

export const allowOnlyNumberWithOnEnter = (onEnter: () => void) => {
  return (e: any) => {
    //prevent keystrokes other than numbers, left,right arrow, enter, backspace,tab, delete
    if (
      [
        8, //backspace
        9, //tab
        37, //left
        39, //right
        46, //delete
        13, //enter
        16, //shift
        ...Array(10)
          .fill(null)
          .map((_: null, index: number) => index + 48), //numbers
      ].indexOf(e.keyCode) === -1
    )
      e.preventDefault();
    else if (e.keyCode === 13) {
      onEnter();
    }
  };
};

export const isValidOTP = (otp: string, digits: number = 6) => otp.length === digits && /^\d+$/.test(otp);

export const getDateFromEpoch = (epoch: number, timezone: string | undefined) => {
  const value = moment(epoch)
    .tz(getTimeZone(timezone || ''))
    .format('DD MMM YYYY');
  return value;
};
export const getDateTimeFromEpoch = (epoch: number) => {
  const value = moment(epoch).format('DD MMM YYYY, hh:mm a');
  return value;
};
export const getDifferenceInDates = (mfaDate: number, timezone: string | undefined) => {
  timezone = getTimeZone(timezone || '');
  const today = moment(Date.now()).tz(timezone || '');
  const mfaDateToCheck = moment(mfaDate).tz(timezone || '');
  const days = Math.abs(today.diff(mfaDateToCheck, 'days'));
  return days;
};
export const shouldShowEnforceMfaNotification = (accountMfaSettings: any, isEnabled: boolean | undefined, isNotificationAlreadyClosed: boolean, isBotworxAdmin: boolean) => {
  return !isBotworxAdmin && !isNotificationAlreadyClosed && accountMfaSettings?.requiredFrom > Date.now() && !isEnabled;
};
