import { AnyAction, combineReducers } from 'redux';
import EntitiesReducerConstants from '../common/entities/entities.constants';
import entitiesReducer, { initialState as entitiesInitialState } from '../common/entities/reducer';
import pageUIReducer, { initialState as initialPageUIState } from '../common/page-ui-state/reducer';
import automationReducer, { initialState as automationInitialState } from '../pages/automation/reducer';
import keywordGroupsReducer from '../pages/keyword-groups/reducer';
import tagsReducer, { initialState as tagsInitialState } from '../pages/tags/reducer';
import templatesReducer from '../pages/templates/reducer';
import commentsViewerReducer from '../pages/dashboard/components/comments-viewer/reducer';
import dashboardReducer from '../pages/dashboard/reducer';
import fbDeployReducer from '../pages/fb-login/reducer';
import landinPageReducer, { initialState as landingPageInitialState } from '../pages/landing/reducer';
import constants from '../pages/login/login.constants';
import loginReducer from '../pages/login/reducer';
import profileManagementReducer from '../pages/user-profile-pages/reducer';
import reportsReducer, { initialState as reportsPageInitialState } from '../pages/reports/reducer';
import inboxReducer from '../pages/inbox/reducer';

import { logOut } from './utils';
import platformActionConfigReducer from '../common/platform-action-config/reducer';
import roleLevelPermissionReducer from '../common/role-level-permission/reducer';
import broadcastsReducer from '../pages/broadcasts/reducer';
import segmentsReducer from '../pages/segments/reducer';
import settingsReducer from '../pages/settings/reducer';
import requestPageAccessReducer from '../pages/request-page-access/reducer';
// import broadcastsReducer from '../pages/broadcasts/reducer';
import adminToolReducer from '../pages/admin/reducer';
import audienceReducer, { initialState as audienceInitialState } from '../pages/audience/reducer';
import sentimentAnalysisPageReducer from '../pages/sentiment-analysis/reducer';

const rootReducer = combineReducers({
  entities: entitiesReducer,
  auth: loginReducer,
  commentsViewer: commentsViewerReducer,
  dashboard: dashboardReducer,
  pageUIState: pageUIReducer,
  fbDeploy: fbDeployReducer,
  automation: automationReducer,
  keywordGroups: keywordGroupsReducer,
  tags: tagsReducer,
  profileManagement: profileManagementReducer,
  landingPage: landinPageReducer,
  templates: templatesReducer,
  reportsPage: reportsReducer,
  platformActionConfig: platformActionConfigReducer,
  inboxPage: inboxReducer,
  userPermissions: roleLevelPermissionReducer,
  broadcastPage: broadcastsReducer,
  segments: segmentsReducer,
  settings: settingsReducer,
  adminTool: adminToolReducer,
  audience: audienceReducer,
  requestPageAccess: requestPageAccessReducer,
  sentimentAnalysis: sentimentAnalysisPageReducer,
});

const resettableRootReducer = (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) => {
  if (action.type === constants.LOG_OUT) {
    logOut();
    //state = undefined; // resets all, here we can keep  whatever state slices we want to. eg.const { users, comment } = state; state = { users, comment };
    state = { pageUIState: { ...initialPageUIState, RECENT_LOG_OUT: true } } as any;
  }
  if (action.type === EntitiesReducerConstants.RESET_STATE_ON_PAGE_CHANGE) {
    if (state) {
      state = { ...state, inboxPage: {} as ReturnType<typeof inboxReducer>, entities: { ...state.entities, posts: {}, subscriptions: null } }; //resetting posts, inbox when page chages
    }
  }
  if (action.type === EntitiesReducerConstants.RESET_DATA_ACCOUNT_CHANGE) {
    state = {
      entities: {
        ...entitiesInitialState,
        accounts: state?.entities.accounts,
        posts: {},
      } as ReturnType<typeof entitiesReducer>,
      auth: state?.auth as ReturnType<typeof loginReducer>,
      commentsViewer: {} as ReturnType<typeof commentsViewerReducer>,
      dashboard: {} as ReturnType<typeof dashboardReducer>,
      pageUIState: state?.pageUIState as ReturnType<typeof pageUIReducer>,
      fbDeploy: {} as ReturnType<typeof fbDeployReducer>,
      automation: automationInitialState as ReturnType<typeof automationReducer>,
      keywordGroups: {} as ReturnType<typeof keywordGroupsReducer>,
      templates: {} as ReturnType<typeof templatesReducer>,
      tags: tagsInitialState as ReturnType<typeof tagsReducer>,
      profileManagement: {
        usersActiveTabPane: state?.profileManagement?.usersActiveTabPane,
      } as ReturnType<typeof profileManagementReducer>,
      landingPage: landingPageInitialState as ReturnType<typeof landinPageReducer>,
      reportsPage: reportsPageInitialState as ReturnType<typeof reportsReducer>,
      platformActionConfig: state?.platformActionConfig as ReturnType<typeof platformActionConfigReducer>,
      inboxPage: {} as ReturnType<typeof inboxReducer>,
      userPermissions: state?.userPermissions as ReturnType<typeof roleLevelPermissionReducer>,
      broadcastPage: state?.broadcastPage as ReturnType<typeof broadcastsReducer>,
      segments: {} as ReturnType<typeof segmentsReducer>,
      settings: {} as ReturnType<typeof settingsReducer>,
      adminTool: state?.adminTool as ReturnType<typeof adminToolReducer>,
      audience: audienceInitialState as ReturnType<typeof audienceReducer>,
      requestPageAccess: {} as ReturnType<typeof requestPageAccessReducer>,
      sentimentAnalysis: {} as ReturnType<typeof sentimentAnalysisPageReducer>,
    }; // We need reset redux store on account changes except accounts,auth and pageUIState.
  }
  return rootReducer(state, action);
};

export default resettableRootReducer;
