import { userProfilePagesRoutes, userProfilePagesTabs } from '../user-profile-pages.constants';
import { PlatformBot, PlatformBots } from '../../../common/entities/entities.types';
import { ActionsRenderType, DateRenderType, NameRenderType, SocialMediaAccountsTableRecord } from '../user-profile-pages.types';
import { Dispatch, History } from '../../../types';
import { setConnectedToFb, setSelectedPlatformBot } from '../../../common/page-ui-state/actions';
import { UsersUiType, PermissionStatus } from '../type';
import { FBConfig } from '../../../config';
import { socialMediaPermissions } from '../components/social-media-accounts/social-media-accounts.constants';
import { platformLabels } from '../../../common/entities/entities.constants';
import { PlatformActionConfigPayload } from '../../../common/platform-action-config/platform-action-config.types';
export { getDefaultUser } from './create-users';

export const getTabFromPath = (path: string) => {
  if (path.includes(userProfilePagesRoutes.ACCOUNT_SETTINGS)) return userProfilePagesTabs[userProfilePagesRoutes.ACCOUNT_SETTINGS];
  if (path.includes(userProfilePagesRoutes.MANAGE_USERS)) return userProfilePagesTabs[userProfilePagesRoutes.MANAGE_USERS];
  if (path.includes(userProfilePagesRoutes.SOCIAL_MEDIA_ACCOUNTS)) return userProfilePagesTabs[userProfilePagesRoutes.SOCIAL_MEDIA_ACCOUNTS];
  if (path.includes(userProfilePagesRoutes.PAGE_HEALTH)) return userProfilePagesTabs[userProfilePagesRoutes.PAGE_HEALTH];
  return userProfilePagesTabs[userProfilePagesRoutes.MY_PROFILE];
};

export const getTableData = (platformBots: PlatformBots, platformActionConfig: PlatformActionConfigPayload) => {
  const tableData: SocialMediaAccountsTableRecord[] = [];
  Object.values(platformBots).forEach((platformBot: PlatformBot) => {
    tableData.push({
      key: `fb-${platformBot.name}`,
      name: platformBot.name,
      type: platformActionConfig?.[platformBot.platformAsInSystemSettings || '']?.platformLabel || platformLabels[platformBot.platformAsInSystemSettings || ''] || '',
      platform: platformBot.platformAsInSystemSettings || '',
      connected: true,
      platformBotId: platformBot.platformBotId,
      deploymentDate: platformBot.createdDate,
      profilePictureUrl: platformBot.profilePictureUrl as string,
      botId: platformBot.botId,
      createdByName: platformBot?.createdBy?.name,
      permissionList: platformBot?.permissions,
    });
  });
  return tableData;
};

export const socialMediaAccountsTableColumns = (nameRender: NameRenderType, actionsRender: ActionsRenderType, dateRender: DateRenderType) => [
  {
    title: 'Profile Name',
    dataIndex: 'name',
    key: 'name',
    render: nameRender,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Created By',
    dataIndex: 'createdByName',
    key: 'createdByName',
  },
  {
    title: 'Date Connected',
    dataIndex: 'deploymentDate',
    key: 'deploymentDate',
    render: dateRender,
  },
  {
    title: 'Actions',
    dataIndex: 'connected',
    key: 'actions',
    render: actionsRender,
  },
];

export const chooseNextPlatformBotOrRedirect = (
  platformBots: PlatformBots = {},
  unlinkedPlatformBotId: string,
  selectedPlatformBot: string = '',
  history: History,
  dispatch: Dispatch,
) => {
  // let nextPlatformBotId: string | undefined | null = selectedPlatformBot;
  if (unlinkedPlatformBotId === selectedPlatformBot) {
    const platformBotIdsArray = Object.keys(platformBots);
    if (platformBotIdsArray.length === 1) {
      dispatch(setConnectedToFb(false));
      dispatch(setSelectedPlatformBot(undefined));
      history.push('/');
      // nextPlatformBotId = null;
    } else {
      const nextPlatformBotId = platformBotIdsArray.find((platformBotId: string) => platformBotId !== unlinkedPlatformBotId);
      dispatch(setSelectedPlatformBot(nextPlatformBotId));
      history.push(`/${nextPlatformBotId}/socialMediaAccounts`);
    }
  }
};
// return nextPlatformBotId;
export const getTabsBasedPermission = (manageUserAllowed: boolean) => {
  const newTabs = {
    ...userProfilePagesTabs,
  };
  if (!manageUserAllowed) {
    delete newTabs.manageUsers;
  }
  return newTabs;
};
export const getExistingUserId = (users: UsersUiType, userEmail: string) => {
  return Object.values(users).find((u: any) => u.email === userEmail)?.id;
};

export const getLocationHash = (location: any, toGetParam?: string) => {
  const urlHashParams = new URLSearchParams(location.hash.replace('#', '?'));
  return urlHashParams.get(toGetParam || '') || '';
};

export const getPermissionsStatuses = (permissionList: string[]) => {
  if (!permissionList) return [];
  const requiredPermissions = Object.keys(FBConfig.permission.required);
  const permissionsWithTexts = socialMediaPermissions['facebook'];

  const permissionStatuses: PermissionStatus[] = [];

  for (const permission of requiredPermissions) {
    permissionStatuses.push({
      permission,
      text: permissionsWithTexts[permission],
      isGiven: permissionList.includes(permission),
      isRequired: true,
    });
  }

  for (const permission of permissionList) {
    if (!requiredPermissions.includes(permission)) {
      permissionStatuses.push({
        permission,
        text: permissionsWithTexts[permission] || permission,
        isGiven: true,
        isRequired: false,
      });
    }
  }

  return permissionStatuses;
};
