import { createAction } from '@reduxjs/toolkit';
import { Template } from '../../../../templates/templates.types';
import constants from '../comments-viewer.constants';
import { MediaContainer } from '../comments-viewer.types';
import { Comment, RawComment } from '../components/comment/comment.types';

export const updatePrimaryTabCounts = createAction<{
  updatedTotalCommentsCount: number;
  updatedActionableCommentsCount: number;
  updatedNonActionableCommentsCount: number;
  updatedEngagementsCount: number;
  updatedCurrentUserAssignedCommentsCount: number;
}>(constants.UPDATE_PRIMARY_TAB_COUNTS);

export const updateNonActionableSecondaryTabCounts = createAction<{
  updatedLabeledCommentsCount: number;
  updatedUnlabeledCommentsCount: number;
}>(constants.UPDATE_NON_ACTIONABLE_SECONDARY_TAB_COUNTS);
export const updateEngagementsSecondaryTabCounts = createAction<{
  updatedHiddenCommentsCount: number;
  updatedBrandReactionsCount: number;
  updatedPublicRepliesCount: number;
  updatedPrivateRepliesCount: number;
  updatedBlockedUsersCount: number;
  updatedDeletedCommentsCount: number;
  updatedReviewedCommentsCount: number;
  updatedAssignedCommentsCount: number;
}>(constants.UPDATE_ENGAGEMENTS_SECONDARY_TAB_COUNTS);

export const updateStatsOnClose = createAction<boolean>(constants.UPDATE_STATS_ON_CLOSE);
export const setCommentViewerCurrentTab = createAction<string>(constants.SET_COMMENT_VIEWER_CURRENT_TAB);
export const setCommentViewerEngagementsCurrentTab = createAction<string>(constants.SET_COMMENT_VIEWER_ENGAGEMENTS_CURRENT_TAB);
export const indicateRecentCommentMessage = createAction<boolean>(constants.INDICATE_RECENT_COMMENT_MESSAGE);
export const indicateSearchMessage = createAction<boolean>(constants.INDICATE_SEARCH_MESSAGE);
export const updateDismissAction = createAction<{ commentDismiss?: boolean; searchDismiss?: boolean }>(constants.UPDATE_DISMISS_ACTION);
export const setReplyModalModeAction = createAction<{ mode: string | null; prevMode?: string | null; commentId: string | null; message?: string } | null>(
  constants.SET_REPLY_MODAL_MODE,
);
export const clearReplyMessageFromQuickReply = createAction(constants.CLEAR_MESSAGE_FROM_QUICK_REPLY);
export const setOpenPreviewReplyModalAction = createAction<boolean>(constants.SET_OPEN_PREVIEW_REPLY_MODAL);
export const setReplyContent = createAction<string>(constants.SET_REPLY_CONTENT);
export const setIncludeSendPrivateReplyButton = createAction<boolean>(constants.SET_INCLUDE_SEND_PRIVATE_REPLY_BUTTON);
export const setPrivateMessageContent = createAction<string>(constants.SET_PRIVATE_MESSAGE_CONTENT);
export const setCreateTemplateName = createAction<string>(constants.CREATE_TEMPLATE_NAME);
export const setSelectedReplyTemplate = createAction<Template | null>(constants.SELECTED_TEMPLATE);
export const setMedia = createAction<MediaContainer>(constants.SET_MEDIA_URL);
export const setReplyType = createAction<string>(constants.SET_REPLY_TYPE);
export const setReplyTypePlusMode = createAction<string | null>(constants.SET_REPLY_TYPE_PLUS_MODE);
export const setSelectedUserComment = createAction<RawComment[]>(constants.SET_SELECTED_COMMENT);
export const setCommentsInSinglePage = createAction<Comment[]>(constants.SET_COMMENT_IN_SINGLE_PAGE);
export const setSelectedCommentPage = createAction<number>(constants.SET_SELECTED_COMMENT_PAGE);
export const setPreviousCommentPage = createAction<number>(constants.SET_SELECTED_PREVIOUS_COMMENT_PAGE);
export const setSelectAllFlag = createAction<boolean>(constants.SET_SELECT_ALL_FLAG);
export const setErrorSelectedComment = createAction(constants.SET_ERROR_USER_COMMENT, (userSelectedComments: RawComment[]) => ({
  payload: { userSelectedComments },
}));
