import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { removeSentimentAnalysisStats, setSelectedPostsCountForSentimentAnalysis, storeSentimentAnalysisStats } from '../actions';
import { SentimentAnalysisData } from '../types';

export type SentimentAnalysisPageStateType = {
  sentimentStats: SentimentAnalysisData;
  sentimentStatsAdvanced: SentimentAnalysisData;
  selectedPostsCount?: number;
};

export const initialState: SentimentAnalysisPageStateType = {
  sentimentStats: {
    present: {},
    past: {},
  },
  sentimentStatsAdvanced: {
    present: {},
    past: {},
  },
};

const sentimentAnalysisPageReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeSentimentAnalysisStats, (state: typeof initialState, action: ReturnType<typeof storeSentimentAnalysisStats>) => {
      if (action.payload.fetchAdvancedSentimentData) {
        if (action.payload.isPast) {
          if (state.sentimentStatsAdvanced?.past) {
            state.sentimentStats.past = action.payload.data;
          } else {
            state.sentimentStatsAdvanced = {
              ...state.sentimentStatsAdvanced,
              past: action.payload.data,
            };
          }
        } else {
          if (state.sentimentStatsAdvanced?.present) {
            state.sentimentStatsAdvanced.present = action.payload.data;
          } else {
            state.sentimentStatsAdvanced = {
              ...state.sentimentStatsAdvanced,
              present: action.payload.data,
            };
          }
        }
      } else {
        if (action.payload.isPast) {
          if (state.sentimentStats?.past) {
            state.sentimentStats.past = action.payload.data;
          } else {
            state.sentimentStats = {
              ...state.sentimentStats,
              past: action.payload.data,
            };
          }
        } else {
          if (state.sentimentStats?.present) {
            state.sentimentStats.present = action.payload.data;
          } else {
            state.sentimentStats = {
              ...state.sentimentStats,
              present: action.payload.data,
            };
          }
        }
      }
    })
    .addCase(removeSentimentAnalysisStats, (state: typeof initialState) => {
      state.sentimentStats = {
        present: {},
        past: {},
      };
    })
    .addCase(setSelectedPostsCountForSentimentAnalysis, (state: typeof initialState, action: ReturnType<typeof setSelectedPostsCountForSentimentAnalysis>) => {
      state.selectedPostsCount = action.payload;
    });
});

export default sentimentAnalysisPageReducer;
