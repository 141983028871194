import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { storeIntentsStats, storeEntityStats } from '../actions';
import { EntityType, IntentsType } from '../intent.type';

const initialStateIntents: IntentsType = {
  intentStats: [],
};
const initialStateIEntities: EntityType = {
  entityStats: [],
};

export const intentsReducer = createReducer(initialStateIntents, (builder: ActionReducerMapBuilder<typeof initialStateIntents>) => {
  builder.addCase(storeIntentsStats, (state: typeof initialStateIntents, action: ReturnType<typeof storeIntentsStats>) => {
    state.intentStats = action.payload;
  });
});
export const entitiesReducer = createReducer(initialStateIEntities, (builder: ActionReducerMapBuilder<typeof initialStateIEntities>) => {
  builder.addCase(storeEntityStats, (state: typeof initialStateIEntities, action: ReturnType<typeof storeEntityStats>) => {
    state.entityStats = action.payload;
  });
});
