import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { storeSentimentStats } from '../actions';
import { SentimentType } from '../types';

const initialState: SentimentType = {
  sentimentStats: {
    present: {},
    past: {},
  },
};

const sentimentReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder.addCase(storeSentimentStats, (state: typeof initialState, action: ReturnType<typeof storeSentimentStats>) => {
    if (action.payload.isPast) {
      state.sentimentStats.past = action.payload.data;
    } else {
      state.sentimentStats.present = action.payload.data;
    }
  });
});

export default sentimentReducer;
