import { Comments } from '../../../common/entities/entities.types';
import { actions } from '../../../config/platform-action-map';
import { Comment } from '../components/comments-viewer/components/comment/comment.types';
import { dashboardTabConstants } from '../constants';

export const getCalculatedWidth = (actualWidth: number, minWidth: number) => {
  // return actualWidth ? `calc(${actualWidth}% + ${minWidth}px)` : '0px';
  return actualWidth && actualWidth > 0 ? `${actualWidth}  ${minWidth}%` : '0';
};

export const supportedEngagementsCount = (
  supportedActions: Set<string>,
  hiddenCount: number,
  brandReactionsCount: number,
  publicRepliesCount: number,
  blockedUsersCount: number,
  deletedCommentsCount: number,
  privateRepliesCount: number,
  reviewedCommentsCount: number,
  assignedCommentsCount: number,
) => {
  return (
    (supportedActions.has(actions.HIDE) ? hiddenCount : 0) +
    (supportedActions.has(actions.LIKE) ? brandReactionsCount : 0) +
    (supportedActions.has(actions.PUBLIC_REPLY) ? publicRepliesCount : 0) +
    (supportedActions.has(actions.BLOCK) ? blockedUsersCount : 0) +
    (supportedActions.has(actions.DELETE) ? deletedCommentsCount : 0) +
    // Todo : Public Reply check -> supportedActions.has(actions.PUBLIC_REPLY) to br removed later from below Private Reply check
    (supportedActions.has(actions.PRIVATE_REPLY) || supportedActions.has(actions.PUBLIC_REPLY) ? privateRepliesCount : 0) +
    (supportedActions.has(actions.REVIEW) ? reviewedCommentsCount : 0) +
    (supportedActions.has(actions.ASSIGN) ? assignedCommentsCount : 0)
  );
};
export const moderationModeFilter = { field: 'isPlatformBotMention', eq: [true] };
export const zeroCommentsFilter = { field: 'commentsReceived', gte: 1 };
export const mentionFilterForNLP = { field: 'source', eq: ['mentions'] };
export const commentFilterForNLP = { field: 'source', eq: ['comments'] };
export const isCommentModeration = (moderationMode: string) => moderationMode === dashboardTabConstants.COMMENTS;
export const isMentionModeration = (moderationMode: string) => moderationMode === dashboardTabConstants.MENTIONS;

export const getSampleCommentIdForPosts = (comments: Comments | null) => {
  const postMappedWithSampleCommentId: { [key: string]: string } = {};
  Object.values(comments || {}).forEach((comment: Comment) => {
    if (comment?.userComment?.postId && !postMappedWithSampleCommentId[comment.userComment.postId]) {
      postMappedWithSampleCommentId[comment.userComment.postId] = comment.userComment.commentId;
    }
  });
  return postMappedWithSampleCommentId;
};
