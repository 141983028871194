import _ from 'lodash';
import { useEffect, useState } from 'react';
import { mobileWidth } from './config';
import { ActiveSubTabProps } from './types';
import * as utils from '../utils/';

export const isMobile = () => {
  const isMobile = /iphone|ipod|android|ie|blackberry/i.test(navigator.userAgent.toLowerCase());
  return isMobile;
};
export const getCurrentSubPage = () => {
  const filters = utils.getFiltersFromUrl();
  return filters?.layout?.activeSubPage?.value;
};

export const isScreenBelowFun = (width: number) => {
  return window.screen.width <= width;
};

export const isMobileFun = () => {
  return window.screen.width <= mobileWidth;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileFun());

  useEffect(() => {
    const handleResize = _.debounce(
      () => {
        setIsMobile(isMobileFun());
      },
      500,
      { leading: true },
    );
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
export const getMobileNavigationProps = (
  activeSubPage: ActiveSubTabProps,
  pageKeyForFilter: string,
  prevLocation?: ActiveSubTabProps,
  nextSubPage?: ActiveSubTabProps,
  isBackButtonPressed?: boolean,
) => {
  return {
    activeSubPage,
    nextSubPage,
    prevLocation,
    pageKeyForFilter,
    isBackButtonPressed,
  };
};

export const isEmptyObject = (obj: any) => {
  return _.isEmpty(obj);
};