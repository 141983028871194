import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { registerAmplifyAnalytics } from '../../../../../analytics/utils';
import { removePlatformBot } from '../../../../../common/entities/actions';
import { deletePlatformBot } from '../../../../../common/entities/actions/async-actions';
import { platforms } from '../../../../../common/entities/entities.constants';
import { usePlatformBotId } from '../../../../../hooks';
import { RootState } from '../../../../../types';
import ConfirmationPopover from '../../../../common/confirmation-popover';
import { unlinkPage } from '../../../../fb-login/actions/async-actions';
import { chooseNextPlatformBotOrRedirect } from '../../../utils';

type UnlinkActionProps = {
  platformBotIdToUnlink: string | null;
};

const UnlinkAction = ({ platformBotIdToUnlink }: UnlinkActionProps) => {
  const dispatch = useDispatch();
  const selectedPlatformBot = usePlatformBotId();
  const history = useHistory();
  const platformBots = useSelector((state: RootState) => state.entities.platformBots);

  const rollback = () => {
    const onSuccess = () => {
      platformBotIdToUnlink && dispatch(removePlatformBot(platformBotIdToUnlink));
      registerAmplifyAnalytics.unlinkAPage(platformBotIdToUnlink || '');
      chooseNextPlatformBotOrRedirect(platformBots, platformBotIdToUnlink!, selectedPlatformBot, history, dispatch);
    };
    if (platformBots && platformBotIdToUnlink && platformBots[platformBotIdToUnlink].platformAsInSystemSettings === platforms.FACEBOOK) {
      platformBotIdToUnlink && dispatch(unlinkPage(platformBotIdToUnlink, onSuccess));
    } else {
      platformBotIdToUnlink && dispatch(deletePlatformBot(platformBots![platformBotIdToUnlink].id, onSuccess));
    }
  };
  return (
    <ConfirmationPopover title={'Are you sure you want to unlink?'} onConfirm={() => rollback()} okText={'Unlink'} cancelText={'Cancel'} placement={'bottomRight'}>
      <a>Unlink</a>
    </ConfirmationPopover>
  );
};

export default UnlinkAction;
