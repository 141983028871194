/* eslint-disable max-lines-per-function */
import transform from '../transformer/platform-action-config';
import { GenericSettings, platformActionConfigFetched, platformActionsConfig, setSupportedPlatforms, setSystemSettings } from '.';
import { AsyncThunkDispatch, RootState } from '../../../types';
import { get } from '../../api-calls/api-client';
import { showErrorModal } from '../../utils';
import { AccountLiteUIConfig } from '../../entities/entities.types';
import { cloneDeep } from 'lodash';
import { systemSettingsTypes } from '../../../config';
import { broadcastEngagementDefaultSettings as broadcastEngagement } from '../../../pages/broadcasts/constants';

export const getPlatformActionConfig = ({
  accountConfig,
  onSuccess,
}: {
  accountId?: string;
  accountConfig: AccountLiteUIConfig;
  moderationMode: string;
  onSuccess?: () => void;
}) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const { auth, entities, platformActionConfig } = getState();
    const authToken = auth.authToken;
    // const currentUserId = auth.user?.id || '';
    const currrentAccountId = entities.account?.id || '';
    /*Searching CurrentAccount Roles from all roles for current user*/
    const currentUserRole = auth.user?.userRoles?.find((role: any) => role.account === currrentAccountId)?.role;
    // const currentUserRole = entities.users[currentUserId]?.userRoles?.find((role: any) => role.account === currrentAccountId)?.role;
    const isSystemSettingsAbsent = Object.keys(platformActionConfig.systemSettings || {}).length === 0;
    try {
      response = isSystemSettingsAbsent
        ? await get(`utils/getSystemSettings?type=${systemSettingsTypes.UI_PLATFORM_CONFIG}`, authToken as string)
        : { data: { settings: platformActionConfig.systemSettings, genericSettings: platformActionConfig.genericSettings }, status: 200 };
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      const { dbActionConfig: actionConfig, genericSettings } = transform.getPlatformActionConfig(cloneDeep(response.data), accountConfig, currentUserRole);
      const filteredBasedonModerationMode: any = {};
      Object.keys(actionConfig).forEach((key: string) => {
        filteredBasedonModerationMode[key] = {
          ...actionConfig[key],
        };
      });
      dispatch(platformActionsConfig(filteredBasedonModerationMode));
      actionConfig && Object.keys(actionConfig) && dispatch(setSupportedPlatforms(Object.keys(actionConfig)));
      dispatch(platformActionConfigFetched(true));
      if (isSystemSettingsAbsent) {
        dispatch(
          setSystemSettings(
            transform.getSystemSettings({
              ...response.data,
              settings: { ...response.data?.settings, default: { broadcastEngagement, ...response.data?.settings?.default } },
            }),
          ),
        );
        dispatch(GenericSettings(genericSettings));
      }
      onSuccess?.();
    }
  };
};
