import { v4 as uuid } from 'uuid';
export const logicalOperatorTypes = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
];

export const conditionOperator = {
  NEWER_THAN: 'newer than',
  OLDER_THAN: 'older than',
};

export const geoConditionOperator = {
  DISTANCE_LESS_THAN: 'distance less than',
  DISTANCE_GREATER_THAN: 'distance greater than',
};
export const broadcastConditionOperator = { BROADCAST_RECEIVED: 'broadcastexist', BROADCAST_NOT_RECEIVED: 'broadcastnotexist' };

export const broadcastGroupTypes = [
  { value: broadcastConditionOperator.BROADCAST_RECEIVED, label: 'broadcast received' },
  { value: broadcastConditionOperator.BROADCAST_NOT_RECEIVED, label: 'broadcast not received' },
];

export const conditionsOpForExists = ['exist', 'not exist'];
export const operatorsSupportingValueUnit = [conditionOperator.NEWER_THAN, conditionOperator.OLDER_THAN];
export const operatorsForDistanceValueUnit = [geoConditionOperator.DISTANCE_LESS_THAN, geoConditionOperator.DISTANCE_GREATER_THAN];
export const operatorsForBroadcasts = [broadcastConditionOperator.BROADCAST_RECEIVED, broadcastConditionOperator.BROADCAST_NOT_RECEIVED];
export const distanceTypesValue = {
  KM: 'km',
  MI: 'mi',
  M: 'm',
};
export const distanceUnitTypes = ['km', 'mi', 'm'];
export const distanceTypes = [
  { value: distanceTypesValue.KM, label: 'KM' },
  { value: distanceTypesValue.MI, label: 'MI' },
  { value: distanceTypesValue.M, label: 'M' },
];

export const geoConditionOperatorOptions: any = [
  { value: geoConditionOperator.DISTANCE_LESS_THAN, label: geoConditionOperator.DISTANCE_LESS_THAN },
  { value: geoConditionOperator.DISTANCE_GREATER_THAN, label: geoConditionOperator.DISTANCE_GREATER_THAN },
];

export const areaCodeConditionOperatorOptions: any = [
  { value: 'regex', label: 'IN' },
  { value: 'not regex', label: 'NOT IN' },
];

export const userInboxVariableConditionOperatorOptions: any = [
  { value: 'regex', label: 'IN' },
  { value: 'not regex', label: 'NOT IN' },
];

export const areaCodeConditionOperator = {
  IN: 'regex',
  NOT_IN: 'not regex',
};

export const userInboxVariableConditionOperator = {
  IN: 'regex',
  NOT_IN: 'not regex',
};

export const conditionOperators = [
  { value: 'equal', label: 'equal to' },
  { value: 'not equal', label: 'not equal to' },
  { value: 'greater than', label: 'greater than' },
  { value: 'less than', label: 'less than' },
  { value: 'substring', label: 'contains' },
  { value: 'not substring', label: 'not contains' },
  { value: 'exist', label: 'exists' },
  { value: 'not exist', label: 'not exists' },
  { value: 'regex', label: 'matches' },
  { value: 'not regex', label: 'not matches' },
  { value: 'word', label: 'has word' },
  { value: 'not word', label: 'not has word' },
  { value: 'expression', label: 'expression' },
  { value: 'not expression', label: 'not expression' },
  { value: conditionOperator.NEWER_THAN, label: conditionOperator.NEWER_THAN },
  { value: conditionOperator.OLDER_THAN, label: conditionOperator.OLDER_THAN },
];

export const allGroupTypes = [...conditionOperators, { value: 'broadcastexist', label: 'Broadcast Received' }, { value: 'broadcastnotexist', label: 'Broadcast Not Received' }];

export const ageConditionOperators = [
  { value: 'age', label: 'age' },
  { value: 'age range', label: 'age range' },
];

const getNewGroup = () => {
  return {
    id: uuid(),
    logicalOperator: 'and',
    ifStatements: [
      {
        id: uuid(),
        variable: { type: '', macro: '', name: '' },
        condition: 'equal',
        value: '',
        valueUnit: unitTypesValue.DAYS,
      },
    ],
  };
};

export const dateOfBirthVariable = { name: 'Date of Birth', type: 'profile', macro: '{{profile.dateOfBirth}}' };
export const geoVariable = { name: 'UserGeo', type: 'profile', macro: '{{profile.location.geo.lat}},{{profile.location.geo.long}}' };
// export const areaCodeVariable = { name: 'UserGeo', type: 'profile', macro: '{{profile.location.geo.lat}},{{profile.location.geo.long}}' };
export const areaCodeVariable = { name: 'US Area Code', type: 'profile', macro: '{{platformUserId}}' };
export const userInboxLabelVariable = { id: 'userInboxLabel', macro: '{{data.variables.messageInboxCustomlabels}}', name: 'User Inbox Labels', type: 'user' };
export const notificationMacro = '{{notification.';
export const countrycodeMacro = 'profile.location.countryCode';

export const getNewAgeGroups = () => {
  const statementId = uuid();
  return [
    {
      id: uuid(),
      variable: dateOfBirthVariable,
      condition: conditionOperator.NEWER_THAN,
      value: '',
      valueUnit: unitTypesValue.YEARS,
      metadata: {
        type: 'date',
        format: 'MM/DD/YYYY',
        statementId,
        condition: 'age',
      },
    },
    {
      id: uuid(),
      variable: dateOfBirthVariable,
      condition: conditionOperator.OLDER_THAN,
      value: '',
      valueUnit: unitTypesValue.YEARS,
      metadata: {
        type: 'date',
        format: 'MM/DD/YYYY',
        statementId,
        condition: 'age',
      },
    },
  ];
};

export const defaultConditions = () => {
  const defaultRecommendation = {
    logicalOperator: 'and',
    childConditions: [getNewGroup()],
  };
  return { ...defaultRecommendation };
};

export type conditions = {
  logicalOperator?: string;
  childConditions?: condition[];
};

export type condition = {
  id?: string;
  logicalOperator: string;
  ifStatements: childConditionsIfStatement[];
  childConditions?: condition[];
};
export type SegmentCondition = {
  ifStatements?: childConditionsIfStatement[];
  childConditions?: conditions[];
  logicalOperator?: string;
  segmentId?: string;
  notCondition?: boolean;
  tagId?: string;
  tagGroupName?: string;
};
export type SegmentGroup = {
  childConditions: SegmentCondition[];
  logicalOperator: string;
  segmentId: string;
};
export type childConditionsIfStatement = {
  id?: string;
  variable: childConditionsIfStatementVariableType;
  condition: string;
  value: string;
  valueUnit?: string;
  metadata?: { [key: string]: any };
};
export type childConditionsIfStatementVariableType = {
  name: string;
  type?: string;
  id?: string;
  macro: string;
};

export const unitTypesValue = {
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  YEARS: 'years',
};

export const timeUnitTypes = [unitTypesValue.DAYS, unitTypesValue.HOURS, unitTypesValue.MINUTES, unitTypesValue.SECONDS];
export const unitTypes = [
  { value: unitTypesValue.DAYS, label: 'Days' },
  { value: unitTypesValue.HOURS, label: 'Hours' },
  { value: unitTypesValue.MINUTES, label: 'Minutes' },
  { value: unitTypesValue.SECONDS, label: 'Seconds' },
];
export const countryCodeMap: any = {
  AF: 'Afghanistan',
  AX: 'Aland',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AQ: 'Fr. S. Antarctic Lands',
  AG: 'Antigua and Barb.',
  AR: 'Argentina',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herz.',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'Br. Indian Ocean Ter.',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Is.',
  CF: 'Central African Rep.',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Dem. Rep. Congo',
  CD: 'Congo',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus Rep.',
  CZ: 'Czech Rep.',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Rep.',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Eq. Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  PF: 'Fr. Polynesia',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard I. and McDonald Is.',
  HN: 'Honduras',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Dem. Rep. Korea',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lao PDR',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  ML: 'Mali',
  MT: 'Malta',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  MP: 'N. Mariana Is.',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  LC: 'Saint Lucia',
  PM: 'St. Pierre and Miquelon',
  VC: 'St. Vin. and Gren.',
  WS: 'Samoa',
  ST: 'São Tomé and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Is.',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'S. Geo. and S. Sandw. Is.',
  SS: 'S. Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Is.',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VI: 'U.S. Virgin Is.',
  EH: 'W. Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  CW: 'Curaçao',
};

/* eslint-disable @typescript-eslint/naming-convention */
export const botPlatformsTooltip: any = {
  internal: 'Amplify Chat Plugin',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twilio_sms: 'SMS',
  gbm: 'Google Business Messaging',
  twitter: 'Twitter',
  youtube: 'YouTube',
  whatsapp: 'WhatsApp',
  twilio_wa: 'WhatsApp',
  karix_wa: 'WhatsApp',
  cm_whatsapp: 'WhatsApp',
  tiktok_organic: 'TikTok',
  tiktok: 'TikTok Paid',
  threads: 'Threads',
};
