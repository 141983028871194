/* eslint-disable max-lines-per-function */
import { getGcPriority } from '../../../common/entities/utils';
import { dashboardTabConstants } from '../../dashboard/constants';
import { defaultTagAsConditions } from '../config';
import { PrivateReplyActionType, commentTypeAPI } from '../constants';

import { CampaignType, IfStatementType, GroupConditionType, ConditionType, ResponseActionType, CampaignTypeUI } from '../types';

export function transformCampaignUIToApi(campaign: CampaignTypeUI) {
  const actions = transformGcResponseActionUIToAPI(campaign);
  const transformedCampaign = {
    ...campaign,
    conditions: rempveIdsFromConditions(campaign.conditions),
    actions,
    priority: getGcPriority(actions),
    ...(campaign.targetPercentage && {
      commentFilter: {
        filterType: 'expression',
        filterValue: `(Math.random()*100 <= ${campaign.targetPercentage})`,
      },
    }),
    ...(campaign.selectedTag && {
      conditions: convertSelectedTagToConditions(campaign.selectedTag),
    }),
  };

  if (!campaign.hasAdvancedConditions && !campaign.selectedTag) {
    transformedCampaign.conditions = { logicalOperator: 'and', childConditions: [] };
  }
  // if (campaign.createFor) {
  //   if (!transformedCampaign.conditions) {
  //     transformedCampaign.conditions = { logicalOperator: 'and', childConditions: [] };
  //   }
  //   transformedCampaign.conditions = {
  //     ...transformedCampaign.conditions,
  //     childConditions: [...transformedCampaign.conditions.childConditions, ...convertCreatedForToConditions(campaign.createFor)],
  //   };
  // }
  if (campaign.createFor) {
    const commentTypeVal = createCommentTypeForAPI(campaign.createFor);
    commentTypeVal ? ((transformedCampaign as CampaignType).commentType = commentTypeVal) : delete (transformedCampaign as CampaignType).commentType;
    (transformedCampaign as CampaignType).targetPostMentions = !!campaign.createFor.postMentions;
  }

  delete transformedCampaign.targetPercentage;
  delete transformedCampaign.analytics;
  delete transformedCampaign?.botId;
  delete transformedCampaign.selectedTag;
  delete transformedCampaign.hasAdvancedConditions;
  delete transformedCampaign.createFor;

  return transformedCampaign;
}

const rempveIdsFromConditions = (conditions: ConditionType) => {
  if (conditions && conditions.childConditions) {
    return {
      ...conditions,
      childConditions: conditions.childConditions.map((condition: GroupConditionType) => {
        delete condition.id;
        return {
          ...condition,
          ifStatements: condition.ifStatements.map((statament: IfStatementType) => {
            delete statament.id;
            return statament;
          }),
        };
      }),
    };
  }
  return conditions;
};

function transformGcResponseActionUIToAPI(transformedCampaign: CampaignType) {
  const actions = transformedCampaign.actions || [];
  return actions.map((a: ResponseActionType) => {
    if ([PrivateReplyActionType.TEXT, PrivateReplyActionType.PUBLIC, PrivateReplyActionType.TAG, PrivateReplyActionType.PRIVATE].includes(a.type)) {
      return {
        type: a.type === PrivateReplyActionType.PRIVATE ? PrivateReplyActionType.TEXT : a.type,
        replyMessage: a.replyMessage,
        replyImageUrl: a.replyImageUrl,
        replyGIFUrl: a.replyGIFUrl,
      };
    } else if (PrivateReplyActionType.MODULE === a.type) {
      return { type: a.type, replyNodes: a.replyNodes };
    }
    return { type: a.type };
  });
}

export const convertSelectedTagToConditions = (selectedTag: string) => {
  return {
    ...defaultTagAsConditions,
    childConditions: [{ ...defaultTagAsConditions.childConditions[0], ifStatements: [{ ...defaultTagAsConditions.childConditions[0].ifStatements[0], value: selectedTag }] }],
  };
};
// export const convertCreatedForToConditions = (createFor?: { [key: string]: boolean }) => {
//   if (createFor) {
//     const ifStatement = {
//       condition: 'equal',
//       value: 'true',
//       variable: { name: 'Mention', type: 'custom', macro: createdForVariableMacro },
//     };
//     if ([dashboardTabConstants.COMMENTS, dashboardTabConstants.MENTIONS].every((x: string) => createFor && createFor[x])) {
//       return [];
//     }
//     if (createFor[dashboardTabConstants.COMMENTS]) {
//       ifStatement.condition = 'not equal';
//     } else if (createFor[dashboardTabConstants.MENTIONS]) {
//       ifStatement.condition = 'equal';
//     }
//     return [
//       {
//         ifStatements: [ifStatement],
//         logicalOperator: 'and',
//       },
//     ];
//   }
//   return [];
// };
export const createCommentTypeForAPI = (createFor?: { [key: string]: boolean }) => {
  if (!createFor) {
    return commentTypeAPI.COMMENTS;
  }

  if (!createFor[dashboardTabConstants.MENTIONS] && !createFor[dashboardTabConstants.COMMENTS]) {
    return undefined;
  }

  if ([dashboardTabConstants.COMMENTS, dashboardTabConstants.MENTIONS].every((x: string) => createFor && createFor[x])) {
    return commentTypeAPI.ALL;
  }
  if (createFor[dashboardTabConstants.COMMENTS]) {
    return commentTypeAPI.COMMENTS;
  } else if (createFor[dashboardTabConstants.MENTIONS]) {
    return commentTypeAPI.MENTIONS;
  }
  return commentTypeAPI.ALL;
};
