export const UserListingTabs = {
  accountOwners: {
    label: 'Admins',
    value: 'accountOwners',
  },
  pageOwners: {
    label: 'Team Members',
    value: 'pageOwners',
  },
};

export const roleOptions = [
  { label: 'Admin', value: 'AccountOwner' },
  { label: 'Team Member', value: 'BotOwner' },
];

export const userRolesLite = {
  ACCOUNT_OWNER: 'AccountOwner',
  BOT_OWNER: 'BotOwner',
  AMPLIFY_ACCOUNT_OWNER: 'AmplifyAccountOwner',
  ACCOUNT_READ_ONLY: 'AccountReadOnly',
  BOT_READ_ONLY: 'BotReadOnly',
};

export const readOnlyUserRoles = [userRolesLite.ACCOUNT_READ_ONLY, userRolesLite.BOT_READ_ONLY];
