import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  showCreateKeywordGroupButton,
  storePropertiesForUpdatingKeywordGroups,
  changeCurrentTab,
  updateBotTrainingStatus,
  didKeywordGroupsChange,
  trainingStarted,
  storeBasePath,
} from '../actions';
import { BotTrainingInfo } from '../keyword-groups.types';

const initialState: {
  showCreateKeywordGroupButton: boolean;
  entitiesId: string;
  currentTab: string;
  botTrainingInfo?: BotTrainingInfo;
  trainingCurrently: boolean;
  keywordGroupsChanged: boolean;
  basePath: string;
} = {
  showCreateKeywordGroupButton: false,
  entitiesId: '',
  currentTab: '',
  trainingCurrently: false,
  keywordGroupsChanged: false,
  basePath: '',
};

const keywordGroupsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(showCreateKeywordGroupButton, (state: typeof initialState, action: ReturnType<typeof showCreateKeywordGroupButton>) => {
      state.showCreateKeywordGroupButton = action.payload;
    })
    .addCase(storeBasePath, (state: typeof initialState, action: ReturnType<typeof storeBasePath>) => {
      state.basePath = action.payload;
    })
    .addCase(storePropertiesForUpdatingKeywordGroups, (state: typeof initialState, action: ReturnType<typeof storePropertiesForUpdatingKeywordGroups>) => {
      state.entitiesId = action.payload.entitiesId;
    })
    .addCase(changeCurrentTab, (state: typeof initialState, action: ReturnType<typeof changeCurrentTab>) => {
      state.currentTab = action.payload;
    })
    .addCase(updateBotTrainingStatus, (state: typeof initialState, action: ReturnType<typeof updateBotTrainingStatus>) => {
      state.botTrainingInfo = { ...state.botTrainingInfo, ...action.payload };
      state.trainingCurrently = action.payload.trainingStatus === 'training' ? true : false;
    })
    .addCase(trainingStarted, (state: typeof initialState) => {
      state.trainingCurrently = true;
    })
    .addCase(didKeywordGroupsChange, (state: typeof initialState, action: ReturnType<typeof didKeywordGroupsChange>) => {
      state.keywordGroupsChanged = action.payload;
    });
});

export default keywordGroupsReducer;
