export const gReCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

export const googleAnalyticsConfig = {
  URL: 'https://www.googletagmanager.com/gtag/js?id=',
  TRACKING_ID: process.env.REACT_APP_TRACKING_ID,
};
export { FBConfig } from './fb-config';
export const logLevel = 'ALL';
export const Amplitude = {
  apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
};

export const numberOfReportsToShow = 50; //10;

export const fallbackProfilePicURL = '/images/icons/stock-insta-dp.svg';
// export const webRendererBaseUrl = 'https://amplify-web-renderer-staging.s3.amazonaws.com';
export const webRendererBaseUrl = 'https://chat.amplify.ai';
export const webRendererCss = 'http://localhost:5000/amplifyChatTheme.min.css';

export const amplifyShortDomain = 'https://afy.ai'; //e.g 'https://afy.ai/KpQBDX'
export const shortUrlKeyLength = 6; //e.g /KpQBDX
export const twilioCharReplaceDoc = 'https://www.twilio.com/docs/messaging/services/smart-encoding-char-list#glyph';

export const systemSettingsTypes = {
  UI_PLATFORM_CONFIG: 'ui_platform_config',
  ACM_BOT_TYPE_SETTINGS: 'acm_bot_type_settings',
};
export const recipientEmail = {
  amplifySupportEmail: 'support@cliqz.ai',
  amplifySalesEmail: 'sales@amplify.ai',
};
