import { createAction } from '@reduxjs/toolkit';
import { GenericObject } from '../../../types';
import { actionTypesToPayload, messageStatusAll, messageTypeAiToUi } from '../config';
import { ConversationUI, InboxMessagesUI } from '../types';
import constants from './constants';

type StoreInboxMessages = {
  messageList: InboxMessagesUI;
  hasMore: boolean;
  pageNumber: number;
  tabName: string;
  endDateForNextCall: string;
  platformUserIDToLoad?: string;
  doNotSelectedFirstMessage?: boolean;
  endDateForPolling?: string;
  isPollingRequest?: boolean;
  isSubscribed?: boolean;
};

type UpdateAMessage = {
  messageId: string;
  updatedValues: {
    [key: string]: any;
  };
  removeMessage?: boolean;
};
export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;
type aggregationDecrementPayload = {
  status: typeof messageTypeAiToUi[keyof typeof messageTypeAiToUi];
  messageStatus: typeof messageStatusAll[number];
};
type StoreConversationMessages = {
  messageList: ConversationUI;
  pageNumber: number;
  lastMessageTime: number;
  hasMore: boolean;
  syncData?: boolean;
  platformUserId?: string;
};
type StoreNLPTagsForFilter = {
  [key: string]: { id: string; count: number }[];
};
type SelectForBulkAction = {
  messageId: string;
  selected: boolean;
};

type UpdateInboxMessages = {
  updateActionType: keyof typeof actionTypesToPayload;
  messages: InboxMessagesUI;
  messagesWithError: InboxMessagesUI;
  messagesWithConflicts: InboxMessagesUI;
  isDifferentUserAssign?: boolean;
};

type UpdateConversationMessageUI = {
  metadata?: any;
};

type bufferMessages = {
  messageId: string;
  messageTime: string;
  message: {
    actions: [];
    data: {};
  };
};

export const storeInboxMessages = createAction<StoreInboxMessages>(constants.STORE_INBOX_MESSAGES);
export const storeConversationMessages = createAction<StoreConversationMessages>(constants.STORE_CONVERSATION);
export const updateConversationMessage = createAction(constants.UPDATE_CONVERSATION_MESSAGE, (conversationId: string, updatedConversation: UpdateConversationMessageUI) => {
  return { payload: { conversationId, updatedConversation } };
});
export const storeSessionData = createAction<GenericObject | undefined>(constants.STORE_SESSION_DATA);
export const resetInboxMessages = createAction(constants.RESET_INBOX_MESSAGES);
export const setSelectedInboxMessage = createAction<string | undefined>(constants.SET_SELECTED_INBOX_MESSAGE);
export const updateAMessage = createAction<UpdateAMessage>(constants.UPDATE_MESSAGE);
export const setFilterChanged = createAction(constants.SET_FILTER_CHANGED);
export const storeInboxAggregation = createAction<any>(constants.STORE_INBOX_AGGREGATION);
export const decrementAggregationForMessageStatus = createAction<aggregationDecrementPayload>(constants.DECREMENT_AGGREAGTION);
export const storeLabelToColorMap = createAction<GenericObject>(constants.STORE_LABEL_TO_COLOR);
export const updateLabelToColorMap = createAction<GenericObject>(constants.UPDATE_LABEL_TO_COLOR);
export const storeNlpTagsForFilter = createAction<StoreNLPTagsForFilter>(constants.STORE_NLP_TAGS_FOR_FILTER);
export const selectForBulkAction = createAction<SelectForBulkAction>(constants.SELECT_FOR_BULK_ACTION);
export const setInboxTab = createAction<string>(constants.SET_INBOX_TAB);
export const updateMessages = createAction<UpdateInboxMessages>(constants.UPDATE_MESSAGES);
export const resetMessageError = createAction<string>(constants.RESET_MESSAGE_ERROR);
export const setNewMessageArrivedStatus = createAction<boolean>(constants.SET_NEW_MESSAGE_ARRIVED_STATUS);
export const storeInboxBufferConversation = createAction<bufferMessages>(constants.STORE_BUFFER_CONVERSATION);
export const setSubscribeStatus = createAction<boolean>(constants.SET_SUBSCRIBE_STATUS);
export const updateInboxUserBlockStatus = createAction<string>(constants.REMOVE_INBOX_USER);
