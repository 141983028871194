import { createAction } from '@reduxjs/toolkit';
import { SelectOption } from '../../../types';
import {
  FanGroupsByField,
  ChangeAudienceFilter,
  FanChangeCountWithBroadcastInfoType,
  FanChangeCountType,
  FilterTypeExcludingAge,
  MemberDetailState,
  MembersPageType,
  AudiencePageType,
  AppliedSegmentUsersFilters,
} from '../type';
import constants from './constants';

export type UpdateIdsForBackLink = {
  [key: string]: string;
};

export const setCurrentAudienceTab = createAction<string>(constants.SETCURRENTAUDIENCETAB);
export const setFanGroupsByField = createAction(constants.SET_FANGROUPS_BY_FIELDS, (fanGroupsByField: FanGroupsByField, totalFansWithFilters: number) => ({
  payload: { fanGroupsByField, totalFansWithFilters },
}));
export const setFanChangeCountWithBroadcastInfo = createAction<{ [key: string]: FanChangeCountWithBroadcastInfoType }>(constants.SET_FAN_CHANGE_WITH_BROADCAST_INFO);

export const setFilterOptionsForField = createAction(
  constants.SET_FILTER_OPTIONS_FOR_FILTER,
  (filterOptions: { [key: string]: SelectOption }, filterType: FilterTypeExcludingAge) => ({
    payload: { filterOptions, filterType },
  }),
);

export const updateTotalFansCount = createAction(constants.SET_TOTAL_FANS, (totalFans: number, recentFanChangeCount: { [key: string]: FanChangeCountType }) => ({
  payload: { totalFans, recentFanChangeCount },
}));

export const resetAudienceTab = createAction(constants.RESET_NEW_MEMBERS_USER_DETAILS, (pageName: AudiencePageType, retainFilters: boolean = false) => ({
  payload: { pageName, retainFilters },
}));

export const applyAudienceFilters = createAction(
  constants.APPLY_AUDIENCE_FILTERS,
  (
    pageName: AudiencePageType,
    filters: { appliedFilters?: ChangeAudienceFilter; subscriptionEndDate?: null | string } | any | AppliedSegmentUsersFilters = {},
    isResetFilter: boolean = false,
    deepMergeFilters: boolean = false,
  ) => ({
    payload: { pageName, isResetFilter, filters, deepMergeFilters },
  }),
);
export const storeMembersData = createAction<{ members: MemberDetailState[]; hasMore: boolean; isLoadingMore: boolean; pageName: MembersPageType }>(
  constants.STORE_NEW_MEMBERS_DATA,
);
export const storeMembersCount = createAction<{ memberCount: number; pageName: MembersPageType }>(constants.SET_NEWMEMBERS_COUNT);
export const updateLabelsInInbox = createAction<string[]>(constants.UPDATE_LABELS_IN_MESSAGE_INBOX);
export const updateMemberData = createAction<{ member: MemberDetailState; pageName: MembersPageType }>(constants.UPDATE_MEMBER_DATA);
export const removeBlockedAudienceUser = createAction<string>(constants.REMOVE_BLOCKED_USER);
// export const storeSessionInAudience = createAction<sessionState>(constants.STORE_SESSION_DATA);
