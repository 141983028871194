// import { platforms } from '../../entities/entities.constants';
import { AccountLiteUIConfig } from '../../entities/entities.types';
import { PlatformActionConfigAPIResponse } from '../platform-action-config.types';

const getPlatformActionConfig = (apiResponse: PlatformActionConfigAPIResponse, accountConfig: AccountLiteUIConfig, role?: string) => {
  const dbActionConfig = apiResponse.settings;
  const genericSettings = apiResponse.genericSettings;
  let overriddenAccountConfigActions: string[] = [];
  if (accountConfig) {
    Object.keys(dbActionConfig).forEach((key: string) => {
      if (accountConfig?.overriddenRoles?.teamMember && accountConfig?.overriddenRoles?.teamMember.role === role) {
        overriddenAccountConfigActions = accountConfig?.overriddenRoles.teamMember[key]?.actions || accountConfig?.overriddenRoles.teamMember?.default?.actions;
      } else if (accountConfig?.overriddenRoles?.admin && accountConfig?.overriddenRoles?.admin.role === role) {
        overriddenAccountConfigActions = accountConfig?.overriddenRoles.admin[key]?.actions || accountConfig?.overriddenRoles.admin?.default?.actions;
      }
      const accountConfigActions = accountConfig[key]?.actions || accountConfig?.default?.actions;
      if (overriddenAccountConfigActions?.length && dbActionConfig[key] && dbActionConfig[key].actions) {
        // We need to support actions which are available in the system level platform settings.
        // Hence we need to check that account level actions are present in the system level config.
        dbActionConfig[key].actions = overriddenAccountConfigActions.filter((a: string) => dbActionConfig[key].actions.includes(a));
      } else if (accountConfigActions && accountConfigActions.length && dbActionConfig[key] && dbActionConfig[key].actions) {
        dbActionConfig[key].actions = accountConfigActions.filter((a: string) => dbActionConfig[key].actions.includes(a));
      }
    });
  }
  return { dbActionConfig, genericSettings };
};

const getSystemSettings = (apiResponse: PlatformActionConfigAPIResponse) => {
  const response = apiResponse.settings;
  return response;
};

const transformations = {
  getPlatformActionConfig,
  getSystemSettings,
};
export default transformations;
