import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import {
  changeCurrentTabTemplates,
  changeTitleTemplates,
  storeTemplatesBasePath,
  showCreateTemplateButton,
  // setTemplatesLoaded,
  storePRTemplatesStringMap,
  storeTemplateToMessageMap,
  deleteTemplateFromMessageMap,
  editTemplateInMessageMap,
  switchTemplatesTab,
  setTemplatesPrevModalMode,
} from '../actions';
import { Templates } from '../templates.types';

export const initialState: {
  showCreateTemplateButton: boolean;
  currentTab: string;
  routeName: string;
  basePath: string;
  activeTab?: string;
  // templatesLoaded: boolean;
  templatesStringMap: Templates;
  templatesPrevModalModal: string;
} = {
  showCreateTemplateButton: false,
  currentTab: '',
  routeName: '',
  basePath: '',
  // templatesLoaded: false,
  templatesStringMap: {},
  templatesPrevModalModal: '',
};

const templatesReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(showCreateTemplateButton, (state: typeof initialState, action: ReturnType<typeof showCreateTemplateButton>) => {
      state.showCreateTemplateButton = action.payload;
    })
    .addCase(changeTitleTemplates, (state: typeof initialState, action: ReturnType<typeof changeTitleTemplates>) => {
      state.routeName = action.payload;
    })
    .addCase(switchTemplatesTab, (state: typeof initialState, action: ReturnType<typeof switchTemplatesTab>) => {
      state.activeTab = action.payload;
    })
    .addCase(storeTemplatesBasePath, (state: typeof initialState, action: ReturnType<typeof storeTemplatesBasePath>) => {
      state.basePath = action.payload;
    })
    .addCase(changeCurrentTabTemplates, (state: typeof initialState, action: ReturnType<typeof changeCurrentTabTemplates>) => {
      state.currentTab = action.payload;
    })
    // .addCase(setTemplatesLoaded, (state: typeof initialState, action: ReturnType<typeof setTemplatesLoaded>) => {
    //   state.templatesLoaded = action.payload;
    // })
    .addCase(storePRTemplatesStringMap, (state: typeof initialState, action: ReturnType<typeof storePRTemplatesStringMap>) => {
      state.templatesStringMap = action.payload;
    })
    .addCase(storeTemplateToMessageMap, (state: typeof initialState, action: ReturnType<typeof storeTemplateToMessageMap>) => {
      state.templatesStringMap[action.payload.message] = action.payload;
    })
    .addCase(deleteTemplateFromMessageMap, (state: typeof initialState, action: ReturnType<typeof deleteTemplateFromMessageMap>) => {
      const requiredKey = Object.keys(state.templatesStringMap).find((key: string) => action.payload === state.templatesStringMap[key].id);
      requiredKey && delete state.templatesStringMap[requiredKey];
    })
    .addCase(editTemplateInMessageMap, (state: typeof initialState, action: ReturnType<typeof editTemplateInMessageMap>) => {
      const requiredKey = Object.keys(state.templatesStringMap).find((key: string) => action.payload.id === state.templatesStringMap[key].id);
      requiredKey && (state.templatesStringMap[requiredKey] = action.payload);
    })
    .addCase(setTemplatesPrevModalMode, (state: typeof initialState, action: ReturnType<typeof setTemplatesPrevModalMode>) => {
      state.templatesPrevModalModal = action.payload;
    });
});

export default templatesReducer;
