const broadcastConstants: BroadcastConstants = {
  STORE_MESSAGE_USAGE: 'STORE_MESSAGE_USAGE',
  STORE_AUDIENCE_DATA: 'STORE_AUDIENCE_DATA',
  SET_FETCHING_MESSAGR_USAGE: 'SET_FETCHING_MESSAGR_USAGE',
};
type BroadcastConstants = {
  STORE_MESSAGE_USAGE: string;
  STORE_AUDIENCE_DATA: string;
  SET_FETCHING_MESSAGR_USAGE: string;
};
export default broadcastConstants;
