import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

export const getHighestPermission = (permissions: any, entity: string, permissionType: string) => {
  if (permissions) {
    if (permissions[entity]) {
      return permissions[entity]['*'] || permissions[entity][permissionType];
    } else if (permissions['*']) {
      return permissions['*']['*'] || permissions['*'][permissionType];
    }
  }
  return false;
};
export const useUserPermissions = (entity: string, permissionType: string) => {
  const permissions = useSelector((state: RootState) => state.userPermissions.currentPermissions);
  if (permissions) {
    if (permissions[entity]) {
      return permissions[entity]['*'] || permissions[entity][permissionType];
    } else if (permissions['*']) {
      return permissions['*']['*'] || permissions['*'][permissionType];
    }
  }
  return false;
};
export const useSystemPermissions = (entity: string, permissionType: string) => {
  const permissions = useSelector((state: RootState) => state.userPermissions.systemLevelPermissions);
  if (permissions) {
    if (permissions[entity]) {
      return permissions[entity]['*'] || permissions[entity][permissionType];
    } else if (permissions['*']) {
      return permissions['*']['*'] || permissions['*'][permissionType];
    }
  }
  return false;
};
