import { ReactComponent as Broadcast } from '../../../../../assets/svg/broadcast/broadcast-white.svg';
import { ReactComponent as Inbox } from '../../../../../assets/svg/dashboard/inbox.svg';
import { ReactComponent as Home } from '../../../../../assets/svg/dashboard/home.svg';
import { ReactComponent as Settings } from '../../../../../assets/svg/settings.svg';
import { ReactComponent as Books } from '../../../../../assets/svg/book.svg';
import { pageKeysForFilter } from '../../../../dashboard/constants';

export const AmpLiteMobNavTabs = {
  INBOX: 'Inbox',
  BROADCASTS: 'Broadcasts',
  SETTINGS: 'Settings',
  RESOURCES: 'Resources',
  HOME: 'Home',
  ACCOUNT_SETTINGS: 'Account',
  PROFILE_SETTINFS: 'Profile',
  SOCIAL_MEDIA_ACCOUNTS: 'Social Media Account',
};

export const AmpLiteMobPages = {
  INBOX: 'inbox',
  BROADCASTS: 'broadcasts',
  SETTINGS: 'settings',
  RESOURCES: 'resources',
  HOME: 'home',
  ACCOUNT_SETTINGS: 'settings',
  SOCIAL_MEDIA_ACCOUNTS: 'socialMediaAccounts',
  PROFILE_SETTINFS: 'profileSettings',
};

export const AmpLiteMoblinks = [
  {
    title: AmpLiteMobNavTabs.HOME,
    route: `/:accountId/:platformBotId/${AmpLiteMobPages.HOME}`,
    page: AmpLiteMobPages.HOME,
    subTabs: [],
    icon: Home,
  },
  {
    title: AmpLiteMobNavTabs.BROADCASTS,
    route: `/:accountId/:platformBotId/${AmpLiteMobPages.BROADCASTS}`,
    page: AmpLiteMobPages.BROADCASTS,
    subTabs: [],
    icon: Broadcast,
  },
  {
    title: AmpLiteMobNavTabs.INBOX,
    route: `/:accountId/:platformBotId/${AmpLiteMobPages.INBOX}`,
    page: AmpLiteMobPages.INBOX,
    pageKeyForFilter: pageKeysForFilter.INBOX,
    subTabs: [],
    icon: Inbox,
  },
  {
    title: AmpLiteMobNavTabs.SETTINGS,
    subTabs: [
      {
        title: AmpLiteMobNavTabs.ACCOUNT_SETTINGS,
        route: `/:accountId/:platformBotId/settings`,
        page: AmpLiteMobPages.ACCOUNT_SETTINGS,
        icon: Settings,
        subTabs: [],
      },
      {
        title: AmpLiteMobNavTabs.SOCIAL_MEDIA_ACCOUNTS,
        route: `/:accountId/:platformBotId/socialMediaAccounts`,
        page: AmpLiteMobPages.SOCIAL_MEDIA_ACCOUNTS,
        icon: Settings,
        subTabs: [],
      },
    ],
    icon: Settings,
  },
  {
    title: AmpLiteMobNavTabs.RESOURCES,
    route: `/:accountId/:platformBotId/resources`,
    page: AmpLiteMobPages.RESOURCES,
    subTabs: [],
    icon: Books,
  },
];

export const homeTabForFbInsta = 'Broadcast Summary';
