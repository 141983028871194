import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorModalContainer from './common/modal/containers/error-modal-container';
import { SpinnerWithPromise } from './common/spinner';
import Routes from './Routes';
import store from './store';
import ProdWarningRibbon from './common/components/ribbon/prod-warning-ribbon';
import RotateDeviceInfo from './common/rotate-device';

const App: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <ErrorModalContainer />
      <SpinnerWithPromise />
      <RotateDeviceInfo />
      <ProdWarningRibbon />
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
};

export default App;
