import React from 'react';
import { Button } from 'antd';
import { WarningModalProps } from '../types';
import { GenericAntModal } from './index';
import ErrorModalStyle from '../../scss/error-modal.module.scss';
export const WarningModal = (props: WarningModalProps) => {
  const {
    errorMessage,
    title,
    visible,
    clearError,
    proceedWithWarningCallback,
    saveBtnText = 'Confirm',
    cancelBtnText = 'Cancel' /*isReportable, sendErrorReport, endPoint */,
  } = props;
  const handleClearError = (): void => {
    clearError && clearError();
  };
  const modalFooter = [
    <div key='footer-btn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {clearError && (
        <Button key='back' type='default' onClick={clearError} className={`cancelBtn button is-light is-medium`}>
          {cancelBtnText}
        </Button>
      )}
      <Button
        key='save'
        type='primary'
        onClick={proceedWithWarningCallback}
        className={`confirmBtn button is-primary is-medium`}
        // disabled={!variableSelected || (!defaultAdvancedValue.default && !defaultAdvancedValue.advanced) || defaultAdvancedValue?.advanced?.toLowerCase()?.includes('set')}
      >
        {saveBtnText}
      </Button>
    </div>,
  ];
  return (
    <GenericAntModal
      title={title}
      visible={visible}
      footer={modalFooter}
      forceRender={true}
      onCancel={handleClearError}
      zIndex={10001}
      closable={false}
      destroyOnClose={true}
      wrapClassName={ErrorModalStyle.errorModalContainer}
      width={330}
      mask={true}
      maskClosable={false}
      centered={true}
    >
      <div> {errorMessage}</div>
    </GenericAntModal>
  );
};
