const audienceConstants: AudienceConstant = {
  SETCURRENTAUDIENCETAB: 'SETCURRENTAUDIENCETAB',
  SET_FANGROUPS_BY_FIELDS: 'SET_FANGROUPS_BY_FIELDS',
  SET_FAN_CHANGE_WITH_BROADCAST_INFO: 'SET_FAN_CHANGE_WITH_BROADCAST_INFO',
  SET_SUBSCRIPTION_ENDDATE: 'SET_SUBSCRIPTION_ENDDATE',
  SET_FILTER_OPTIONS_FOR_FILTER: 'SET_FILTER_OPTIONS_FOR_FILTER',
  APPLY_AUDIENCE_FILTERS: 'APPLY_AUDIENCE_FILTERS',
  RESET_ANALYSIS_TAB: 'RESET_ANALYSIS_TAB',
  SET_TOTAL_FANS: 'SET_TOTAL_FANS',
  STORE_NEW_MEMBERS_DATA: 'STORE_NEW_MEMBERS_DATA',
  RESET_NEW_MEMBERS_USER_DETAILS: 'RESET_NEW_MEMBERS_USER_DETAILS',
  UPDATE_LABELS_IN_MESSAGE_INBOX: 'UPDATE_LABELS_IN_MESSAGE_INBOX',
  SET_NEWMEMBERS_COUNT: 'SET_NEWMEMBERS_COUNT',
  STORE_SESSION_DATA: 'STORE_SESSION_DATA',
  UPDATE_MEMBER_DATA: 'UPDATE_MEMBER_DATA',
  REMOVE_BLOCKED_USER: 'REMOVE_BLOCKED_USER',
};

type AudienceConstant = {
  SETCURRENTAUDIENCETAB: string;
  SET_FANGROUPS_BY_FIELDS: string;
  SET_FAN_CHANGE_WITH_BROADCAST_INFO: string;
  SET_SUBSCRIPTION_ENDDATE: string;
  SET_FILTER_OPTIONS_FOR_FILTER: string;
  APPLY_AUDIENCE_FILTERS: string;
  RESET_ANALYSIS_TAB: string;
  SET_TOTAL_FANS: string;
  STORE_NEW_MEMBERS_DATA: string;
  RESET_NEW_MEMBERS_USER_DETAILS: string;
  UPDATE_LABELS_IN_MESSAGE_INBOX: string;
  SET_NEWMEMBERS_COUNT: string;
  STORE_SESSION_DATA: string;
  UPDATE_MEMBER_DATA: string;
  REMOVE_BLOCKED_USER: string;
};
export default audienceConstants;
