export const SwConfig = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    // eslint-disable-next-line no-console
    console.info('service worker on update state');
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      // eslint-disable-next-line no-console
      console.info('service worker waitingServiceWorker');
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        // eslint-disable-next-line no-console
        console.info('service worker state change');
        if (event.target.state === "activated") {
          // eslint-disable-next-line no-console
          console.info('service worker activated');
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: (registration: ServiceWorkerRegistration) => {
    // eslint-disable-next-line no-console
    console.info('service worker on success state');
    // eslint-disable-next-line no-console
    console.log(registration);
  },
};

export const handleSWUpdateOnRouteChange = (): void => {
  if (!navigator || typeof navigator.serviceWorker === 'undefined') {
    // eslint-disable-next-line no-console
    console.log('navigator.serviceWorker is undefined');
    return;
  }
  // eslint-disable-next-line no-console
  console.log('Updating service worker');
  navigator.serviceWorker.getRegistrations().then((registrationsArray: any) => {
    Array.isArray(registrationsArray) &&
      registrationsArray.forEach((sw: any) => {
        sw && sw.update();
      });
  });
};