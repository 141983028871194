import { getItemFromQueryParam } from './url-operations';

//if tken exits no login will appear

export const initializeAuthFromQueryParams = () => {
  const token = getItemFromQueryParam('token');
  const useAsIframe = shouldUseAsIframe();
  if (token && useAsIframe) {
    return {
      user: {
        firstName: 'iframe',
        id: 'test',
        email: 'iframe',
        mfa: { enabled: false },
      },
      permissions: null,
      authToken: Array.isArray(token) ? token[0] : token,
    };
  }
  return null;
};

export const shouldUseAsIframe = () => Boolean(getItemFromQueryParam('useAsIframe')); //used in page ui state to set initial state
