import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
// import { liteFeatureTabs } from '../../constants';
import { storeStandardCampaign, storeStandardKeywords } from '../actions';
import { LandingPageReduxState } from '../types';

export const initialState: LandingPageReduxState = {
  standardTaggingCampaigns: [],
  standardKeywords: {},
  standardCampaignsInsta: [],
  standardCampaignsTwitter: [],
};

const landingPageReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeStandardCampaign, (state: typeof initialState, action: ReturnType<typeof storeStandardCampaign>) => {
      state.standardTaggingCampaigns = action.payload.stndardCampaigns;
      state.standardCampaignsInsta = action.payload.standardCampaignsInsta;
      state.standardCampaignsTwitter = action.payload.standardCampaignsTwitter;
    })
    .addCase(storeStandardKeywords, (state: typeof initialState, action: ReturnType<typeof storeStandardKeywords>) => {
      state.standardKeywords = action.payload;
    });
});

export default landingPageReducer;