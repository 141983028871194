import { Key, pathToRegexp } from 'path-to-regexp';
import { RouteParams } from '../../../types/state';
import { deeplyLinkedRoutes } from '../constants';

export const initializeRouteParamsFromUrl = () => {
  let routeParams: RouteParams = {};
  const url = window.location.pathname;
  routeParams = getRouteParams(url);
  return routeParams;
};

export const getRouteParams = (url: string) => {
  const requiredMatch: string[] = [];
  const requiredKeys: Key[] = [];
  Object.values(deeplyLinkedRoutes).some((route: string) => {
    const keys: Key[] = [];
    const regexp = pathToRegexp(route, keys);
    const match = regexp.exec(url);
    if (match) {
      keys.forEach((key: Key, index: number) => {
        if (key.name) {
          requiredMatch.push(match[index + 1]);
          requiredKeys.push(keys[index]);
        }
      });
      return true;
    }
    return false;
  });

  const values = requiredMatch;
  const params = requiredKeys.reduce((memo: RouteParams, key: Key, index: number) => {
    memo[key.name as keyof RouteParams] = values[index];
    return memo;
  }, {});
  return params;
};
