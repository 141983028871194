import { inboxConstants } from '../../inbox/constants';

export const DoneAction = {
  like: 'Comment liked',
  block: 'User banned',
  public: 'Comment replied',
  publicReply: 'Comment(s) replied',
  privateReply: 'Comment(s) privately replied',
  delete: 'Comment deleted',
  hide: 'Comment hidden',
  createCampaign: 'Campaign created',
  updateCampaign: 'Campaign updated',
  deleteCampaign: 'Campaign deleted',
  createTag: 'Tag created',
  updateTag: 'Tag updated',
  deleteTag: 'Tag deleted',
  changeUserInfo: 'User information saved',
  changePassword: 'Password changed',
  changeAccountInfo: 'Account information saved',
  createUser: 'User created',
  deleteUser: 'User deleted',
  review: 'Comment reviewed',
  templateCreated: 'Template created',
  templateUpdated: 'Template updated',
  templateDeleted: 'Template archived',
  [inboxConstants.ACTIONABLE]: 'Conversation moved to Primary section',
  [inboxConstants.NON_ACTIONABLE]: 'Conversation moved to General section',
  [inboxConstants.UNSUBSCRIBE]: 'User is unsubscribed',
  [inboxConstants.SUBSCRIBE]: 'User is subscribed',
  roleCreate: 'User role created',
  createSegment: 'Segment created',
  updateSegment: 'Segment updated',
  deleteSegment: 'Segment deleted',
  unlike: 'Comment unliked',
  assign: 'Comment assigned',
  unassign: 'Comment unassigned',
  unblock: 'User unbanned',
  unhide: 'Comment shown',
  deletePublicReply: 'Reply comment deleted',
};

export const SuccessText = 'successfully!';
