/* eslint-disable max-lines-per-function */
/*eslint-disable no-useless-escape*/
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

export const checkIfValidlatitudeAndlongitude = (str: string = '') => {
  // Regular expression to check if string is a latitude and longitude
  const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
  return regexExp.test(str);
};

export const checkIfValidAreaCodes = (str: string = '') => {
  // Regular expression to check if string is a latitude and longitude
  const regexPattern = /^\s*([2-9]\d{2}\s*,\s*)*([2-9]\d{2})\s*$/;
  return regexPattern.test(str);
};

export const convertAreaCodeValToRegex = (inputString?: string) => {
  const numbers: any = inputString?.split(',').map((number?: any) => number.trim());
  const regexPattern = `^\\+1((${numbers.join(')|(')}))\\d{7}$`;

  let err;
  try {
    new RegExp(regexPattern);
  } catch (error: any) {
    err = error?.response?.message;
  }
  return { regexPattern, error: err };
};

export const extractNumbersFromAreaCodeRegex = (regexPattern?: any) => {
  const match = regexPattern.match(/\(\d+\)/g);
  if (match) {
    const numbers = match.map((group: any) => group.match(/\d+/)[0]);
    return numbers.join(', ');
  }
  return null;
};
export const editSegment = (segmentConditions: any, action: any) => {
  const { key, value, segmentId, conditions } = action;

  const segments = _.cloneDeep(segmentConditions);
  let segment = {
    ...find(segments, {
      id: segmentId,
    }),
  };
  const idx = findIndex(segments, (o: any) => o.id === segmentId);
  if ('InExCondition' === key) {
    if (value === 'include') {
      delete segment.notCondition;
    } else {
      segment.notCondition = true;
    }
    segments[idx] = segment;
  } else {
    segment[key] = value;
    segment = {
      ...segment,
      ...conditions,
      childConditions: conditions.childConditions,
      ifStatements: conditions.ifStatements,
    };
    segments[idx] = segment;
  }
  return segments;
};

export const removeSegment = (segmentConditions: any, segmentId: any) => {
  const segments = _.cloneDeep(segmentConditions);
  const idx = findIndex(segments, (o: any) => o.id === segmentId);
  segments && segments.splice(idx, 1);
  return segments;
};

export const editTargetCondition = (segmentConditions: any, operator: any) => {
  const segments = _.cloneDeep(segmentConditions);
  return { ...segments, logicalOperator: operator };
};

export const addGroup = (segmentConditions?: any) => {
  const newGroup: any = {
    id: uuid(),
    logicalOperator: 'and',
    ifStatements: [
      {
        id: uuid(),
        variable: '',
        condition: 'equal',
        value: '',
      },
    ],
  };
  const isSegmentGroup = true;

  if (isSegmentGroup) {
    newGroup.segmentId = '';
    newGroup.childConditions = [
      {
        id: uuid(),
      },
    ];
    delete newGroup.ifStatements;
  }
  const segments = _.cloneDeep(segmentConditions || []);
  const operatorInUse = segmentConditions?.[0]?.logicalOperator || 'and';
  newGroup.logicalOperator = operatorInUse;
  return [...segments, newGroup];
};
