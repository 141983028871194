import React, { ReactNode } from 'react';
// import AmplifyPopover from '../ant-popover';
import Styles from './index.module.scss';
type Props = {
  text: ReactNode;
  onClick?: any;
};

const Ribbon = ({ text, onClick }: Props) => {
  return (
    <div className={Styles.ribbon} onClick={onClick}>
      {text}
    </div>
  );
};

export default Ribbon;
