import React, { ReactElement } from 'react';
import AmplifyButtonStyles from './button.module.scss';
import classNames from 'classnames';

type AmplifyButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  [key: string]: unknown;
};

const AmplifyButton = ({ className, onClick, disabled, ...rest }: AmplifyButtonProps): ReactElement => {
  return <button className={classNames(AmplifyButtonStyles.ampLiteButton, className)} onClick={onClick} disabled={disabled} {...rest} />;
};
export default AmplifyButton;
