/* eslint-disable max-len */
import { get, post } from '../../../../../common/api-calls/api-client';
import { showErrorModal } from '../../../../../common/utils';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../../../types';
import { storeFbPages, storeInstaAccessToken, storeInstagramPages } from '../../../../fb-login/actions';
import { setPlatformBotPermissions } from '../../../actions';
import { SocialMediaAccountsTableRecord } from '../../../user-profile-pages.types';
import { ConfirmTwitterDeploymentApiResponse, DeployTwitterApiResponse, instaPageListFbApiResponse } from '../social-media-accounts.types';

export const deployTwitter = ({ redirectPageType }: { redirectPageType: string }, done?: (url: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const accountId = state.entities.account?.id;
    const platformBotId = state.pageUIState.selectedPlatformBot;
    const authToken = state.auth?.authToken;
    const url = `platformBots/deployTwitterBot`;
    const body = {
      accountId,
      isAmplifyLite: true,
      uiQueryParams: {
        accountId,
        ...(platformBotId && { platformBotId }),
        redirect: redirectPageType,
      },
    };
    post(url, body, authToken!)
      .then((response: { data: DeployTwitterApiResponse }) => {
        const url = response?.data?.url;
        done && done(url);
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
      });
  };
};
export const confirmTwitterDeployment = (
  { backendQueryParam, oAuthToken, oAuthVerifier }: { backendQueryParam: string; oAuthToken: string; oAuthVerifier: string },
  done?: (success: boolean) => void,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const url = `twitter/callback?oauth_token=${oAuthToken}&oauth_verifier=${oAuthVerifier}&q=${backendQueryParam}`;
    get(url, authToken!)
      .then((response: { data: ConfirmTwitterDeploymentApiResponse }) => {
        const success = response?.data?.success;
        done && done(success);
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
        done && done(false);
      });
  };
};

export const getPlatformBotPermissions = (record: SocialMediaAccountsTableRecord, done?: (data: string[]) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const { botId = '', platformBotId } = record;
    const url = `bots/${botId}/getFbTokenPermissions?platform=${record.platform}`;
    get(url, authToken!)
      .then((response: { data: string[] }) => {
        const permissions = [...response.data].sort() || [];
        dispatch(setPlatformBotPermissions(platformBotId || '', permissions));
        done && done(response.data);
      })
      .catch((error: any) => {
        dispatch(setPlatformBotPermissions(platformBotId || '', []));
        showErrorModal(error, dispatch);
      });
  };
};
export const getInstagramPageList = (code?: string, redirectUri?: string, done?: (pageList: any[]) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `facebook/getInstagramAccountList`;
    const state = getState();
    const authToken = state.auth?.authToken;
    const accountId = state.entities?.account?.id;
    const isFbSystemUser =
      state?.auth?.permissions?.botPermissions &&
      (state?.auth?.permissions?.botPermissions['*'] || state?.auth?.permissions?.botPermissions['deployAsFbBusinessManagerSystemUser']);
    post(url, { code, accountId, isFbSystemUser, redirectUri }, authToken!)
      .then((response: instaPageListFbApiResponse) => {
        const transformedInstaPagesList: any = (response?.data?.currUserPages || [])?.filter((igpage?: any) => !!igpage?.instagramAccountId);

        dispatch(storeInstaAccessToken(response.data.fbAccessToken));
        dispatch(storeInstagramPages(transformedInstaPagesList));
        response?.data?.systemUserPages?.length && dispatch(storeFbPages({ fbPageList: { systemUserPages: response.data.systemUserPages } }));
        done && done(transformedInstaPagesList);
        return transformedInstaPagesList;
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
        dispatch(storeInstagramPages([]));
      });
  };
};
