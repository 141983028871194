import { Popconfirm } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';
import React, { ReactElement } from 'react';
import { useConfirmationModalContext } from '../../../common/components/confirmationModal';
import styles from './confirmation-popover.module.scss';

export type ConfirmationPopoverProps = {
  children: ReactElement;
  title: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  okText: string;
  cancelText: string;
  overRideExistingClass?: boolean;
  overlayClassName?: string;
  placement?: 'topRight' | 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom' | undefined;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  align?: any;
  okButtonProps?: NativeButtonProps;
  disabled?: boolean;
  isConfirmationModalSupport?: boolean;
};

const ConfirmationPopover = ({
  children,
  title,
  onConfirm,
  okText,
  onCancel,
  cancelText,
  overlayClassName,
  getPopupContainer,
  placement = 'topRight',
  disabled,
  overRideExistingClass = true,
  isConfirmationModalSupport = false,
  ...rest
}: ConfirmationPopoverProps) => {
  const modalContext = useConfirmationModalContext();
  const handleOnClick = async () => {
    if (isConfirmationModalSupport) {
      const result = await modalContext.showConfirmation();
      result && onConfirm && onConfirm();
    } else {
      onConfirm && onConfirm();
    }
  };
  return (
    <Popconfirm
      title={title}
      onConfirm={handleOnClick}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      icon={null}
      overlayClassName={overRideExistingClass ? overlayClassName || styles.container : `${styles.container} ${overlayClassName}`}
      placement={placement}
      getPopupContainer={getPopupContainer}
      destroyTooltipOnHide
      disabled={disabled}
      {...rest}
    >
      {children}
    </Popconfirm>
  );
};

export default ConfirmationPopover;
