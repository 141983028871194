const FBDeployConstants: FBDeployConstantsType = {
  TOGGLE_FB_LOGIN_MODAL_DISPLAY: 'TOGGLE_FB_LOGIN_MODAL_DISPLAY',
  STORE_FB_PAGES: 'STORE_FB_PAGES',
  SHOW_FB_PAGE_LIST: 'SHOW_FB_PAGE_LIST',
  FB_PAGE_DEPLOY_SUCCESS: 'FB_PAGE_DEPLOY_SUCCESS',
  STORE_INSTAGRAM_PAGES: 'STORE_INSTAGRAM_PAGES',
  STORE_IG_PAGES_GRAPH_API: 'STORE_IG_PAGES_GRAPH_API',
  STORE_INSTA_ACCESS_TOKEN: 'STORE_INSTA_ACCESS_TOKEN',
  SET_FB_PAGE_RESPONSE: 'SET_FB_PAGE_RESPONSE',
};
type FBDeployConstantsType = {
  TOGGLE_FB_LOGIN_MODAL_DISPLAY: string;
  STORE_FB_PAGES: string;
  SHOW_FB_PAGE_LIST: string;
  FB_PAGE_DEPLOY_SUCCESS: string;
  STORE_INSTAGRAM_PAGES: string;
  STORE_IG_PAGES_GRAPH_API: string;
  STORE_INSTA_ACCESS_TOKEN: string;
  SET_FB_PAGE_RESPONSE: string;
};

export default FBDeployConstants;
