import { pageFilterKeys } from '../../../../constants';
import { commentViewerTabs } from '../../comments-viewer.constants';
import { SetFilterToStorageAndRedux } from '../../comments-viewer.types';

const CommentConstants: CommentConstantsType = {
  COMMENT_ACTION_SUCCESS: 'COMMENT_ACTION_SUCCESS',
  COMMENT_ACTION_FAILURE: 'COMMENT_ACTION_FAILURE',
  MAX_ACTIONS_SHOWN: 5,
  MAX_ACTIONS_SHOWN_WITH_ASSIGN: 3,
};
type CommentConstantsType = {
  COMMENT_ACTION_SUCCESS: string;
  COMMENT_ACTION_FAILURE: string;
  MAX_ACTIONS_SHOWN: number;
  MAX_ACTIONS_SHOWN_WITH_ASSIGN: number;
};
export default CommentConstants;
export const engagementTypes = {
  MANUAL: 'manual',
  AUTOMATED: 'automated',
  EXTERNAL: 'external',
};
export const engagementActionTypes = {
  hide: {
    [engagementTypes.AUTOMATED]: 'automatedHide',
    [engagementTypes.MANUAL]: 'hide',
    [engagementTypes.EXTERNAL]: 'externalHide',
  },
  like: {
    [engagementTypes.AUTOMATED]: 'automatedLike',
    [engagementTypes.MANUAL]: 'like',
    [engagementTypes.EXTERNAL]: 'externalLike',
  },
  block: {
    [engagementTypes.AUTOMATED]: 'automatedBlock',
    [engagementTypes.MANUAL]: 'block',
  },
  publicReply: {
    [engagementTypes.AUTOMATED]: 'automatedPublicReply',
    [engagementTypes.MANUAL]: 'publicReply',
    [engagementTypes.EXTERNAL]: 'externalPublicReply',
  },
  privateReply: {
    [engagementTypes.AUTOMATED]: 'automatedPrivateReply',
    [engagementTypes.MANUAL]: 'privateReply',
  },
  delete: {
    [engagementTypes.AUTOMATED]: 'automatedDelete',
    [engagementTypes.MANUAL]: 'delete',
    [engagementTypes.EXTERNAL]: 'externalUserCommentDelete',
  },
  review: {
    [engagementTypes.AUTOMATED]: 'automatedReview',
    [engagementTypes.MANUAL]: 'review',
  },
  assign: {
    [engagementTypes.MANUAL]: 'assign',
  },
};
export const engagementActions = {
  AUTOMATED_HIDE: 'automatedHide',
  MANUAL_HIDE: 'hide',
  EXTERNAL_HIDE: 'externalHide',
  AUTOMATED_PUBLIC_REPLY: 'automatedPublicReply',
  EXTERNAL_PUBLIC_REPLY: 'externalPublicReply',
  MANUAL_PUBLIC_REPLY: 'publicReply',
  EXTERNAL_LIKE: 'externalLike',
  AUTOMATED_LIKE: 'automatedLike',
  MANUAL_LIKE: 'like',
  AUTOMATED_BLOCK: 'automatedBlock',
  MANUAL_BLOCK: 'block',
  EXTERNAL_DELETE: 'externalDelete',
  AUTOMATED_DELETE: 'automatedDelete',
  MANUAL_DELETE: 'delete',
  MANUAL_REVIEW: 'review',
  MANUAL_PRIVATE_REPLY: 'privateReply',
  AUTOMATED_PRIVATE_REPLY: 'automatedPrivateReply',
  AUTOMATED_REVIEW: 'automatedReview',
  MANUAL_ASSIGN: 'assign',
};
export const engagementTabNamesAndActions = {
  [commentViewerTabs.hiddenComments]: engagementActionTypes.hide,
  [commentViewerTabs.brandReaction]: engagementActionTypes.like,
  [commentViewerTabs.privateReplies]: engagementActionTypes.privateReply,
  [commentViewerTabs.publicReplies]: engagementActionTypes.publicReply,
  [commentViewerTabs.blockedUsers]: engagementActionTypes.block,
  [commentViewerTabs.reviewedComments]: engagementActionTypes.review,
  [commentViewerTabs.deletedComments]: engagementActionTypes.delete,
  [commentViewerTabs.assignedComments]: engagementActionTypes.assign,
};

export const setFiltersToStorage: SetFilterToStorageAndRedux = {
  page: 'comments',
  key: pageFilterKeys.FILTERS,
  storeInLocalStorage: false,
};
