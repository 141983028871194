import { chartColors as defaultChartColors, engagementChartColors } from '../common/components/charts/config';
import { options } from '../common/components/word-cloud/config';
import { DefaultCommentBreakDownChartConfig } from '../pages/dashboard/components/comment-moderation/utility/config';
import {
  chartColors,
  commentBreakDownChartConfig,
  intentTagStrokeColor,
  wordMapColors,
  sentimentColors,
  leftNavigationBgClassName,
  globalContainerClassName,
  logoImage,
  logoImageHeader,
  feviconImage,
  trillerLoaderColor,
  brandName,
} from './triller-theme-config';
import * as cliqzTheme from './cliqz-theme-config';
import * as juliusTheme from './julius-theme-config';

const hostname = (window as any).location?.host;
const trillerTheme = {
  chartColors,
  commentBreakDownChartConfig,
  intentTagStrokeColor,
  wordMapColors,
  sentimentColors,
  leftNavigationBgClassName,
  globalContainerClassName,
  logoImage,
  logoImageHeader,
  feviconImage,
  trillerLoaderColor,
  brandName,
};
const themeConfig: any = {
  'triller-staging.afy.ai': trillerTheme,
  'triller.afy.ai': trillerTheme,
  '50a298893872.ngrok.io': trillerTheme,
  'app-staging.cliqz.ai': cliqzTheme,
  'cliqz.amplify.ai': cliqzTheme,
  // 'localhost:3003': cliqzTheme,
  'app.cliqz.ai': cliqzTheme,
  'julius.amplify.ai': juliusTheme,
  // 'amplify-lite-ui-feature.s3-website-us-east-1.amazonaws.com': juliusTheme,
};

export const getChartColors = (): string[] => {
  return themeConfig[hostname] && themeConfig[hostname].chartColors ? themeConfig[hostname].chartColors : defaultChartColors;
};
export const getEngagementChartColors = (): any => {
  return themeConfig[hostname] && themeConfig[hostname].engagementChartColors ? themeConfig[hostname].engagementChartColors : engagementChartColors;
};

export const getCommentBreakdownColors = (): any => {
  return themeConfig[hostname] && themeConfig[hostname].commentBreakDownChartConfig ? themeConfig[hostname].commentBreakDownChartConfig : DefaultCommentBreakDownChartConfig;
};

export const getIntentTagStrokeColor = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].intentTagStrokeColor ? themeConfig[hostname].intentTagStrokeColor : '';
};

export const getWordMapConfig = (): any => {
  return themeConfig[hostname] && themeConfig[hostname].wordMapColors ? { ...options, colors: themeConfig[hostname].wordMapColors } : options;
};

export const getSentimentColors = (colorMapper: any): any => {
  return themeConfig[hostname] && themeConfig[hostname].sentimentColors ? themeConfig[hostname].sentimentColors : colorMapper;
};

export const getLeftNavigationBgClassName = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].leftNavigationBgClassName ? themeConfig[hostname].leftNavigationBgClassName : 'leftNavigationBarBg';
};
export const getGlobalContainerClassName = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].globalContainerClassName ? themeConfig[hostname].globalContainerClassName : '';
};
export const getLogoImageName = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].logoImage ? themeConfig[hostname].logoImage : 'amplify-logo.png';
};
export const getHeaderLogoImage = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].logoImageHeader ? themeConfig[hostname].logoImageHeader : 'amplify-logo.png';
};
export const getFeviconImageUrl = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].feviconImage ? themeConfig[hostname].feviconImage : '';
};
export const getLoaderColor = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].trillerLoaderColor ? themeConfig[hostname].trillerLoaderColor : '#1297E0';
};
export const getPrimaryAccentColor = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].primaryAccentColor ? themeConfig[hostname].primaryAccentColor : '#1297E0';
};
export const getSignUpLink = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].signUpLink;
};
export const getSignUpPromptText = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].signUpPromptText ? themeConfig[hostname].signUpPromptText : "Don't have an account with us yet? ";
};
export const getSignUpText = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].signUpText ? themeConfig[hostname].signUpText : 'Sign Up here ';
};
export const getBrandName = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].brandName ? themeConfig[hostname].brandName : 'Amplify';
};
export const getLogoImageNameSecondary = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].logoImageSecondary ? themeConfig[hostname].logoImageSecondary : themeConfig[hostname]?.logoImage || 'amplify-logo.png';
};
export const getFontFamily = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].fontFamily ? themeConfig[hostname].fontFamily : themeConfig[hostname]?.fontFamily || 'Nunito Sans';
};
export const getContactUsEmail = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].contactUsEmail
    ? themeConfig[hostname].contactUsEmail
    : themeConfig[hostname]?.contactUsEmail || 'support@amplifyai.zendesk.com';
};
export const getSupportEmail = (): string => {
  return themeConfig[hostname] && themeConfig[hostname].supportEmail ? themeConfig[hostname].supportEmail : themeConfig[hostname]?.supportEmail || 'support@amplify.ai';
};

export const getHeaderFontsAndIconsColor = (): string | undefined => {
  return themeConfig[hostname] && themeConfig[hostname].headerFontsAndIconsColor;
};

export const getInfoIcon = () => {
  return themeConfig[hostname] && themeConfig[hostname].infoIconSrc;
};
