import { platforms } from '../../common/entities/entities.constants';

export const inboxConstants = {
  INBOX: 'INBOX',
  ACTIVE: 'active',
  ENGAGED: 'engaged',
  DND: 'dnd',
  NOT_CRITICAL: 'notCritical',
  BLOCKED: 'blocked',
  ACTIONABLE: 'actionable',
  NON_ACTIONABLE: 'nonActionable',
  UNSUBSCRIBE: 'Unsubscribe',
  PRIMARY: 'Primary',
  GENERAL: 'General',
  SEARCH: 'Search',
  SUBSCRIBE: 'Subscribe',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};

export const fileTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const fileExtensions = {
  [fileTypes.IMAGE]: ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.svg', '.tif', '.tiff'],
  [fileTypes.VIDEO]: ['.avi', '.mov', '.mp4', '.wmv', '.avchd', '.webm', '.flv'],
};

// export const messageTabs = [
//   {
//     label: 'Active',
//     value: inboxConstants.ACTIVE,
//   },
//   {
//     label: 'Engaged',
//     value: inboxConstants.ENGAGED,
//   },
//   {
//     label: 'Do Not Disturb',
//     value: inboxConstants.DND,
//   },
//   {
//     label: 'Not Critical',
//     value: inboxConstants.NOT_CRITICAL,
//   },
// ];

export const messageTabs = [
  {
    tabLabel: 'My Inbox',
    label: 'My Inbox',
    value: inboxConstants.ASSIGNED,
    isTabSupported: true,
  },
  {
    tabLabel: 'Primary',
    label: 'Primary',
    value: inboxConstants.ACTIONABLE,
    isTabSupported: true,
  },
  {
    tabLabel: 'General',
    label: 'General',
    value: inboxConstants.NON_ACTIONABLE,
    isTabSupported: true,
  },
  {
    label: 'Unsubscribe',
    value: inboxConstants.UNSUBSCRIBE,
    isTabSupported: false,
    supportedPlatforms: [platforms.TWILIO_SMS],
  },
  {
    label: 'Subscribe',
    value: inboxConstants.SUBSCRIBE,
    isTabSupported: false,
    supportedPlatforms: [platforms.TWILIO_SMS],
  },
  {
    tabLabel: 'Search Results',
    value: inboxConstants.SEARCH,
    isTabSupported: false,
  },
  // {
  //   label: 'Block',
  //   value: inboxConstants.BLOCKED,
  //   isTabSupported: false,
  // },
  // {
  //   label: 'Do Not Disturb',
  //   value: inboxConstants.DND,
  // },
  // {
  //   label: 'Not Critical',
  //   value: inboxConstants.NOT_CRITICAL,
  // },
];

export const messageStatusConstants = {
  NEW: 'new',
  READ: 'read',
  ENGAGED: 'engaged',
};

export const singleActionTypesAPI = {
  ASSIGN: 'assign',
  UNASSIGN: 'unassign',
  REACT: 'react',
  UNREACT: 'unreact',
};

export const bulkActionTypesAPI = {
  ASSIGN: 'assign',
  REPLY_MESSAGE: 'replyMessage',
  UPDATE_MESSAGE_STATUS: 'updateMessageStatus',
  UPDATE_INBOX_STATUS: 'updateInboxStatus',
  UPDATE_CUSTOM_LABELS: 'updateCustomLabels',
};

export const animatedSearchPlaceholderTexts = ['name', 'number', 'word or phrase'];
export const sessionStartTypes = {
  BROADCAST: 'broadcast',
  CTM_AD: 'ctm_ad',
  GET_STARTED: 'get_started',
  COMMENT_RESPONSE: 'comment_response',
  ORGANIC: 'organic_user_engagement',
  DRIP: 'drip',
  TAP: 'tap',
  TYPE: 'type',
  AD: 'ad',
  REACTION: 'reaction',
  ICE_BREAKER: 'ice_breaker',
  REFERRAL: 'referral',
  PERSISTENT_MENU: 'persistent_menu',
  IG_STORY: 'story',
  UNKNOWN: 'unknown',
};

export const sessionTypeLabel: any = {
  [sessionStartTypes.BROADCAST]: 'Broadcast',
  [sessionStartTypes.COMMENT_RESPONSE]: 'Campaign',
  [sessionStartTypes.DRIP]: 'Drip',
  [sessionStartTypes.TAP]: 'Module',
  [sessionStartTypes.AD]: 'Ad',
  [sessionStartTypes.IG_STORY]: 'Story',
};

export const sessionStartTypeMap = {
  [sessionStartTypes.BROADCAST]: 'Broadcast',
  [sessionStartTypes.CTM_AD]: 'CTM Ad',
  [sessionStartTypes.GET_STARTED]: 'Get Started',
  [sessionStartTypes.COMMENT_RESPONSE]: 'Growth Campaigns',
  [sessionStartTypes.ORGANIC]: 'Organic User Engagements',
  [sessionStartTypes.DRIP]: 'Drips',
  [sessionStartTypes.TAP]: 'Tap',
  [sessionStartTypes.TYPE]: 'Type',
  [sessionStartTypes.AD]: 'Ad',
  [sessionStartTypes.REACTION]: 'Reaction',
  [sessionStartTypes.ICE_BREAKER]: 'Ice Breaker',
  [sessionStartTypes.REFERRAL]: 'Referral',
  [sessionStartTypes.PERSISTENT_MENU]: 'Persistent Menu',
  [sessionStartTypes.UNKNOWN]: 'Unknown',
};

export const msgSubTypesList = {
  TAP: 'tap',
  TYPE: 'type',
  DRIP: 'drip',
  BROADCAST: sessionStartTypes.BROADCAST,
  RN_BROADCAST: 'Rn_Broadcast',
  ECHO: 'echo',
};

/*eslint-disable @typescript-eslint/naming-convention*/
export const msgSubTypesText = {
  [msgSubTypesList.TAP]: 'Tap',
  [msgSubTypesList.TYPE]: 'Type',
  [msgSubTypesList.DRIP]: 'Drip',
  [msgSubTypesList.BROADCAST]: 'Broadcast',
  [msgSubTypesList.RN_BROADCAST]: 'Marketing Message',
  [msgSubTypesList.ECHO]: 'Echo Message',
};

export const msgSubTypeVerbs = {
  [msgSubTypesList.TAP]: 'Tapped',
  [msgSubTypesList.TYPE]: 'Typed',
  [msgSubTypesList.DRIP]: 'Drip',
  [msgSubTypesList.BROADCAST]: 'Broadcast',
  [msgSubTypesList.RN_BROADCAST]: 'Marketing',
  [msgSubTypesList.ECHO]: 'Echo',
};
export const VariableTabRoutes = {
  USERVARIABLE: 'User Variable',
  SESSIONS: 'Sessions',
};
