import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { SearchFilterTag, TagGroup } from '../../../../../../common/tag/tag.types';
import { applySearchFilterTags, setInputModalVisibility, setSearchFilterTags, applyCustomSearchText } from '../actions';

const initialState: {
  selectedSearchFilterTags: SearchFilterTag[];
  appliedSearchFilterTagsPostTypes: TagGroup;
  appliedSearchFilterTagsKeywords: TagGroup;
  appliedSearchFilterTagsIntents: TagGroup;
  appliedSearchFilterTagsSentiments: TagGroup;
  appliedSearchFilterTagsRecommendations: TagGroup;
  appliedSearchFilterTagsAmplifyEngaged: TagGroup;
  isInputModalVisible: boolean;
  appliedCustomSearchText?: string;
  appliedSearchFilterTagsMessageStatus?: TagGroup;
  appliedSearchFilterTagsCustomLabels?: TagGroup;
  appliedSearchFilterTagsPostMentions: TagGroup;
  appliedSearchFilterTagsForReplyFilters: TagGroup;
  appliedSearchFilterTagsAssignedStatus?: TagGroup;
  appliedSearchFilterTagsSessionStartType?: TagGroup;
} = {
  selectedSearchFilterTags: [],
  appliedSearchFilterTagsPostTypes: {},
  appliedSearchFilterTagsKeywords: {},
  appliedSearchFilterTagsIntents: {},
  appliedSearchFilterTagsSentiments: {},
  appliedSearchFilterTagsRecommendations: {},
  appliedSearchFilterTagsAmplifyEngaged: {},
  appliedSearchFilterTagsMessageStatus: {},
  appliedSearchFilterTagsCustomLabels: {},
  appliedSearchFilterTagsPostMentions: {},
  appliedSearchFilterTagsForReplyFilters: {},
  appliedSearchFilterTagsAssignedStatus: {},
  isInputModalVisible: false,
};

//Higher order reducer. To use it pass unique name as string.
const searchFilterReducer = (searchFilterName: string) =>
  createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
    builder
      .addCase(applySearchFilterTags(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof applySearchFilterTags>>) => {
        state.appliedSearchFilterTagsCustomLabels = action.payload.customLabels || {};
        state.appliedSearchFilterTagsMessageStatus = action.payload.messageStatus || {};
        state.appliedSearchFilterTagsAssignedStatus = action.payload.assignedStatus || {};
        state.appliedSearchFilterTagsSessionStartType = action.payload.sessionStartType || {};
        state.appliedSearchFilterTagsPostTypes = action.payload.postTypes;
        state.appliedSearchFilterTagsKeywords = action.payload.keywords;
        state.appliedSearchFilterTagsIntents = action.payload.intents;
        state.appliedSearchFilterTagsSentiments = action.payload.sentiments;
        state.appliedSearchFilterTagsRecommendations = action.payload.recommendations;
        state.appliedSearchFilterTagsAmplifyEngaged = action.payload.amplifyEngaged;
        state.appliedSearchFilterTagsPostMentions = action.payload.isPostMention;
        state.appliedSearchFilterTagsForReplyFilters = action.payload.isIndirectComment;
      })
      .addCase(applyCustomSearchText(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof applyCustomSearchText>>) => {
        state.appliedCustomSearchText = action.payload;
      })
      .addCase(setSearchFilterTags(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof setSearchFilterTags>>) => {
        state.selectedSearchFilterTags = action.payload;
      })
      .addCase(setInputModalVisibility(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof setInputModalVisibility>>) => {
        state.isInputModalVisible = action.payload;
      });
  });

export default searchFilterReducer;
