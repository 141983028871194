export const chartColors = ['#0050E8', '#1297E0', '#0a088c'];
export const engagementChartColors = {
  banned: '#426AB4',
  liked: '#91B3F0',
  privateReplied: '#3264BF',
  replied: '#5480D0',
  reviewed: '#1B3B74',
  hidden: '#C1D7FF',
  deleted: '#28529D',
  assigned: '#249AF0',
};
