const sentimentStatsConstant: SentimentAnalysisStatsConstantType = {
  STORE_SENTIMENT_ANALYSIS_STATS: 'STORE_SENTIMENT_ANALYSIS_STATS',
  REMOVE_SENTIMENT_ANALYSIS_STATS: 'REMOVE_SENTIMENT_ANALYSIS_STATS',
  SET_SELECTED_POSTS_COUNT: 'SET_SELECTED_POSTS_COUNT',
};
type SentimentAnalysisStatsConstantType = {
  STORE_SENTIMENT_ANALYSIS_STATS: string;
  REMOVE_SENTIMENT_ANALYSIS_STATS: string;
  SET_SELECTED_POSTS_COUNT: string;
};
export default sentimentStatsConstant;
