import { platforms } from '../../common/entities/entities.constants';
import { botPlatforms } from '../automation/constants';
import { ListTypeIngest, ListTypeSegment } from './segment.types';

export const headerNames = {
  NAME: 'name',
  TOTAL_CONTACTS: 'userCount',
  DATE_CREATED: 'createdDate',
};

export const fetchContactsPromiseTrackerArea = 'fetching-contacts';

const SegmentsConstants: SegmentsConstantsType = {
  STORE_SEGMENTS_BASE_PATH: 'STORE_SEGMENTS_BASE_PATH',
  STORE_SEGMENTS: 'STORE_SEGMENTS',
  STORE_SEGMENT: 'STORE_SEGMENT',
  STORE_SEGMENT_USER_COUNT: 'STORE_SEGMENT_USER_COUNT',
};
type SegmentsConstantsType = {
  STORE_SEGMENTS_BASE_PATH: string;
  STORE_SEGMENTS: string;
  STORE_SEGMENT: string;
  STORE_SEGMENT_USER_COUNT: string;
};

export const Segments: ListTypeSegment = 'segment';
export const Ingest: ListTypeIngest = 'ingest';

export const SegmentListType = {
  segments: Segments,
  ingest: Ingest,
};
export const TableHeaderNameTitle = {
  [Ingest]: 'Name of Ingested List',
  [Segments]: 'Name of Segment',
};

export const segmentPaginationDefaultValue = {
  page: 1,
  size: 50,
  total: 1,
  pages: 1,
  sort: headerNames.NAME,
};

export const emailsToBRShownAsCreatedByIngestList = ['noreply@amplify.ai', 'noreply+admintool@amplify.ai'];
export const phoneNumberPlatforms = [platforms.TWILIO_SMS, botPlatforms.MESSENGER_PEOPLE, platforms.KARIX_WA, platforms.CM_WHATSAPP, platforms.WHATSAPP, platforms.SPRINKLR_APPLE];

export const defaultExporterIncludeFields = [
  'Account ID',
  'Bot ID',
  'Platform Bot ID',
  'Platform',
  'Subscriptions',
  'Last Message Sent To User',
  'Broadcast History',
  'User Activity - All',
  'User Activity - Active',
  'User Activity - Commented',
  'User Activity - Reacted',
  'User Activity - Active Weekly',
  'User Activity - Active Weekly Rolling',
  'User Activity - Active Monthly',
  'User Activity - Sent',
  'User Activity - Delivered',
  'User Activity - Read',
  'User Activity - Engaged',
  'User Activity - Clicked',
  'User Activity - Messaged',
  'Profile Pic',
  'Date of Birth',
  'Locale',
  'Timezone',
  'Messaging Status',
  'Location Type',
  'Timezone Name',
  'Created Date',
  'Address Line 1',
  'Address Line 2',
  'City',
  'State',
  'State Code',
  'Country Code',
  'Postal Code',
  'Latitude',
  'Longitude',
  'Referrals',
  'Platform UserName',
  'Profile Emails',
  'Profile Phone Numbers',
  'Click Count',
  'Message In Count',
  'Message Out Count',
];

export const exportUserToAPIFieldMapping = [
  {
    csvFieldName: 'Account ID',
    name: 'accountId',
  },
  {
    csvFieldName: 'Bot ID',
    name: 'botId',
  },
  {
    csvFieldName: 'Platform Bot ID',
    name: 'platformBotId',
  },
  {
    csvFieldName: 'Platform',
    name: 'platform',
  },
  {
    csvFieldName: 'Subscriptions',
    name: 'subscriptions',
  },
  {
    csvFieldName: 'Last Message Sent To User Timestamp',
    name: 'messageHistory.lastMessageSentToUser',
  },
  {
    csvFieldName: 'Broadcast History',
    name: 'messageHistory.broadcastHistory',
  },
  {
    csvFieldName: 'User Activity - All',
    name: 'userEventsSession.all',
  },
  {
    csvFieldName: 'User Activity - Active',
    name: 'userEventsSession.active',
  },
  {
    csvFieldName: 'User Activity - Commented',
    name: 'userEventsSession.activeComments',
  },
  {
    csvFieldName: 'User Activity - Reacted',
    name: 'userEventsSession.activeReactions',
  },
  {
    csvFieldName: 'User Activity - Active Weekly',
    name: 'userEventsSession.active_weekly',
  },
  {
    csvFieldName: 'User Activity - Active Weekly Rolling',
    name: 'userEventsSession.activeWeeklyRolling',
  },
  {
    csvFieldName: 'User Activity - Active Monthly',
    name: 'userEventsSession.activeMonthly',
  },
  {
    csvFieldName: 'User Activity - Sent',
    name: 'userEventsSession.sent',
  },
  {
    csvFieldName: 'User Activity - Delivered',
    name: 'userEventsSession.delivered',
  },
  {
    csvFieldName: 'User Activity - Read',
    name: 'userEventsSession.read',
  },
  {
    csvFieldName: 'User Activity - Engaged',
    name: 'userEventsSession.engaged',
  },
  {
    csvFieldName: 'User Activity - Clicked',
    name: 'userEventsSession.clicked',
  },
  {
    csvFieldName: 'User Activity - Messaged',
    name: 'userEventsSession.messaged',
  },
  {
    csvFieldName: 'Profile Pic',
    name: 'profile.profilePic',
  },
  {
    csvFieldName: 'Date of Birth',
    name: 'profile.dateOfBirth',
  },
  {
    csvFieldName: 'Locale',
    name: 'profile.locale',
  },
  {
    csvFieldName: 'Timezone',
    name: 'profile.timezone',
  },
  {
    csvFieldName: 'Messaging Status',
    name: 'profile.messagingStatus',
  },
  {
    csvFieldName: 'Location Type',
    name: 'profile.locationType',
  },
  {
    csvFieldName: 'Timezone Name',
    name: 'profile.timezoneName',
  },
  {
    csvFieldName: 'Created Date',
    name: 'profile.createdDate',
  },
  {
    csvFieldName: 'Address Line 1',
    name: 'profile.location.line1',
  },
  {
    csvFieldName: 'Address Line 2',
    name: 'profile.location.line2',
  },
  {
    csvFieldName: 'City',
    name: 'profile.location.city',
  },
  {
    csvFieldName: 'State',
    name: 'profile.location.state',
  },
  {
    csvFieldName: 'State Code',
    name: 'profile.location.stateCode',
  },
  {
    csvFieldName: 'Country Code',
    name: 'profile.location.countryCode',
  },
  {
    csvFieldName: 'Postal Code',
    name: 'profile.location.postalCode',
  },
  {
    csvFieldName: 'Latitude',
    name: 'profile.location.geo.lat',
  },
  {
    csvFieldName: 'Longitude',
    name: 'profile.location.geo.long',
  },
  {
    csvFieldName: 'Referrals',
    name: 'profile.referrals',
  },
  {
    csvFieldName: 'Platform UserName',
    name: 'profile.platformUsername',
  },
  {
    csvFieldName: 'Profile Emails',
    name: 'profile.emailIds',
  },
  {
    csvFieldName: 'Profile Phone Numbers',
    name: 'profile.phoneNumbers',
  },
  {
    csvFieldName: 'Click Count',
    name: 'engagement.clickCount',
  },
  {
    csvFieldName: 'Message In Count',
    name: 'engagement.messageInCount',
  },
  {
    csvFieldName: 'Message Out Count',
    name: 'engagement.messageOutCount',
  },
];

export default SegmentsConstants;
