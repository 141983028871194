export const keywordGroupsTabs: KeywordGroupsTabs = {
  amplifyStandard: 'Amplify Standard',
  custom: 'Custom',
};
export const keywordGroupsTabRoutes = {
  [keywordGroupsTabs.amplifyStandard]: 'standard',
  [keywordGroupsTabs.custom]: 'custom',
};
export const trainingStatus: { SUCCESS: string; FAILED: string; RUNNING: string } = {
  SUCCESS: 'success',
  FAILED: 'fail',
  RUNNING: 'training',
};

const KeywordGroupsConstants: KeywordGroupsConstantsType = {
  SHOW_CREATE_KEYWORD_GROUP_BUTTON: 'SHOW_CREATE_KEYWORD_GROUP_BUTTON',
  CHANGE_DRAWER_TITLE: 'CHANGE_DRAWER_TITLE',
  STORE_ENTITIES_ID: 'STORE_ENTITIES_ID',
  CHANGE_CURRENT_TAB: 'CHANGE_CURRENT_TAB',
  UPDATE_BOT_TRAINING_STATUS: 'UPDATE_BOT_TRAINING_STATUS',
  TRAINING_STARTED: 'TRAINING_STARTED',
  TRAINING_FINISHED: 'TRAINING_FINISHED',
  DID_KEYWORD_GROUPS_CHANGE: 'DID_KEYWORD_GROUPS_CHANGE',
  STORE_BASE_PATH_KEYWORDS: 'STORE_BASE_PATH_KEYWORDS',
};
type KeywordGroupsConstantsType = {
  SHOW_CREATE_KEYWORD_GROUP_BUTTON: string;
  CHANGE_DRAWER_TITLE: string;
  STORE_ENTITIES_ID: string;
  CHANGE_CURRENT_TAB: string;
  UPDATE_BOT_TRAINING_STATUS: string;
  TRAINING_STARTED: string;
  TRAINING_FINISHED: string;
  DID_KEYWORD_GROUPS_CHANGE: string;
  STORE_BASE_PATH_KEYWORDS: string;
};
export default KeywordGroupsConstants;

type KeywordGroupsTabs = { amplifyStandard: string; custom: string };
export const utilityTabs: UtilityTabs = {
  qrCode: 'QR Code',
  shortURL: 'Short URL',
  mediaViewURL: 'Media View URL',
};
type UtilityTabs = { qrCode: string; shortURL: string; mediaViewURL: string };
export const utilityTabRoutes = {
  [utilityTabs.qrCode]: 'QR Code',
  [utilityTabs.shortURL]: 'Short URL',
  [utilityTabs.mediaViewURL]: 'Media View URL',
};
