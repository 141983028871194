const EntitiesReducerConstants: EntitiesReducerConstantsType = {
  STORE_USER_ACCOUNT: 'STORE_USER_ACCOUNT',
  STORE_PLATFORM_BOTS: 'STORE_PLATFORM_BOTS',
  REMOVE_PLATFORM_BOT: 'REMOVE_PLATFORM_BOT',
  SET_SELECTED_PLATFORM_BOT: 'SET_SELECTED_PLATFORM_BOT',
  SET_ROUTE_PARAMS: 'SET_ROUTE_PARAMS',
  SET_SELECTED_TIME_FILTER: 'SET_SELECTED_TIME_FILTER',
  SET_VARIABLE_FOR_PLATFORM_BOT: 'SET_VARIABLE_FOR_PLATFORM_BOT',
  SET_LABEL_FOR_PLATFORM_BOT: 'SET_LABEL_FOR_PLATFORM_BOT',
  ADD_NEW_LABEL_FOR_THE_PLATFORM: 'ADD_NEW_LABEL_FOR_THE_PLATFORM',
  DELETE_CUSTOM_LABEL: 'DELETE_CUSTOM_LABEL',
  STORE_COMMENTS_AND_USERS: 'STORE_COMMENTS_AND_USERS',
  STORE_POSTS: 'STORE_POSTS',
  APPEND_POSTS: 'APPEND_POSTS',
  FETCHING_COMMENTS: 'FETCHING_COMMENTS',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_FAILURE: 'FETCH_COMMENTS_FAILURE',
  TAKE_ACTION_ON_COMMENT: 'TAKE_ACTION_ON_COMMENT',
  UNDO_ACTION_ON_COMMENT: 'UNDO_ACTION_ON_COMMENT',
  UNBLOCK_COMMENT_USER: 'UNBLOCK_COMMENT_USER',
  DELETE_COMMENT_REPLY: 'DELETE_COMMENT_REPLY',
  BLOCK_COMMENT_USER: 'BLOCK_COMMENT_USER',
  STORE_KEYWORD_GROUPS: 'STORE_KEYWORD_GROUPS',
  CLEAR_KEYWORD_GROUPS: 'CLEAR_KEYWORD_GROUPS',
  STORE_TAGGING_GCS: 'STORE_TAGGING_GCS',
  STORE_TAGGING_GC: 'STORE_TAGGING_GC',
  UPDATE_TAGGING_GC: 'UPDATE_TAGGING_GC',
  STORE_PRIVATE_REPLY_TAGS: 'STORE_PRIVATE_REPLY_TAGS',
  STORE_ACCOUNTS: 'STORE_ACCOUNTS',
  RESET_DATA_ACCOUNT_CHANGE: 'RESET_DATA_ACCOUNT_CHANGE',
  SET_CAMPAIGN_TAGS_FOR_PLATFORM_BOT: 'SET_CAMPAIGN_TAGS_FOR_PLATFORM_BOT',
  STORE_BOTS: 'STORE_BOTS',
  UPDATE_BOT_WITH_NLP: 'UPDATE_BOT_WITH_NLP',
  STORE_ARCHIVED_TEMPLATES: 'STORE_ARCHIVED_TEMPLATES',
  STORE_TEMPLATES: 'STORE_TEMPLATES',
  STORE_TEMPLATE: 'STORE_TEMPLATE',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  STORE_PLATFORM_USERS: 'STORE_PLATFORM_USERS',
  TAKE_BULK_ACTION_ON_COMMENT: 'TAKE_BULK_ACTION_ON_COMMENT',
  BLOCK_BULK_COMMENT_USER: 'BLOCK_BULK_COMMENT_USER',
  RESET_STATE_ON_PAGE_CHANGE: 'RESET_STATE_ON_PAGE_CHANGE',
  SET_ERROR_USER_BULK_COMMENT: 'SET_ERROR_USER_BULK_COMMENT',
  STORE_BROADCASTS: 'STORE_BROADCASTS',
  APPEND_BROADCASTS: 'APPEND_BROADCASTS',
  STORE_BROADCAST: 'STORE_BROADCAST',
  STORE_BROADCASTS_ENGAGEMENT_SETTINGS: 'STORE_BROADCASTS_ENGAGEMENT_SETTINGS',
  DELETE_BROADCAST: 'DELETE_BROADCAST',
  STORE_MODULES: 'STORE_MODULES',
  UPDATE_MODULE: 'UPDATE_MODULE',
  STORE_NOTIF_LABELS: 'STORE_NOTIFICATION_LABELS',
  UPDATE_BROADCAST_ANALYTICS: 'UPDATE_BROADCAST_ANALYTICS',
  SEARCHED_PLATFORM_USERS: 'SEARCHED_PLATFORM_USERS',
  TARGETED_USERS: 'TARGETED_USERS',
  ADD_TARGETED_USER: 'ADD_TARGETED_USER',
  DELETE_TARGETED_USERS: 'DELETE_TARGETED_USERS',
  ADD_TEST_USERS_TO_BOT: 'ADD_TEST_USERS_TO_BOT',
  REMOVE_TEST_USERS_FROM_BOT: 'REMOVE_TEST_USERS_FROM_BOT',
  STORE_SEGMETS: 'STORE_SEGMETS',
  REMOVE_SEGMENT: 'REMOVE_SEGMENT',
  APPEND_VARIABLE_VALUE: 'APPEND_VARIABLE_VALUE',
  UPDATED_BOT_CONFIG: 'UPDATED_BOT_CONFIG',
  LATEST_BROADCAST_TIME: 'LATEST_BROADCAST_TIME',
  CLEAR_SEARCHFILTER_TAGS: 'CLEAR_SEARCHFILTER_TAGS',
  REMOVE_SEARCHFILTER_TAGS_FORDATE: 'REMOVE_SEARCHFILTER_TAGS_FORDATE',
  SET_SEARCHFILTER_TAGS_FORDATE: 'SET_SEARCHFILTER_TAGS_FORDATE',
  SET_SELECTEDTAG_RANGE: 'SET_SELECTEDTAG_RANGE',
  STORE_USER_INGESTS: 'STORE_USER_INGESTS',
  STORE_INGEST: 'STORE_INGEST',
  STORE_VARIABLE: 'STORE_VARIABLE',
  STORE_SUBSCRIPTIONS: 'STORE_SUBSCRIPTIONS',
  STORE_SESSION_START_TYPES: 'STORE_SESSION_START_TYPES',
  STORE_SEGMENT_TAGS: 'STORE_SEGMENT_TAGS',
  STORE_SEGMENT_TAG: 'STORE_SEGMENT_TAG',
  STORE_SEGMENT_USER: 'STORE_SEGMENT_USER',
};
type EntitiesReducerConstantsType = {
  STORE_USER_ACCOUNT: string;
  STORE_PLATFORM_BOTS: string;
  REMOVE_PLATFORM_BOT: string;
  SET_SELECTED_PLATFORM_BOT: string;
  SET_ROUTE_PARAMS: string;
  SET_SELECTED_TIME_FILTER: string;
  SET_VARIABLE_FOR_PLATFORM_BOT: string;
  SET_LABEL_FOR_PLATFORM_BOT: string;
  STORE_COMMENTS_AND_USERS: string;
  STORE_POSTS: string;
  APPEND_POSTS: string;
  FETCHING_COMMENTS: string;
  FETCH_COMMENTS_SUCCESS: string;
  FETCH_COMMENTS_FAILURE: string;
  TAKE_ACTION_ON_COMMENT: string;
  UNDO_ACTION_ON_COMMENT: string;
  UNBLOCK_COMMENT_USER: string;
  DELETE_COMMENT_REPLY: string;
  BLOCK_COMMENT_USER: string;
  STORE_KEYWORD_GROUPS: string;
  CLEAR_KEYWORD_GROUPS: string;
  STORE_TAGGING_GCS: string;
  STORE_TAGGING_GC: string;
  UPDATE_TAGGING_GC: string;
  STORE_PRIVATE_REPLY_TAGS: string;
  STORE_ACCOUNTS: string;
  RESET_DATA_ACCOUNT_CHANGE: string;
  SET_CAMPAIGN_TAGS_FOR_PLATFORM_BOT: string;
  STORE_BOTS: string;
  UPDATE_BOT_WITH_NLP: string;
  STORE_ARCHIVED_TEMPLATES: string;
  STORE_TEMPLATES: string;
  STORE_TEMPLATE: string;
  REMOVE_TEMPLATE: string;
  STORE_PLATFORM_USERS: string;
  ADD_NEW_LABEL_FOR_THE_PLATFORM: string;
  DELETE_CUSTOM_LABEL: string;
  TAKE_BULK_ACTION_ON_COMMENT: string;
  BLOCK_BULK_COMMENT_USER: string;
  RESET_STATE_ON_PAGE_CHANGE: string;
  SET_ERROR_USER_BULK_COMMENT: string;
  STORE_BROADCASTS_ENGAGEMENT_SETTINGS: string;
  STORE_BROADCASTS: string;
  APPEND_BROADCASTS: string;
  STORE_BROADCAST: string;
  DELETE_BROADCAST: string;
  STORE_MODULES: string;
  UPDATE_MODULE: string;
  STORE_NOTIF_LABELS: string;
  UPDATE_BROADCAST_ANALYTICS: string;
  SEARCHED_PLATFORM_USERS: string;
  TARGETED_USERS: string;
  ADD_TARGETED_USER: string;
  DELETE_TARGETED_USERS: string;
  ADD_TEST_USERS_TO_BOT: string;
  REMOVE_TEST_USERS_FROM_BOT: string;
  STORE_SEGMETS: string;
  REMOVE_SEGMENT: string;
  APPEND_VARIABLE_VALUE: string;
  UPDATED_BOT_CONFIG: string;
  LATEST_BROADCAST_TIME: string;
  CLEAR_SEARCHFILTER_TAGS: string;
  REMOVE_SEARCHFILTER_TAGS_FORDATE: string;
  SET_SEARCHFILTER_TAGS_FORDATE: string;
  SET_SELECTEDTAG_RANGE: string;
  STORE_USER_INGESTS: string;
  STORE_INGEST: string;
  STORE_SUBSCRIPTIONS: string;
  STORE_VARIABLE: string;
  STORE_SESSION_START_TYPES: string;
  STORE_SEGMENT_TAGS: string;
  STORE_SEGMENT_TAG: string;
  STORE_SEGMENT_USER: string;
};
export default EntitiesReducerConstants;

export const platforms = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  TWILIO_SMS: 'twilio_sms',
  YOUTUBE: 'youtube',
  TWILIO_WA: 'twilio_wa',
  WHATSAPP: 'whatsapp',
  KARIX_WA: 'karix_wa',
  CM_WHATSAPP: 'cm_whatsapp',
  TIKTOK: 'tiktok',
  TIKTOK_ORGANIC: 'tiktok_organic',
  SPRINKLR_APPLE: 'sprinklr_amb',
  THREADS: 'threads',
};

export const platformLabels = {
  [platforms.FACEBOOK]: 'Facebook',
  [platforms.INSTAGRAM]: 'Instagram',
  [platforms.TWITTER]: 'Twitter',
  [platforms.TWILIO_SMS]: 'SMS',
  [platforms.YOUTUBE]: 'YouTube',
  [platforms.TWILIO_WA]: 'WhatsApp - Twilio',
  [platforms.WHATSAPP]: 'WhatsApp',
  [platforms.KARIX_WA]: 'WhatsApp - Karix',
  [platforms.CM_WHATSAPP]: 'WhatsApp - CM',
  [platforms.TIKTOK]: 'TikTok Paid',
  [platforms.TIKTOK_ORGANIC]: 'TikTok',
  [platforms.THREADS]: 'Threads',
};

export const MediaTypes = {
  GIF: 'gif',
  JPEG: 'jpeg',
  PNG: 'png',
  JPG: 'jpg',
  // APNG: 'apng',
  // AVIF: 'avif',
  // SVG: 'svg+xml',
  // WEBP: 'webp',
};
export const supportedReplyTypeForAttachment = new Set(['publicReply']);

// export const supportedPlatforms = new Set([platforms.FACEBOOK, platforms.INSTAGRAM, platforms.TWITTER, platforms.TWILIO_SMS, platforms.TIKTOK]);
export const attachmentSupportedPlatforms = new Set([platforms.FACEBOOK, platforms.TWITTER, platforms.TWILIO_SMS, platforms.INSTAGRAM, platforms.THREADS]);
export const supportedImageAttachmentTypes = new Set([MediaTypes.JPEG, MediaTypes.PNG, MediaTypes.JPG]);
export const supportedGIFttachmentTypes = new Set([MediaTypes.GIF]);
// Platforms map for which we have implemented deployment flow. // Removing Twitter for now
export const deploymentFLowPlatforms = new Set([platforms.FACEBOOK, platforms.INSTAGRAM, platforms.YOUTUBE, platforms.TIKTOK_ORGANIC, platforms.TIKTOK, platforms.THREADS]);
export const supportedPlatformsForGCs = new Set([platforms.FACEBOOK, platforms.INSTAGRAM, platforms.TWITTER]);

export const variableTypes = {
  USER_LABEL: 'userlabel',
};

export const postType = {
  PAID: 'paid',
  ORGANIC: 'organic',
};

export const inboxKeywordConditions = {
  logicalOperator: 'and',
  childConditions: [
    {
      logicalOperator: 'or',
      ifStatements: [],
    },
  ],
};

export const inboxKeywordVariable = {
  variable: {
    name: 'Message Text',
    macro: '{{message.text}}',
    type: 'system',
  },
  condition: 'substring',
};

export const platformsLink = {
  INSTAGRAM: 'https://www.instagram.com/',
};

export const PlatformLinkColor = {
  linkColor: '#001733',
  withoutLinkColor: '#1cbdea',
};

export const showPageHealthForPlatforms = [
  platforms.INSTAGRAM,
  platforms.FACEBOOK,
  platforms.TIKTOK,
  platforms.TIKTOK_ORGANIC,
  platforms.YOUTUBE,
  platforms.TWITTER,
  platforms.TWILIO_SMS,
  platforms.WHATSAPP,
  platforms.THREADS,
];

export const showHealthPageHeaderForPlatforms = [platforms.FACEBOOK, platforms.INSTAGRAM];

export const showPermissionsStatusForPlatforms = [platforms.FACEBOOK, platforms.INSTAGRAM];

export const showCommentsEnabledForPlatforms = [platforms.INSTAGRAM, platforms.FACEBOOK, platforms.TIKTOK, platforms.TIKTOK_ORGANIC, platforms.YOUTUBE, platforms.TWITTER, platforms.THREADS];

export const showInboxEnabledForPlatforms = [platforms.TWILIO_SMS, platforms.FACEBOOK, platforms.INSTAGRAM, platforms.WHATSAPP];

export const showMessagingEnabledForPlatforms = [platforms.INSTAGRAM];

export const showIsAdminForPlatforms = [platforms.FACEBOOK];

export const showEUPrivacyEnabledForPlatforms = [platforms.FACEBOOK];
