import { SegmentTagAPI, SegmentTags } from '../entities.types';

export const transformSegmentTagsAPItoUI = (segmentTagsAPI: SegmentTagAPI[]) => {
  const segmentTags: SegmentTags = {};

  segmentTagsAPI.forEach((segmentTag: SegmentTagAPI) => {
    segmentTags[segmentTag.id] = {
      ...segmentTag,
    };
  });

  return segmentTags;
};
