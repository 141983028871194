import React from 'react';
import { Button } from 'antd';
import { ErrorModalProps } from '../types';
import { GenericAntModal } from './index';
import ErrorModalStyle from '../../scss/error-modal.module.scss';
// import { FlagOutlined } from '@ant-design/icons';
// import { useCurrentUser, useIds } from '../../../hooks';
// import { errorReportMailBody } from '../../utils';
import { useIsMobile } from '../../../mobile/utils';
export const ErrorModal = (props: ErrorModalProps) => {
  const { errorMessage, title, visible, clearError, errorContent, description /*isReportable, sendErrorReport, endPoint */ } = props;
  // const { accountId } = useIds();
  // const user = useCurrentUser();
  const isMobile = useIsMobile();
  const handleClearError = (): void => {
    clearError && clearError();
  };
  // const handleReportError = (): void => {
  //   const errorEmailBody = user && errorReportMailBody(user.id, user.email, endPoint || '', errorMessage);
  //   accountId && sendErrorReport && errorEmailBody && sendErrorReport(errorEmailBody, accountId);
  // };
  const modalFooter = [
    <div key='ok' className='columns is-gapless' style={{ alignItems: 'center' }}>
      {/* {isReportable && (
        <div className='column is-8' style={isMobile ? { display: 'contents' } : { cursor: 'pointer' }}>
          <span onClick={handleReportError} style={{ color: 'rgb(248 106 20)' }}>
            <FlagOutlined /> Report this issue
          </span>
        </div>
      )} */}
      <div className='column' style={isMobile ? { display: 'contents' } : {}}>
        <Button type='primary' onClick={handleClearError} className={ErrorModalStyle.errorModalButton} style={{ float: 'right' }}>
          Close
        </Button>
      </div>
    </div>,
  ];
  return (
    <GenericAntModal
      title={title}
      visible={visible}
      footer={modalFooter}
      forceRender={true}
      onCancel={handleClearError}
      zIndex={10001}
      closable={false}
      destroyOnClose={true}
      wrapClassName={ErrorModalStyle.errorModalContainer}
      width={330}
      mask={true}
      maskClosable={false}
      centered={true}
    >
      <div> {errorContent || errorMessage}</div>
      {description?.length ? <div> {description}</div> : null}
    </GenericAntModal>
  );
};
