/* eslint-disable @typescript-eslint/naming-convention */
// import { Location } from 'history';
import * as config from '../config';
import { processType, steps } from '../pages/ingest-list/constants';
import { AmplitudeEventProps } from '../types';
import { isLocalhost } from '../utils';
import * as eventConst from './event-constants';
// const gtag = window.gtag;
export function loadJsAnalyticsScriptToHead() {
  // If script is already loaded then don't add it again.
  const amplifyGoogleAnalytics = document.getElementById('amplifyGoogleAnalytics');
  if (amplifyGoogleAnalytics || isLocalhost()) {
    return;
  }
  // if (amplifyGoogleAnalytics) {
  //   return;
  // }
  const url = `${config.googleAnalyticsConfig.URL}${config.googleAnalyticsConfig.TRACKING_ID}`;
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'amplifyGoogleAnalytics';
  script.async = true;
  script.src = url;
  head.appendChild(script);
  initializeGaDataLayer();
}
// eslint-disable-next-line
function gtag(...args: any[]): void {
  // eslint-disable-next-line no-console
  console.log('args', args);
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(arguments);
}
function initializeGaDataLayer(): void {
  (window as any).gtag = gtag;
  gtag('js', new Date());
  gtag('config', config.googleAnalyticsConfig.TRACKING_ID);
}
/*eslint-disable max-lines-per-function*/
export const RegisterGAAnalytics = () => {
  return {
    logIn: () => {
      gtag('event', 'login', {
        method: 'Email',
      });
      gtag('event', 'user_id_available');
    },
    signUp: () => {
      gtag('event', 'sign_up', {
        method: 'Email',
      });
    },
    signUpFailed: () => {
      gtag('event', 'sign_up_failed', {
        method: 'Email',
      });
    },
    userMenuCLick: () => {
      gtag('event', 'click_top_menu');
    },
    onCommentTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `click_comment_viewer_tab`, {
        tab_name: ` ${tabName}`,
        ...eventProps,
      });
    },
    onDashboardCommentSectionClick: (tabName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `click_dashboard_comment_section`, {
        section_name: `${tabName}`,
        ...eventProps,
      });
    },
    commentSearch: (searchType: string, searchValue: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `search_comments`, {
        search_value: searchValue,
        search_type: searchType,
        ...eventProps,
      });
    },
    unlinkAPage: (pageName: string) => {
      gtag('event', `click_unlink_page`, {
        page_name: pageName,
      });
    },
    readyToAmplify: () => {
      gtag('event', `click_ready_to_amplify`);
    },
    connectSocialMedia: (socialMediaName: string) => {
      gtag('event', `click_connect_to_${socialMediaName}`);
    },
    actionOnComments: (actionType: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `comment_action_${actionType}`, {
        ...eventProps,
      });
    },
    handleHomeConnectTOFB: () => {
      gtag('event', `click_connect_to_fb_home`);
    },
    toggleTagStatus: (tagStatus: boolean, tagName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `${tagStatus ? 'enable' : 'disable'}_tag`, {
        tag_name: tagName,
        ...eventProps,
      });
    },
    toggleCampaignStatus: (campaignStatus: boolean, campaignName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `${campaignStatus ? 'enable' : 'disable'}_campaign`, {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    createTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_tag', {
        tag_name: tagName,
        ...eventProps,
      });
    },
    updateTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'update_tag', {
        tag_name: tagName,
        ...eventProps,
      });
    },
    createCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_campaign', {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    updateCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'update_campaign', {
        campaign_name: campaignName,
        ...eventProps,
      });
    },
    createKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_keyword', {
        keyword_name: keywordName,
        ...eventProps,
      });
    },
    manageKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'manage_keyword', {
        keyword_name: keywordName,
        ...eventProps,
      });
    },
    trainedButtonClicked: (eventProps: AmplitudeEventProps) => {
      gtag('event', 'click_train_button', {
        ...eventProps,
      });
    },
    dateRangeClicks: (pageSection: string, selectedDateRage: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'click_date_range', {
        page_section: pageSection,
        selected_date_range: selectedDateRage,
        ...eventProps,
      });
    },
    clickGoToComment: (url: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'go_to_comment_url', {
        comment_url: url,
        ...eventProps,
      });
    },
    setAccountUserIdInConfig: (accountId: string, userId: string, externalUser: boolean) => {
      gtag('config', config.googleAnalyticsConfig.TRACKING_ID, {
        account_id: accountId,
        user_id: userId,
        current_user_id: userId, //this will be passed as custom parameter, for customized use-case
        external_user: externalUser,
      });
    },
    createTemplate: (templateName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_template', {
        template_name: templateName,
        ...eventProps,
      });
    },
    updateTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'update_template', {
        template_name: templateName,
        template_id: templateId,
        ...eventProps,
      });
    },
    deleteTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'delete_template', {
        template_name: templateName,
        template_id: templateId,
        ...eventProps,
      });
    },
    createBroadcast: (broadcastName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_broadcast', {
        broadcast_name: broadcastName,
        ...eventProps,
      });
    },
    updateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'update_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    archiveBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'archive_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    activateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'activate_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    testBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'test_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    viewBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'view_broadcast', {
        broadcast_name: broadcastName,
        broadcast_id: broadcastId,
        ...eventProps,
      });
    },
    clickReportDownload: (reportName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'click_report_download', {
        report_name: reportName,
        ...eventProps,
      });
    },
    reportEmailSent: (reportName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'report_sent_via_email', {
        report_name: reportName,
        ...eventProps,
      });
    },
    inboxMessageMoved: (fromTab: string, toTab: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'inbox_message_moved', {
        from_tab: fromTab,
        to_tab: toTab,
        ...eventProps,
      });
    },
    messageReplied: (repliedTo: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'message_replied', {
        replied_to: repliedTo,
        ...eventProps,
      });
    },
    inboxMessageStatusChanged: (fromStatus: string, toStatus: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'inbox_message_status_changed', {
        from_status: fromStatus,
        to_status: toStatus,
        ...eventProps,
      });
    },
    inboxMessageLabelAdded: (labelName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'inbox_message_Label_added', {
        label_name: labelName,
        ...eventProps,
      });
    },
    inboxMessageLabelRemoved: (labelName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'inbox_message_Label_removed', {
        label_name: labelName,
        ...eventProps,
      });
    },
    bulkActionTaken: (messageIds: string, actionType: string, eventProps: AmplitudeEventProps) => {
      gtag('message_bulk_action_taken', {
        message_ids: messageIds,
        action_type: actionType,
        ...eventProps,
      });
    },
    createSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'create_segment', {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    updateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'update_segment', {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    deleteSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', 'delete_segment', {
        segment_name: segmentName,
        ...eventProps,
      });
    },
    fetchInboxMessages: (pageNumber: number, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.FETCH_MESSAGE_INBOX, {
        page_number: pageNumber,
        ...eventProps,
      });
    },
    createInboxMessageLabel: (labelName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.CREATE_MESSAGE_INBOX_LABEL, {
        label_name: labelName,
        ...eventProps,
      });
    },
    showSessionsBtnClicked: (eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.SHOW_SESSIONS_CLICKED, {
        ...eventProps,
      });
    },
    inboxManageLabelClicked: (eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.SHOW_MANAGE_LABEL_CLICKED, {
        ...eventProps,
      });
    },
    viewConversation: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.VIEW_CONVERSATION, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserUpdated: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.INBOX_PLATFORM_USER_UPDATED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserSubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.INBOX_PLATFORM_USER_SUBSCRIBED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    platformUserUnsubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.INBOX_PLATFORM_USER_UNSUBSCRIBED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    inboxUserDetailsClicked: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.INBOX_USER_DETAILS_CLICKED, {
        platform_user_id: platformUserId,
        ...eventProps,
      });
    },
    inboxShowVariablesClicked: (eventProps: AmplitudeEventProps) => {
      gtag('event', eventConst.INBOX_SHOW_VARIABLES_CLICKED, {
        ...eventProps,
      });
    },
    audienceFiltersApplied: (
      isClearingFilters: boolean,
      eventProps: AmplitudeEventProps,
      countries: string,
      states: string,
      cities: string,
      genders: string,
      ages: string,
      labels: string,
      locales: string,
      timezones: string,
    ) => {
      if (countries || states || cities || genders || ages || labels || locales || timezones) {
        gtag('event', `audience_filters_applied`, {
          ...eventProps,
          ...(countries.length ? { countries } : {}),
          ...(states.length ? { states } : {}),
          ...(cities.length ? { cities } : {}),
          ...(genders.length ? { genders } : {}),
          ...(ages ? { ages } : {}),
          ...(labels.length ? { labels } : {}),
          ...(locales.length ? { locales } : {}),
          ...(timezones.length ? { timezones } : {}),
        });
      } else if (isClearingFilters) {
        gtag('event', `audience_clear_filter`, { ...eventProps });
      }
    },
    audienceFansByModalOpen: (category: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_fans_by_${category}_modal_opened`, {
        ...eventProps,
      });
    },
    audienceTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_${tabName}_tab_view`, {
        ...eventProps,
      });
    },
    audienceToggleUnavailabilityStatus: (isUnavailableHidden: boolean, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_show_data_unavailable`, {
        ...eventProps,
        showUnavailableData: isUnavailableHidden,
      });
    },
    audienceSubscriptionDateChanged: (from: string, to: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_subscription_date_changed`, {
        ...eventProps,
        from,
        to,
      });
    },
    audienceCreateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_create_segment`, {
        ...eventProps,
        segment_name: segmentName,
      });
    },
    audienceGoToInbox: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_goto_inbox_clicked`, {
        ...eventProps,
        platformUserId,
      });
    },
    audienceSubscriptionChange: (platformUserId: string, isUnsubcribeEvent: boolean, eventProps: AmplitudeEventProps) => {
      gtag('event', isUnsubcribeEvent ? `audience_unsubscribe_clicked` : `audience_subscribe_clicked`, {
        ...eventProps,
        platformUserId,
      });
    },
    audienceModifyNewMemberLabels: (platformUserId: string, label: string, isAdd: boolean, eventProps: AmplitudeEventProps) => {
      gtag('event', isAdd ? `audience_custom_label_added` : `audience_custom_label_removed`, {
        ...eventProps,
        platformUserId,
        label,
      });
    },
    audienceAllNewMemberSelected: (pageName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_select_all_new_members_clicked`, {
        ...eventProps,
        pageName,
      });
    },
    audienceNewMemberClicked: (platformUserId: string, pageName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_select_new_member_clicked`, {
        ...eventProps,
        pageName,
        platformUserId,
      });
    },
    audienceSendMessageClicked: (pageName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_send_message_button_clicked`, {
        ...eventProps,
        pageName,
      });
    },
    audienceMessageSent: (platformUserIds: string, pageName: string, eventProps: AmplitudeEventProps) => {
      gtag('event', `audience_message_sent`, {
        ...eventProps,
        platformUserIds,
        pageName,
      });
    },
    handleIngestClickList: () => {
      gtag('event', 'ingest_list_click', {});
    },
    downloadStandardTemplate: (key: string) => {
      if (key === processType.STANDARD) {
        gtag('event', 'ingest_downloaded_cliqzStandard_template', {});
      } else if (key === 'CommunityComplete') {
        gtag('event', 'ingest_downloaded_communityComplete_template', {});
      } else if (key === processType.COMMUNITY) {
        gtag('event', 'ingest_downloaded_community_template', {});
      }
    },
    ingestSteps: (stepkey: string) => {
      if (stepkey === steps.FILE_UPLOAD) {
        gtag('event', 'ingest_step1', {});
      } else if (stepkey === steps.SEGMENT_CREATION) {
        gtag('event', 'ingest_step2', {});
      } else if (stepkey === steps.FEILD_MAPPING) {
        gtag('event', 'ingest_step3', {});
      } else if (stepkey === steps.FILE_PREVIEW) {
        gtag('event', 'ingest_step4', {});
      }
    },
    handleClickDoneIngest: () => {
      gtag('event', 'ingest_user_clicked_done', {});
    },
    handleViewClickIngest: () => {
      gtag('event', 'ingest_user_clicked_view', {});
    },
  };
};
