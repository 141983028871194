import { createAction } from '@reduxjs/toolkit';
import { KeywordGroups } from '../../keyword-groups/keyword-groups.types';
import { StandardTaggingCampaignType } from '../types';
import LandingPageConstants from './constants';
type payloadType = {
  stndardCampaigns: StandardTaggingCampaignType[];
  standardCampaignsInsta: StandardTaggingCampaignType[];
  standardCampaignsTwitter: StandardTaggingCampaignType[];
};

export const storeStandardCampaign = createAction<payloadType>(LandingPageConstants.STORE_STANDARD_TAGGING_CAMPAIGNS);
export const storeStandardKeywords = createAction<KeywordGroups>(LandingPageConstants.STORE_STANDARD_KEYWORD_GROUP);
