const PlatformActionConfigReducerConstants: PlatformActionConfigReducerConstantsType = {
  PLATFORM_ACTIONS_CONFIG: 'PLATFORM_ACTIONS_CONFIG',
  PLATFORM_ACTION_CONFIG_FETCHED: 'PLATFORM_ACTION_CONFIG_FETCHED',
  SUPPORTED_PLATFORMS: 'SUPPORTED_PLATFORMS',
  ATTACHMENT_SUPPORTED_PLATFORMS: 'ATTACHMENT_SUPPORTED_PLATFORMS',
  SYSTEM_SETTINGS: 'SYSTEM_SETTINGS',
  BLACKLIST_ERRORS: 'BLACKLIST_ERRORS',
};

type PlatformActionConfigReducerConstantsType = {
  PLATFORM_ACTIONS_CONFIG: string;
  PLATFORM_ACTION_CONFIG_FETCHED: string;
  SUPPORTED_PLATFORMS: string;
  ATTACHMENT_SUPPORTED_PLATFORMS: string;
  SYSTEM_SETTINGS: string;
  BLACKLIST_ERRORS: string;
};
export default PlatformActionConfigReducerConstants;
