export const userProfilePagesTabs: UserProfilePagesTabsType = {
  accountSettings: 'Account Settings',
  manageUsers: 'Manage Users',
  socialMediaAccounts: 'Social Media Accounts',
  pageHealth: 'Page Health',
};

export const userProfilePagesRoutes: UserProfilePagesRoutesType = {
  MY_PROFILE: 'profile',
  ACCOUNT_SETTINGS: 'accountSettings',
  MANAGE_USERS: 'manageUsers',
  SOCIAL_MEDIA_ACCOUNTS: 'socialMediaAccounts',
  PAGE_HEALTH: 'pageHealth',
};

type UserProfilePagesTabsType = { [key: string]: 'My Profile' | 'Account Settings' | 'Manage Users' | 'Social Media Accounts' | 'Page Health' };
type UserProfilePagesRoutesType = { [key: string]: 'profile' | 'accountSettings' | 'manageUsers' | 'socialMediaAccounts' | 'pageHealth' };
