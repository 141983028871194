const automationStatsConstants: AutomationStatsConstants = {
  STORE_CAMPAIGNS: 'STORE_CAMPAIGNS',
  SET_ACTIVE_TAB_PANE: 'SET_ACTIVE_TAB_PANE',
  STORE_BASE_PATH: 'STORE_BASE_PATH',
};
type AutomationStatsConstants = {
  STORE_CAMPAIGNS: string;
  SET_ACTIVE_TAB_PANE: string;
  STORE_BASE_PATH: string;
};
export default automationStatsConstants;
