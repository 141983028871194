import { ActionReducerMapBuilder, combineReducers, createReducer } from '@reduxjs/toolkit';
import { GenericObject } from '../../../types';
import { ALLCONSTANT } from '../../automation/constants';
import searchFilterReducer from '../../dashboard/components/comments-viewer/components/search-filter/reducer';
import {
  storeInboxMessages,
  resetInboxMessages,
  setSelectedInboxMessage,
  updateAMessage,
  storeConversationMessages,
  setFilterChanged,
  storeInboxAggregation,
  decrementAggregationForMessageStatus,
  updateLabelToColorMap,
  storeLabelToColorMap,
  storeNlpTagsForFilter,
  selectForBulkAction,
  setInboxTab,
  updateMessages,
  resetMessageError,
  setNewMessageArrivedStatus,
  storeInboxBufferConversation,
  setSubscribeStatus,
  storeSessionData,
  updateConversationMessage,
  updateInboxUserBlockStatus,
} from '../actions';
import { getFilteredSelectedMessages } from '../components/utils';
import { tabsToMessageStatus, messageCountLimitForBulkOPeration } from '../config';
import { /*bulkActionTypesAPI,*/ inboxConstants, messageStatusConstants, messageTabs } from '../constants';
import { InboxMessage, InboxMessagesUI, LastOperationErrorInfo } from '../types';

export const initialState: {
  inbox: {
    [key: string]: any;
    messagesWithError: { [key: string]: LastOperationErrorInfo };
  };
  conversation: {
    messageList: { [key: string]: any };
    pageNumber?: number;
    lastMessageTime?: number;
    hasMore: boolean;
    bufferConversation: { [key: string]: any };
    sessionData?: GenericObject;
  };
  customLabelToColor: GenericObject;
  nlpTags: GenericObject;
} = {
  inbox: {
    messageList: {},
    hasMore: true,
    selectedInboxMessageId: '',
    aggregation: {},
    selectedMessagesForBulkAction: {},
    currentTab: messageTabs[0].value,
    messagesWithError: {},
    hasNewMessageArrived: true,
    isSubscribeStatus: false,
  },
  conversation: { messageList: {}, hasMore: false, bufferConversation: {} },
  customLabelToColor: {},
  nlpTags: {},
};
/* eslint-disable max-lines-per-function */

const getNextMessageId = (messageList: InboxMessagesUI, messageId: string) => {
  const newList = { ...messageList };
  delete newList[messageId];

  const messageAsArray = Object.values(messageList || {});
  const currentMessageIndex = messageAsArray.findIndex((message: any) => message.id === messageId);

  const nextOrPrevMessage = messageAsArray[currentMessageIndex + 1] || messageAsArray[currentMessageIndex - 1] || {};
  return { selectedID: nextOrPrevMessage.id, messageList: newList };
};

// const getclosestMessageId = (messageList: InboxMessagesUI, selectedMessageId: string, movedMessages?: string[]) => {
//   const newList = { ...messageList };
//   if (movedMessages) {
//     movedMessages.forEach(id => delete newList[id]);
//   }

//   const messageAsArray = Object.values(messageList || {});
//   const currentMessageIndex = messageAsArray.findIndex((message: any) => message.id === selectedMessageId);

//   const nextOrPrevMessage = messageAsArray[currentMessageIndex + 1] || messageAsArray[currentMessageIndex - 1] || {};
//   return { selectedID: nextOrPrevMessage.id, messageList: newList };
// };

const doesMessageBelongsToSameTab = (oldStatus: string, newStatus: string) => {
  if (oldStatus === newStatus) {
    return true;
  }
  return Object.keys(tabsToMessageStatus).some((tab: string) => {
    const statusApplicable = tabsToMessageStatus[tab];
    if (statusApplicable.includes(oldStatus)) {
      return statusApplicable.includes(newStatus);
    }
    return false;
  });
};
/*eslint-disable max-lines-per-function*/
const inboxReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeInboxAggregation, (state: typeof initialState, action: ReturnType<typeof storeInboxAggregation>) => {
      state.inbox.aggregation = action.payload;
    })
    .addCase(decrementAggregationForMessageStatus, (state: typeof initialState, action: ReturnType<typeof decrementAggregationForMessageStatus>) => {
      const aggr = state.inbox.aggregation;
      const { status, messageStatus } = action.payload;
      const value = (aggr && aggr[status] && aggr[status].messageStatus && aggr[status].messageStatus[messageStatus]) || 0;
      if (value) {
        state.inbox.aggregation[status].messageStatus[messageStatus] = value - 1;
      }
    })

    .addCase(updateAMessage, (state: typeof initialState, action: ReturnType<typeof updateAMessage>) => {
      //removeMessage is true when current tab is myinbox and message assigned to diff user or unassigned
      if (!doesMessageBelongsToSameTab(action.payload.updatedValues.status, state.inbox.messageList[action.payload.messageId]?.status) || action.payload.removeMessage) {
        const { selectedID, messageList } = getNextMessageId(state.inbox.messageList, action.payload.messageId);
        state.inbox = { ...state.inbox, selectedInboxMessageId: selectedID, messageList };
      } else {
        state.inbox = {
          ...state.inbox,
          messageList: {
            ...state.inbox.messageList,
            [action.payload.messageId]: {
              ...state.inbox.messageList[action.payload.messageId],
              ...action.payload.updatedValues,
            },
          },
        };
      }
    })
    .addCase(updateMessages, (state: typeof initialState, action: ReturnType<typeof updateMessages>) => {
      const { messages: updatedMessages, /*updateActionType,*/ messagesWithConflicts, messagesWithError, isDifferentUserAssign } = action.payload;
      const { selectedInboxMessageId, messageList, currentTab } = state.inbox;
      const messageToBeMovedInOtherTab: GenericObject = {};
      const allUpdatedMessages = { ...updatedMessages, ...messagesWithConflicts, ...messagesWithError };
      const newMessages = { ...messageList, ...updatedMessages, ...messagesWithConflicts, ...messagesWithError };
      Object.values(allUpdatedMessages).forEach((msg: InboxMessage) => {
        if (!tabsToMessageStatus[currentTab].includes(msg.status!)) {
          messageToBeMovedInOtherTab[msg.id] = msg;
          delete newMessages[msg.id];
        }
      });
      if (isDifferentUserAssign) {
        //isDifferentuserAssign is true when current tab is my inbox and selected messages are reassigned to new user. so remove all successfully updated messages
        Object.values(updatedMessages).forEach((msg: InboxMessage) => {
          messageToBeMovedInOtherTab[msg.id] = msg;
          delete newMessages[msg.id];
        });
      }
      if (messageToBeMovedInOtherTab[selectedInboxMessageId]) {
        const messageAsArray: InboxMessage[] = Object.values(messageList || {});
        const currentMessageIndex = messageAsArray.findIndex((message: any) => message.id === selectedInboxMessageId);
        let nextOrPrevMessage = messageAsArray.slice(currentMessageIndex + 1)?.find((msg: any) => {
          return !messageToBeMovedInOtherTab[msg.id];
        });
        if (!nextOrPrevMessage) {
          nextOrPrevMessage = messageAsArray
            .slice(0, currentMessageIndex)
            .reverse()
            ?.find((msg: any) => {
              return !messageToBeMovedInOtherTab[msg.id];
            });
        }
        if (nextOrPrevMessage) {
          state.inbox.selectedInboxMessageId = nextOrPrevMessage.id;
        } else {
          state.inbox.selectedInboxMessageId = undefined;
        }
      }
      state.inbox.messageList = newMessages;

      const selectedForBulkOperation: GenericObject = {};
      const messageIdAndErrorMap: GenericObject = {};
      Object.keys(updatedMessages).forEach((key: string) => {
        //on success remove error
        delete messageIdAndErrorMap[key];
      });
      Object.keys(messagesWithError).forEach((key: string) => {
        //add into errors and make it selected
        selectedForBulkOperation[key] = true;
        messageIdAndErrorMap[key] = messagesWithError[key].lastOperationErrorInfo;
      });

      state.inbox.selectedMessagesForBulkAction = selectedForBulkOperation;
      state.inbox.messagesWithError = messageIdAndErrorMap;
      // state.inbox.messageList = {
      //   ...state.inbox.messageList,
      //   ...messagesWithConflicts, //always refresh data if conflicts,
      //   ...messagesWithError //update message with Error
      // };
    })
    .addCase(setSelectedInboxMessage, (state: typeof initialState, action: ReturnType<typeof setSelectedInboxMessage>) => {
      state.inbox.selectedInboxMessageId = action.payload;
      state.conversation.messageList = {};
    })
    .addCase(resetInboxMessages, (state: typeof initialState) => {
      state.inbox = initialState.inbox;
    })
    .addCase(storeInboxMessages, (state: typeof initialState, action: ReturnType<typeof storeInboxMessages>) => {
      const platformUserIDToLoad = action.payload.platformUserIDToLoad;
      if (action.payload.isPollingRequest) {
        let hasNewMessageArrived = false;
        const latestDataNotSame = Object.values(action.payload.messageList)?.[0]?.lastUpdatedDate !== (Object.values(state.inbox.messageList)?.[0] as any)?.lastUpdatedDate;
        Object.keys(action.payload.messageList).forEach((messageId: string) => {
          if (state.inbox.messageList[messageId]) {
            delete state.inbox.messageList[messageId];
          }
          if (latestDataNotSame) {
            hasNewMessageArrived = hasNewMessageArrived || action.payload.messageList[messageId].messageStatus === messageStatusConstants.NEW;
          }
        });
        state.inbox = {
          ...state.inbox,
          hasNewMessageArrived: state.inbox.hasNewMessageArrived || hasNewMessageArrived,
          messageList: {
            ...action.payload.messageList,
            ...state.inbox.messageList,
          },
        };
      } else {
        const { tabName: currentTab, ...restPayload } = action.payload; //added currentTab instead of tabName to avoid confusion
        if (action.payload.pageNumber === 1) {
          state.inbox = {
            ...state.inbox,
            ...restPayload,
            currentTab,
          };
          state.inbox.selectedInboxMessageId =
            platformUserIDToLoad && action.payload.messageList[platformUserIDToLoad]
              ? action.payload.platformUserIDToLoad
              : !action.payload.doNotSelectedFirstMessage && Object.keys(action.payload.messageList || {})?.[0];
        } else {
          state.inbox = {
            ...state.inbox,
            ...action.payload,
            ...restPayload,
            messageList: {
              ...state.inbox.messageList,
              ...action.payload.messageList,
            },
          };
        }

        if (!state.inbox.selectedInboxMessageId) {
          state.inbox.selectedInboxMessageId =
            platformUserIDToLoad && action.payload.messageList[platformUserIDToLoad]
              ? action.payload.platformUserIDToLoad
              : !action.payload.doNotSelectedFirstMessage && Object.keys(action.payload.messageList || {})?.[0];
        }
      }
    })
    .addCase(storeConversationMessages, (state: typeof initialState, action: ReturnType<typeof storeConversationMessages>) => {
      const bufferConversation: any = {};
      Object.values(state.conversation.bufferConversation).forEach((conversation: any) => {
        if (conversation.platformUserId === action.payload.platformUserId) {
          bufferConversation[conversation.id] = conversation;
        }
      });
      Object.values(action.payload.messageList).forEach((message: any) => {
        delete state.conversation.bufferConversation[message.id];
      });

      if (action.payload.syncData) {
        state.conversation.messageList = {
          ...bufferConversation,
          ...action.payload.messageList,
          ...state.conversation.messageList,
        };
      } else if (action.payload.pageNumber === 1) {
        state.conversation = {
          ...state.conversation,
          ...action.payload,
          messageList: {
            ...action.payload.messageList,
            ...bufferConversation,
          },
        };
      } else {
        state.conversation = {
          ...state.conversation,
          ...action.payload,
          messageList: {
            ...bufferConversation,
            ...state.conversation.messageList,
            ...action.payload.messageList,
          },
        };
      }
    })
    .addCase(updateConversationMessage, (state: typeof initialState, action: ReturnType<typeof updateConversationMessage>) => {
      const { conversationId, updatedConversation } = action.payload;
      if (state.conversation.messageList[conversationId]) {
        state.conversation.messageList[conversationId] = {
          ...state.conversation.messageList[conversationId],
          ...updatedConversation,
        };
      } else if (state.conversation.bufferConversation[conversationId]) {
        state.conversation.bufferConversation[conversationId] = {
          ...state.conversation.bufferConversation[conversationId],
          ...updatedConversation,
        };
      }
    })
    .addCase(storeLabelToColorMap, (state: typeof initialState, action: ReturnType<typeof storeLabelToColorMap>) => {
      state.customLabelToColor = action.payload;
    })
    .addCase(updateLabelToColorMap, (state: typeof initialState, action: ReturnType<typeof updateLabelToColorMap>) => {
      state.customLabelToColor = { ...state.customLabelToColor, ...action.payload };
    })
    .addCase(storeNlpTagsForFilter, (state: typeof initialState, action: ReturnType<typeof storeNlpTagsForFilter>) => {
      state.nlpTags = action.payload;
    })
    //to reset all selected call selectForBulkAction({messageId: ALLCONSTANT, selected: false})
    .addCase(selectForBulkAction, (state: typeof initialState, action: ReturnType<typeof selectForBulkAction>) => {
      const { messageId, selected } = action.payload;
      if (messageId === ALLCONSTANT) {
        if (selected === true) {
          const allSelected: { [key: string]: boolean } = {};
          Object.keys(state.inbox.messageList)
            .slice(0, messageCountLimitForBulkOPeration)
            .forEach((id: string) => {
              allSelected[id] = true;
            });
          state.inbox.selectedMessagesForBulkAction = allSelected;
          state.inbox.showMaximumLimitReachedMessage = false;
        } else {
          state.inbox.selectedMessagesForBulkAction = {};
          state.inbox.showMaximumLimitReachedMessage = false;
        }
      } else {
        const { filteredSelectedMessagesCount } = getFilteredSelectedMessages(state.inbox.messageList, state.inbox.selectedMessagesForBulkAction);
        if (selected && filteredSelectedMessagesCount === messageCountLimitForBulkOPeration) {
          state.inbox.showMaximumLimitReachedMessage = true;
        } else if (selected) {
          state.inbox.selectedMessagesForBulkAction[messageId] = true;
          state.inbox.showMaximumLimitReachedMessage = false;
        } else {
          delete state.inbox.selectedMessagesForBulkAction[messageId];
          state.inbox.showMaximumLimitReachedMessage = false;
        }
      }
    })
    .addCase(setInboxTab, (state: typeof initialState, action: ReturnType<typeof setInboxTab>) => {
      state.inbox.currentTab = action.payload;
      state.inbox.selectedMessagesForBulkAction = {}; //deselect all if tab is changed to avoid bugs
      delete state.inbox.pageNumber;
    })
    .addCase(resetMessageError, (state: typeof initialState, action: ReturnType<typeof resetMessageError>) => {
      const messageId = action.payload;
      if (messageId === ALLCONSTANT) {
        state.inbox.messagesWithError = {};
      } else {
        delete state.inbox.messagesWithError[messageId];
      }
    })
    .addCase(setFilterChanged, (state: typeof initialState) => {
      state.inbox.filterChanged = true;
    })
    .addCase(setNewMessageArrivedStatus, (state: typeof initialState, action: ReturnType<typeof setNewMessageArrivedStatus>) => {
      state.inbox.hasNewMessageArrived = action.payload;
    })
    .addCase(storeInboxBufferConversation, (state: typeof initialState, action: ReturnType<typeof storeInboxBufferConversation>) => {
      state.conversation.bufferConversation = {
        ...state.conversation.bufferConversation,
        ...action.payload,
      };
    })
    .addCase(setSubscribeStatus, (state: typeof initialState, action: ReturnType<typeof setSubscribeStatus>) => {
      state.inbox.isSubscribeStatus = action.payload;
    })
    .addCase(storeSessionData, (state: typeof initialState, action: ReturnType<typeof storeSessionData>) => {
      state.conversation.sessionData = action.payload;
    })
    .addCase(updateInboxUserBlockStatus, (state: typeof initialState, action: ReturnType<typeof updateInboxUserBlockStatus>) => {
      const userMessages: InboxMessage[] = Object.values(state.inbox.messageList);
      const userMessageId = userMessages?.find((message: InboxMessage) => message?.platformUserId === action.payload)?.id || '';
      if (state?.inbox?.messageList?.[userMessageId]?.status) state.inbox.messageList[userMessageId].status = 'blocked';
    });
});

export default combineReducers({
  searchFilter: searchFilterReducer(inboxConstants.INBOX),
  messages: inboxReducer,
});
