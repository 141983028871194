import { segmentTagTypeConst, segmentTypeConst } from './constants';

export const shortURLClickTrackers = {
  start: '[[Amp_Short_Tracker_Start]]',
  end: '[[Amp_Short_Tracker_End]]',
};
export const broadcastPollInterval = {
  broadcastPollingInterval: 30000, //30s
  clearIntervalForBroadcast: 600000, //10min
};

/*eslint-disable @typescript-eslint/naming-convention*/
export const TIME_IN_MINUTES_FOR_BROADCASTS_POLLING = 30;
export const SEGMENT_COUNT_THRESHOLD = 4;
export const DEFAULT_OPT_OUT_TEXT = 'STOP2END';

export const segmentTagColor: { [key: string]: string } = {
  [segmentTypeConst]: 'purple',
  [segmentTagTypeConst]: 'blue',
};