import React, { ReactElement } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import styles from './spinner.module.scss';
import { getLoaderColor } from '../../config/theme-config';

export const SpinnerWithPromise = (): ReactElement | null => {
  const { promiseInProgress } = usePromiseTracker();
  if (!promiseInProgress) {
    return null;
  }
  return <Spinner />;
};

export const Spinner = (): ReactElement => {
  return (
    <div className={styles.spinner} data-testid='spinner'>
      <Loader type='ThreeDots' color={`${getLoaderColor()}`} height={100} width={100} />
    </div>
  );
};
