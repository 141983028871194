import { apiServerUrl } from '../../../../../common/api-calls/config';
import { platforms } from '../../../../../common/entities/entities.constants';
import { PlatformBots } from '../../../../../common/entities/entities.types';
import { FbPageList } from '../../../../fb-login/types';

export const filterOutConnectedPagesFromAccessedPages = (allFbPages: FbPageList = { currentUserPages: [] }, platformBots: PlatformBots = {}) => {
  const filteredCurrentUserPages = allFbPages?.currentUserPages?.filter((p: { id: string }) => !platformBots[p.id]);
  const filteredFbPageList = { ...allFbPages, currentUserPages: filteredCurrentUserPages || [] };
  return filteredFbPageList;
};

export const getPageLink = (platformBotId?: string, platform?: string, username?: string) => {
  if (!platformBotId || !platform) return '';
  switch (platform) {
    case platforms.INSTAGRAM:
      return `https://www.instagram.com/${username}`;
    case platforms.THREADS:
      return `https://www.threads.net/@${username}`;
    case platforms.TWITTER:
      return `https://www.twitter.com/${username}`;
    case platforms.YOUTUBE:
      return `https://www.youtube.com/channel/${platformBotId}`;
    case platforms.FACEBOOK:
      return `https://www.facebook.com/${platformBotId}`;
    case platforms.TIKTOK_ORGANIC:
      return username ? `https://www.tiktok.com/@${username}` : '';
    case platforms.TWILIO_SMS:
      return '';
    default:
      return ``;
  }
};

export const getApiEndPointForYoutubeDeployment = (accountId: string, botId: string, authToken: string) => {
  let params = `accountId=${accountId}&isAmplifyLite=${true}&access_token=${authToken}&finalCallbackUrl=${document.location.href}`;

  if (botId) {
    params += `&botId=${botId}`;
  }
  const url = `${apiServerUrl}platformBots/deployYouTubeBot?${params}`;
  return url;
};

export const getApiEndPointForTikTokDeployment = (accountId: string, botId: string, authToken: string, isPaid: boolean) => {
  let params = `accountId=${accountId}&isAmplifyLite=${true}&access_token=${authToken}&isPaidAccount=${isPaid}`;
  if (botId) {
    params += `&botId=${botId}`;
  }
  params += `&finalCallbackUrl=${document.location.origin + document.location.pathname}`;
  const url = `${apiServerUrl}platformBots/deployTikTokBot?${params}`;
  return url;
};

export const getApiEndPointForThreadsDeployment = (accountId: string, botId: string, authToken: string) => {
  let params = `accountId=${accountId}&isAmplifyLite=${true}&access_token=${authToken}`;
  if (botId) {
    params += `&botId=${botId}`;
  }
  params += `&finalCallbackUrl=${document.location.origin + document.location.pathname}`;
  const url = `${apiServerUrl}platformBots/deployThreadsBot?${params}`;
  return url;
};
