import { ActiveSubTabProps } from '../mobile/types';

export const getLayOutFilterParams = (activeSubPage: ActiveSubTabProps, _pageKeyForFilter?: string, _prevLocation?: string, _nextSubPage?: ActiveSubTabProps) => {
  // prevLocation = window.location.pathname + window.location.search;

  return {
    // nextSubPage,
    // prevLocation,
    activeSubPage,
    // pageKeyForFilter,
  };
};
//test
