import momentTimeZone from 'moment-timezone';
import { convertToBotTime } from '../../pages/broadcasts/utils';
import moment from 'moment';

export const getTimeZonesAsOptions = () => {
  const timezones: { value: string; label: string }[] = [];
  momentTimeZone.tz.names().map((tz: string) => timezones.push({ value: tz, label: tz }));
  return timezones;
};

export const timesAsOptions = getTimeZonesAsOptions();

export const getStartDateEndDateWithDayInterval = () => {
  const startDate = Date.parse(momentTimeZone.utc().startOf('day').format());
  const endDate = Date.parse(momentTimeZone.utc().endOf('day').format());
  return { startDate, endDate };
};

export const convertDateToTimezone = (dateString: string, timeZone: string, dateFormat: { date: string; time: string } = { date: 'DD MMM YYYY', time: 'hh:mmA (z)' }) => {
  const date: any = dateString && convertToBotTime(dateString, timeZone);
  return { date: moment(date).format(dateFormat.date), time: moment(date).format(dateFormat.time) };
};
/*eslint-disable @typescript-eslint/naming-convention*/
const unsupportedMomentTimezonesMap: any = {
  'US/Pacific-New': 'US/Pacific',
};

export const getTimeZone = (tz: string = ''): string => (unsupportedMomentTimezonesMap[tz] ? unsupportedMomentTimezonesMap[tz] : tz);
