import { connect } from 'react-redux';
import { RootState, AsyncThunkDispatch } from '../../../types';
import { ErrorModal } from '../components/index';
import { ErrorModalProps } from '../types';
import { setPageUIState } from '../../page-ui-state/actions';
import { sendReportErrorEmail } from '../actions/async-actions';

const mapStateToProps = (state: RootState): ErrorModalProps => {
  const errorMessage = state?.pageUIState?.error?.errorMessage as string;
  const isReportable = state?.pageUIState?.error?.isReportable;
  const endPoint = state?.pageUIState?.error?.endPoint;
  const description = state?.pageUIState?.error?.description as string;
  const errorProps: ErrorModalProps = {
    visible: errorMessage ? true : false,
    errorMessage: errorMessage || '',
    isReportable,
    endPoint,
    description: description || '',
  };
  return errorProps;
};
const mapDispatchToProps = (dispatch: AsyncThunkDispatch) => {
  return {
    clearError: (): void => {
      dispatch(setPageUIState({ key: 'error', value: null }));
    },
    sendErrorReport: (errorMessage: string, accountId: string): void => {
      dispatch(sendReportErrorEmail(accountId, errorMessage));
    },
  };
};
const ErrorModalContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorModal);

export default ErrorModalContainer;
