import { dashboardTabConstants } from '../pages/dashboard/constants';

export const RoutesMap = {
  DASHBOARD: 'Dashboard',
  KEYWORD_GROUPS: 'Keyword Groups',
  TAGS: 'Tags',
  GETTING_STARTED: 'Home',
  MODERATION: 'Moderation',
  BRAND: 'Brand Lift',
  SMART: 'Smart Replies',
  REPORTING: '',
  COMMENT_VIEWER: 'Comment Viewer',
  SOCIAL_MEDIA_ACCOUNTS: 'Social Media Accounts',
  INVITE_ADD_TEAM_MEMBERS: 'Manage Users',
  PROFILE: 'Profile',
  ACCOUNT_SETTINGS: 'Account Settings',
  CREATE_USER: 'Create User',
  CREATE_TAG: 'Create Tag',
  CREATE_BRAND_LIFT: 'Create Brand Lift',
  CREATE_SMART_REPLIES: 'Create Smart Replies',
  CREATE_MODERATION: 'Create Moderation',
  CREATE_KEYWORD_GROUP: 'Create Keyword Group',
  TEMPLATES: 'Templates',
  CREATE_TEMPLATE: 'Create Template',
  EDIT_TEMPLATE: 'Edit Template',
  REPORTS: 'Reports',
  INBOX: 'Inbox',
  COMMENTS_MODERATION: 'Comment Moderation',
  COMMENTS_ENGAGEMENTS: 'Comment Engagements',
  MENTION_MODERATION: 'Mention Moderation',
  MENTION_ENGAGEMENTS: 'Mention Engagements',
  RECENT_BROADCASTS: 'Recent',
  DRAFT_BROADCASTS: 'Draft',
  SCHEDULED_BROADCASTS: 'Scheduled',
  REPEAT_BROADCASTS: 'Repeat',
  ARCHIVED_BROADCASTS: 'Archived',
  EDIT_BROADCAST: 'Broadcasts | Edit Broadcast',
  VIEW_BROADCAST: 'Broadcasts | View Broadcast',
  CREATE_BROADCAST: 'Broadcasts | Create Broadcast',
  SEGMENTS: 'Segments',
  CREATE_SEGMENTS: 'Create Segment',
  EDIT_SEGMENTS: 'Edit Segment',
  CLONE_SEGMENTS: 'Clone Segment',
};
export const routeToPage: { [key: string]: string } = {
  '/:accountId': 'Home',
  '/:accountId/:platformBotId/dashboard': 'Dashboard',
  [`/:accountId/:platformBotId/dashboard/${dashboardTabConstants.COMMENTS}`]: 'Dashboard',
  [`/:accountId/:platformBotId/dashboard/${dashboardTabConstants.MENTIONS}`]: 'Dashboard',
  '/:accountId/:platformBotId/inbox': 'Inbox',
  '/:accountId/:platformBotId/profile': 'Profile',
  '/:accountId/:platformBotId/accountSettings': 'Account Settings',
  '/:accountId/:platformBotId/tags/standard': 'Tags',
  '/:accountId/:platformBotId/tags/custom': 'Tags',
  '/:accountId/:platformBotId/tags/createTag': 'Create Tag',
  '/:accountId/:platformBotId/tags/editTag/:tagId': 'Tags',
  '/:accountId/:platformBotId/keywordGroups/standard': 'Keyword Groups',
  '/:accountId/:platformBotId/keywordGroups/custom': 'Keyword Groups',
  '/:accountId/:platformBotId/keywordGroups/createKeywordGroup': 'Create Keyword Group',
  '/:accountId/:platformBotId/keywordGroups/editKeywordGroup/:keywordGroupId': 'Keyword Groups',
  '/:accountId/:platformBotId/commentModeration/standard': 'Moderation',
  '/:accountId/:platformBotId/commentModeration/custom': 'Moderation',
  '/:accountId/:platformBotId/commentModeration/createCampaign': 'Create Moderation',
  '/:accountId/:platformBotId/commentModeration/editCampaign/:campaignId': 'Moderation',
  '/:accountId/:platformBotId/brandLift/standard': 'Brand Lift',
  '/:accountId/:platformBotId/brandLift/custom': 'Brand Lift',
  '/:accountId/:platformBotId/brandLift/createCampaign': 'Create Brand Lift',
  '/:accountId/:platformBotId/brandLift/editCampaign/:campaignId': 'Brand Lift',
  '/:accountId/:platformBotId/templates/active': 'Active Templates',
  '/:accountId/:platformBotId/templates/archived': 'Archived Templates',
  '/:accountId/:platformBotId/templates/createTemplate': 'Create Template',
  '/:accountId/:platformBotId/templates/editTemplate/:campaignId': 'Edit Template',
  '/:accountId/:platformBotId/templates/viewTemplate/:campaignId': 'View Template',
  '/:accountId/:platformBotId/templates/cloneTemplate/:campaignId': 'Clone Template',
  '/:accountId/:platformBotId/smartReplies/standard': 'Smart Replies',
  '/:accountId/:platformBotId/smartReplies/custom': 'Smart Replies',
  '/:accountId/:platformBotId/smartReplies/createCampaign': 'Create Smart Replies',
  '/:accountId/:platformBotId/smartReplies/editCampaign/:campaignId': 'Smart Replies',
  '/:accountId/:platformBotId/socialMediaAccounts': 'Social Media Accounts',
  '/:accountId/:platformBotId/manageUsers': 'Manage Users',
  '/:accountId/:platformBotId/pageHealth': 'Page Health',
  '/:accountId/:platformBotId/manageUsers/createUser': 'Create User',
  '/:accountId/:platformBotId/reports': 'Reports',
  '/:accountId/:platformBotId/comments/moderation': 'Comment Moderation',
  '/:accountId/:platformBotId/comments/engagements': 'Comment Engagements',
  '/:accountId/:platformBotId/mentions/engagements': 'Mention Engagements',
  '/:accountId/:platformBotId/mentions/moderation': 'Mention Moderation',
  '/:accountId/:platformBotId/broadcasts/editBroadcast/:broadcastId': 'Broadcasts | Edit Broadcast',
  '/:accountId/:platformBotId/broadcasts/viewBroadcast/:broadcastId': 'Broadcasts | View Broadcast',
  '/:accountId/:platformBotId/broadcasts/createBroadcast': 'Broadcasts | Create Broadcast',
  '/:accountId/:platformBotId/segments': 'Segments',
  '/:accountId/:platformBotId/segments/editSegment/:segmentId': 'Segments | Edit Segments',
  '/:accountId/:platformBotId/segments/createSegment': 'Segments | Create Segments',
  '/adminTools/accountCreation': 'Admin Tools | Account Creation',
  '/adminTools/smsDeployment': 'Admin Tools | SMS Deployment',
  '/adminTools/vanityNumber': 'Admin Tools | Vanity Number',
  '/:accountId/:platformBotId/resources': 'Resources',
  '/:accountId/:platformBotId/manageLabels': 'Manage Labels',
  '/requestPageAccess': 'Request Page Access',
};

export const deleteConfirmationText = (entity: string) => `Are you sure you want to delete this ${entity}?`;
export const archiveConfirmationText = (entity: string) => `Are you sure you want to archive this ${entity}?`;
export const confirmationText = (entity: string, action: string) => `Are you sure you want to ${action} this ${entity}?`;
export const resetConfirmationText = () => `Are you sure you want to reset the password?`;

export const Action = {
  DELETE: 'delete',
  ARCHIVE: 'archive',
  EDIT: 'edit',
  CREATE: 'create',
  RESET: 'reset',
};

export const Entity = {
  TEMPLATE: 'template',
  KEYWORD_GROUP: 'keyword group',
  CAMPAIGN: 'campaign',
  TAG: 'tag',
  USER: 'user',
  SEGMENTS: 'segment',
  BROADCAST: 'broadcast',
  ACCOUNT: 'account',
};

export const notAvailable = 'N/A';

type auditLogsEntitiesType = {
  QR_CODE: string;
  SHORT_URL: string;
  MEDIA_VIEW_URL: string;
  BROADCAST: string;
  USER: string;
  SEGMENT: string;
};

export const auditLogsEntities: auditLogsEntitiesType = {
  QR_CODE: 'qrCode',
  SHORT_URL: 'shortUrl',
  MEDIA_VIEW_URL: 'mediaViewUrl',
  BROADCAST: 'broadcast',
  USER: 'user',
  SEGMENT: 'segment',
};

export const cliqzUIString = 'Cliqz-UI';
export const shortUrlMetaDataReleaseDate = '20230721';
