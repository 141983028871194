export const DefaultCommentBreakDownChartConfig = {
  width: 12,
  colors: {
    actionableColor: '#0a088c',
    nonActionableColor: '#1297e0',
    taggedColor: '#25b0fc',
    hiddenComments: '#9fc1ff',
    brandReaction: '#77a2ef',
    publicReplies: '#5480d0',
    blockedUsers: '#3c5cbc',
    deletedComments: '#0a088c',
    engagementTotal: ' #5480d0',
    reviewedComments: '#1b3b74',
    engaged: '#0050E8',
  },
};

export type CommentBreakDownChartConfigType = {
  width: number;
  colors: {
    [key: string]: string;
  };
};

export const minPercentWidthOfCommentBreakdowns = 10;
export const innerEngagementsRadius = 70;
export const minToAllEngagements = 5;
export const maxCommentBulkAction = 100;
