import { logLevel as configuredLogLevel } from "../../config";
export enum LogLevel {
  ALL = "ALL",
  ERROR = "ERROR",
  NONE = "NONE"
};

export class Logger {
  private logLevel: LogLevel;
  constructor(logLevel: LogLevel = configuredLogLevel as LogLevel) {
    this.logLevel = logLevel;
  }

  public setLogLevel(logLevel: LogLevel): void {
    this.logLevel = logLevel;
  }

  public log(...args: any): void {
    if (this.logLevel === LogLevel.ALL) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }

  public error(...args: any): void {
    if ((this.logLevel !== LogLevel.NONE)) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  }
}

export const logger: Logger = new Logger();