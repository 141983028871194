import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { GenericModalProps } from '../types';
import { setCssVariable } from '../../utils';

export const GenericAntModal = (props: GenericModalProps) => {
  const { title = 'Error', destroyOnClose = true, mask = false, visible: visibleProp, footer, onOk, onCancel, afterClose, ...rest } = props;
  const [visible, setVisible] = useState(visibleProp);
  useEffect(() => {
    setVisible(visibleProp);
    visibleProp && setCssVariable();
  }, [visibleProp]);

  const hideModal = (): void => {
    setVisible(false);
  };

  const handleOk = (e: SyntheticEvent): void => {
    hideModal();
    if (onOk) {
      onOk(e);
    }
  };
  const handleCancel = (e: SyntheticEvent): void => {
    hideModal();
    if (onCancel) {
      onCancel(e);
    }
  };
  return (
    <Modal title={title} destroyOnClose={destroyOnClose} visible={visible} onOk={handleOk} onCancel={handleCancel} footer={footer} mask={mask} afterClose={afterClose} {...rest}>
      {props.children}
    </Modal>
  );
};
