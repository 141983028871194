import { createAction } from '@reduxjs/toolkit';
import { UserRole } from '../../../types/state';
import constants from '../login.constants';
import { User, UserPermissions, AuthToken } from '../login.types';

export const setAuthToken = createAction<AuthToken>(constants.SET_AUTH_TOKEN);
export const setTempAuthDetails = createAction(constants.SET_TEMP_AUTHDETAILS, (authToken: string, email: string, qrCodeUri?: string) => ({
  payload: { authToken, email, qrCodeUri },
}));
export const clearTempAuth = createAction(constants.CLEAR_TEMP_AUTHDETAILS);
export const storeUserDetails = createAction<User>(constants.STORE_USER_DETAILS);
export const storeUserPermissions = createAction<UserPermissions>(constants.STORE_USER_PERMISSIONS);
export const storeUserRoles = createAction<UserRole[]>(constants.STORE_USER_ROLES);
export const logOut = createAction(constants.LOG_OUT);
export const setLogActivityFlag = createAction<boolean>(constants.SET_LOG_ACTIVITY_FLAG);
