import React, { MouseEventHandler, useState } from 'react';
import { ReactComponent as Copy } from '../../../assets/svg/copy.svg';
import { logger } from '../../utils/logger';
import Styles from './copy.module.scss';

type CopyButtonProps = {
  toolTipPosition?: 'top';
  showCopiedTooltip?: string;
  textToCopy?: string;
  copiedTooltipText?: string;
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  copyElement?: React.ReactNode;
  dataTestId?: string;
};

const CopyButton = (props: CopyButtonProps) => {
  const {
    textToCopy = '',
    copiedTooltipText = 'Copied!',
    className,
    color,
    width = 16,
    height = 16,
    copyElement = <Copy color={color} width={width} height={height} />,
    toolTipPosition,
    dataTestId,
  } = props;
  const [showCopiedTooltip, setshowCopiedTooltip] = useState(false);

  const copyText = (e: MouseEventHandler<HTMLAnchorElement>): void => {
    (e as any).stopPropagation();
    try {
      textToCopy && navigator.clipboard.writeText(textToCopy);
      showCopiedTooltipFunc();
    } catch (error) {
      logger.error(error);
    }
  };
  const showCopiedTooltipFunc = () => {
    setshowCopiedTooltip(true);
    setTimeout(() => setshowCopiedTooltip(false), 2000);
  };

  return (
    <span className={textToCopy ? `${Styles.copyButton} ${className || ''}` : `${Styles.copyButton} ${className || ''} disable-actions`}>
      <span onClick={(e: any) => copyText(e)} data-testid={dataTestId}>
        {copyElement}
      </span>
      {showCopiedTooltip && (
        <p style={{ top: toolTipPosition === 'top' ? '-20px' : '20px' }} className={`${Styles.copiedTooltipFloating} ${showCopiedTooltip ? Styles.active : ''}`}>
          <span>{copiedTooltipText}</span>
        </p>
      )}
    </span>
  );
};

export default CopyButton;
