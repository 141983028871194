import React from 'react';

type CloseIconProps = {
  color?: string;
  // width?: string;
  // height?: string;
};

const CloseIcon = ({ color }: CloseIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24.67' height='24.67' viewBox='0 0 24.67 24.67'>
      <rect style={{ fill: 'transparent' }} width='24.67' height='24.67' />
      <path
        d='M2.354,292.237a.493.493,0,0,0-.343.846l4.547,4.583-4.547,4.579a.495.495,0,1,0,.7.7l4.541-4.579,4.543,4.579a.495.495,0,0,0,.7-.7l-4.547-4.579,4.547-4.583a.492.492,0,1,0-.7-.692l-4.543,4.576L2.71,292.391a.493.493,0,0,0-.357-.154Z'
        transform='translate(5.084 -285.332)'
        {...(color && { fill: color })}
      />
    </svg>
  );
};

export default CloseIcon;
