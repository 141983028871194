import { createAction } from '@reduxjs/toolkit';
import constants from './constants';
import { WordCloudEntities, TopicType } from '../types';

type TopicTrendsAction = {
  topicType: TopicType;
  wordCloudEntities: WordCloudEntities;
};

export const storeTopicTrends = createAction<TopicTrendsAction>(constants.STORE_WORD_CLOUD);
export const resetTopicTrends = createAction<TopicType>(constants.RESET_TOPIC_TRENDS);
