import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadJsAnalyticsScriptToHead } from './analytics/google-analytics';
import App from './App';
import { FBConfig } from './config';
import reportWebVitals from './reportWebVitals';
import * as ServiceWorkerRegistration from './serviceWorkerRegistration';
import { SwConfig } from './sw-config';
import './index.css';
import { getFeviconImageUrl } from './config/theme-config';
import { isMobile } from './mobile/utils';
import _ from 'lodash';
import { set100vhVar } from './common/utils';

function initializeRequiredPlugins(): void {
  initializeFB();
  initializeGoogleAnalytics();
  replaceFeviconIcon();
}

function replaceFeviconIcon(): void {
  const faviconUrl = getFeviconImageUrl();
  if (!faviconUrl) {
    return;
  }
  let link: any = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = faviconUrl;
}
function initializeGoogleAnalytics() {
  loadJsAnalyticsScriptToHead();
}
/* eslint-disable */
function initializeFB(): void {
  (window as any).fbAsyncInit = function () {
    const init = {
      appId: FBConfig.id,
      cookie: true, // enable cookies to allow the server to access
      // the session
      xfbml: true, // parse social plugins on this page
      version: FBConfig.graphAPIVersion, // use graph api version 2.8
    };
    (window as any).FB.init(init);
  };
  // Load the SDK asynchronously
  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s) as any;
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs?.parentNode?.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}
/* eslint-enable */

// Run the application when both DOM is ready and page content is loaded
if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body) {
  initializeRequiredPlugins();
} else {
  document.addEventListener('DOMContentLoaded', initializeRequiredPlugins, false);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorkerRegistration.register(SwConfig);
reportWebVitals();

//mobile specific

(window as any).isMobile = isMobile();

(() => {
  set100vhVar();
  const handleResize = _.debounce(set100vhVar, 100);
  window.addEventListener('resize', handleResize);
})();
