import { apiServerUrl } from './../api-calls/config';

const prodDomains = ['cliqz.amplify.ai', 'triller.afy.ai', 'app.amplify.ai', 'app.cliqz.ai', 'julius.amplify.ai'];
export const prodEndPoint = 'https://api.amplify.ai/api/v1/';

export const shouldShowUsingProdWarning = () => {
  return (apiServerUrl as string) === prodEndPoint && !isDeployedOnProd();
};

export const isDeployedOnProd = () => {
  return prodDomains.includes(window.location.hostname);
};
