import { createAction } from '@reduxjs/toolkit';
import { SentimentStatsTypeUI } from '../../dashboard/components/sentiments/types';
import constants from './constants';

type payload = {
  data: SentimentStatsTypeUI;
  isPast?: boolean;
  fetchAdvancedSentimentData: boolean;
};

export const storeSentimentAnalysisStats = createAction<payload>(constants.STORE_SENTIMENT_ANALYSIS_STATS);
export const removeSentimentAnalysisStats = createAction(constants.REMOVE_SENTIMENT_ANALYSIS_STATS);
export const setSelectedPostsCountForSentimentAnalysis = createAction<number>(constants.SET_SELECTED_POSTS_COUNT);
