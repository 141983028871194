import Humanize from 'humanize-plus';
import moment, { Moment } from 'moment-timezone';

export const AddCommaSeparator = (valueToConvert: number | string) => {
  const valueProp = typeof valueToConvert === 'string' ? parseInt(valueToConvert, 10) : valueToConvert;
  return Humanize.intComma(valueProp);
};

export const humanizeNumber = (valueToConvert: number, decimals: number = 2) => {
  return Humanize.compactInteger(valueToConvert, decimals);
};

export const humanizeNumberWithComma = (value?: number, toFixedDecimal?: number, toFixedDecimalThreshold?: number): string => {
  const fixedValue = value?.toFixed(toFixedDecimal);
  const [integerPart, decimalPart] = fixedValue?.split('.') || [];
  const formattedIntegerPart = Humanize.intComma(parseInt(integerPart || '0', 10));
  const showDecimalPart = toFixedDecimal && value && parseFloat(`0.${decimalPart}`) !== 0 && (!toFixedDecimalThreshold || parseFloat(fixedValue || '0') < toFixedDecimalThreshold);

  return `${formattedIntegerPart}${showDecimalPart ? `.${decimalPart}` : ''}`;
};

export const humanizeDate = (date: Moment, options: any = {}, timezone: string = '') => {
  if (timezone) {
    date.tz(timezone); /*humanize date according to a needed timezone*/
  }
  return date.calendar(null, {
    lastWeek: '[Last] dddd',
    lastDay: options.showTime ? '[Yesterday], h:mm A' : '[Yesterday]',
    sameDay: options.showTime || options.minFormat ? 'h:mm A' : '[Today]',
    nextDay: options.showTime ? '[Tomorrow], h:mm A' : '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: function sameElse() {
      if (!options.isCurrentYearNeeded && (this as any)?.year?.() === moment().year()) {
        return options.minFormat ? `DD MMM` : 'ddd DD MMM';
      }
      return options.minFormat ? 'DD MMM YYYY' : 'ddd DD MMM YYYY';
    },
  });
};

export const calculateAgeFromDob = (dateString: string, format: string = 'MM/DD/YYYY') => {
  const dob = moment(dateString, format);
  const now = moment();
  if (dateString && dob.isValid()) {
    return now.diff(dob, 'years').toString();
  }
  return '';
};

export function concatWithSpace(str1: string = '', str2: string = ''): string {
  if (str1 && str2) {
    return `${str1} ${str2}`;
  }
  return str1 || str2;
}
