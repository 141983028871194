import { notAvailable } from '../../constants';
import {
  AgeFilterType,
  FanGroupFieldType,
  FilterType,
  FilterTypeExcludingAge,
  CommonFilterType,
  GeoFilterType,
  NotificationFilterType,
  SubscriptionFilterType,
  AudiencePageType,
  AppliedSegmentUsersFilters,
} from './type';
import variables from './analysis.module.scss';
import localesAndTimeZones from './locales-timezones-list.json';
import { platforms } from '../../common/entities/entities.constants';
import { GenericObject } from '../../types';
import { ALLCONSTANT } from '../automation/constants';

const { missingDataColor: missingColor, chartOrange, chartYellow, chartRed, chartTeal, chartBlue, chartGreen, chartIndigo } = variables;

export const AudienceTabs: { [key: string]: AudiencePageType } = {
  ANALYSIS: 'analysis',
  NEWMEMBERS: 'newMembers',
  SEGMENTUSERS: 'segmentUsers',
};

export const GeoFanGroupCategories: { [key in GeoFilterType]: GeoFilterType } = { COUNTRY: 'COUNTRY', STATE: 'STATE', CITY: 'CITY' };

export const CommonFanGroupCategories: { [key in CommonFilterType]: CommonFilterType } = { GENDER: 'GENDER' };

export const AgeFanGroupCategory: { [key in AgeFilterType]: AgeFilterType } = { AGE: 'AGE' };

export const NotificationFanGroupCategories: { [key in NotificationFilterType]: NotificationFilterType } = {
  LABEL: 'LABEL',
  ...CommonFanGroupCategories,
  TIMEZONE: 'TIMEZONE',
  LOCALE: 'LOCALE',
};

export const SubscriptionFanGroupCategories: { [key in SubscriptionFilterType]: SubscriptionFilterType } = {
  ...GeoFanGroupCategories,
  ...CommonFanGroupCategories,
  ...AgeFanGroupCategory,
};

export const FanGroupCategoriesWithoutAge: { [key in FilterTypeExcludingAge]: FilterTypeExcludingAge } = {
  ...GeoFanGroupCategories,
  ...NotificationFanGroupCategories,
};

export const FanGroupCategories: { [key in FilterType]: FilterType } = {
  ...FanGroupCategoriesWithoutAge,
  ...AgeFanGroupCategory,
};

const CommonGroupFields: { [key: string]: FanGroupFieldType } = {
  [FanGroupCategories.GENDER]: {
    name: 'gender',
    label: 'Gender',
    segmentName: 'Gender',
    id: FanGroupCategories.GENDER,
    field: 'profile.gender',
    aggregationType: 'terms',
    missing: notAvailable,
    missingColor,
    establishedGroups: [
      { label: 'Male', value: 'male', fill: chartYellow },
      { label: 'Female', value: 'female', fill: chartRed },
      { label: 'Non-Binary', value: 'nonbinary', fill: chartOrange },
      { label: 'Not Specified', value: 'not-specified', fill: chartTeal },
    ],
  },
};

export const SubscriptionGroupFields: { [key: string]: FanGroupFieldType } = {
  [FanGroupCategories.COUNTRY]: {
    name: 'country',
    label: 'Country',
    segmentName: 'Country',
    id: FanGroupCategories.COUNTRY,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    field: 'profile.location.country',
    aggregationType: 'terms',
    missing: notAvailable,
    missingColor,
  },
  [FanGroupCategories.STATE]: {
    name: 'state',
    label: 'States',
    segmentName: 'State',
    id: FanGroupCategories.STATE,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    field: 'profile.location.state',
    aggregationType: 'terms',
    missing: notAvailable,
    missingColor,
  },
  [FanGroupCategories.CITY]: {
    name: 'city',
    label: 'Cities',
    segmentName: 'City',
    id: FanGroupCategories.CITY,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    field: 'profile.location.city',
    aggregationType: 'terms',
    missing: notAvailable,
    missingColor,
  },
  ...CommonGroupFields,
  [FanGroupCategories.AGE]: {
    name: 'age',
    label: 'Age',
    segmentName: 'Date of Birth',
    id: FanGroupCategories.AGE,
    field: 'profile.dateOfBirth',
    aggregationType: 'date_range',
    missing: '01/01/1800',
    missingColor,
    establishedGroups: [
      { label: '<17', value: '<17', fill: chartRed, from: 17 },
      { label: '17-27', value: '17-27', fill: chartYellow, from: 27, to: 17 },
      { label: '28-37', value: '28-37', fill: chartOrange, from: 37, to: 28 },
      { label: '38-47', value: '38-47', fill: chartTeal, from: 47, to: 38 },
      { label: '48-60', value: '48-60', fill: chartGreen, from: 60, to: 48 },
      { label: '>60', value: '>60', fill: chartBlue, to: 60 },
    ],
  },
};

const locales = localesAndTimeZones.locales.locale
  .map((locale: any) => ({ label: locale.englishName, value: locale.codes.code.standard.representation, fill: '' }))
  .sort((first: any, second: any) => {
    if (first.label < second.label) return -1;
    else if (first.label > second.label) return 1;
    return 0;
  });

const timeZones = localesAndTimeZones.timeZones.map((tz: any) => ({ ...tz, value: parseFloat(tz.value).toString(), fill: '' }));

export const NotificationGroupFields: { [key: string]: FanGroupFieldType } = {
  ...CommonGroupFields,
  [FanGroupCategories.LOCALE]: {
    name: 'locale',
    label: 'Languages',
    segmentName: 'Language',
    id: FanGroupCategories.LOCALE,
    field: 'profile.locale',
    aggregationType: 'terms',
    missing: notAvailable,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    establishedGroups: locales,
    missingColor,
  },
  [FanGroupCategories.TIMEZONE]: {
    name: 'timezone',
    label: 'Timezones',
    segmentName: 'Timezone',
    id: FanGroupCategories.TIMEZONE,
    field: 'profile.timezone',
    aggregationType: 'terms',
    missing: 50,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    establishedGroups: timeZones,
    missingColor,
  },
  [FanGroupCategories.LABEL]: {
    name: 'label',
    label: 'Label',
    segmentName: 'Label',
    id: FanGroupCategories.LABEL,
    field: 'notifications.label',
    aggregationType: 'terms',
    missing: notAvailable,
    fillColors: [chartRed, chartOrange, chartTeal, chartBlue, chartGreen, chartIndigo],
    missingColor,
  },
};

export const FanGroupFields: { [key: string]: FanGroupFieldType } = { ...SubscriptionGroupFields, ...NotificationGroupFields };

export const defaultFilters: GenericObject = {
  [platforms.TWILIO_SMS]: { COUNTRY: ['United States'] },
};

export const defaultAggregationField: GenericObject = {
  [platforms.TWILIO_SMS]: 'subscriptions',
  [platforms.WHATSAPP]: 'subscriptions',
  [platforms.SPRINKLR_APPLE]: 'subscriptions',
  DEFAULT: 'notifications.label',
};

// export const speedometerSegmentColors = ['#95DD4D', '#79C32F', '#62A81C', '#62A81C'];

export const defaultAgeInYears = { min: 0, max: 150 };

export const AudienceErrorAndMessageConstants = {
  createSegmentSuccess: 'Segment Created Successfully',
  createSegmentFailure: 'Failed Creating Segment',
  ageFilterError: 'To should be more than From',
};

export const AggregationBucketLimit = 10000;

export const standardDateFormat = 'YYYYMMDD';

export const dataUnavailableText = 'Data Unavailable';

export const noDataAvailableText = 'No Data Available';

export const subscriptionDateRangeInDays = 30;

export const calculateTotalFansForDays = 90;

export const platformFanTermMap = {
  [platforms.FACEBOOK]: 'Subscribers',
  [platforms.INSTAGRAM]: 'Subscribers',
  [platforms.WHATSAPP]: 'Subscribers',
  [platforms.SPRINKLR_APPLE]: 'Subscribers',
  [platforms.TWILIO_SMS]: 'Users',
  DEFAULT: 'Users',
};

export const audienceConfig = {
  NEWMEMBERSDATERANGE: 7,
  MEMBERPAGINATIONLIMIT: 100,
  MEMBERSELECTIONLIMIT: 100,
  MINDIGITFORUSERSEARCH: 6,
};

export const unsetAudienceFilters = {
  appliedFilters: undefined,
  subscriptionEndDate: undefined,
  included: undefined,
  excluded: undefined,
  subscribed: undefined,
  searchId: undefined,
};

export const defaultSegmentUserFilters: AppliedSegmentUsersFilters = { included: [ALLCONSTANT], excluded: [], subscribed: false, searchId: '' };

export const platformsWithPhoneNumberSearch = [platforms.CM_WHATSAPP, platforms.KARIX_WA, platforms.WHATSAPP, platforms.TWILIO_WA, platforms.TWILIO_SMS];
