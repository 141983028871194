import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUserPermissions } from '../../../common/role-level-permission/hooks/permission';
import { permissionEntities, permissionType } from '../../../common/role-level-permission/permission.constants';
import { showErrorModal } from '../../../common/utils';
import { useCurrentAccount, useCurrentAccountId, useCurrentBot, useCurrentPlatformBotObj, useCurrentUser } from '../../../hooks';
import { RootState } from '../../../types';
import { botConfigTypeCliqz } from '../../settings/constant';
import { readOnlyUserRoles, UserListingTabs, userRolesLite } from '../components/manage-users/constants';
import queryString from 'query-string';
import { SocialMediaAccountsTableRecord } from '../user-profile-pages.types';
import UnlinkAction from '../components/social-media-accounts/components/unlink-action';
import PermissionViewer from '../components/social-media-accounts/components/permissions-action';
import { RoleApiType } from '../type';
import { UserRole } from '../../../types/state';
import { encodeValue } from '../../../common/utils/persist-time-filter';
import { useHistory } from 'react-router-dom';

export const useSegregatedUsers = () => {
  const segregatedUsers = useSelector((state: RootState) => state.profileManagement.segregatedUsers);
  return segregatedUsers;
};

export const useRoles = (userId: string) => {
  const users = useSelector((state: RootState) => state.entities.users);
  const user = users[userId] || {};
  return user.userRoles || [];
};

export const useIsReadOnlyUser = () => {
  const userRoles = useSelector((state: RootState) => state.auth?.user?.userRoles);
  const accountId = useSelector((state: RootState) => state.entities.account?.id);

  return userRoles?.some((roleObj: RoleApiType) => roleObj.account === accountId && readOnlyUserRoles.includes(roleObj.role));
};

export const useUserData = (userId: string) => {
  const users = useSelector((state: RootState) => state.entities.users);
  return users[userId];
};

export const useManageUserPermission = () => {
  const editAccount = useUserPermissions(permissionEntities.ACCOUNT, permissionType.EDIT_ACCOUNT);
  return editAccount;
};

export const useUserActiveTabPane = () => {
  let currentActiveTab = useSelector((state: RootState) => state.profileManagement.usersActiveTabPane);
  const editAccountPermission = useManageUserPermission();
  const currentUser = useCurrentUser();
  const segregatedUsers = useSegregatedUsers();
  const currentActiveTabVal = currentActiveTab === UserListingTabs.accountOwners?.value ? 'accountOwner' : 'botOwner';

  if (!editAccountPermission && !segregatedUsers?.[currentActiveTabVal]?.find((user?: any) => user?.email === currentUser?.email)) {
    currentActiveTab = currentActiveTab === UserListingTabs.accountOwners?.value ? UserListingTabs.pageOwners?.value : UserListingTabs.accountOwners?.value;
  }

  return currentActiveTab;
};

export const useIsAdminUser = () => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const accountId = useCurrentAccountId();
  const overriddenRoles = useSelector((state: RootState) => state.entities.account?.config?.amplifyLiteUIConfig?.overriddenRoles?.admin)?.role;

  const isSuperAdminOrBotworkAdmin = currentUser && currentUser.roles && ['SuperAdmin', 'BotworxAdmin'].includes(currentUser.roles);

  if (isSuperAdminOrBotworkAdmin) {
    return true;
  }
  //overridden role
  if (overriddenRoles && overriddenRoles === currentUser?.roles) {
    return true;
  }

  let userRole = currentUser?.roles;
  if (currentUser?.userRoles) {
    const roleObj = currentUser.userRoles.find((roleObj: UserRole) => {
      return roleObj.account === accountId;
    });
    userRole = roleObj?.role;
  }

  return Boolean(userRole && [userRolesLite.ACCOUNT_OWNER, userRolesLite.AMPLIFY_ACCOUNT_OWNER].includes(userRole));
};

export const useIsBotworxAdminUser = () => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const isSuperAdminOrBotworkAdmin = currentUser && currentUser.roles && ['SuperAdmin', 'BotworxAdmin'].includes(currentUser.roles);
  if (isSuperAdminOrBotworkAdmin) {
    return true;
  }
  return false;
};

export const useIsAmplifyAccountOwner = () => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const isAmplifyAccountOwner = currentUser && currentUser.roles && ['AmplifyAccountOwner'].includes(currentUser.roles);
  if (isAmplifyAccountOwner) {
    return true;
  }
  return false;
};

export const useIsAccountOwnersOrBotOwners = () => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const IsAccountOwnersOrBotOwners = currentUser && currentUser.roles && [userRolesLite.ACCOUNT_OWNER, userRolesLite.BOT_OWNER].includes(currentUser.roles);
  if (IsAccountOwnersOrBotOwners) {
    return true;
  }
  return false;
};

export const useBotConfigForActBlue = () => {
  const currentAccount: any = useCurrentAccount();
  const currentPlatformBotObj = useCurrentPlatformBotObj();
  if (currentAccount?.config?.uiConfig?.[currentPlatformBotObj?.platformAsInSystemSettings || '']?.enableBroadcastDonationDisplay === true) {
    return true;
  }
  return false;
};
export const useBroadcastIdFromUrl = () => {
  if (window.location.pathname.includes('viewBroadcast')) {
    return window.location?.pathname.split('?filters')[0].split('/')[window.location?.pathname.split('/')?.length - 2];
  } else if (window.location.pathname.includes('editBroadcast')) {
    return window.location?.pathname.split('?filters')[0].split('/')[window.location?.pathname.split('/')?.length - 1];
  }
  return '';
};
export const useShowAccountDetailsBannerForNonBotworxAdmin = () => {
  const isBotworxAdmin = useIsBotworxAdminUser();
  const { accounts, account } = useSelector((state: RootState) => state.entities);
  const hasMultipleAccountAccess = accounts && Object.values(accounts).length > 1;
  const currentPlatform = useCurrentPlatformBotObj();
  return { isBotworxAdmin, account, hasMultipleAccountAccess, currentPlatform };
};

export const useIsCliqzTypeBotConfig = () => {
  const bot = useCurrentBot();
  if (bot && bot.type === botConfigTypeCliqz) {
    return true;
  }
  return false;
};

export const useErrorForYoutubeDeployment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const error = { message: parsed.errMsg };

    if (parsed.errCode) {
      showErrorModal(error, dispatch);
      history.push({ pathname: window.location.pathname, search: `?filters=${encodeValue({ layout: { activeSubPage: { title: 'Social Media Accounts', value: 'main' } } })}` });
    } //eslint-disable-next-line
  }, [dispatch]);
};

export const useRenderSocialMedialAction = (handlePermissionClick?: (recordId: string) => void) => {
  const hasUnlinkPermissions = useUserPermissions(permissionEntities.BOT, permissionType.DEPLOY_BOT);
  const hasUnlinkTwitterPermission = useUserPermissions(permissionEntities.BOT, permissionType.DEPLOY_BOT_TWITTER);
  const actionsRender = (_connected: boolean, record: SocialMediaAccountsTableRecord) => {
    return hasUnlinkPermissions && !(record.type === 'Twitter' && !hasUnlinkTwitterPermission) ? (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <UnlinkAction platformBotIdToUnlink={record.platformBotId} />
        <PermissionViewer {...{ record, handlePermissionClick }} />
      </div>
    ) : (
      <span>&ndash;</span>
    );
  };
  return { actionsRender, hasUnlinkPermissions, hasUnlinkTwitterPermission };
};
