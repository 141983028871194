const commentModerationConstants: CommentModerationConstants = {
  STORE_COMMENT_MODERATION_STATS: 'STORE_COMMENT_MODERATION_STATS',
  HAVE_STATS_CHANGED: 'HAVE_STATS_CHANGED',
  STORE_ENGAGEMENT_STATS: 'STORE_ENGAGEMENT_STATS',
  IS_ENGAGEMENTS_VIEWER: 'IS_ENGAGEMENTS_VIEWER',
};
type CommentModerationConstants = {
  STORE_COMMENT_MODERATION_STATS: string;
  HAVE_STATS_CHANGED: string;
  STORE_ENGAGEMENT_STATS: string;
  IS_ENGAGEMENTS_VIEWER: string;
};
export default commentModerationConstants;
