import { TooltipPlacement } from 'antd/lib/tooltip';
import React from 'react';
import AmplifyTooltip from '../../pages/common/tooltip';

interface Props {
  helpText?: JSX.Element | string;
  label?: JSX.Element | string;
  containerClassName?: string;
  placement?: TooltipPlacement;
  iconSrc?: string;
}

const LabelWithHelpText = (props: Props) => {
  const { helpText, label, containerClassName, placement = 'top' } = props;
  return (
    <span style={{ display: 'flex' }} className={containerClassName}>
      {label}
      <AmplifyTooltip text={helpText} placement={placement}>
        <img style={{ marginLeft: '.6rem' }} src={props.iconSrc || '/images/icons/infoIcon-blue.svg'} alt='info icon' />
      </AmplifyTooltip>
    </span>
  );
};

export default LabelWithHelpText;
