import { CapitalLetterTransform, CharTransform, DigitTransform, SingleCharTransform, SmallLetterTransform } from './characterTransformInterfaces';

export const availableFontList = {
  'cursive-font': {
    fontLabel: '𝒞𝓊𝓇𝓈𝒾𝓋ℯ',
    value: 'cursive-font',
    label: 'Cursive',
    transforms: [
      new SingleCharTransform('A', '𝒜'),
      new SingleCharTransform('B', 'ℬ'),
      new CharTransform('C', 'D', '𝒞'),
      new CharTransform('E', 'F', 'ℰ'),
      new SingleCharTransform('G', '𝒢'),
      new SingleCharTransform('H', 'ℋ'),
      new SingleCharTransform('I', 'ℐ'),
      new CharTransform('J', 'K', '𝒥'),
      new SingleCharTransform('L', 'ℒ'),
      new SingleCharTransform('M', 'ℳ'),
      new CharTransform('N', 'Q', '𝒩'),
      new SingleCharTransform('R', 'ℛ'),
      new CharTransform('S', 'Z', '𝒮'),
      new CharTransform('a', 'd', '𝒶'),
      new SingleCharTransform('e', 'ℯ'),
      new SingleCharTransform('f', '𝒻'),
      new SingleCharTransform('g', 'ℊ'),
      new CharTransform('h', 'n', '𝒽'),
      new SingleCharTransform('o', 'ℴ'),
      new CharTransform('p', 'z', '𝓅'),
    ],
    unicodeCharactersList: `𝒜ℬ𝒞𝒟ℰℱ𝒢ℋℐ𝒥𝒦ℒℳ𝒩𝒪𝒫𝒬ℛ𝒮𝒯𝒰𝒱𝒲𝒳𝒴𝒵𝒶𝒷𝒸𝒹ℯ𝒻ℊ𝒽𝒾𝒿𝓀𝓁𝓂𝓃ℴ𝓅𝓆𝓇𝓈𝓉𝓊𝓋𝓌𝓍𝓎𝓏`,
  },
  'sans-serif-font': {
    fontLabel: '𝖲𝖺𝗇𝗌 𝖲𝖾𝗋𝗂𝖿',
    value: 'sans-serif-font',
    label: 'Sans Serif',
    transforms: [new CapitalLetterTransform('𝖠'), new SmallLetterTransform('𝖺'), new DigitTransform('𝟢')],
    unicodeCharactersList: `𝖠𝖡𝖢𝖣𝖤𝖥𝖦𝖧𝖨𝖩𝖪𝖫𝖬𝖭𝖮𝖯𝖰𝖱𝖲𝖳𝖴𝖵𝖶𝖷𝖸𝖹𝖺𝖻𝖼𝖽𝖾𝖿𝗀𝗁𝗂𝗃𝗄𝗅𝗆𝗇𝗈𝗉𝗊𝗋𝗌𝗍𝗎𝗏𝗐𝗑𝗒𝗓𝟢𝟣𝟤𝟥𝟦𝟧𝟨𝟩𝟪𝟫`,
  },
  'monospace-font': {
    fontLabel: '𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎',
    value: 'monospace-font',
    label: 'Monospace',
    transforms: [new CapitalLetterTransform('𝙰'), new SmallLetterTransform('𝚊'), new DigitTransform('𝟶')],
    unicodeCharactersList: `𝙰𝙱𝙲𝙳𝙴𝙵𝙶𝙷𝙸𝙹𝙺𝙻𝙼𝙽𝙾𝙿𝚀𝚁𝚂𝚃𝚄𝚅𝚆𝚇𝚈𝚉𝚊𝚋𝚌𝚍𝚎𝚏𝚐𝚑𝚒𝚓𝚔𝚕𝚖𝚗𝚘𝚙𝚚𝚛𝚜𝚝𝚞𝚟𝚠𝚡𝚢𝚣𝟶𝟷𝟸𝟹𝟺𝟻𝟼𝟽𝟾𝟿`,
  },
  'gothic-font': {
    fontLabel: '𝔊𝔬𝔱𝔥𝔦𝔠',
    value: 'gothic-font',
    label: 'Gothic',
    transforms: [
      new CharTransform('A', 'B', '𝔄'),
      new SingleCharTransform('C', 'ℭ'),
      new CharTransform('D', 'G', '𝔇'),
      new SingleCharTransform('H', 'ℌ'),
      new SingleCharTransform('I', 'ℑ'),
      new CharTransform('J', 'Q', '𝔍'),
      new SingleCharTransform('R', 'ℜ'),
      new CharTransform('S', 'Y', '𝔖'),
      new SingleCharTransform('Z', 'ℨ'),
      new SmallLetterTransform('𝔞'),
    ],
    unicodeCharactersList: `𝔄𝔅ℭ𝔇𝔈𝔉𝔊ℌℑ𝔍𝔎𝔏𝔐𝔑𝔒𝔓𝔔ℜ𝔖𝔗𝔘𝔙𝔚𝔛𝔜ℨ𝔞𝔟𝔠𝔡𝔢𝔣𝔤𝔥𝔦𝔧𝔨𝔩𝔪𝔫𝔬𝔭𝔮𝔯𝔰𝔱𝔲𝔳𝔴𝔵𝔶𝔷`,
  },
};

export function fontize(text: string, fontName: string) {
  if ((availableFontList as any)?.[fontName]?.transforms) {
    const codesBuffer = [];
    for (let i = 0; i < text.length; i++) {
      const code = text.charCodeAt(i);
      const transform = (availableFontList as any)[fontName].transforms.find((t: any) => t.matches(code));
      if (transform) transform.transform(code, codesBuffer);
      else codesBuffer.push(code);
    }
    return String.fromCharCode(...codesBuffer);
  }
  return text;
}
