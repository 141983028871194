import { createAction } from '@reduxjs/toolkit';
import { BotConfig, BotProfile } from '../../../common/entities/entities.types';
import { constants } from '../constant';
import { BotMacros, DynamicFieldUIMapped } from '../settings.types';

export const storeBotMessageSettings = createAction<DynamicFieldUIMapped | null>(constants.STORE_BOT_MESSAGE_SETTINGS);
export const storeBotMessageDefaultValue = createAction<BotMacros>(constants.STORE_BOT_MESSAGE_DEFAULT_VALUE);
export const storeBotProfile = createAction<BotProfile>(constants.STORE_BOT_PROFILE_DETAILS);
export const storeSMSText = createAction<string>(constants.STORE_SMS_TEXT);
export const storeBotConfig = createAction<BotConfig | undefined>(constants.STORE_BOT_CONFIG);
