import React, { ReactElement, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from '../../types';
import useAuthVerification from './use-auth-verification';

type PrivateRouteProps = {
  component: ComponentType;
  exact?: boolean;
  path?: string;
  [key: string]: unknown;
};

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps): ReactElement => {
  const isUserLoggedIn = useAuthVerification();
  const loggedOutRecently = useSelector((state: RootState) => state.pageUIState.RECENT_LOG_OUT);
  return (
    <Route
      {...rest}
      render={(props: any): ReactElement =>
        isUserLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: !loggedOutRecently ? { from: props.location } : undefined,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
