import { Popover, PopoverProps } from 'antd';
import React from 'react';

type AmplifyPopoverProps = PopoverProps;

const AmplifyPopover = (props: AmplifyPopoverProps) => {
  const { children, ...rest } = props;
  return (
    <Popover
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default AmplifyPopover;
