import { Subscription, Subscriptions } from '../types/subscriptions.types';

export const transformSubscriptionsAPItoUI = (subscriptions: Subscription[]) => {
  const mappedSubscriptions: Subscriptions = {};

  subscriptions.forEach((subscription: Subscription) => {
    mappedSubscriptions[subscription.id] = {
      ...subscription,
    };
  });

  return mappedSubscriptions;
};
