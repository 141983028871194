import React, { Component, CSSProperties, ReactNode } from 'react';
import cx from 'classnames';
import Button from '../../../pages/common/buttons/button';

type GenericModalProps = {
  save?: () => void;
  dismissed?: () => void;
  active: boolean;
  className?: string;
  noModalCardClass?: string;
  modalBackGroundProps?: any;
  modalSectionProps?: any;
  isErrorMessageModal?: boolean;
  style?: CSSProperties;
  modalStyles?: CSSProperties;
  modalBodyStyles?: CSSProperties;
  buttons?: { text: string; className?: string; action: () => void }[];
};
export class GenericAmplifyModal extends Component<GenericModalProps> {
  save = () => {
    const { save } = this.props;
    save && save();
    this.done();
  };

  done = () => {
    const { dismissed } = this.props;
    dismissed && dismissed();
  };

  render() {
    const { active, buttons, className = '', noModalCardClass, modalBackGroundProps, modalSectionProps, isErrorMessageModal, style, modalStyles, modalBodyStyles } = this.props;
    const activeClass = active ? ' is-active' : '';
    let modalButtons: any;
    // Buttons if passed on the props.
    if (buttons && buttons.length) {
      modalButtons = buttons.map(
        (b: { text: string; className?: string; action: () => void }, i: number): ReactNode => {
          return (
            <Button className={`button ${b.className ? b.className : 'is-primary'}`} key={i} onClick={b.action}>
              {b.text}
            </Button>
          );
        },
      );
    }

    const cardClassName = cx({
      [className]: true,
      'modal-card': !noModalCardClass,
    });
    const modalCardBodyClassName = cx({
      'modal-card-body': !noModalCardClass,
    });
    return (
      <div className={`modal ${activeClass} ${isErrorMessageModal ? 'error-modal' : ''}`}>
        <div className='modal-background' style={style} {...modalBackGroundProps}></div>
        <div className={cardClassName} style={modalStyles}>
          <section className={modalCardBodyClassName} style={modalBodyStyles} {...modalSectionProps}>
            {this.props.children}
          </section>
          {modalButtons ? <footer className='modal-card-foot'>{modalButtons}</footer> : null}
        </div>
      </div>
    );
  }
}
