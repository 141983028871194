import { Comment, TabNameWithEngagementType } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { colorForPostType } from '../../../pages/dashboard/components/comments-viewer/components/comment/utils';
import { tagInfoBasedOnAction } from '../../../pages/common/recommendation-tag/utils';
import { amplifyEngagedTagGroup, AmplifyGeneratedEngagement, defaultCommentTags } from '../../../pages/common/tag/tag.constants';
import { DisplayTagsKeysComments, SearchFilterTag, TagGroup, Tags as TagsType } from '../../../pages/common/tag/tag.types';
import { CommentUsers } from '../../../pages/dashboard/components/comments-viewer/components/user/user.types';
import { commentTypes, commentViewerTabs, TagGroupNames } from '../../../pages/dashboard/components/comments-viewer/comments-viewer.constants';
import { getSentimentRating, getTagFromIdAndGroup } from '../../../pages/dashboard/components/comments-viewer/utils';
import { FetchCommentsApiResponse } from '../../../types';
import variables from '../../scss/variables.module.scss';
import { Comments, PrivateReplyTags } from '../entities.types';
import { capitalizeFirstLetter, formatTagKey } from '../utils';
import { engagementTabNamesAndActions, engagementTypes } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.constants';

export function getCommentsFromResponse(apiResponse: FetchCommentsApiResponse, privateReplyTags: PrivateReplyTags) {
  const comments: Comments = {};
  apiResponse.forEach((comment: FetchCommentsApiResponse[0]) => {
    const sentimentRating = (typeof comment.sentiment.score !== 'undefined' && getSentimentRating(comment.sentiment.score)) || '';
    comments[comment.commentId] = {
      userComment: comment,
      text: comment.text,
      senderName: comment.sender,
      createdTime: comment.commentDate,
      commentId: comment.commentId,
      platformUserId: comment.platformUserId,
      parentId: comment?.parentId,
      actions: comment.actions,
      tags: {
        isPostMention: getPostMention(comment),
        isIndirectComment: getIndirectCommentTag(comment),
        postTypes: {
          ...(comment.postType && {
            [comment.postType]: {
              color: colorForPostType(comment.postType),
              label: capitalizeFirstLetter(comment.postType),
              id: comment.postType,
              groupType: 'postTypes' as keyof TagsType,
            },
          }),
        },
        keywords: getKeywordsMap(comment),
        intents: getIntentsMap(comment),
        sentiments: {
          ...(sentimentRating && {
            [sentimentRating]: getTagFromIdAndGroup(sentimentRating, TagGroupNames.SENTIMENTS),
          }),
        },
        sentiment: {},
        recommendations: {
          ...(comment.commentTag && {
            [comment.commentTag]: {
              color:
                privateReplyTags[formatTagKey(comment.commentTag)] && privateReplyTags[formatTagKey(comment.commentTag)].actionType
                  ? tagInfoBasedOnAction(privateReplyTags[formatTagKey(comment.commentTag)].actionType).color
                  : tagInfoBasedOnAction('').color,
              label: comment.commentTag,
              id: comment.commentTag,
              groupType: 'recommendations' as keyof TagsType,
            },
          }),
        },
        amplifyEngaged: getAmplifyEngagedTypeLabel(comment),
      },
    };
  });
  return comments;
}

function getAmplifyEngagedTypeLabel(comment: FetchCommentsApiResponse[0]): { [key: string]: SearchFilterTag } {
  const { actions } = comment;
  if (!Object.keys(actions).length) return {};
  const engagementTabs = Object.keys(engagementTabNamesAndActions);
  const tabNamesForAmplifyEngaged: TabNameWithEngagementType = {};
  engagementTabs.forEach((tabName: string) => {
    if (actions[engagementTabNamesAndActions[tabName as keyof typeof engagementTabNamesAndActions][engagementTypes.MANUAL] as keyof typeof actions]) {
      tabNamesForAmplifyEngaged[tabName] = engagementTypes.MANUAL;
    }
    if (actions[engagementTabNamesAndActions[tabName as keyof typeof engagementTabNamesAndActions][engagementTypes.AUTOMATED] as keyof typeof actions]) {
      tabNamesForAmplifyEngaged[tabName] = engagementTypes.AUTOMATED;
    }
  });
  if (!Object.keys(tabNamesForAmplifyEngaged).length) return {};
  return {
    [AmplifyGeneratedEngagement]: {
      ...amplifyEngagedTagGroup.automated,
      tabNamesForAmplifyEngaged,
    },
  };
}
export function getPostMention(comment: { isPostMention: boolean; isPlatformBotMention?: boolean }) {
  const mentionsMap: { [key: string]: SearchFilterTag } = {};
  if (comment.isPlatformBotMention) {
    if (comment.isPostMention) {
      mentionsMap['isPostMention'] = {
        color: variables.mentions,
        id: '1',
        label: 'Post Mention',
        groupType: 'isPostMention' as keyof TagsType,
      };
    } else {
      mentionsMap['Comment Mention'] = { color: variables.mentions, id: '0', label: 'Comment Mention', groupType: 'isPostMention' as keyof TagsType };
    }
  }
  return mentionsMap;
}

export function getIndirectCommentTag({ isIndirectComment }: { isIndirectComment?: boolean }): TagGroup {
  const commentTypeIndex = +!!isIndirectComment;
  const commentType = commentTypes[commentTypeIndex];
  return { [commentType.id]: commentType };
}

export function getIntentsMap(comment: { intents: string[] }) {
  const intentsMap: { [key: string]: SearchFilterTag } = {};
  comment.intents.forEach((intentInput: string | any) => {
    const intent = intentInput.name || intentInput;
    intentsMap[intent] = {
      color: variables.intent,
      id: intent,
      label: (intent && intent.split('__')[1]) || intent,
      groupType: 'intents' as keyof TagsType,
    };
  });
  const labelMap: { [key: string]: string } = {};
  Object.values(intentsMap).forEach((tag: SearchFilterTag) => {
    if (!labelMap[tag.label]) {
      labelMap[tag.label] = tag.id;
    } else {
      intentsMap[labelMap[tag.label]].label = labelMap[tag.label];
      intentsMap[tag.id].label = tag.id;
    }
  });
  return intentsMap;
}
export function getKeywordsMap(comment: { entities: string[] }) {
  const keywords: { [key: string]: SearchFilterTag } = {};
  comment.entities.forEach((entityInput: string | any) => {
    const entity = entityInput.name || entityInput;
    keywords[entity] = {
      color: variables.entity,
      id: entity,
      label: entity,
      groupType: 'keywords' as keyof TagsType,
    };
  });
  return keywords;
}

export const getCommentUsersFromComments = (comments: Comments) => {
  const commentUsers: CommentUsers = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (commentUsers[comment.userComment.platformUserId || comment.senderName]) {
      commentUsers[comment.userComment.platformUserId || comment.senderName].totalNumberOfComments++;
      commentUsers[comment.userComment.platformUserId || comment.senderName].commentIds = [
        ...commentUsers[comment.userComment.platformUserId || comment.senderName].commentIds,
        comment.commentId,
      ];

      const isUserBlocked = commentUsers[comment.userComment.platformUserId || comment.senderName].blocked;
      if (!isUserBlocked) {
        commentUsers[comment.userComment.platformUserId || comment.senderName].blocked =
          comment.actions.automatedBlock || comment.actions.block || comment.actions.externalBlock ? true : false;
      }
      const updatedTags = { ...commentUsers[comment.userComment.platformUserId || comment.senderName].tags };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { amplifyEngaged, ...currentCommentDisplayTags } = comment.tags;
      const currentCommentTags: any = { ...currentCommentDisplayTags };
      Object.keys(currentCommentTags).forEach((tagGroupKey: string) => {
        Object.keys(currentCommentTags[tagGroupKey as DisplayTagsKeysComments]).forEach((tagId: string) => {
          if (!updatedTags[tagGroupKey as DisplayTagsKeysComments][tagId]) {
            updatedTags[tagGroupKey as DisplayTagsKeysComments][tagId] = { ...currentCommentTags[tagGroupKey as DisplayTagsKeysComments][tagId] };
          }
        });
      });
      commentUsers[comment.userComment.platformUserId || comment.senderName].tags = {
        ...updatedTags,
        ...generateBlockedUserAmplifyEngagedTag(comment),
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { amplifyEngaged: removed, ...tags } = defaultCommentTags();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { amplifyEngaged, ...commentDisplayTags } = comment.tags;
      Object.keys(commentDisplayTags).forEach((tagGroupKey: string) => {
        Object.keys(commentDisplayTags[tagGroupKey as DisplayTagsKeysComments] || {}).forEach((tagId: string) => {
          tags[tagGroupKey as DisplayTagsKeysComments][tagId] = { ...commentDisplayTags[tagGroupKey as DisplayTagsKeysComments][tagId] };
        });
      });
      commentUsers[comment.userComment.platformUserId || comment.senderName] = {
        name: comment.senderName,
        totalNumberOfComments: 1,
        tags: {
          ...tags,
          ...generateBlockedUserAmplifyEngagedTag(comment),
        },
        blocked: comment.actions.automatedBlock || comment.actions.block ? true : false,
        userId: comment.userComment.platformUserId || comment.senderName,
        commentIds: [comment.commentId],
      };
    }
  });
  return commentUsers;
};

const generateBlockedUserAmplifyEngagedTag = (comment: Comment): { amplifyEngaged: TagGroup } => {
  return comment.actions.automatedBlock || comment.actions.block
    ? {
        amplifyEngaged: {
          [AmplifyGeneratedEngagement]: {
            id: AmplifyGeneratedEngagement,
            color: '',
            label: AmplifyGeneratedEngagement,
            groupType: 'amplifyEngaged',
            tabNamesForAmplifyEngaged: { [commentViewerTabs.blockedUsers]: comment.actions.automatedBlock ? engagementTypes.AUTOMATED : engagementTypes.MANUAL },
          },
        },
      }
    : { amplifyEngaged: {} };
};
