export const trillerLoaderColor = '#705DFB';
export const logoImage = 'Julius-By-Triller-logo.png';
export const logoImageHeader = 'Julius-By-Triller-logo.png';
export const feviconImage = '/julius.ico';
export const globalContainerClassName = 'juliusContainer';
export const chartColors = ['#3625B5', '#705DFB', '#A599FF'];
export const signUpLink = 'https://app.juliusworks.com'; // Update the correct signup link
export const signUpPromptText = "Don't have an account with us yet? ";
export const signUpText = 'Sign Up here';
export const logoImageSecondary = 'Julius-By-Triller-logo.png';
export const fontFamily = 'Open Sans, sans-serif';
export const brandName = 'JULIUS';
export const primaryAccentColor = '#55BADA';
export const contactUsEmail = 'sales@amplify.ai'; // Update the correct email
export const supportEmail = 'support@amplify.ai'; // Update the correct email
export const headerFontsAndIconsColor = '#705DFB';
export const infoIconSrc = '/images/icons/infoIcon-julius.svg';

export const commentBreakDownChartConfig: any = {
  width: 12,
  colors: {
    actionableColor: '#0A088C',
    nonActionableColor: '#332690',
    taggedColor: '#0050E8',
    hiddenComments: '#1297E0',
    brandReaction: '#5B4FB2',
    publicReplies: '#7467D1',
    blockedUsers: '#8A7AFF',
    deletedComments: '#A49BE6',
    engagementTotal: ' #B6ACFF',
    reviewedComments: '#432AFC',
    engaged: '#5C47F4',
  },
};
export const intentTagStrokeColor = '#705DFB';
export const wordMapColors = ['#170055', '#302572', '#463EA1', '#4942E4', '#504BCB', '#705DFB', '#7868E6', '#427DF1', '#2A3FC1', '#5264D9', '#8795F0'];
export const sentimentColors = {
  extremelyNegative: '#f25d5d',
  veryNegative: '#ff7c7c',
  moderatelyNegative: '#ff9d9d',
  neutral: '#909090',
  moderatelyPositive: '#6ee5d3',
  veryPositive: '#34cbb4',
  extremelyPositive: '#2aaa97',
};
export const leftNavigationBgClassName = 'leftNavigationBarBgJulius';

export const engagementChartColors = {
  banned: '#6C5DD9',
  liked: '#B0A6F8',
  privateReplied: '#52479F',
  replied: '#8E83D9',
  reviewed: '#40368A',
  hidden: '#C4BCFF',
  deleted: '#6F67AC',
  assigned: '#5746D1',
};
