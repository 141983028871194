const localAPIServerUrl = 'https://api-staging.amplify.ai/api/v1/';
export const apiServerUrl = process.env.NODE_ENV === 'development' ? localAPIServerUrl : process.env.REACT_APP_API_BASE_URL;
// export const apiServerUrl = 'https://api.amplify.ai/api/v1/';

export const customAnalyticsAPI = 'analytics/other';

export const resourceAuthAPIUrl = `${apiServerUrl}utils/verifyToken`;

export const awsLogUrl = 'https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/APILogs/log-events$3FfilterPattern$3D';
export const popupUrlOrigin = `https://my.cliqz.ai`;
export const apiStagingUrl = 'https://api-staging.amplify.ai/api/v1/';
