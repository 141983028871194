export const maxSizeLimitForCompression = {
  broadcast: 0.52,
  fallbackValueForBroadcast: 0.45,
  profilePhoto: 0.32,
  fallbackValueForProfilePhoto: 0.25,
  inboxNonSMS: 2,
  fallbackValueForInboxNonSMS: 1.8,
  inboxSMS: 0.6,
  fallbackValueForInboxSMS: 0.45,
};

export const maxSizeLimitInMb = {
  image: 2,
  video: 50,
  audio: 10,
};
