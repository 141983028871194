const adminToolConstants: AdminToolConstant = {
  STORE_ADMIN_TOOL_STATUS: 'STORE_ADMIN_TOOL_STATUS',
  UPDATE_IDS_FOR_BACK_TO_GENERATE_LINK: 'UPDATE_IDS_FOR_BACK_TO_GENERATE_LINK',
};

type AdminToolConstant = {
  STORE_ADMIN_TOOL_STATUS: string;
  UPDATE_IDS_FOR_BACK_TO_GENERATE_LINK: string;
};

export default adminToolConstants;
