import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { storeTopicTrends, resetTopicTrends } from '../actions';
import { TopicTrendsType } from '../types';

const initialState: TopicTrendsType = {
  keywords: [],
  phrases: [],
  questions: [],
};

const TopicTrendsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeTopicTrends, (state: typeof initialState, action: ReturnType<typeof storeTopicTrends>) => {
      state[action.payload.topicType] = action.payload.wordCloudEntities;
    })
    .addCase(resetTopicTrends, (state: typeof initialState, action: ReturnType<typeof resetTopicTrends>) => {
      state[action.payload] = [];
    });
});

export default TopicTrendsReducer;
