import { BotProfile } from '../../../common/entities/entities.types';
import { getTwilioSmartEncodedString } from '../../../utils/segment-counter/twilio-smart-encoder';
import { botProfileMacros } from '../constant';
import { BotMacros, DynamicField, DynamicFieldUI, DynamicFieldUIMapped, SystemSettingsBotAPIResponse, SystemSettingsBotUI } from '../settings.types';
import { extractFirstLastName } from '../utils';

export const systemSettingsBotAPItoUI = (data: SystemSettingsBotAPIResponse, botMacros?: BotMacros) => {
  const transformedSettings: {
    [key: string]: DynamicFieldUI;
  } = {};
  data.settings.cliqz.forEach((setting: DynamicField) => {
    transformedSettings[setting.botMacroId] = {
      ...setting,
      defaultValue: botMacros ? (botMacros[setting.botMacroId] ? botMacros[setting.botMacroId] : setting.defaultValue) : setting.defaultValue,
      blackListErrors: setting.blackListErrors,
    };
  });
  const transformedData: SystemSettingsBotUI = {
    ...data,
    settings: {
      ...data.settings,
      cliqz: { ...transformedSettings },
    },
  };
  return transformedData;
};

export const BotMacrosAPItoUI = (botMacros: BotMacros) => {
  const profile: BotProfile = {
    name: botMacros[botProfileMacros.name],
    profilePic: botMacros[botProfileMacros.profilePic],
    primaryPhoneNumber: botMacros[botProfileMacros.primaryPhoneNumber],
    popupId: botMacros[botProfileMacros.popupId],
  };
  if (botMacros[botProfileMacros.firstName]) {
    profile.completeName = {
      firstName: botMacros[botProfileMacros.firstName],
      lastName: botMacros[botProfileMacros.lastName],
    };
  } else {
    const { firstName, lastName } = extractFirstLastName(profile.name);
    profile.completeName = { firstName, lastName };
  }
  return Object.entries(profile).length ? profile : null;
};

export const transformedBotMacroUItoAPI = (mappedSettings: DynamicFieldUIMapped) => {
  const settings: BotMacros = {};
  Object.values(mappedSettings).forEach((setting: DynamicFieldUI) => {
    let defaultValue = setting.defaultValue as string;
    if (!setting.readOnly) {
      defaultValue = getTwilioSmartEncodedString(defaultValue).encodedString;
    }
    settings[setting.botMacroId] = defaultValue;
  });
  return settings;
};

export const transformeBotProfileUItoBotMacroAPI = (botProfile: BotProfile) => {
  const settings: BotMacros = {};
  botProfile?.completeName?.firstName && (botProfile = { ...botProfile, ...botProfile.completeName });
  Object.entries(botProfile).forEach((profile: [string, any]) => {
    const macroKey = botProfileMacros[profile[0]];
    if (macroKey) {
      settings[macroKey] = profile[1];
    }
  });
  return settings;
};
