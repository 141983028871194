import React from 'react';
import { Col, Input, Row } from 'antd'; // Adjust based on your actual Input component's library
import MediaWithCloseIcon from '../../media-close-icon/media-close-icon';
import { mediaFormatTypes } from '../../media-close-icon/config';
import Styles from '../../../../common/components/confirmationModal/confirmation.modal.module.scss'; // Update with the correct path
import AmplifyTooltip from '../../tooltip';
import { ReactComponent as InfoIcon } from '../../../../assets/svg/information.svg'; // Update with the correct path

export const renderMsgPreviewTextArea = (broadcastMsgPreview: any = {}) => {
  return (
    <div className='is-flex' style={{ width: '100%', justifyContent: 'start' }}>
      <Input.TextArea
        value={broadcastMsgPreview?.text || broadcastMsgPreview?.title || ''}
        readOnly
        autoSize={{ minRows: 5, maxRows: 6 }}
        className={`${broadcastMsgPreview?.url ? 'mr-3' : ''} ${Styles.msgPreviewContainer}`}
      />{' '}
      {broadcastMsgPreview?.url && (
        <MediaWithCloseIcon
          containerClassName={Styles.mediaImgIcon}
          readOnly={true}
          fileURL={Array.isArray(broadcastMsgPreview?.url) ? broadcastMsgPreview?.url?.[0] : broadcastMsgPreview?.url}
          onClose={() => {}}
          mediaFormat={mediaFormatTypes.IMAGE}
        />
      )}
    </div>
  );
};

export const renderMessagePreview = (broadcastMsgPreview: any = {}, targetUser?: any) => {
  return (
    <Row className={`${Styles.broadcastDetailRow}`}>
      <Col className={`${Styles['left-audit-detail']} pr-0`} style={{ width: '100%' }}>
        <div className={`${Styles['detail-label']} mb-1`}>
          Message Preview
          <AmplifyTooltip
            text={
              <div>
                {' '}
                {`The message preview is for the following test user : ${targetUser?.firstName + ' ' + targetUser?.lastName || ''} ${
                  targetUser?.platformUserId ? `(${targetUser?.platformUserId})` : ''
                }`}{' '}
              </div>
            }
          >
            <InfoIcon fill='#999999' stroke='#999999' strokeWidth='0.1px' height='12px' style={{ cursor: 'pointer', margin: '0px 0px -2px 3px' }} />
          </AmplifyTooltip>
        </div>
        <div className={`${Styles['detail-value']}`}> {renderMsgPreviewTextArea(broadcastMsgPreview)}</div>
      </Col>
    </Row>
  );
};
