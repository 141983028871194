/* eslint-disable @typescript-eslint/naming-convention */
export const FETCH_MESSAGE_INBOX = 'inbox_fetch_messages';
export const CREATE_MESSAGE_INBOX_LABEL = 'inbox_message_label_created';
export const SHOW_SESSIONS_CLICKED = 'inbox_show_sessions_clicked';
export const SHOW_MANAGE_LABEL_CLICKED = 'inbox_manage_label_clicked';
export const VIEW_CONVERSATION = 'inbox_view_conversation';
export const INBOX_PLATFORM_USER_UPDATED = 'inbox_platform_user_updated';
export const INBOX_PLATFORM_USER_SUBSCRIBED = 'inbox_platform_user_subscribed';
export const INBOX_PLATFORM_USER_UNSUBSCRIBED = 'inbox_platform_user_unsubscribed';
export const INBOX_USER_DETAILS_CLICKED = 'inbox_user_details_clicked';
export const INBOX_SHOW_VARIABLES_CLICKED = 'inbox_show_variables_clicked';
