import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { toggleFbWarningModalDisplay, storeFbPages, showFbPageList, fbPageDeploymentSuccess, storeInstagramPages, setFbPageResponse, storeInstaAccessToken } from '../actions';
import { FbDeployInitialStateType } from '../types';

const fbDeployInitialState: FbDeployInitialStateType = {
  showFBLoginModal: false,
  fbPageList: undefined,
  instaPageList: undefined,
  showFbPageList: false,
  fbPageDeployed: false,
  fbPageResponse: undefined,
  instaAccessToken: undefined,
  instaPageListFromFb: undefined,
};

const fbDeployReducer = createReducer(fbDeployInitialState, (builder: ActionReducerMapBuilder<typeof fbDeployInitialState>) => {
  builder
    .addCase(toggleFbWarningModalDisplay, (state: typeof fbDeployInitialState, action: ReturnType<typeof toggleFbWarningModalDisplay>) => {
      state.showFBLoginModal = action.payload.showFBLoginModal;
    })
    .addCase(storeFbPages, (state: typeof fbDeployInitialState, action: ReturnType<typeof storeFbPages>) => {
      state.fbPageList = action.payload.fbPageList;
    })
    .addCase(showFbPageList, (state: typeof fbDeployInitialState, action: ReturnType<typeof showFbPageList>) => {
      state.showFbPageList = action.payload.showFbPageList;
    })
    .addCase(fbPageDeploymentSuccess, (state: typeof fbDeployInitialState, action: ReturnType<typeof fbPageDeploymentSuccess>) => {
      state.fbPageDeployed = action.payload.fbPageDeployed;
    })
    .addCase(storeInstagramPages, (state: typeof fbDeployInitialState, action: ReturnType<typeof storeInstagramPages>) => {
      state.instaPageList = action.payload;
    })
    .addCase(storeInstaAccessToken, (state: typeof fbDeployInitialState, action: ReturnType<typeof storeInstaAccessToken>) => {
      state.instaAccessToken = action.payload;
    })
    .addCase(setFbPageResponse, (state: typeof fbDeployInitialState, action: ReturnType<typeof setFbPageResponse>) => {
      state.fbPageResponse = action.payload.fbPageResponse;
    });
});

export default fbDeployReducer;
