import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetEntities, storeUserAccount } from '../../../../common/entities/actions';
import { AccountLiteUIConfig, Accounts } from '../../../../common/entities/entities.types';
import { setFilter, setInitialDataFetchAttempted, setPageUIState, setRouteParams } from '../../../../common/page-ui-state/actions';
import { getPlatformActionConfig } from '../../../../common/platform-action-config/actions/async-actions';
import { userLevelPermissions } from '../../../../common/role-level-permission/hooks';
import { useCurrentPlatformBotObj, useModerationMode, useResetFilters } from '../../../../hooks';
import { RootState } from '../../../../types';
import { AudienceTabs } from '../../../audience/constants';
import { pageFilterKeys, pageKeysForFilter } from '../../../dashboard/constants';

export const useAccount = () => {
  const selectedAccount = useSelector((state: RootState) => state.entities?.account);
  const accounts: Accounts = useSelector((state: RootState) => state.entities?.accounts) as Accounts;
  const permissionsRes = useSelector((state: RootState) => state.userPermissions.RoleLevelPermissions);
  const moderationMode = useModerationMode();
  const bot = useCurrentPlatformBotObj();
  const dispatch = useDispatch();
  const { resetFilters } = useResetFilters();
  const { pathname } = useLocation();

  const history = useHistory();
  const handleAccountChange = (accountId: string) => {
    const account = accounts[accountId];
    if (account) {
      resetFilters();
      dispatch(resetEntities());
      dispatch(setPageUIState({ key: 'selectedPlatformBot', value: undefined }));
      dispatch(setInitialDataFetchAttempted(false));
      dispatch(storeUserAccount(account));
      dispatch(setRouteParams({ property: 'accountId', value: account.id }));
      /*removed getUsers call and now directly calling platformActionConfig*/
      // dispatch(getUsers(onGetUsersSuccess));
      accountId &&
        dispatch(
          getPlatformActionConfig({
            accountId,
            accountConfig: account?.config?.amplifyLiteUIConfig as AccountLiteUIConfig,
            moderationMode,
            onSuccess: () => {},
          }),
        );
      userLevelPermissions(dispatch, accountId, bot?.botId || '', permissionsRes);
      dispatch(setFilter(pageKeysForFilter.AUDIENCE, pageFilterKeys.FILTERS, { tabName: AudienceTabs.ANALYSIS }));
      if (pathname.includes('inbox') || pathname.includes('sentimentAnalysis')) {
        history.replace({ search: '' }); //for now  we are just clearing search for inbox page
      }
    }
  };

  return { selectedAccount, handleAccountChange, accounts };
};
