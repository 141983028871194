import { BotConfig, labelsTypeUI, Modules, moduleTypeApi, SegmentTags, SegmentTagUI, SegmentUser, VariableType } from './../entities.types';
import { createAction } from '@reduxjs/toolkit';
import { KeywordGroups } from '../../../pages/keyword-groups/keyword-groups.types';
import { CommentUsers } from '../../../pages/dashboard/components/comments-viewer/components/user/user.types';
import constants from '../entities.constants';
import {
  Accounts,
  BotNLP,
  Bots,
  Comments,
  PlatformBots,
  PlatformUsersUI,
  PrivateReplyTags,
  TaggingGC,
  TaggingGCs,
  UserAccount,
  VariablesTypeUI,
  Posts,
  BroadcastsUI,
  UsersUpdateOnBot,
  DeleteTargeting,
  TestUsersUpdateOnBot,
  UpdateTargetingOnBot,
} from '../entities.types';
import { Template, Templates } from '../../../pages/templates/templates.types';
import { CommentActions, Comment } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { BroadcastTypeUI, segregatedEngagementSettingsTypeUI } from '../../../pages/broadcasts/types';
import { Segments } from '../../../pages/segments/segment.types';
import { CommentTags } from '../../../pages/common/tag/tag.types';
import { Ingest, UserIngest } from '../types/userIngest.types';
import { Subscriptions } from '../types/subscriptions.types';

export const storeUserAccount = createAction<UserAccount | undefined>(constants.STORE_USER_ACCOUNT);
export const storeAccounts = createAction<Accounts>(constants.STORE_ACCOUNTS);
export const storePlatformBots = createAction<PlatformBots>(constants.STORE_PLATFORM_BOTS);
export const storeBots = createAction<Bots>(constants.STORE_BOTS);
export const removePlatformBot = createAction<string>(constants.REMOVE_PLATFORM_BOT);

export const storeVariableForTheplatform = createAction<VariablesTypeUI>(constants.SET_VARIABLE_FOR_PLATFORM_BOT);
export const storeLabelForTheplatform = createAction<labelsTypeUI>(constants.SET_LABEL_FOR_PLATFORM_BOT);
export const addLabelForTheplatform = createAction<labelsTypeUI>(constants.ADD_NEW_LABEL_FOR_THE_PLATFORM);
export const deleteLabel = createAction<string>(constants.DELETE_CUSTOM_LABEL);
export const storeCommentsAndCommentUsers = createAction<{ comments: Comments; commentUsers: CommentUsers }>(constants.STORE_COMMENTS_AND_USERS);
export const storePosts = createAction<Posts>(constants.STORE_POSTS);
export const appendPosts = createAction<Posts>(constants.APPEND_POSTS);

export const actOnComment = createAction(constants.TAKE_ACTION_ON_COMMENT, (id: string, updatedComment: Comment) => ({
  payload: { id, updatedComment },
}));
export const undoActOnComment = createAction(constants.UNDO_ACTION_ON_COMMENT, (commentId: string, updatedComment: Comment) => ({
  payload: { commentId, updatedComment },
}));
export const unBlockCommentUser = createAction(constants.UNBLOCK_COMMENT_USER, (userId: string, updatedComment: Comment) => ({ payload: { userId, updatedComment } }));
export const deleteCommentReply = createAction(constants.DELETE_COMMENT_REPLY, (commentId: string, updatedComment: Comment) => ({
  payload: { commentId, updatedComment },
}));
export const blockCommentUser = createAction(constants.BLOCK_COMMENT_USER, (id: string, updatedComment: Comment) => ({ payload: { id, updatedComment } }));
export const storeKeywordGroups = createAction<KeywordGroups>(constants.STORE_KEYWORD_GROUPS);
export const clearKeywordGroups = createAction(constants.CLEAR_KEYWORD_GROUPS);
export const updateBotWithNLP = createAction<BotNLP>(constants.UPDATE_BOT_WITH_NLP);

export const storeTaggingGCs = createAction<TaggingGCs>(constants.STORE_TAGGING_GCS);
export const storeTaggingGC = createAction<TaggingGC>(constants.STORE_TAGGING_GC);
export const updateTaggingGCInUi = createAction<TaggingGC>(constants.UPDATE_TAGGING_GC);

export const storePrivateReplyTags = createAction<PrivateReplyTags>(constants.STORE_PRIVATE_REPLY_TAGS);
export const resetEntities = createAction(constants.RESET_DATA_ACCOUNT_CHANGE);

export const storeArchivedTemplates = createAction<Templates>(constants.STORE_ARCHIVED_TEMPLATES);
export const storeTemplates = createAction<Templates>(constants.STORE_TEMPLATES);
export const storeTemplate = createAction<Template>(constants.STORE_TEMPLATE);
export const removeTemplate = createAction<string>(constants.REMOVE_TEMPLATE);
export const storeSegments = createAction<Segments>(constants.STORE_SEGMETS);

export const removeSegment = createAction<string>(constants.REMOVE_SEGMENT);
export const appendVariableValue = createAction<any>(constants.APPEND_VARIABLE_VALUE);

export const storePlatformUsers = createAction<PlatformUsersUI>(constants.STORE_PLATFORM_USERS);

export const bulkActOnComment = createAction(constants.TAKE_BULK_ACTION_ON_COMMENT, (comments: Comments) => ({
  payload: { comments },
}));

export const blockBulkCommentUser = createAction(constants.BLOCK_BULK_COMMENT_USER, (action: keyof CommentActions, comments: Comments) => ({
  payload: { action, comments },
}));

export const resetStateOnPageChange = createAction(constants.RESET_STATE_ON_PAGE_CHANGE);

export const setErrorUserComment = createAction(constants.SET_ERROR_USER_BULK_COMMENT, (comments: Comments, response: any) => ({
  payload: { comments, response },
}));

export const storeBroadcastEngagementSettings = createAction<segregatedEngagementSettingsTypeUI>(constants.STORE_BROADCASTS_ENGAGEMENT_SETTINGS);
export const storeBroadcasts = createAction<BroadcastsUI>(constants.STORE_BROADCASTS);
export const appendBroadcasts = createAction<BroadcastsUI>(constants.APPEND_BROADCASTS);
export const storeBroadcast = createAction<BroadcastTypeUI>(constants.STORE_BROADCAST);
export const deleteBroadcastFromSore = createAction<string>(constants.DELETE_BROADCAST);
export const updateBroadcastsAnalytics = createAction<BroadcastsUI>(constants.UPDATE_BROADCAST_ANALYTICS);
export const fetchedSearchedPlatformUsers = createAction<UsersUpdateOnBot>(constants.SEARCHED_PLATFORM_USERS);
export const fetchedTargetedUsers = createAction<UsersUpdateOnBot>(constants.TARGETED_USERS);
export const addTargetedUsers = createAction<UpdateTargetingOnBot>(constants.ADD_TARGETED_USER);
export const deleteTargetedUser = createAction<DeleteTargeting>(constants.DELETE_TARGETED_USERS);
export const addTestUsersToBot = createAction<TestUsersUpdateOnBot>(constants.ADD_TEST_USERS_TO_BOT);
export const updatedBotConfig = createAction<{ botId: string; config: BotConfig }>(constants.UPDATED_BOT_CONFIG);
export const setLatestBroadcastTime = createAction<string>(constants.LATEST_BROADCAST_TIME);
export const storeModules = createAction<{ versionId: string; modules: Modules }>(constants.STORE_MODULES);
export const updateModule = createAction<{ versionId: string; module: moduleTypeApi }>(constants.UPDATE_MODULE);
export const storeNotifLabels = createAction<{ versionId: string; notificationLabels: string[] }>(constants.STORE_NOTIF_LABELS);

export const storeUserIngests = createAction<UserIngest>(constants.STORE_USER_INGESTS);
export const storeUserIngest = createAction<Ingest>(constants.STORE_INGEST);
export const clearSearchFilterTagsAndSelectedRange = createAction(constants.CLEAR_SEARCHFILTER_TAGS);
export const removeSearchFilterTagsForDate = createAction<string>(constants.REMOVE_SEARCHFILTER_TAGS_FORDATE);
export const storeSearchFilterTagsWithDate = createAction<{ commentTags: CommentTags; dateRange: string }>(constants.SET_SEARCHFILTER_TAGS_FORDATE);
export const storeSubscriptions = createAction<Subscriptions | null>(constants.STORE_SUBSCRIPTIONS);
export const storeVariable = createAction<VariableType>(constants.STORE_VARIABLE);

export const storeSessionStartTypeForInbox = createAction<string[]>(constants.STORE_SESSION_START_TYPES);

export const storeSegmentTags = createAction<SegmentTags>(constants.STORE_SEGMENT_TAGS);
export const storeSegmentTag = createAction<SegmentTagUI>(constants.STORE_SEGMENT_TAG);
export const storeSegmentUser = createAction<SegmentUser>(constants.STORE_SEGMENT_USER);
