import React, { useRef } from 'react';
import classNames from 'classnames';
import styles from './image-with-download.module.scss';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download.svg';
import { NotificationTypes, showMessage } from '../../pages/common/toast-notification';

//eslint-disable-next-line max-lines-per-function
const ImageWithDownload = (props: any) => {
  const { src = '', fallback, className = '', alt = '', imageStyle = {}, wrapperClassName = '', restrictDownload, downloadText = 'Download' } = props;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const downloadBlob = (data: Blob) => {
    const urlCreator = window.URL || window.webkitURL;
    const imageData = urlCreator.createObjectURL(data);
    const tag = document.createElement('a');
    tag.href = imageData;
    tag.download = src.indexOf('http') > -1 ? src.substring(src.lastIndexOf('/') + 1) : 'Download Image';
    tag.style.display = 'none';
    document.body.appendChild(tag);
    tag.click();
    setTimeout(() => {
      document.body.removeChild(tag);
      urlCreator.revokeObjectURL(imageData);
    }, 2000);
  };
  const onDownload = (e: any) => {
    if (!!src) {
      e.stopPropagation();
      fetch(src.indexOf('http') > -1 ? src + `?cacheblock=true` : src)
        .then((response: any) => response.blob())
        .then((data: any) => {
          downloadBlob(data);
        })
        .catch(async () => {
          //artifical image creation and download.
          const img = imgRef.current;
          if (img) {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob: any) => {
              downloadBlob(blob);
            });
          }
        })
        .catch((error: any) => {
          //eslint-disable-next-line no-console
          console.error('[Image Download Error]', error);
          showMessage('Image Download Failed', '', NotificationTypes.ERROR);
        });
    }
  };
  return (
    <div className={classNames(styles.imageWrapper, wrapperClassName)}>
      <img src={src || fallback} className={className} alt={alt} style={imageStyle} ref={imgRef} />
      {!restrictDownload && (
        <div className={styles.imageMask}>
          <div className={styles.imageDownloadMaskInfo} onClick={onDownload}>
            <span role='img' aria-label='download' className='anticon'>
              <DownloadIcon />
            </span>
            {downloadText}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageWithDownload;
