import { RecommendedAction } from '../rec-tag.constants';
import styles from '../recommendation.module.scss';
import variables from './../../../../common/scss/variables.module.scss';

export const tagInfoBasedOnAction = (action: string) => {
  let color = variables.recommendationDefault;
  let className = styles.isDefaultRecommendation;
  let icon = '';
  const defaultReturn = { color, className, icon };
  if (!action) return defaultReturn;
  if (action.includes('like')) {
    color = variables.recommendationLike;
    className = styles.isLikeRecommendation;
    icon = RecommendedAction.LIKE;
  }
  if (action.includes('hide')) {
    color = variables.recommendationHide;
    className = styles.isHideRecommendation;
    icon = RecommendedAction.HIDE;
  }
  if (action.includes('block')) {
    color = variables.recommendationBlock;
    className = styles.isBlockRecommendation;
    icon = RecommendedAction.BLOCK;
  }
  if (action.includes('delete')) {
    color = variables.recommendationBlock;
    className = styles.isBlockRecommendation;
    icon = RecommendedAction.DELETE;
  }
  if (action.includes('public')) {
    color = variables.recommendationPublicReply;
    className = styles.isPublicReplyRecommendation;
    icon = RecommendedAction.PUBLIC_REPLY;
  }
  if (action.includes('review')) {
    color = variables.recommendationReview;
    className = styles.isReviewRecommendation;
    icon = RecommendedAction.REVIEW;
  }
  if (action.includes('tag')) {
    color = variables.recommendationTag;
    className = styles.isTag;
  }
  return { color, className, icon };
};
