import { createAction } from '@reduxjs/toolkit';
import { GenericObject } from '../../../types';
import PageUIStateConstants from '../constants';
import { PageUIStateActionPayload } from '../types';

export const setPageUIState = createAction<PageUIStateActionPayload>(PageUIStateConstants.SET_PAGE_UI_STATE);

export const setSelectedPlatformBot = createAction<string | undefined>(PageUIStateConstants.SET_SELECTED_PLATFORM_BOT);
export const setSelectedTimeFilter = createAction<string>(PageUIStateConstants.SET_SELECTED_TIME_FILTER);
export const setEngagementsTimeFilter = createAction<string>(PageUIStateConstants.SET_ENGAGEMENTS_TIME_FILTER);
export const setConnectedToFb = createAction<boolean>(PageUIStateConstants.SET_CONNECTED_TO_FB);
export const setSideBarShow = createAction<boolean>(PageUIStateConstants.SET_SIDEBAR);

export const setFilter = createAction(PageUIStateConstants.SET_FILTER, (pageName: string, filterKey: string, filterValue: any, storeInLocalStorage: boolean = true) => {
  return {
    payload: { pageName, filterKey, filterValue, storeInLocalStorage },
  };
});
export const setInitialDataFetchAttempted = createAction<boolean>(PageUIStateConstants.SET_INITIAL_DATA_FETCH_ATTEMPTED);
export const initializeFilter = createAction<string>(PageUIStateConstants.SET_INITIAL_FILTER);
export const initializePageFilter = createAction(PageUIStateConstants.SET_INITIAL_PAGE_FILTER, (pageName: string, filterPresets?: GenericObject) => {
  return { payload: { pageName, filterPresets } };
});
export const clearCommentsFilter = createAction<string>(PageUIStateConstants.CLEAR_COMMENTS_FILTER);
export const storeIsOverrideNlpEnabled = createAction<boolean>(PageUIStateConstants.STORE_OVERRIDE_NLP);
export const isAccountsFetched = createAction<boolean>(PageUIStateConstants.IS_ACCOUNTS_FETCHED);
export const setRouteParams = createAction<{ property: string; value: any }>(PageUIStateConstants.ROUTE_PARAMS);
export const setApplicableTabsSubTabs = createAction<string[]>(PageUIStateConstants.APPLICABLE_TABS_SUB_TABS);
export const setFilterCommentsFromAPIForPosts = createAction<boolean>(PageUIStateConstants.FILTER_COMMENTS_FROM_API);
export const setSelectedTagRange = createAction<string>(PageUIStateConstants.SET_SELECTEDTAG_RANGE);
export const setMobileSideBarVisibility = createAction<boolean>(PageUIStateConstants.SET_MOBILESIDEBAR_VISIBILITY);
