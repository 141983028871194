interface TransformerInterface {
  startCode: any;
  endCode: any;
  replacementCodes: any[];
}

export class CharTransform implements TransformerInterface {
  startCode: any;
  endCode: any;
  replacementCodes: any[];

  constructor(startLetter: string, endLetter: string, startReplacement: string) {
    this.startCode = startLetter.charCodeAt(0);
    this.endCode = endLetter.charCodeAt(0);
    this.replacementCodes = startReplacement.split('').map((c: string) => c.charCodeAt(0));
  }

  matches(charCode: any) {
    return charCode >= this.startCode && charCode <= this.endCode;
  }

  transform(charCode: any, buffer: any) {
    buffer.push(...this.replacementCodes);
    buffer[buffer.length - 1] += charCode - this.startCode;
  }
}

export class SmallLetterTransform extends CharTransform {
  constructor(startReplacement: any) {
    super('a', 'z', startReplacement);
  }
}

export class CapitalLetterTransform extends CharTransform {
  constructor(startReplacement: any) {
    super('A', 'Z', startReplacement);
  }
}

export class DigitTransform extends CharTransform {
  constructor(startReplacement: any) {
    super('0', '9', startReplacement);
  }
}

export class SingleCharTransform extends CharTransform {
  constructor(origin: any, transformed: any) {
    super(origin, origin, transformed);
  }
}
