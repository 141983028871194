/* eslint-disable @typescript-eslint/naming-convention */
import { Account } from '../../../common/entities/entities.types';
import { UserRole } from '../../../types/state';
import { userRolesLite } from '../components/manage-users/constants';
import { UserApiType, UsersApiType, UsersUiType, UsersSegregated, RoleApiType, UserUIType } from '../type';

export const usersToFrontEnd = (users: UsersApiType, account?: Account) => {
  const usersToUI: UsersUiType = {};
  const usersSegregated: UsersSegregated = {
    accountOwner: [],
    botOwner: [],
  };
  users.forEach((user: UserApiType) => {
    const transformedUser = transformUserToUI(user);
    usersToUI[transformedUser.id] = transformedUser;
    const userRoles = transformedUser.userRoles || [];
    if (
      userRoles.some((roleObj: RoleApiType) => {
        return (
          roleObj.account === account?.id &&
          (account?.config?.amplifyLiteUIConfig?.overriddenRoles?.admin?.role
            ? roleObj.role === account?.config?.amplifyLiteUIConfig?.overriddenRoles?.admin?.role || roleObj.role === userRolesLite.ACCOUNT_OWNER
            : roleObj.role === userRolesLite.ACCOUNT_OWNER)
        );
      })
    ) {
      transformedUser.liteRoleLabel = account?.config?.amplifyLiteUIConfig?.overriddenRoles?.admin?.role
        ? account?.config?.amplifyLiteUIConfig?.overriddenRoles?.admin?.role
        : userRolesLite.ACCOUNT_OWNER;
      transformedUser.createdByName = transformedUser?.createdBy?.name;
      usersSegregated.accountOwner.push(transformedUser);
    } else if (
      userRoles.some((roleObj: RoleApiType) => {
        return (
          roleObj.account === account?.id &&
          (account?.config?.amplifyLiteUIConfig?.overriddenRoles?.teamMember?.role
            ? roleObj.role === account?.config?.amplifyLiteUIConfig?.overriddenRoles?.teamMember?.role || roleObj.role === userRolesLite.BOT_OWNER
            : roleObj.role === userRolesLite.BOT_OWNER)
        );
      })
    ) {
      transformedUser.liteRoleLabel = account?.config?.amplifyLiteUIConfig?.overriddenRoles?.teamMember?.role
        ? account?.config?.amplifyLiteUIConfig?.overriddenRoles?.teamMember?.role
        : userRolesLite.BOT_OWNER;
      transformedUser.createdByName = transformedUser?.createdBy?.name;
      usersSegregated.botOwner.push(transformedUser);
    }
  });
  return {
    usersToUI,
    usersSegregated,
  };
};
// export const tansformUserToApi = (users: UsersApiType) => {
//   const userTO
// }

// const userMapToApi = {
//   firstName: 'FirstName',
//   lastName: 'LastName',
//   email: 'email',
//   id: 'id',
//   emailConfirmed: 'emailConfirmed',
//   'userRoles[].role': 'userRoles[].role',
//   'userRoles[].account': 'userRoles[].account',
//   'userRoles[].bot': 'userRoles[].bot',
// };

const transformUserToUI = (user: UserApiType) => {
  const userUI = {
    firstName: user.FirstName,
    lastName: user.LastName,
    userRoles: user.userRoles,
    ...user,
  };

  delete userUI.FirstName;
  delete userUI.LastName;

  return userUI;
};

export const transformUserToApi = (user: UserUIType) => {
  const userApi = {
    id: user.id,
    FirstName: user.firstName,
    LastName: user.lastName,
    email: user.email,
    emailConfirmed: user.emailConfirmed,
    lastUpdatedDate: (user as any).lastUpdatedDate,
    createdDate: (user as any).createdDate,
    createdBy: (user as any).createdBy,
    isAmplifyLite: user.isAmplifyLite,
    phoneNumber: user.phoneNumber,
    userRoles: user.userRoles,
  };
  return userApi;
};

export const transformUserRoleToUI = (userRoles: any[]): UserRole[] => {
  return userRoles.map((userRole: any) => ({ role: userRole.role || '', account: userRole.account || '', botId: userRole.bot || undefined }));
};
