import constants from './constants';
import { ReportsTableRecord } from '../types';
import { createAction } from '@reduxjs/toolkit';

type ReportsToUI = {
  [key: string]: ReportsTableRecord;
};

export const storeReports = createAction<ReportsToUI>(constants.STORE_REPORTS);
export const deleteReportFromState = createAction<string>(constants.DELETE_REPORT);
export const updateReports = createAction<ReportsToUI>(constants.UPDATE_REPORTS);
