export const liteFeatureTabs = {
  TOPICS_OF_INTEREST: 'topicsOFInterest',
  ADD_KEYWORDS: 'addKeywords',
};

export const ampLiteFeatures = [
  {
    name: liteFeatureTabs.TOPICS_OF_INTEREST,
    title: 'Topics of Interest',
    description: `Please choose some or all of the topics listed below. We will start tagging your comments right away!`,
  },
  {
    name: liteFeatureTabs.ADD_KEYWORDS,
    title: 'Add Keywords',
    description: 'Users talking about competitors? Specific words being used a lot? Keep a watch on their activity',
  },
];
export const topOfMindTopics = [
  {
    name: 'custSupport',
    title: 'Customer Support',
  },
  {
    name: 'religiousPhrase',
    title: 'Religious Phrases',
  },
  {
    name: 'productIssue',
    title: 'Product Issues',
  },
  {
    name: 'racialSuggestion',
    title: 'Racial Suggestions',
  },
  {
    name: 'hostileTone',
    title: 'Hostile Tone',
  },
  {
    name: 'sexualRef',
    title: 'Sexual References',
  },
];
export const topOfMindKeywords = [
  {
    name: 'businessThreads',
    title: 'Business Threats',
  },
  {
    name: 'competitors',
    title: 'Competitors',
  },
  {
    name: 'createYourOwn',
    title: 'Create your own',
  },
  {
    name: 'addCompetitor',
    title: 'Racial Suggestions',
  },
];
export const brandLiftActions = [
  {
    name: 'positiveEngage',
    title: 'Highly Positive Engagement',
    description: 'Brand can Like very positive comments by users',
  },
  {
    name: 'appreciateUsers',
    title: 'Appreciate Top Users',
    description: 'Engage with top users by using reactions such as Love, HaHa',
  },
  {
    name: 'sentimentBoost',
    title: 'Sentiment Boost',
    description: 'React positively to users who commented or reacted to Brand comments',
  },
];
export const smartReplyActions = [
  {
    name: 'publicReplies',
    title: 'Public Replies',
    description: 'One line about what this is about',
  },
  {
    name: 'automatedReply',
    title: 'Automated Public',
    description: 'Replies One line about what this is about',
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const KEYCODE = { TAB: 9, RETURN: 13, ESC: 27, UP: 38, DOWN: 40 };
export const jobTypes = {
  COMMENT_MODERATION_REPORT: 'COMMENT_MODERATION_REPORT',
  NLP: 'NLP',
  CONCEPT_MAP: 'CONCEPT_MAP',
  FEED_SCRAPER: 'FEED_SCRAPER',
  INSTAGRAM_FEED_SCRAPER: 'INSTAGRAM_FEED_SCRAPER',
  SHOPIFY_CUSTOMER_SCRAPER: 'SHOPIFY_CUSTOMER_SCRAPER',
  ATHENA_QUERY_RESULT: 'ATHENA_QUERY_RESULT',
  ATHENA_COMMENT_RESPONDER: 'ATHENA_COMMENT_RESPONDER',
  PLATFORM_USERS_EXPORTER: 'PLATFORM_USERS_EXPORTER',
  YOUTUBE_COMMENTS_SCRAPER: 'YOUTUBE_COMMENTS_SCRAPER',
};

export const EmailType = {
  SALES: 'sales',
  CLIQZ_ALERTS: 'cliqz',
};
export const slackChannels = {
  CLIQZ_ALERTS: 'cliqzalerts',
};
