import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { changeCurrentTab, changeDrawerTitle, showCreateRecommendationButton, storeBasePath } from '../actions';

export const initialState: {
  showCreateRecommendationButton: boolean;
  currentTab: string;
  routeName: string;
  basePath: string;
} = {
  showCreateRecommendationButton: false,
  currentTab: '',
  routeName: '',
  basePath: '',
};

const recommendationsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(showCreateRecommendationButton, (state: typeof initialState, action: ReturnType<typeof showCreateRecommendationButton>) => {
      state.showCreateRecommendationButton = action.payload;
    })
    .addCase(changeDrawerTitle, (state: typeof initialState, action: ReturnType<typeof changeDrawerTitle>) => {
      state.routeName = action.payload;
    })
    .addCase(storeBasePath, (state: typeof initialState, action: ReturnType<typeof storeBasePath>) => {
      state.basePath = action.payload;
    })
    .addCase(changeCurrentTab, (state: typeof initialState, action: ReturnType<typeof changeCurrentTab>) => {
      state.currentTab = action.payload;
    });
});

export default recommendationsReducer;
