import { useHistory } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Tags } from '../pages/common/tag/tag.types';
import rootReducer from '../reducers';
import store from '../store';
export type { AttemptLoginApiResponse, FetchCommentsApiResponse, GetBotsAndPlatformBotsApiResponse, GetUserAccountApiResponse, GetUserDetailsApiResponse } from './api-calls';
export type { Auth, GetUserPermissionsApiResponse, pageUIStateType, User, UserPermissions } from './state';

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk<T = void> = ThunkAction<Promise<T>, RootState, unknown, Action<string>>;
export type AsyncThunkDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type History = ReturnType<typeof useHistory>;

export type SelectOption = {
  label: string;
  value: string;
  profilePictureUrl?: string;
  platform?: string;
};
export type SuggestedActionOptions = {
  [key: string]: [
    {
      label: string;
      value: string;
    },
  ];
};
export type initialGlobalState = {};

export type AmplifyError = {
  errorMessage: React.ReactNode;
  type?: ErrorType;
  errorCode?: number;
  title?: string;
  isReportable?: boolean;
  endPoint?: string;
  description?: string;
};

/* eslint-disable @typescript-eslint/naming-convention*/
export type AmplitudeEventProps = {
  platform: string;
  platform_bot_id: string;
  platform_bot_name: string;
};

export enum ErrorType {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type EntityWithTags = {
  tags: Tags;
  [key: string]: unknown;
};

export type IntervalFunction = () => unknown | void;

declare global {
  interface Window {
    dataLayer: any;
    gtag: any;
  }
}

export type GenericObject = { [key: string]: any };

export type Unionize<T extends object> = {
  [P in keyof T]: { [Q in P]: T[P] };
}[keyof T];
