import React, { MouseEventHandler } from 'react';
import { Avatar } from 'antd';
import { platfromOverlayIcon } from '../utils';
import styles from './page-selector.module.scss';
import AmplifyAvatar from '../../avatar/ant-avatar';
import { usePlatformBots } from '../../../../hooks';
import { getPageLink } from '../../../user-profile-pages/components/social-media-accounts/utils';
import { AmpLitePages } from '../../sidebar/constants';
import { platforms } from '../../../../common/entities/entities.constants';

export type AvatarProps = {
  iconSize?: number | 'small' | 'large' | 'default';
  iconSrc?: string;
  shape?: 'circle' | 'square';
  fallBackImgSrc?: string;
  optionIsBot?: boolean;
  platform?: string;
  overlayIcon?: string;
  customTextForFallback?: string;
  platformBotId?: string;
  botSelectorePage?: string;
  children?: any;
};

// eslint-disable-next-line max-lines-per-function
const PageSelectorAvatar = (props: AvatarProps) => {
  const { iconSize = 'small', iconSrc, shape = 'circle', fallBackImgSrc, overlayIcon, customTextForFallback, platformBotId, botSelectorePage } = props;
  // const [showCopiedTooltip, setshowCopiedTooltip] = useState(false);
  const platformBots = usePlatformBots() || {};
  const pageLink = platformBotId ? getPageLink(platformBotId, platformBots?.[platformBotId]?.platform, platformBots?.[platformBotId]?.instaUsername) : '';

  const onProfilePicClick = (e: MouseEventHandler<HTMLAnchorElement>, pageLink: string) => {
    if (platformBotId && platformBots?.[platformBotId]?.platform === platforms.TWILIO_SMS) {
      copyText(e, pageLink);
    } else {
      window.open(pageLink, '_blank');
    }
  };

  const copyText = (e: MouseEventHandler<HTMLAnchorElement>, textToCopy: string): void => {
    (e as any).stopPropagation();

    textToCopy && navigator.clipboard.writeText(textToCopy);
  };

  return (
    <>
      <Avatar.Group className={botSelectorePage && botSelectorePage === AmpLitePages.CHOOSE_ACCOUNT ? '' : styles.avatarGroup}>
        <a
          onClick={(e: any) => {
            e.preventDefault();
          }}
        >
          <div className={styles.profileIconTextContainer}>
            <span
              onClick={(e: any) => {
                pageLink && onProfilePicClick(e, pageLink);
              }}
              style={{ cursor: pageLink ? 'pointer' : 'auto' }}
            >
              <AmplifyAvatar
                iconSize={iconSize}
                iconSrc={iconSrc}
                shape={shape}
                fallBackImgSrc={customTextForFallback ? '' : fallBackImgSrc}
                customTextForFallback={customTextForFallback}
                isHighlight={pageLink && props.optionIsBot ? true : false}
              />
            </span>
            {props.children}
          </div>
          {props.optionIsBot && (
            <img
              src={overlayIcon ? overlayIcon : platfromOverlayIcon[props.platform || '']}
              alt='Platform Icon'
              className={styles.platformIcon}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          )}
        </a>
      </Avatar.Group>
    </>
  );
};
export default PageSelectorAvatar;
