import { maxSizeLimitInMb, maxSizeLimitForCompression } from '../../pages/settings/components/config';
import { platforms } from '../entities/entities.constants';
import { PlatformActionConfigPayload } from '../platform-action-config/platform-action-config.types';

export const getFBPageProfilePicURL = (platformBotId: string) => {
  return `https://graph.facebook.com/${platformBotId}/picture?type=square`;
};

export const getAllowedAttachmentImageSizeByPlatform = (
  //eventually we should do this logic on the basis of system settings
  platform: string,
  _systemSettings?: PlatformActionConfigPayload,
) => {
  let imageSize = maxSizeLimitInMb.image;
  if (platform === platforms.TWILIO_SMS) {
    imageSize = maxSizeLimitForCompression.broadcast;
  }

  return imageSize;
};
