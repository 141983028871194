import { v4 as uuid } from 'uuid';
import { platforms } from '../../common/entities/entities.constants';
import { dashboardTabConstants } from '../dashboard/constants';

export const actionsInBrandLift = ['like'];
export const actionsInCommentModeration = ['hide', 'block', 'delete', 'review'];
export const actionsInSmartReplies = ['public', 'rich', 'privateReply'];
export const createdForVariableMacro = '{{volatile.comment.isPlatformBotMention}}';
export const tagMacro = '{{volatile.commentTag}}';

export const defaultCampaignState = {
  platform: platforms.FACEBOOK,
  ignoreOtherUserCaps: true,
  name: '',
  paused: true,
  platformBotId: '',
  postId: '',
  postType: 'all',
  replyToUserType: 'all',
  respondToIndirectComments: true,
  sendDelay: 0,
  sentiments: ['any'],
  type: 'standard',
  targetPercentage: '100',
  conditions: {
    logicalOperator: 'and',
    childConditions: [],
  },
  actions: [{ type: 'like', id: uuid() }],
  createFor: { [dashboardTabConstants.COMMENTS]: true },
};

export const allActionsSupported = [...actionsInBrandLift, ...actionsInCommentModeration, ...actionsInSmartReplies];

export const variableTypeToVariableGroup: { [key: string]: string } = {
  entity: 'Entities',
  intent: 'User Intents',
  system: 'Amplify System',
};

export const variablesNotToDisplay = ['{{message.timestamp}}', '{{volatile.locale}}'];

export const variableToLabelMap: { [key: string]: string } = {
  '{{message.text}}': 'Comment Text',
  '{{message.textProcessorResult.originalText}}': 'Raw Comment Text',
};
export const defaultTagAsConditions = {
  logicalOperator: 'and',
  childConditions: [
    {
      logicalOperator: 'and',
      ifStatements: [{ variable: { name: 'volatile.commentTag', type: 'custom', macro: tagMacro }, condition: 'equal', value: '' }],
    },
  ],
};
