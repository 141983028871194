import { createAction } from '@reduxjs/toolkit';
import constants from '../templates.constants';
import { Template, Templates } from '../templates.types';

export const switchTemplatesTab = createAction<string>(constants.SWITCH_TEMPLATES_TAB);
export const storeTemplatesBasePath = createAction<string>(constants.STORE_TEMPLATES_BASE_PATH);
export const showCreateTemplateButton = createAction<boolean>(constants.SHOW_CREATE_TEMPLATE_BUTTON);
export const changeCurrentTabTemplates = createAction<string>(constants.CHANGE_CURRENT_TAB_TEMPLATES);
export const changeTitleTemplates = createAction<string>(constants.CHANGE_TITLE_TEMPLATES);
// export const setTemplatesLoaded = createAction<boolean>(constants.SET_TEMPLATES_LOADED);

export const storePRTemplatesStringMap = createAction<Templates>(constants.STORE_TEMPLATES_STRING_MAP);
export const storeTemplateToMessageMap = createAction<Template>(constants.CREATE_TEMPLATE_MESSAGE_MAP);
export const deleteTemplateFromMessageMap = createAction<string>(constants.DELETE_TEMPLATE_MESSAGE_MAP);
export const editTemplateInMessageMap = createAction<Template>(constants.EDIT_TEMPLATE_MESSAGE_MAP);
export const setTemplatesPrevModalMode = createAction<string>(constants.TEMPLATES_PREV_MODE);
