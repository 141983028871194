type Narrowable = string | number | boolean | undefined | null | void | {};
const tuple = <T extends Narrowable[]>(...t: T) => t;
type MinMaxPair = [number, number];

export const options = {
  colors: ['#0e204e', '#6bc6f8', '#38d1cb', '##2ebacc', '#59b6e9', '#738CCC', '#00304A', '#0A088C', '#444444', '#1297E0', '#829F33'],
  enableTooltip: true,
  deterministic: true,
  fontFamily: 'NunitoSans-Bold, Nunito Sans',
  fontSizes: [8, 30] as MinMaxPair,
  fontStyle: 'normal',
  fontWeight: '700',
  padding: 0.25,
  rotations: 1,
  rotationAngles: tuple(0, 0),
  scale: 'sqrt' as const,
  spiral: 'archimedean' as const,
  transitionDuration: 1200,
};
