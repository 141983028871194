import React from 'react';
export const LoginComponent = React.lazy(() => import(/* webpackChunkName: "Login" */ '../pages/login'));
// general group
export const GeneralComponent = React.lazy(() => import(/* webpackChunkName: "general" */ './general'));

// broadcast group
export const BroadcastComponent = React.lazy(() => import(/* webpackChunkName: "broadcast" */ './broadcast'));

// comments group
export const CommentsComponent = React.lazy(() => import(/* webpackChunkName: "comments" */ './comments'));

// setting group
export const SettingComponent = React.lazy(() => import(/* webpackChunkName: "settings" */ './settings'));

// applyout group
export const AppLyoutComponent = React.lazy(() => import(/* webpackChunkName: "app-layout" */ '../app-layout'));

// inbox group
export const InboxComponent = React.lazy(() => import(/* webpackChunkName: "inbox" */ './inbox'));

// admin tool group
export const AdminToolComponent = React.lazy(() => import(/* webpackChunkName: "admin-tool" */ './admin-tool'));

// Audiance group
export const AudianceComponent = React.lazy(() => import(/* webpackChunkName: "Audiance" */ './audiance'));

export const PageAccessComponent = React.lazy(() => import(/* webpackChunkName: "Request Page Access" */ './page-access'));
