import { uniqBy } from 'lodash';
import moment, { Moment } from 'moment';
import { TagsPriorityAsPerAction } from '../../../pages/automation/constants';
import { platforms } from '../entities.constants';
import { Bot, PlatformBots, SuggestedAction } from '../entities.types';

export const selectPlatformBot = (allPlatformBots: PlatformBots, platformBotInUrl: string, isMobile?: boolean) => {
  if (allPlatformBots[platformBotInUrl]) return platformBotInUrl;
  const platformBotIds = Object.keys(allPlatformBots);
  if (platformBotIds.length) {
    const facebookPlatformBotIds = platformBotIds.filter((platformBotId: string) => allPlatformBots[platformBotId].platformAsInSystemSettings === platforms.FACEBOOK);
    const twillioPlatformBotIds = platformBotIds.filter((platformBotId: string) => allPlatformBots[platformBotId].platformAsInSystemSettings === platforms.TWILIO_SMS);
    const facebookOrTwillioPlatformBotIds = isMobile ? twillioPlatformBotIds : facebookPlatformBotIds;
    const platformBotIdsToSort = facebookOrTwillioPlatformBotIds.length ? facebookOrTwillioPlatformBotIds : platformBotIds;
    return platformBotIdsToSort.sort((p1: string, p2: string) => allPlatformBots[p1].name.localeCompare(allPlatformBots[p2].name))[0];
  }
};

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatTagKey = (tagname: string) => (tagname ? tagname.toLowerCase().trim() : '');

/**
 * It should return hight priority if multiple actions are configured.
 * Lower number is higher priority e.g. 2 will have more priority than 6.
 * @param actions
 */
export const getGcPriority = (actions: SuggestedAction[]): number => {
  let priority = 9;
  for (const action of actions) {
    if (TagsPriorityAsPerAction[action.type] && priority > TagsPriorityAsPerAction[action.type]) {
      priority = TagsPriorityAsPerAction[action.type];
    }
  }
  return priority;
};

export const getPlatformUsers = (bot: Bot) => {
  return bot && bot.platformUsers;
};
export const getTargetedUsers = (bot: Bot) => {
  // const sortedATargetedUsers
  const targetedUsersUnSorted = [...(bot?.targetedUsers || [])];
  const targetingsSorted = targetedUsersUnSorted?.sort((a: { lastUpdatedDate: string }, b: { lastUpdatedDate: string }) =>
    moment(b.lastUpdatedDate).isAfter(a.lastUpdatedDate) ? 1 : -1,
  );
  const targetedUsers = bot && uniqBy(targetingsSorted, 'userId');
  return targetedUsers;
};
export const getTestUsers = (bot: Bot) => {
  return bot && bot.testUsers;
};

export const getCurrentBotPlatform = (selectedPlatformBot?: string, platformBots?: PlatformBots) => {
  const platformBotId = selectedPlatformBot || '';
  return platformBots ? platformBots[platformBotId]?.platform : undefined;
};
export const getCurrentBotPlatformAsInSystemSettings = (selectedPlatformBot?: string, platformBots?: PlatformBots) => {
  const platformBotId = selectedPlatformBot || '';
  return platformBots ? platformBots[platformBotId]?.platformAsInSystemSettings : undefined;
};

export const getBucketLimit = (startDate: Moment | string, endDate: Moment | string) => {
  const bucketLimit = endDate && startDate ? moment(endDate).diff(moment(startDate), 'days') + 1 : 10000;
  return bucketLimit;
};

export const getObjectToParams = (obj: any) => {
  return Object.keys(obj)
    .filter((key: any) => !!obj[key])
    .map((key: any) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
};
