import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { Post } from '../../../../../../../common/entities/entities.types';
import { setPostfilter, setPostSelectModalVisible, applyPostfilter, setIsPostFetching } from '../actions';

const initialState: {
  selectedPosts: Post[];
  appliedPosts: Post[];
  isPostSelectModalVisible: boolean;
  isPostFetching: boolean;
} = {
  selectedPosts: [],
  appliedPosts: [],
  isPostSelectModalVisible: false,
  isPostFetching: false,
};

//Higher order reducer. To use it pass unique name as string.
const postFilterReducer = (searchFilterName: string) =>
  createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
    builder
      .addCase(setPostfilter(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof setPostfilter>>) => {
        state.selectedPosts = action.payload;
      })
      .addCase(applyPostfilter(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof applyPostfilter>>) => {
        state.appliedPosts = action.payload;
      })
      .addCase(setPostSelectModalVisible(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof setPostSelectModalVisible>>) => {
        state.isPostSelectModalVisible = action.payload;
      })
      .addCase(setIsPostFetching(searchFilterName), (state: typeof initialState, action: ReturnType<ReturnType<typeof setIsPostFetching>>) => {
        state.isPostFetching = action.payload;
      });
  });

export default postFilterReducer;
