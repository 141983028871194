import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { platformActionsConfig, platformActionConfigFetched, setSupportedPlatforms, GenericSettings, setSystemSettings } from '../actions';
import { PlatformActionConfig } from '../platform-action-config.types';

export const initialState: PlatformActionConfig = {
  configData: {},
  platformActionConfigFetched: false,
  supportedPlatforms: [],
  systemSettings: {},
  genericSettings: { blackListErrors: [] },
};
const platformActionConfigReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(platformActionsConfig, (state: typeof initialState, action: ReturnType<typeof platformActionsConfig>) => {
      state.configData = action.payload;
    })
    .addCase(platformActionConfigFetched, (state: typeof initialState, action: ReturnType<typeof platformActionConfigFetched>) => {
      state.platformActionConfigFetched = action.payload;
    })
    .addCase(setSupportedPlatforms, (state: typeof initialState, action: ReturnType<typeof setSupportedPlatforms>) => {
      state.supportedPlatforms = action.payload;
    })
    .addCase(setSystemSettings, (state: typeof initialState, action: ReturnType<typeof setSystemSettings>) => {
      state.systemSettings = action.payload;
    })
    .addCase(GenericSettings, (state: typeof initialState, action: ReturnType<typeof GenericSettings>) => {
      state.genericSettings = action.payload;
    });
});

export default platformActionConfigReducer;
