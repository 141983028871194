const intentsConstants: IntentsConstants = {
  STORE_USERS: 'STORE_USERS',
  STORE_BOT_USERS: 'STORE_BOT_USERS',
  DELETE_USER: 'DELETE_USER',
  SET_USERS_ACTIVE_TAB_PANE: 'SET_USERS_ACTIVE_TAB_PANE',
  SET_PLATFORMBOT_PERMISSIONS: 'SET_PLATFORMBOT_PERMISSIONS',
  SET_PAGE_HEALTH_DETAILS: 'SET_PAGE_HEALTH_DETAILS',
  SET_PAGE_HEALTH_DETAILS_LOADING: 'SET_PAGE_HEALTH_DETAILS_LOADING',
};
type IntentsConstants = {
  STORE_USERS: string;
  STORE_BOT_USERS: string;
  DELETE_USER: string;
  SET_USERS_ACTIVE_TAB_PANE: string;
  SET_PLATFORMBOT_PERMISSIONS: string;
  SET_PAGE_HEALTH_DETAILS: string;
  SET_PAGE_HEALTH_DETAILS_LOADING: string;
};
/* eslint-disable @typescript-eslint/naming-convention */
export const USER_EXISTS_MSG = 'User already exists with this emailId';
export default intentsConstants;
