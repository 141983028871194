import { formatTagKey } from '../../../common/entities/utils';
import { campaignTypes } from '../../automation/constants';
import { CampaignTypeUI, ErrorObjectType } from '../../automation/types';
import { TaggingGC, TaggingGCAssociatedCampaign, PrivateReplyTags } from '../../../common/entities/entities.types';
import { registerAmplifyAnalytics } from '../../../analytics/utils';
import { ReactNode } from 'react';
import { updateRecommendationInBackend } from '../../../common/entities/actions/async-actions';
import { ActiveCampaign } from '../tags.types';
import transform from '../../../common/entities/transformer';
import { AmplitudeEventProps, Dispatch } from '../../../types';
import { deleteConfirmationText, Entity } from '../../../constants';

export const getRouteForCampaignResponse = (actionType: string) => {
  switch (actionType) {
    case 'hide':
    case 'block':
    case 'delete':
    case 'review':
      return 'commentModeration';
    case 'like':
      return 'brandLift';
    case 'public':
      return 'smartReplies';
    default:
      return '';
  }
};

export const generateDeleteText = (taggingGC: TaggingGC) => {
  const areYouSure = deleteConfirmationText(Entity.TAG);
  let associatedCampaignName = '';
  const areAssociatedCampaignsPresent = taggingGC.associatedCampaigns.length;
  if (areAssociatedCampaignsPresent) {
    if (
      taggingGC.associatedCampaigns.some((assocCampaign: TaggingGCAssociatedCampaign) => {
        associatedCampaignName = `${associatedCampaignName ? `${associatedCampaignName}", "` : ''}${assocCampaign.name}`;
        if (!assocCampaign.paused) {
          return true;
        }
        return false;
      })
    )
      return `This tag is being used by active campaign "${associatedCampaignName}".`;
    return `This tag is being used by paused campaign "${associatedCampaignName}". ${areYouSure}`;
  }
  return areYouSure;
};

export const isActiveCampaignPresent = (taggingGC: TaggingGC) => {
  if (
    taggingGC.associatedCampaigns.some((assocCampaign: TaggingGCAssociatedCampaign) => {
      if (!assocCampaign.paused) {
        return true;
      }
      return false;
    })
  )
    return true;
  return false;
};

export const getActiveCampaignNameIfUsed = (tagName: string, allCampaigns: { [key: string]: CampaignTypeUI }): ActiveCampaign => {
  const campaigns = (allCampaigns && Object.values(allCampaigns)) || [];
  const activeCampaignWhereTagUsed = campaigns.find((campaign: CampaignTypeUI) => {
    const targetedTag = tagTargetedInCampaign(campaign) || '';
    return targetedTag && !campaign.paused && campaign.type !== campaignTypes.TAGGING && formatTagKey(targetedTag) === formatTagKey(tagName);
  });
  return { name: activeCampaignWhereTagUsed?.name || '', id: activeCampaignWhereTagUsed?.id || '' };
};

export const tagTargetedInCampaign = (campaign: CampaignTypeUI) => campaign?.conditions?.childConditions?.[0]?.ifStatements?.[0]?.value || undefined;
export const getAssociatedTagForTaggingGC = (allTags: PrivateReplyTags, replyMessage?: string) => {
  if (replyMessage) {
    return allTags[formatTagKey(replyMessage)];
  }
};

export const validateAndChangeTaggingGCState = (
  checked: boolean,
  callbacks: {
    setChangingSwitchState: React.Dispatch<React.SetStateAction<boolean>>;
    setShowErrorModal: React.Dispatch<React.SetStateAction<ErrorObjectType>>;
    dispatch: Dispatch;
  },
  allCampaigns: {
    [key: string]: CampaignTypeUI;
  },
  taggingGC: TaggingGC,
  errorModalContent: (activeCampaign: ActiveCampaign) => ReactNode,
  amplitudeEventProps: AmplitudeEventProps,
) => {
  callbacks.setChangingSwitchState(true);
  const done = () => {
    callbacks.setChangingSwitchState(false);
    registerAmplifyAnalytics.toggleTagStatus(!checked, taggingGC.title, amplitudeEventProps);
  };
  let activeCampaign = { name: '', id: '' };
  if (!checked && (activeCampaign = getActiveCampaignNameIfUsed(taggingGC.title, allCampaigns)) && activeCampaign.name) {
    callbacks.setShowErrorModal({
      jsx: errorModalContent(activeCampaign),
      show: true,
    });
    callbacks.setChangingSwitchState(false);
  } else callbacks.dispatch(updateRecommendationInBackend(transform.updateRecommendationActiveStateUiToApi(taggingGC, !checked), done));
};
