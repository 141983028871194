import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, SelectOption as SelectOptionType } from '../../../../types';
import { PlatformBots, PlatformBot } from '../../../../common/entities/entities.types';
import { useSelectedPage } from '../hooks';
import Styles from './page-selector.module.scss';
import { Select } from 'antd';
import { FormatOptionLabel } from './option-label-formatter';
// import { platforms } from '../../../../common/entities/entities.constants';
import SelectDropdownArrow from '../../select-dropdown-arrow';
import { PageSelectorOption } from '../header.types';
import { getProfilePic } from '../utils';
import { platforms } from '../../../../common/entities/entities.constants';
import { useIsMobile } from '../../../../mobile/utils';
import { checkValueInOptionGroups, getProfilePicPlaceholderProps } from '../../../inbox/components/utils';
import { PlatformActionConfigPayload } from '../../../../common/platform-action-config/platform-action-config.types';
import { getHeaderFontsAndIconsColor } from '../../../../config/theme-config';
// import { disableBodyScrollBar } from '../../../../common/utils';
const { Option, OptGroup } = Select;

const platformOrder = [platforms.FACEBOOK, platforms.INSTAGRAM, platforms.TWITTER];

export const getPlatformForSystemSettings = (platformFromBot: string, platformActionConfig: PlatformActionConfigPayload) => {
  let platformAsInSystemSettings = platformFromBot;
  Object.keys(platformActionConfig).find((platform: string) => {
    if (platform === platformFromBot) {
      platformAsInSystemSettings = platform;
      return true;
    } else if (platformActionConfig[platform].providers?.includes(platformFromBot)) {
      platformAsInSystemSettings = platform;
      return true;
    }
    return false;
  });
  return platformAsInSystemSettings;
};
export const preparePageSelectorOptionGroups = (platformBots: PlatformBots, platformActionConfig: any, isMobile: boolean) => {
  const optGroups: { [key: string]: PlatformBot[] } = {};
  Object.values(platformBots).forEach((platformBot: PlatformBot) => {
    const platformVal = getPlatformForSystemSettings(platformBot.platform, platformActionConfig);
    if (platformVal) {
      if (optGroups[platformVal]) {
        optGroups[platformVal].push(platformBot);
      } else {
        optGroups[platformVal] = [platformBot];
      }
    }
  });
  const getAvailablePlatforms = () => {
    const platformInOrder = platformActionConfig && Object.entries(platformActionConfig).length ? Object.keys(platformActionConfig) : platformOrder;
    return platformInOrder.filter((platformType: string) => Object.keys(optGroups).includes(platformType));
  };
  let platformTypesAvailable = getAvailablePlatforms();
  if (isMobile && platformTypesAvailable.includes(platforms.TWILIO_SMS)) {
    platformTypesAvailable = platformTypesAvailable.filter((variableGroup: string) => variableGroup !== platforms.TWILIO_SMS);
    platformTypesAvailable = [platforms.TWILIO_SMS, ...platformTypesAvailable];
  }
  const optionsForVariableDropdown = platformTypesAvailable.map((variableGroup: string) => {
    const options: PageSelectorOption[] = optGroups[variableGroup].map((platformBot: PlatformBot) => ({
      value: platformBot.platformBotId,
      label: platformBot.name,
      profilePictureUrl: platformBot.profilePictureUrl,
      platform: platformBot.platform,
    }));
    const sortedOptions = options.sort((a: PageSelectorOption, b: PageSelectorOption) => a.label?.localeCompare(b.label));
    return {
      label: variableGroup,
      options: sortedOptions,
    };
  });
  return optionsForVariableDropdown;
};
/* eslint-disable max-lines-per-function*/
type FbPageSelectorType = {
  containerClassName?: string;
  platformBotsForOptions?: PlatformBots;
  isLoading?: boolean;
  botSelectorePage?: string;
};

const FbPageSelector = ({ containerClassName, platformBotsForOptions, isLoading, botSelectorePage = '' }: FbPageSelectorType) => {
  const optionsMap = useSelector((state: RootState) => state.entities?.platformBots) || {};
  const isMobile = useIsMobile();
  const platformTypeToVariableGroupProperties = useSelector((state: RootState) => state.platformActionConfig.configData);

  const optionList = preparePageSelectorOptionGroups(platformBotsForOptions || optionsMap, platformTypeToVariableGroupProperties, isMobile);
  const { selectedPage, setSelectedPage } = useSelectedPage();
  /*eslint-disable react-hooks/exhaustive-deps*/
  const hasValueInOptionGroups = useMemo(() => !isLoading && checkValueInOptionGroups(selectedPage, optionList), [selectedPage, optionsMap, isLoading]);
  const [hovered, setHovered] = useState(false);

  return (
    <div className={`${containerClassName} ${isMobile ? 'is-hidden-desktop' : 'is-hidden-mobile'} ` + Styles.pageSelectWrapper}>
      <Select
        placeholder='Please select page'
        style={{ width: '100%' }}
        onChange={setSelectedPage}
        value={hasValueInOptionGroups ? selectedPage : undefined}
        loading={isLoading}
        showSearch={true}
        optionFilterProp={'label'}
        suffixIcon={!isLoading ? <SelectDropdownArrow fill={getHeaderFontsAndIconsColor()} /> : undefined}
        dropdownClassName={'page-select-dropdown'}
        dropdownMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById(isMobile ? 'settingsFBPageSelectorPopupcontainer' : 'navMenuTransparentExample') || document.body}
        listHeight={isMobile ? 160 : undefined}
        onDropdownVisibleChange={(open: boolean) => setHovered(open)}
      >
        {optionList.map((optGroup: { label: string; options: SelectOptionType[] }) => {
          const platform = optGroup.label;
          return optGroup?.options.length ? (
            <OptGroup
              label={
                <FormatOptionLabel
                  label={platformTypeToVariableGroupProperties[optGroup.label as string]?.botSelectorLabel || platform}
                  iconSrc={platformTypeToVariableGroupProperties[optGroup.label as string]?.botSelectorIcon}
                  shape={'circle'}
                  botSelectorePage={botSelectorePage}
                />
              }
              key={optGroup.label}
            >
              {optGroup.options.map((option: SelectOptionType) => {
                return (
                  <Option key={option.value} value={option.value} platform={option.platform} label={option.label + option.platform}>
                    <FormatOptionLabel
                      label={option.label}
                      value={option.value}
                      iconSrc={option.profilePictureUrl ? option.profilePictureUrl : getProfilePic(option, platform)}
                      overlayIcon={platformTypeToVariableGroupProperties[optGroup.label as string]?.botSelectorIcon}
                      iconSize={'large'}
                      optionIsBot={true}
                      isPopoverShow={hovered}
                      platform={platform}
                      {...getProfilePicPlaceholderProps(option.label)}
                      botSelectorePage={botSelectorePage}
                    />
                  </Option>
                );
              })}
            </OptGroup>
          ) : (
            <> </>
          );
        })}
      </Select>
    </div>
  );
};

export default FbPageSelector;
