import { createAction } from '@reduxjs/toolkit';
import constants from './constants';

type StoreAdminToolStatus = {
  featureName: string;
  [key: string]: any;
};
export type UpdateIdsForBackLink = {
  [key: string]: string;
};

export const storeAdminToolStatus = createAction<StoreAdminToolStatus>(constants.STORE_ADMIN_TOOL_STATUS);
export const updateIdsForBackLink = createAction<UpdateIdsForBackLink>(constants.UPDATE_IDS_FOR_BACK_TO_GENERATE_LINK);
