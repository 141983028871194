const postFilterConstants: PostFilterConstantsType = {
  APPLY_POST_FILTER: 'APPLY_POST_FILTER',
  SET_POST_FILTER: 'SET_POST_FILTER',
  SET_POST_MODAL_VISIBILITY: 'SET_POST_MODAL_VISIBILITY',
  SET_IS_POST_FECTHING: 'SET_IS_POST_FECTHING',
};
type PostFilterConstantsType = {
  APPLY_POST_FILTER: string;
  SET_POST_FILTER: string;
  SET_POST_MODAL_VISIBILITY: string;
  SET_IS_POST_FECTHING: string;
};
export default postFilterConstants;
