import { createAction } from '@reduxjs/toolkit';
import constants from './constants';
import { SentimentStatsTypeUI } from '../types';

type payload = {
  data: SentimentStatsTypeUI;
  isPast?: boolean;
};

export const storeSentimentStats = createAction<payload>(constants.STORE_SENTIMENT_STATS);
