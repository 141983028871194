import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from './rotate-device.module.scss';

const RotateDevice = () => {
  const [show, setShow] = useState(false);
  const handleResize = _.debounce(() => {
    if (window.screen.width < 992) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, 0);
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return show ? (
    <div className={styles.container}>
      <img className={styles.logo} alt='info' src={`/images/icons/infoIcon-blue.svg`} />
      <div className={styles.title}>Cliqz is best viewed in portrait mode</div>
      <div className={styles.subtitle}>Please rotate your device</div>
    </div>
  ) : null;
};

export default RotateDevice;
