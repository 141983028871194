/* eslint-disable no-useless-escape */
// eslint-disable-next-line max-len
// export const urlRegexForClickTracking = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
export const urlRegexForClickTracking = /(http(s)?:\/\/.)(www\.)?(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.{}]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.{}])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.{}]*\)|[-A-Z0-9+&@#\/%=~_|${}])/gim;
// export const urlRegexForClickTrackingNew = /(http(s)?:\/\/.)(www\.)?(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])+/gim;
export const urlRegexForClickTrackingNew = /(https?:\/\/[\S]+)/gim;

export const macroRegex = /{{[^}]*}}/gim;
export const macroRegexNonGlobal = /{{[^}]*}}/im;
export const accountTypeMatcher = /\[(.*?)\]/g;
export const punctuationsNotIncludedInURL = '?!:,.';
export const phoneNumberRegex = /^[+]{1}(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/;
export const advancedTwigTemplateRegex = /{%\s*if\s+[\s\S]*?%\}[\s\S]*?(?:{%\s*else\s*%\}[\s\S]*?)?{%\s*endif\s*%\}/i;
export const defaultTwigTemplate = /\{\{\s*([^}]+)\s*\|\s*default\('([^}]*)'\)?\}\}/i;
export const variableRegex = /\{\{(.*?)\}\}/i;
export const checkObjNotation = /\['([^']+)'\]/;
export const facebookPageIdRegex = /^[0-9]{15,}$/;

export const combinedRegexForVars = new RegExp(advancedTwigTemplateRegex.source + '|' + defaultTwigTemplate.source + '|' + variableRegex.source, 'igm');
export const combinedTwigPattern = new RegExp(advancedTwigTemplateRegex.source + '|' + defaultTwigTemplate.source, 'igm');
