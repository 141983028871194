import React from 'react';
import { useDispatch } from 'react-redux';
import { platforms } from '../../../../../common/entities/entities.constants';
import AmplifyTooltip from '../../../../common/tooltip';
import { useIsBotworxAdminUser } from '../../../hooks';
import { SocialMediaAccountsTableRecord } from '../../../user-profile-pages.types';
import { getPlatformBotPermissions } from '../actions/async-actions';

type PermissionProps = {
  record: SocialMediaAccountsTableRecord;
  handlePermissionClick?: (recordId: string) => void;
};

const Permissions = ({ record, handlePermissionClick = () => {} }: PermissionProps) => {
  const isBotworxAdmin = useIsBotworxAdminUser();
  const dispatch = useDispatch();
  return isBotworxAdmin && record.platform === platforms.FACEBOOK ? (
    <AmplifyTooltip text='View Permissions' placement='top'>
      <div
        style={{ marginLeft: '10px', cursor: 'pointer' }}
        onClick={() => {
          if (!record.permissionList) {
            dispatch(getPlatformBotPermissions(record, () => handlePermissionClick(record?.platformBotId || '')));
          } else {
            handlePermissionClick(record?.platformBotId || '');
          }
        }}
        data-testid='permissionslist'
      >
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M17.5 1.6665L15.8333 3.33317M9.49165 9.67484L12.9167 6.24984M12.9167 6.24984L15.4167 8.74984L18.3333 5.83317L15.8333 3.33317M12.9167 6.24984L15.8333 3.33317M9.49165 9.67484C9.92194 10.0994 10.264 10.6049 10.4981 11.1622C10.7322 11.7195 10.8538 12.3176 10.8558 12.922C10.8579 13.5265 10.7403 14.1254 10.5099 14.6843C10.2795 15.2431 9.94087 15.7509 9.51344 16.1783C9.08601 16.6057 8.57825 16.9444 8.0194 17.1748C7.46055 17.4052 6.86165 17.5227 6.25718 17.5207C5.65271 17.5187 5.0546 17.3971 4.49731 17.163C3.94002 16.9288 3.43454 16.5868 3.00998 16.1565C2.17509 15.2921 1.71312 14.1343 1.72356 12.9326C1.734 11.7308 2.21603 10.5813 3.06582 9.7315C3.9156 8.88171 5.06516 8.39969 6.2669 8.38925C7.46863 8.37881 8.62639 8.84078 9.49082 9.67567L9.49165 9.67484Z'
            stroke='var(--primary-accent-color)'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </AmplifyTooltip>
  ) : null;
};

export default Permissions;
