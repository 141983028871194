import { VariablesTypeUI, VariableType } from '../../common/entities/entities.types';
import { FieldMapping, IngestionStep, Option, Field, StatusTypeProcessing, StatusTypeSuccess, StatusTypeFail, SegmentCreate } from './ingest.types';

export type Steps = {
  FILE_UPLOAD: string;
  SEGMENT_CREATION: string;
  FEILD_MAPPING: string;
  FILE_PREVIEW: string;
};

export const steps: Steps = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  SEGMENT_CREATION: 'SEGMENT_CREATION',
  FEILD_MAPPING: 'FEILD_MAPPING',
  FILE_PREVIEW: 'FILE_PREVIEW',
};

export const communityIngetionSteps: IngestionStep[] = [
  {
    id: 0,
    stepId: steps.FILE_UPLOAD,
    title: 'Step 1',
    status: 'process',
  },
  {
    id: 1,
    stepId: steps.FEILD_MAPPING,
    title: 'Step 2',
    status: 'wait',
  },
  {
    id: 2,
    stepId: steps.SEGMENT_CREATION,
    title: 'Step 3',
    status: 'wait',
  },
  {
    id: 3,
    stepId: steps.FILE_PREVIEW,
    title: 'Step 4',
    status: 'wait',
  },
];

export const manualIngetionSteps: IngestionStep[] = [
  {
    id: 0,
    stepId: steps.FILE_UPLOAD,
    title: 'Step 1',
    status: 'process',
  },
  {
    id: 1,
    stepId: steps.FEILD_MAPPING,
    title: 'Step 2',
    status: 'wait',
  },
  {
    id: 2,
    stepId: steps.SEGMENT_CREATION,
    title: 'Step 3',
    status: 'wait',
  },
  {
    id: 3,
    stepId: steps.FILE_PREVIEW,
    title: 'Step 4',
    status: 'wait',
  },
];

export const processType = {
  COMMUNITY: 'Community',
  MANUAL: 'ManualMapping',
  STANDARD: 'CLIQZStandardTemplate',
  UNSUBSCRIBE: 'Unsubscribe',
};
export const processTypeOption: Option[] = [
  {
    label: 'Manual Mapping',
    value: processType.MANUAL,
  },
  {
    label: 'Cliqz Standard Template',
    value: processType.STANDARD,
  },
  {
    label: 'Community Template',
    value: processType.COMMUNITY,
  },
  {
    label: 'Unsubscribe Template',
    value: processType.UNSUBSCRIBE,
  },
];

export const stepsForFileProcess: { [key: string]: IngestionStep[] } = {
  [processType.COMMUNITY]: communityIngetionSteps,
  [processType.STANDARD]: communityIngetionSteps,
  [processType.UNSUBSCRIBE]: communityIngetionSteps,
  [processType.MANUAL]: manualIngetionSteps,
};

export const labelForFileProcess: { [key: string]: string } = {
  [processType.COMMUNITY]: 'Community Template',
  [processType.MANUAL]: 'Manually Mapped',
  [processType.STANDARD]: 'Standard Template',
  [processType.UNSUBSCRIBE]: 'Unsubscribe Template',
};

export const standardCSVHeaderKeys = {
  PHONE_NUMBER: 'PHONE_NUMBER',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  GENDER: 'GENDER',
  EMAIL: 'EMAIL',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  LINE2: 'LINE2',
  LINE1: 'LINE1',
  CITY: 'CITY',
  STATE: 'STATE',
  STATE_CODE: 'STATE_CODE',
  COUNTRY: 'COUNTRY',
  COUNTRY_CODE: 'COUNTRY_CODE',
  POSTAL_CODE: 'POSTAL_CODE',
  LATITUDE: 'LATITUDE',
  LONGITUDE: 'LONGITUDE',
  ADD_VARIABLE: 'ADD_VARIABLE',
};

export const standardCSVHeaderKeysLabels = {
  [standardCSVHeaderKeys.FIRST_NAME]: 'First Name',
  [standardCSVHeaderKeys.LAST_NAME]: 'Last Name',
  [standardCSVHeaderKeys.PHONE_NUMBER]: 'Phone Number',
  [standardCSVHeaderKeys.GENDER]: 'Gender',
  [standardCSVHeaderKeys.EMAIL]: 'Email',
  [standardCSVHeaderKeys.DATE_OF_BIRTH]: 'Date Of Birth',
  [standardCSVHeaderKeys.LINE1]: 'Street Address Line 1',
  [standardCSVHeaderKeys.LINE2]: 'Street Address Line 2',
  [standardCSVHeaderKeys.CITY]: 'City',
  [standardCSVHeaderKeys.STATE]: 'State',
  [standardCSVHeaderKeys.STATE_CODE]: 'State Code',
  [standardCSVHeaderKeys.COUNTRY]: 'Country',
  [standardCSVHeaderKeys.COUNTRY_CODE]: 'Country Code',
  [standardCSVHeaderKeys.POSTAL_CODE]: 'Postal Code',
  [standardCSVHeaderKeys.LATITUDE]: 'Latitude',
  [standardCSVHeaderKeys.LONGITUDE]: 'Longitude',
  [standardCSVHeaderKeys.ADD_VARIABLE]: '+ Add Variable',
};

export const mandatoryFeildMapping: FieldMapping = {
  [standardCSVHeaderKeys.PHONE_NUMBER]: { name: 'platformUserId' },
};

export const mandatoryOverridbaleFeildMapping: FieldMapping = {
  [standardCSVHeaderKeys.GENDER]: { name: 'gender' },
  [standardCSVHeaderKeys.FIRST_NAME]: { name: 'firstName' },
  [standardCSVHeaderKeys.LAST_NAME]: { name: 'lastName' },
};

export const defaultFeildMapping: FieldMapping = {
  ...mandatoryFeildMapping,
  ...mandatoryOverridbaleFeildMapping,
  [standardCSVHeaderKeys.DATE_OF_BIRTH]: { name: 'dateOfBirth', format: 'YYYY-MM-DD' },
  [standardCSVHeaderKeys.EMAIL]: { name: 'email' },
  [standardCSVHeaderKeys.LINE1]: { name: 'line1' },
  [standardCSVHeaderKeys.LINE2]: { name: 'line2' },
  [standardCSVHeaderKeys.CITY]: { name: 'city' },
  [standardCSVHeaderKeys.STATE]: { name: 'state' },
  [standardCSVHeaderKeys.STATE_CODE]: { name: 'stateCode' },
  [standardCSVHeaderKeys.COUNTRY]: { name: 'country' },
  [standardCSVHeaderKeys.COUNTRY_CODE]: { name: 'countryCode' },
  [standardCSVHeaderKeys.POSTAL_CODE]: { name: 'postalCode' },
  [standardCSVHeaderKeys.LATITUDE]: { name: 'lat' },
  [standardCSVHeaderKeys.LONGITUDE]: { name: 'long' },
  [standardCSVHeaderKeys.ADD_VARIABLE]: { name: 'addVariable' }, // This is not a standardCSV field just for add variable. need to filter out when sending to API
};
export const dobFormats = {
  FORMAT1: 'YYYY-MM-DD',
  FORMAT2: 'YYYY/MM/DD',
  FORMAT3: 'DD MMM YYYY',
  FORMAT4: 'DD MMMM YYYY',
};
export const dobFormatsOptions: Option[] = [
  {
    label: dobFormats.FORMAT1,
    value: dobFormats.FORMAT1,
  },
  {
    label: dobFormats.FORMAT2,
    value: dobFormats.FORMAT2,
  },
  {
    label: dobFormats.FORMAT3,
    value: dobFormats.FORMAT3,
  },
  {
    label: dobFormats.FORMAT4,
    value: dobFormats.FORMAT4,
  },
];

export const getColumnFieldsAsOptions: (keepOriginalCSVHeaderLabel?: boolean) => Option[] = (keepOriginalCSVHeaderLabel?: boolean) => {
  return Object.entries(defaultFeildMapping).map((value: [string, Field]) => {
    return { label: keepOriginalCSVHeaderLabel ? value[0] : standardCSVHeaderKeysLabels[value[0]] || value[0], value: value[1].name };
  });
};

export const getVariableFieldsAsOptions: (variables: VariablesTypeUI) => Option[] = (variables: VariablesTypeUI) => {
  return Object.values(variables).map((value: VariableType) => {
    return { label: value.name, value: value.name } as Option;
  });
};

export const errorKeys = {
  uploadeFile: 'uploadeFile',
  newSegmentName: 'newSegmentName',
  newSegmentDsc: 'newSegmentDsc',
  fieldMapping: 'fieldMapping',
  fieldMappingWarning: 'fieldMappingWarning',
  variableMappingWarning: 'variableMappingWarning',
};

export const phoneNumberTypes = {
  US_NUMBERS: 'us',
  INTERNATIONAL_NUMBERS: 'international',
};

export const PROCESSING: StatusTypeProcessing = 'PROCESSING';
export const SUCCESS: StatusTypeSuccess = 'SUCCESS';
export const FAIL: StatusTypeFail = 'FAIL';
export const ingsetStatusType = {
  PROCESSING,
  SUCCESS,
  FAIL,
};

export const IngestionPolling = {
  POLLING_INTERVAL: 20000, // 20 sec
  POLLING_DURATION: 12 * 60 * 60 * 1000, //12 hour
};

/*eslint-disable @typescript-eslint/naming-convention*/
export const TIME_IN_MINUTES_FOR_INGEST_POLLING = 720;

export const defaultSegmentName: SegmentCreate = { name: '', description: '', isNameError: true };

export const IngestrionHelpText = {
  cleanUp: 'Choose phone number format used in CSV.',
  columnName: 'If you see data instead of header column names, then csv must be missing header column. You should add one to the CSV before continuing.',
  segmentName: 'Segment name will be created as <IngestName>-NEW and <IngestName>-EXISTING',
};
export const ValidationWarning = {
  templateInvalid: 'Template Invalid',
  fieldMappingIncomlete: 'Field Mapping incomplete',
  proceed: 'Gender, first or last name is still unmapped, do you really want to proceed?',
  phoneValidation: 'Phone Number is necessary to be mapped, please select the column which refers to it.',
};
export const templateValidation: { [key: string]: string[] } = {
  [processType.UNSUBSCRIBE]: [standardCSVHeaderKeys.PHONE_NUMBER],
  [processType.COMMUNITY]: [
    standardCSVHeaderKeys.FIRST_NAME,
    standardCSVHeaderKeys.LAST_NAME,
    standardCSVHeaderKeys.PHONE_NUMBER,
    standardCSVHeaderKeys.DATE_OF_BIRTH,
    standardCSVHeaderKeys.GENDER,
  ],
  [processType.STANDARD]: [
    standardCSVHeaderKeys.FIRST_NAME,
    standardCSVHeaderKeys.LAST_NAME,
    standardCSVHeaderKeys.PHONE_NUMBER,
    standardCSVHeaderKeys.DATE_OF_BIRTH,
    standardCSVHeaderKeys.GENDER,
  ],
};

export const SampleCSVDownload: { [key: string]: string } = {
  [processType.COMMUNITY]: 's3://amplify-admin-tools/cliqz/user-ingest-templates/community-template.csv',
  CommunityComplete: 's3://amplify-admin-tools/cliqz/user-ingest-templates/cliqz-user-ingest-complete-template.csv',
  [processType.STANDARD]: 's3://amplify-admin-tools/cliqz/user-ingest-templates/cliqz-user-ingest-standard-template.csv',
  [processType.UNSUBSCRIBE]: 's3://amplify-admin-tools/cliqz/user-ingest-templates/cliqz-user-ingest-unsubscribe-template.csv',
};

export const templateFieldOrderMapper = {
  [processType.COMMUNITY]: [
    standardCSVHeaderKeys.PHONE_NUMBER,
    standardCSVHeaderKeys.FIRST_NAME,
    standardCSVHeaderKeys.LAST_NAME,
    standardCSVHeaderKeys.GENDER,
    standardCSVHeaderKeys.DATE_OF_BIRTH,
  ],
  [processType.STANDARD]: [
    standardCSVHeaderKeys.PHONE_NUMBER,
    standardCSVHeaderKeys.FIRST_NAME,
    standardCSVHeaderKeys.LAST_NAME,
    standardCSVHeaderKeys.GENDER,
    standardCSVHeaderKeys.DATE_OF_BIRTH,
  ],
  [processType.UNSUBSCRIBE]: [standardCSVHeaderKeys.PHONE_NUMBER],
};

export const targetUsersLabels = {
  DEFAULT: {
    label: 'All Users (default)',
    subtitle: 'New and existing users will be tagged.',
    subtitleSub: 'New and existing users will be subscribed.',
    value: 'ALL',
  },
  EXISTING_USER: {
    label: 'Existing Users',
    subtitle: 'Only existing users will be tagged.',
    subtitleSub: 'Only existing users will be subscribed.',
    value: 'EXISTING',
  },
  NEW_USER: {
    label: 'New Users',
    subtitle: 'Only new users will be tagged.',
    subtitleSub: 'Only new users will be subscribed.',
    value: 'NEW',
  },
};
export const optionTypes = {
  SUBSCRIPTION: 'subscription',
  UNBLOCK_BLOCKED_USERS: 'unblockBlockedUsers',
  BLOCK_USERS: 'blockUsers',
  UN_SUBSCRIPTION: 'un-subscription',
};
