// import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageUIState } from '../common/page-ui-state/actions';
import { getFiltersFromUrl } from '../utils';
import { RootState } from '../types';
import { ActiveSubTabProps, MobileNavigationProps } from './types';
import { getMobileNavigationProps } from './utils';

export const useMobileNavigation = () => {
  const mobileNavigationProps = useMobileNavigationProps();

  const dispatch = useDispatch();
  const dispatchSetMobileNavigation = useCallback(
    (activeSubPageProps: ActiveSubTabProps, pageKeyForFilter: string, prevLocation?: ActiveSubTabProps, nextLocation?: ActiveSubTabProps, isBackbuttonPressed: boolean = false) => {
      dispatch(
        setPageUIState({
          key: 'mobileNavigation',
          value: getMobileNavigationProps(activeSubPageProps, pageKeyForFilter, prevLocation, nextLocation, isBackbuttonPressed),
        }),
      );
    },
    [dispatch],
  );

  const initMobileNavigation = useCallback(
    (activeSubPageProps: ActiveSubTabProps, pageKeyForFilter: string) => {
      const filters = getFiltersFromUrl();
      const { layout } = filters;
      const { activeSubPage } = layout || {};
      dispatch(setPageUIState({ key: 'mobileNavigation', value: getMobileNavigationProps(activeSubPage || activeSubPageProps, pageKeyForFilter) }));
    },
    [dispatch],
  );
  return { mobileNavigationProps, dispatchSetMobileNavigation, initMobileNavigation };
};

export const useMobileNavigationProps = (): MobileNavigationProps | undefined => {
  return useSelector((state: RootState) => state.pageUIState?.mobileNavigation as any);
};
