export const trillerLoaderColor = '#14172e';
export const logoImage = 'convers8-logo.png';
export const logoImageHeader = 'convers8-logo.png';
export const feviconImage = '/favicon-convers8.ico';
export const globalContainerClassName = 'trillerContainer';
export const chartColors = ['#0050E8', '#676C95', '#FE0D8A'];
export const brandName = 'Converse8.ai';
export const commentBreakDownChartConfig: any = {
  width: 12,
  colors: {
    actionableColor: '#FE0D8A',
    nonActionableColor: '#676C95',
    taggedColor: '#96b2d3 ',
    hiddenComments: '#14172E',
    brandReaction: '#4d5383',
    publicReplies: '#888ec1',
    blockedUsers: '#bfc2de',
    deletedComments: '#0a088c',
    engagementTotal: '#0E1121', //'#222',
    reviewedComments: '#1b3b74',
    engaged: '#0050E8',
  },
};
export const intentTagStrokeColor = '#FE0D8A';
export const wordMapColors = ['#FE2798', '#15182E', '#8BA6C6', '#A6AAC1', '#FFC6E4', '#FFFFFF', '#BDBED0', '#0A088C', '#444444', '#1297E0', '#829F33'];
export const sentimentColors = {
  veryNegative: '#FF0C3A',
  moderatelyNegative: '#FF5195',
  neutral: '#5A6F89',
  moderatelyPositive: '#E960B5',
  veryPositive: '#FE2798',
};
export const leftNavigationBgClassName = 'leftNavigationBarBgTriller';
