import { actionsNotSupportedInMentions } from '../../config/platform-action-map';
import { ActionLabelValuePair, ActionOptionsAsPerPlatform, ActionOptionsAsPerTypeAndPlatform, campaignTypes } from '../../pages/automation/constants';
import { dashboardTabConstants } from '../../pages/dashboard/constants';
import { SelectOption } from '../../types';
import { getActionsMentionsIntersectionAsPerCreateFor } from '../components/tagging-gc-content/utils';
import { PlatformActionConfigPayload } from '../platform-action-config/platform-action-config.types';

export const getFilteredActions = (
  platform: string,
  actionPlatformConfig: PlatformActionConfigPayload,
  isAutomationTab: boolean,
  subTab?: string,
  createCampaignFor?: { [key: string]: boolean },
) => {
  const actionConfigLabelValuePair =
    actionPlatformConfig && actionPlatformConfig[platform as keyof typeof actionPlatformConfig]?.comment?.actions
      ? [
          ...(actionPlatformConfig[platform as keyof typeof actionPlatformConfig]?.comment?.actions || []).map((action: string) => ({
            label: ActionLabelValuePair[action],
            value: action,
          })),
        ]
      : actionPlatformConfig && actionPlatformConfig[platform as keyof typeof actionPlatformConfig]?.actions
      ? actionPlatformConfig[platform as keyof typeof actionPlatformConfig]?.actions.map((action: string) => ({
          label: ActionLabelValuePair[action],
          value: action,
        }))
      : [{ label: '', value: '' }];

  const actions = isAutomationTab
    ? ActionOptionsAsPerTypeAndPlatform &&
      Object.keys(ActionOptionsAsPerTypeAndPlatform) &&
      Object.keys(ActionOptionsAsPerTypeAndPlatform).includes(campaignTypes.STANDARD) &&
      Object.keys(ActionOptionsAsPerTypeAndPlatform[campaignTypes.STANDARD])
      ? ActionOptionsAsPerTypeAndPlatform[campaignTypes.STANDARD][subTab || '']
      : actionConfigLabelValuePair
    : ActionOptionsAsPerPlatform && Object.keys(ActionOptionsAsPerPlatform).includes(platform || '')
    ? ActionOptionsAsPerPlatform[platform as keyof typeof ActionOptionsAsPerPlatform].actions
    : actionConfigLabelValuePair;

  let currentPlatformActionsFromConfig = getActionsMentionsIntersectionAsPerCreateFor(createCampaignFor, actionPlatformConfig, platform);
  currentPlatformActionsFromConfig = currentPlatformActionsFromConfig.filter((action: any) => !actionPlatformConfig[platform]?.disallowActionsInAutomate?.includes(action));
  let actionOptions: SelectOption[] = actions.filter((option: any) => {
    return currentPlatformActionsFromConfig.includes(option.value);
  });

  if (createCampaignFor && Object.keys(createCampaignFor).length === 1 && createCampaignFor[dashboardTabConstants.MENTIONS]) {
    actionOptions = actionOptions.filter((opt: SelectOption) => !(actionsNotSupportedInMentions[platform] && actionsNotSupportedInMentions[platform].includes(opt.value)));
  }
  return actionOptions;
};
