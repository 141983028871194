/* eslint-disable @typescript-eslint/naming-convention */
export const FBConfig = {
  graphAPIVersion: 'v11.0',
  id: process.env.REACT_APP_AUTH_FACEBOOK_APPID,
  testPageId: process.env.REACT_APP_AUTH_FACEBOOK_TESTPAGEID,
  permission: {
    required: {
      pages_manage_metadata: 'Allow Amplify to subscribe and receive webhooks about activity on the Page, and to update settings on the Page.',
      pages_show_list: 'Allow Amplify to access the list of the Pages that a person manages.',
      pages_manage_engagement: 'Allow Amplify to create, edit and delete comments posted on the Page',
      pages_read_engagement:
        'Allow Amplify to read content (posts, photos, videos, events) posted by the Page, read followers data (including name, PSID), and profile picture, and read metadata and other insights about the Page.',
      pages_read_user_content:
        'Allow Amplify to read user generated content on the Page, such as posts, comments, and ratings by users or other Pages, and to delete user comments on Page posts',
      pages_messaging: 'Allow Amplify to send messages from Pages you manage.',
      // pages_messaging_subscriptions: 'Allow Amplify to send messages from Pages you manage 24 hours or more after last interaction.',
      pages_manage_ads: 'Allow Amplify to manage ads associated with the Page. The allowed usage for this permission is to create and manage ads for the Page.',
      business_management: 'Allows Amplify to read and write with the Business Manager API.',
    },
    optional: {
      instagram_basic: "Allow Amplify to read an Instagram account profile's info and media",
      instagram_manage_comments: 'Allow Amplify to create, delete and hide comments on behalf of the Instagram account linked to a Page.',
      ads_read:
        'Allow Amplify to access the Ads Insights API to pull Ads report information for Ad accounts you own or have been granted access to by the owner or owners of other ad accounts through this permission.',
      instagram_manage_messages: 'Allow Amplify to send messages from your IG account.',
    },
  },
};
/* eslint-enabled @typescript-eslint/naming-convention */
