import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { storeSegments, storeSegmentsBasePath, storeSegmentUserCount } from '../actions';
import { Segments } from '../segment.types';

export const initialState: {
  routeName: string;
  basePath: string;
  segments: Segments;
  userCounts: string;
} = {
  routeName: '',
  basePath: '',
  segments: {},
  userCounts: '',
};

const segmentsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeSegmentsBasePath, (state: typeof initialState, action: ReturnType<typeof storeSegmentsBasePath>) => {
      state.basePath = action.payload;
    })
    .addCase(storeSegments, (state: typeof initialState, action: ReturnType<typeof storeSegments>) => {
      state.segments = action.payload;
    })
    .addCase(storeSegmentUserCount, (state: typeof initialState, action: ReturnType<typeof storeSegmentUserCount>) => {
      state.userCounts = action.payload;
    });
});

export default segmentsReducer;
