import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import _, { isEqual } from 'lodash';
import { storeCampaigns } from '../../../pages/automation/actions';
import { engagementActionTypes } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.constants';
import { CommentActions, /*ReplyObjAPI,*/ Comment /*RawComment*/ } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { storeSegment } from '../../../pages/segments/actions';
import { storeUsers, setPlatformBotPermissions } from '../../../pages/user-profile-pages/actions';
import {
  actOnComment,
  addTargetedUsers,
  setLatestBroadcastTime,
  updatedBotConfig,
  undoActOnComment,
  unBlockCommentUser,
  blockCommentUser,
  clearKeywordGroups,
  removePlatformBot,
  storeAccounts,
  storeBots,
  storeCommentsAndCommentUsers,
  storeKeywordGroups,
  storePlatformBots,
  storePrivateReplyTags,
  storeTaggingGC,
  storeTaggingGCs,
  storeUserAccount,
  storeVariableForTheplatform,
  storeLabelForTheplatform,
  addLabelForTheplatform,
  deleteLabel,
  updateBotWithNLP,
  updateTaggingGCInUi,
  storeTemplates,
  storeArchivedTemplates,
  storeTemplate,
  removeTemplate,
  storePlatformUsers,
  bulkActOnComment,
  blockBulkCommentUser,
  setErrorUserComment,
  storePosts,
  appendPosts,
  storeBroadcasts,
  storeBroadcast,
  storeNotifLabels,
  storeModules,
  deleteBroadcastFromSore,
  fetchedSearchedPlatformUsers,
  fetchedTargetedUsers,
  deleteTargetedUser,
  addTestUsersToBot,
  appendBroadcasts,
  storeSegments,
  removeSegment,
  deleteCommentReply,
  storeSearchFilterTagsWithDate,
  removeSearchFilterTagsForDate,
  clearSearchFilterTagsAndSelectedRange,
  storeUserIngests,
  storeUserIngest,
  storeSubscriptions,
  storeVariable,
  storeSessionStartTypeForInbox,
  storeBroadcastEngagementSettings,
  appendVariableValue,
  storeSegmentTags,
  storeSegmentTag,
  storeSegmentUser,
  updateModule,
} from '../actions';
import { Bot, Bots, Entities, SegmentTagUI } from '../entities.types';

export const initialState: Entities = {
  campaigns: {},
  variables: {},
  customLabels: {},
  users: {},
  bots: {},
  platformUsers: {},
  broadcasts: {},
  tags: {},
  userIngests: {},
  subscriptions: null,
  segmentTags: {},
};

// eslint-disable-next-line max-lines-per-function
const entitiesReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeUserAccount, (state: typeof initialState, action: ReturnType<typeof storeUserAccount>) => {
      state.account = action.payload;
      if (action.payload) {
        state.accounts = { ...state.accounts, [action.payload.id]: action.payload };
      }
    })
    .addCase(storeAccounts, (state: typeof initialState, action: ReturnType<typeof storeAccounts>) => {
      state.accounts = action.payload;
    })
    .addCase(storePlatformBots, (state: typeof initialState, action: ReturnType<typeof storePlatformBots>) => {
      state.platformBots = action.payload;
    })
    .addCase(setPlatformBotPermissions, (state: typeof initialState, action: ReturnType<typeof setPlatformBotPermissions>) => {
      const { platformBotId, permissions } = action.payload;
      if (state.platformBots && state.platformBots[platformBotId]) {
        state.platformBots[platformBotId] = { ...state.platformBots[platformBotId], permissions };
      }
    })
    .addCase(storeBots, (state: typeof initialState, action: ReturnType<typeof storeBots>) => {
      //If additional values are present from earlier calls we are keeping that.
      const newBots = action.payload || {};
      const currentBots = state.bots || {};
      const mergedBots = Object.entries(newBots).reduce((acc: Bots, [newBotId, newBot]: [string, Bot]) => {
        return { ...acc, [newBotId]: { ...currentBots[newBotId], ...newBot } };
      }, {});

      state.bots = mergedBots;
    })
    .addCase(updateBotWithNLP, (state: typeof initialState, action: ReturnType<typeof updateBotWithNLP>) => {
      state.bots = { ...state.bots, [action.payload.botId]: { ...(state.bots && state.bots[action.payload.botId]), nlp: action.payload } };
    })
    .addCase(updatedBotConfig, (state: typeof initialState, action: ReturnType<typeof updatedBotConfig>) => {
      state.bots = { ...state.bots, [action.payload.botId]: { ...(state.bots && state.bots[action.payload.botId]), config: action.payload.config } };
    })
    .addCase(removePlatformBot, (state: typeof initialState, action: ReturnType<typeof removePlatformBot>) => {
      state.platformBots && delete state.platformBots[action.payload];
    })
    .addCase(storeCommentsAndCommentUsers, (state: typeof initialState, action: ReturnType<typeof storeCommentsAndCommentUsers>) => {
      state.comments = action.payload.comments;
      state.commentUsers = action.payload.commentUsers;
    })
    .addCase(actOnComment, (state: typeof initialState, action: ReturnType<typeof actOnComment>) => {
      const { id, updatedComment } = action.payload;
      if (state.comments?.[id]) {
        state.comments[id] = updatedComment!;
      }
    })
    .addCase(undoActOnComment, (state: typeof initialState, action: ReturnType<typeof undoActOnComment>) => {
      const { commentId, updatedComment } = action.payload;
      if (state.comments?.[commentId]) {
        state.comments[commentId] = updatedComment!;
      }
    })
    .addCase(unBlockCommentUser, (state: typeof initialState, action: ReturnType<typeof unBlockCommentUser>) => {
      const { userId, updatedComment } = action.payload;
      if (state.commentUsers?.[userId]) {
        const blockedActionList = Object.values(engagementActionTypes['block']);
        state.commentUsers[userId].blocked = false;
        state.commentUsers[userId].commentIds.forEach((commentId: string) => {
          if (state.comments && state.comments[commentId]) {
            blockedActionList.forEach((blockedAction: string) => delete state.comments?.[commentId].actions[blockedAction as keyof CommentActions]);
          }
        });
      }
      // we can still update UI
      state.comments?.[updatedComment.commentId] && (state.comments[updatedComment.commentId] = updatedComment);
    })
    .addCase(deleteCommentReply, (state: typeof initialState, action: ReturnType<typeof deleteCommentReply>) => {
      const { commentId, updatedComment } = action.payload;
      if (state.comments?.[commentId]) {
        state.comments[commentId] = updatedComment!;
      }
    })
    .addCase(blockCommentUser, (state: typeof initialState, action: ReturnType<typeof blockCommentUser>) => {
      if (state.commentUsers?.[action.payload.id]) {
        const { updatedComment } = action.payload;

        state.commentUsers[action.payload.id].blocked = true;
        state.commentUsers[action.payload.id].commentIds.forEach((commentId: string) => {
          state.comments?.[commentId] && (state.comments[commentId].actions.block = updatedComment.actions.block);
        });

        // we can still update UI
        state.comments && (state.comments[updatedComment.commentId] = updatedComment);
      }
    })
    .addCase(storeCampaigns, (state: typeof initialState, action: ReturnType<typeof storeCampaigns>) => {
      state.campaigns = action.payload.campaigns;
    })
    .addCase(storeVariableForTheplatform, (state: typeof initialState, action: ReturnType<typeof storeVariableForTheplatform>) => {
      state.variables = action.payload;
    })
    .addCase(storeLabelForTheplatform, (state: typeof initialState, action: ReturnType<typeof storeLabelForTheplatform>) => {
      state.customLabels = action.payload;
    })
    .addCase(storeSessionStartTypeForInbox, (state: typeof initialState, action: ReturnType<typeof storeSessionStartTypeForInbox>) => {
      state.sessionStartTypes = action.payload;
    })
    .addCase(addLabelForTheplatform, (state: typeof initialState, action: ReturnType<typeof addLabelForTheplatform>) => {
      state.customLabels = { ...state.customLabels, ...action.payload };
    })
    .addCase(deleteLabel, (state: typeof initialState, action: ReturnType<typeof deleteLabel>) => {
      delete state.customLabels[action.payload];
    })
    .addCase(storeKeywordGroups, (state: typeof initialState, action: ReturnType<typeof storeKeywordGroups>) => {
      state.keywordGroups = action.payload;
    })
    .addCase(clearKeywordGroups, (state: typeof initialState) => {
      state.keywordGroups = undefined;
    })
    .addCase(storeTaggingGCs, (state: typeof initialState, action: ReturnType<typeof storeTaggingGCs>) => {
      state.taggingGCs = action.payload;
    })
    .addCase(storeTaggingGC, (state: typeof initialState, action: ReturnType<typeof storeTaggingGC>) => {
      state.taggingGCs ? (state.taggingGCs[action.payload.id] = action.payload) : (state.taggingGCs = { [action.payload.id]: action.payload });
    })
    .addCase(updateTaggingGCInUi, (state: typeof initialState, action: ReturnType<typeof updateTaggingGCInUi>) => {
      state.taggingGCs && (state.taggingGCs[action.payload.id] = { ...action.payload, associatedCampaigns: state.taggingGCs[action.payload.id].associatedCampaigns });
    })
    .addCase(storeUsers, (state: typeof initialState, action: ReturnType<typeof storeUsers>) => {
      state.users = action.payload.usersToUI;
    })
    .addCase(storePrivateReplyTags, (state: typeof initialState, action: ReturnType<typeof storePrivateReplyTags>) => {
      state.privateReplyTags = action.payload;
    })
    .addCase(storeArchivedTemplates, (state: typeof initialState, action: ReturnType<typeof storeArchivedTemplates>) => {
      state.archivedTemplates = action.payload;
    })
    .addCase(storeTemplates, (state: typeof initialState, action: ReturnType<typeof storeTemplates>) => {
      state.templates = action.payload;
    })
    .addCase(storeTemplate, (state: typeof initialState, action: ReturnType<typeof storeTemplate>) => {
      const template = action.payload;
      state.templates && (state.templates[template.id] = template);
    })
    .addCase(removeTemplate, (state: typeof initialState, action: ReturnType<typeof removeTemplate>) => {
      const templateId = action.payload;
      state.templates && delete state.templates[templateId];
    })
    .addCase(storePlatformUsers, (state: typeof initialState, action: ReturnType<typeof storePlatformUsers>) => {
      state.platformUsers = { ...state.platformUsers, ...action.payload };
    })
    .addCase(bulkActOnComment, (state: typeof initialState, action: ReturnType<typeof bulkActOnComment>) => {
      if (state.comments) {
        const { comments } = action.payload;

        state.comments = { ...state.comments, ...comments };
      }
    })
    .addCase(blockBulkCommentUser, (state: typeof initialState, action: ReturnType<typeof blockBulkCommentUser>) => {
      if (state.commentUsers) {
        const { comments } = action.payload;
        Object.values(comments).forEach((comment: Comment) => {
          if (comment.actions.block) {
            state.commentUsers && (state.commentUsers[comment.userComment.platformUserId].blocked = true);
            state.commentUsers &&
              state.commentUsers[comment.userComment.platformUserId].commentIds.forEach((commentId: string) => {
                state.comments && (state.comments[commentId].actions.block = comment.actions.block);
              });
          }
          // we can still update UI
          state.comments && (state.comments[comment.commentId] = comment);
        });
      }
    })
    .addCase(setErrorUserComment, (state: typeof initialState, action: ReturnType<typeof setErrorUserComment>) => {
      if (state.comments) {
        const { comments, response } = action.payload;
        state.comments = comments;
        Object.values(comments).forEach((comment: Comment) => {
          Object.values(response).forEach((res: any) => {
            if (comment.commentId === res.commentId) {
              state.comments && (state.comments[comment.commentId].userComment.errorMessage = res.message);
            }
          });
        });
      }
    })
    .addCase(storePosts, (state: typeof initialState, action: ReturnType<typeof storePosts>) => {
      state.posts = action.payload;
    })
    .addCase(appendPosts, (state: typeof initialState, action: ReturnType<typeof storePosts>) => {
      state.posts = { ...state.posts, ...action.payload };
    })
    .addCase(storeBroadcastEngagementSettings, (state: typeof initialState, action: ReturnType<typeof storeBroadcastEngagementSettings>) => {
      state.broadcastEngagmentSettings = action.payload;
    })
    .addCase(storeBroadcasts, (state: typeof initialState, action: ReturnType<typeof storeBroadcasts>) => {
      state.broadcasts = action.payload;
    })
    .addCase(appendBroadcasts, (state: typeof initialState, action: ReturnType<typeof appendBroadcasts>) => {
      const newBroadcasts = { ...state.broadcasts, ...action.payload };
      if (!isEqual(state.broadcasts, newBroadcasts)) {
        state.broadcasts = newBroadcasts;
      }
    })
    .addCase(storeBroadcast, (state: typeof initialState, action: ReturnType<typeof storeBroadcast>) => {
      const broadcast = action.payload;
      state.broadcasts && (state.broadcasts[broadcast.id] = broadcast);
    })
    .addCase(deleteBroadcastFromSore, (state: typeof initialState, action: ReturnType<typeof deleteBroadcastFromSore>) => {
      delete state.broadcasts[action.payload];
    })
    .addCase(storeModules, (state: typeof initialState, action: ReturnType<typeof storeModules>) => {
      const { versionId, modules }: any = action.payload;
      if (versionId) {
        const allVersions = { ...state.versions, [versionId]: { ...state.versions?.[versionId], modules } };
        state.versions = allVersions;
      }
      state.versions?.[versionId]?.modules && (state.versions[versionId].modules = modules);
    })
    .addCase(updateModule, (state: typeof initialState, action: ReturnType<typeof updateModule>) => {
      const { versionId, module }: any = action.payload;
      if (versionId && state.versions?.[versionId]?.modules) {
        const allVersions = { ...state.versions, [versionId]: { ...state.versions?.[versionId], modules: { ...state.versions?.[versionId]?.modules, [module.id]: module } } };
        state.versions = allVersions;
      }
    })
    .addCase(storeNotifLabels, (state: typeof initialState, action: ReturnType<typeof storeNotifLabels>) => {
      const { versionId, notificationLabels }: any = action.payload;
      if (versionId) {
        const allVersions = { ...state.versions, [versionId]: { ...state.versions?.[versionId], notificationLabels } };
        state.versions = allVersions;
      }
    })
    .addCase(fetchedSearchedPlatformUsers, (state: typeof initialState, action: ReturnType<typeof fetchedSearchedPlatformUsers>) => {
      state.bots[action.payload.botId].platformUsers = action.payload.users;
    })
    .addCase(fetchedTargetedUsers, (state: typeof initialState, action: ReturnType<typeof fetchedTargetedUsers>) => {
      state.bots[action.payload.botId].targetedUsers = action.payload.users;
    })
    .addCase(deleteTargetedUser, (state: typeof initialState, action: ReturnType<typeof deleteTargetedUser>) => {
      const bot = { ...state.bots[action.payload.botId] };
      const targetedUsers = bot && bot.targetedUsers && [...bot.targetedUsers];

      const idx = _.findIndex(targetedUsers, (o: any) => o.id === action.payload.targetingId);
      targetedUsers && targetedUsers.splice(idx, 1);
      state.bots[action.payload.botId].targetedUsers = targetedUsers;
    })
    .addCase(addTargetedUsers, (state: typeof initialState, action: ReturnType<typeof addTargetedUsers>) => {
      const targeting = action.payload;
      const bot = { ...state.bots[action.payload.botId] };
      const targetedUsers = bot && bot.targetedUsers && [targeting, ...bot?.targetedUsers];
      state.bots[action.payload.botId].targetedUsers = targetedUsers;
    })

    .addCase(addTestUsersToBot, (state: typeof initialState, action: ReturnType<typeof addTestUsersToBot>) => {
      state.bots[action.payload.botId].testUsers = action.payload.testUsers;
    })
    .addCase(storeSegments, (state: typeof initialState, action: ReturnType<typeof storeSegments>) => {
      state.segments = action.payload;
    })
    .addCase(storeSegment, (state: typeof initialState, action: ReturnType<typeof storeSegment>) => {
      state.segments ? (state.segments[action.payload.id] = action.payload) : (state.segments = { [action.payload.id]: action.payload });
    })
    .addCase(removeSegment, (state: typeof initialState, action: ReturnType<typeof removeSegment>) => {
      const segmentsId = action.payload;
      state.segments && delete state.segments[segmentsId];
    })
    .addCase(appendVariableValue, (state: typeof initialState, action: ReturnType<typeof appendVariableValue>) => {
      state.variableValues = action.payload;
    })
    .addCase(setLatestBroadcastTime, (state: typeof initialState, action: ReturnType<typeof setLatestBroadcastTime>) => {
      state.broadcastTime = action.payload;
    })
    .addCase(clearSearchFilterTagsAndSelectedRange, (state: typeof initialState) => {
      state.tags = {};
    })
    .addCase(removeSearchFilterTagsForDate, (state: typeof initialState, action: ReturnType<typeof removeSearchFilterTagsForDate>) => {
      if (action.payload === 'all') {
        state.tags = {};
      } else if (state.tags && action.payload) {
        delete state.tags[action.payload];
      }
    })
    .addCase(storeSearchFilterTagsWithDate, (state: typeof initialState, action: ReturnType<typeof storeSearchFilterTagsWithDate>) => {
      const { dateRange, commentTags } = action.payload;
      state.tags = { ...state.tags, [dateRange]: commentTags };
    })
    .addCase(storeUserIngests, (state: typeof initialState, action: ReturnType<typeof storeUserIngests>) => {
      state.userIngests = action.payload;
    })
    .addCase(storeUserIngest, (state: typeof initialState, action: ReturnType<typeof storeUserIngest>) => {
      state.userIngests[action.payload.id] = action.payload;
    })
    .addCase(storeVariable, (state: typeof initialState, action: ReturnType<typeof storeVariable>) => {
      state.variables[action.payload.id] = action.payload;
    })
    .addCase(storeSubscriptions, (state: typeof initialState, action: ReturnType<typeof storeSubscriptions>) => {
      state.subscriptions = action.payload;
    })
    .addCase(storeSegmentTags, (state: typeof initialState, action: ReturnType<typeof storeSegmentTags>) => {
      state.segmentTags = action.payload;
    })
    .addCase(storeSegmentTag, (state: typeof initialState, action: ReturnType<typeof storeSegmentTag>) => {
      if (action.payload.id) {
        state.segmentTags = {
          ...state.segmentTags,
          [action.payload.id]: action.payload,
        };
      }
    })
    .addCase(storeSegmentUser, (state: typeof initialState, action: ReturnType<typeof storeSegmentUser>) => {
      if (action.payload.id) {
        state.segmentTags = {
          ...state.segmentTags,
          [action.payload.id]: {
            ...state.segmentTags?.[action.payload.id],
            users: action.payload.users,
          } as SegmentTagUI,
        };
      }
    });
});

export default entitiesReducer;
