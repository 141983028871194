import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { storePageRequestList, storePendingPageList } from '../actions';
import { RequestList } from '../types';

const initialState: RequestList = {
  acceptedPageList: null,
  pendingPageList: null,
};

const requestPageAccessReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storePageRequestList, (state: typeof initialState, action: ReturnType<typeof storePageRequestList>) => {
      state.acceptedPageList = action.payload;
    })
    .addCase(storePendingPageList, (state: typeof initialState, action: ReturnType<typeof storePendingPageList>) => {
      state.pendingPageList = action.payload;
    });
});

export default requestPageAccessReducer;
