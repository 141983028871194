import { createAction } from '@reduxjs/toolkit';
import constants from '../segment.constants';
import { Segments, Segment } from '../segment.types';

export const storeSegmentsBasePath = createAction<string>(constants.STORE_SEGMENTS_BASE_PATH);

export const storeSegments = createAction<Segments>(constants.STORE_SEGMENTS);

export const storeSegment = createAction<Segment>(constants.STORE_SEGMENT);

export const storeSegmentUserCount = createAction<string>(constants.STORE_SEGMENT_USER_COUNT);
