import { Dispatch } from '../../../types';
import { setCurrentPermissions } from '../actions';

export const userLevelPermissions = (dispatch: Dispatch, accountId: string, botId: string, permissionsRes: any) => {
  let userRole = '';
  if (permissionsRes?.account?.[accountId || '']?.role) {
    userRole = permissionsRes?.account?.[accountId || '']?.role || '';
  } else if (permissionsRes?.account?.[accountId || '']?.bot?.[botId || '']?.role) {
    userRole = permissionsRes?.account?.[accountId || '']?.bot?.[botId || '']?.role || '';
  }
  if (!userRole) {
    userRole = permissionsRes?.admin?.role || '';
  }
  dispatch(setCurrentPermissions(permissionsRes?.rolePermissions[userRole]));
};
