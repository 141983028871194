export const trillerLoaderColor = '#14172e';
export const logoImage = 'Cliqz_Logo-Early.png';
export const logoImageHeader = 'cliqz-logo-header.png';
export const feviconImage = '/cliqz-logo.ico';
export const globalContainerClassName = 'cliqzContainer';
export const chartColors = ['#3367c3', '#1ecfff', '#001733'];
export const signUpLink = 'https://www.cliqz.ai/signup/';
export const signUpPromptText = "Don't have a CLIQZ account yet? ";
export const signUpText = 'Request Early Access here';
export const logoImageSecondary = 'Vector.svg';
export const fontFamily = 'Verdana, Geneva, sans-serif';
export const brandName = 'CLIQZ';
export const primaryAccentColor = '#1ecfff';
export const contactUsEmail = 'sales@cliqz.ai';
export const supportEmail = 'support@cliqz.ai';

export const commentBreakDownChartConfig: any = {
  width: 12,
  colors: {
    actionableColor: '#001733',
    nonActionableColor: '#1ecfff',
    taggedColor: '#82e3fd ',
    hiddenComments: '#14172E',
    brandReaction: '#4d5383',
    publicReplies: '#888ec1',
    blockedUsers: '#bfc2de',
    deletedComments: '#0a088c',
    engagementTotal: '#0E1121', //'#222',
    reviewedComments: '#1b3b74',
    engaged: '#3367c3',
  },
};
export const intentTagStrokeColor = '#1ecfff';
export const wordMapColors = ['#001733', '#04547a', '#0696b7', '#1ecfff', '#57d8fb', '#8ce3fb', '#3c5b6a', '#0e2f56', '#35557c', '#55677e', '#6c737c'];
export const sentimentColors = {
  extremelyNegative: '#f50331',
  veryNegative: '#f73b5e',
  moderatelyNegative: '#f97c94',
  neutral: '#1ecfff',
  moderatelyPositive: '#0696b7',
  veryPositive: '#04547a',
  extremelyPositive: '#001733',
};
export const leftNavigationBgClassName = 'leftNavigationBarBgCliqz';
