import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { haveStatsChanged, storeCommentModerationStats, storeEngagementStats, setIsEngagementsViewer } from '../actions';
import { CommentModerationType, EngagementType } from '../types';

const initialStateCM: CommentModerationType = {
  commentStats: {},
  statsHaveChanged: false,
};
const initialStateEngagements: EngagementType = {
  commentStats: {},
  statsHaveChanged: false,
  isEngagementsViewer: false,
};

const commentModerationReducer = createReducer(initialStateCM, (builder: ActionReducerMapBuilder<typeof initialStateCM>) => {
  builder
    .addCase(storeCommentModerationStats, (state: typeof initialStateCM, action: ReturnType<typeof storeCommentModerationStats>) => {
      state.commentStats = action.payload;
    })
    .addCase(haveStatsChanged, (state: typeof initialStateCM, action: ReturnType<typeof haveStatsChanged>) => {
      state.statsHaveChanged = action.payload;
    });
});

export const engagementsReducer = createReducer(initialStateEngagements, (builder: ActionReducerMapBuilder<typeof initialStateEngagements>) => {
  builder
    .addCase(storeEngagementStats, (state: typeof initialStateEngagements, action: ReturnType<typeof storeEngagementStats>) => {
      state.commentStats = action.payload;
    })
    .addCase(setIsEngagementsViewer, (state: typeof initialStateEngagements, action: ReturnType<typeof setIsEngagementsViewer>) => {
      state.isEngagementsViewer = action.payload;
    });
});

export default commentModerationReducer;
