/* eslint-disable max-lines-per-function */
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { timeFilterOptions } from '../../../pages/common/time-filter/time-filter.constants';
import { defaultFiltersForPages } from '../../../pages/dashboard/components/comments-viewer/comments-viewer.constants';
import { CommentsModeConstants, dashboardTabConstants, dashboardTimeFilterKeys, pageFilterKeys, pageKeysForFilter } from '../../../pages/dashboard/constants';
import { GenericObject, SelectOption } from '../../../types';
import { shouldUseAsIframe } from '../../utils/enable-as-iframe';
import { decodeString, encodeValue, getFiltersForPageFromURL, getTimeFilterFromStorage, setTimeFilterToStorage } from '../../utils/persist-time-filter';
import {
  initializeFilter,
  setConnectedToFb,
  setSideBarShow,
  setFilter,
  setInitialDataFetchAttempted,
  setPageUIState,
  setSelectedPlatformBot,
  setSelectedTimeFilter,
  setEngagementsTimeFilter,
  storeIsOverrideNlpEnabled,
  isAccountsFetched,
  setRouteParams,
  setApplicableTabsSubTabs,
  setFilterCommentsFromAPIForPosts,
  clearCommentsFilter,
  initializePageFilter,
  setSelectedTagRange,
  setMobileSideBarVisibility,
} from '../actions';
import { pageUIStateType } from '../types';
import { initializeRouteParamsFromUrl } from '../utils';

const routeParams = initializeRouteParamsFromUrl();

const defaultFiltersForPage = (pageName: string, filterPresets: GenericObject = {}) => {
  const pageFilters: { [key: string]: string } = {};
  const filtersFromStorage = getTimeFilterFromStorage(pageName);
  Object.values(pageFilterKeys).forEach((value: string) => {
    pageFilters[value] = defaultFiltersForPages({ ...filtersFromStorage?.filters, ...filterPresets })[pageName][value];
  });
  return pageFilters;
};

const isValidTimeFilterValue = (time: string = ''): boolean => {
  const timeFilterValues = timeFilterOptions.map((option: SelectOption) => option.value);
  return timeFilterValues.indexOf(time) > -1;
};

const getDashboardInitialFilter = () => {
  const filtersFromStorage = getTimeFilterFromStorage('dashboard')?.filters || {};
  const pageFiltersFromURL = decodeString(getFiltersForPageFromURL('dashboard')?.filters) || {};
  Object.values(dashboardTimeFilterKeys).forEach((value: string) => {
    if (!isValidTimeFilterValue(pageFiltersFromURL?.[value]?.time)) {
      //if Urlfilter doesnt have proper value get from storage if propervalue else set last7 days as default
      pageFiltersFromURL[value] = isValidTimeFilterValue(filtersFromStorage?.[value]?.time) ? filtersFromStorage[value] : { time: timeFilterOptions[2].value };
    }
  });
  return {
    filters: encodeValue(pageFiltersFromURL),
  };
};

const getPageInitialFilter = (pageName: string, filterPresets?: GenericObject) => {
  return {
    ...defaultFiltersForPage(pageName, filterPresets),
    ...getFiltersForPageFromURL(pageName),
  };
};

export const initialState: pageUIStateType = {
  error: null,
  isConnectedToFb: false,
  selectedTimeFilter: timeFilterOptions[2].value,
  engagementsTimeFilter: timeFilterOptions[2].value,
  isInitialDataFetchAttempted: false,
  routeParams,
  filters: {
    dashboard: {},
    comments: {},
  },
  commentsLoaded: false,
  isOverrideNlpEnabled: false,
  isAccountsFetched: false,
  applicableTabsSubTabs: [],
  moderationMode: dashboardTabConstants.COMMENTS,
  commentsMode: CommentsModeConstants.MODERATION,
  useAppAsIframe: shouldUseAsIframe(),
  filterCommentsFromAPIForPosts: false,
  isMobileSidebarVisible: false,
  mfaNotificationClosed: localStorage.getItem('mfaNotificationClosed') === 'true',
  isCurrentUserDetailsFetched: false,
  isMFAActivated: false,
  sidebarShow: true,
};

const pageUIReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(initializeFilter, (state: typeof initialState, action: ReturnType<typeof initializeFilter>) => {
      const initialFilters = getDashboardInitialFilter();
      state.filters[action.payload] = initialFilters;
    })
    .addCase(initializePageFilter, (state: typeof initialState, action: ReturnType<typeof initializePageFilter>) => {
      const initialFilters = getPageInitialFilter(action.payload.pageName, action.payload.filterPresets || {});
      state.filters[action.payload.pageName] = initialFilters;
    })
    .addCase(clearCommentsFilter, (state: typeof initialState, action: ReturnType<typeof clearCommentsFilter>) => {
      state.filters[action.payload] = undefined;
    })
    .addCase(setPageUIState, (state: typeof initialState, action: ReturnType<typeof setPageUIState>) => {
      state[action.payload.key] = action.payload.value;
    })
    .addCase(setSelectedPlatformBot, (state: typeof initialState, action: ReturnType<typeof setSelectedPlatformBot>) => {
      state.selectedPlatformBot = action.payload;
    })
    .addCase(setSelectedTimeFilter, (state: typeof initialState, action: ReturnType<typeof setSelectedTimeFilter>) => {
      state.selectedTimeFilter = action.payload;
    })
    .addCase(setEngagementsTimeFilter, (state: typeof initialState, action: ReturnType<typeof setEngagementsTimeFilter>) => {
      state.engagementsTimeFilter = action.payload;
    })
    .addCase(setConnectedToFb, (state: typeof initialState, action: ReturnType<typeof setConnectedToFb>) => {
      state.isConnectedToFb = action.payload;
    })
    .addCase(setSideBarShow, (state: typeof initialState, action: ReturnType<typeof setSideBarShow>) => {
      state.sidebarShow = action.payload;
    })
    .addCase(setFilter, (state: typeof initialState, action: ReturnType<typeof setFilter>) => {
      let filterValue = action.payload.filterValue;
      if (action.payload.filterKey === pageFilterKeys.FILTERS && action.payload.filterValue && typeof action.payload.filterValue === 'object') {
        const oldFilters: any = decodeString(state.filters[action.payload.pageName]?.[action.payload.filterKey] || '');
        filterValue = encodeValue({ ...oldFilters, ...action.payload.filterValue });
        action.payload.storeInLocalStorage &&
          setTimeFilterToStorage(action.payload.pageName, action.payload.filterKey, {
            ...oldFilters,
            ...action.payload.filterValue,
            ...(action.payload.pageName === pageKeysForFilter.COMMENTS ? { posts: [], tag: [], customFilter: '' } : {}),
          });
      } else {
        action.payload.storeInLocalStorage && setTimeFilterToStorage(action.payload.pageName, action.payload.filterKey, action.payload.filterValue);
      }
      state.filters[action.payload.pageName] = {
        ...state.filters[action.payload.pageName],
        [action.payload.filterKey]: filterValue,
      };
    })
    .addCase(setInitialDataFetchAttempted, (state: typeof initialState, action: ReturnType<typeof setInitialDataFetchAttempted>) => {
      state.isInitialDataFetchAttempted = action.payload;
    })
    .addCase(storeIsOverrideNlpEnabled, (state: typeof initialState, action: ReturnType<typeof storeIsOverrideNlpEnabled>) => {
      state.isOverrideNlpEnabled = action.payload;
    })
    .addCase(isAccountsFetched, (state: typeof initialState, action: ReturnType<typeof isAccountsFetched>) => {
      state.isAccountsFetched = action.payload;
    })
    .addCase(setRouteParams, (state: typeof initialState, action: ReturnType<typeof setRouteParams>) => {
      const { property, value } = action.payload;
      state.routeParams[property as 'accountId' | 'platformBotId'] = value;
    })
    .addCase(setApplicableTabsSubTabs, (state: typeof initialState, action: ReturnType<typeof setApplicableTabsSubTabs>) => {
      state.applicableTabsSubTabs = action.payload;
    })
    .addCase(setFilterCommentsFromAPIForPosts, (state: typeof initialState, action: ReturnType<typeof setFilterCommentsFromAPIForPosts>) => {
      state.filterCommentsFromAPIForPosts = action.payload;
    })
    .addCase(setSelectedTagRange, (state: typeof initialState, action: ReturnType<typeof setSelectedTagRange>) => {
      state.selectedTagRange = action.payload;
    })
    .addCase(setMobileSideBarVisibility, (state: typeof initialState, action: ReturnType<typeof setMobileSideBarVisibility>) => {
      state.isMobileSidebarVisible = action.payload;
    });
});

export default pageUIReducer;
