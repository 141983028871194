/* eslint-disable max-lines-per-function */
import { Segments, FetchSegmentsApiResponse, Segment, FetchSegmentApiResponse } from '../../../pages/segments/segment.types';
import {
  condition,
  childConditionsIfStatement,
  conditions,
  operatorsSupportingValueUnit,
  operatorsForDistanceValueUnit,
  areaCodeVariable,
  broadcastConditionOperator,
  conditionsOpForExists,
  operatorsForBroadcasts,
} from '../../components/tagging-gc-content/tagging-gc-content.constants';
import { v4 as uuid } from 'uuid';
import { convertAreaCodeValToRegex, extractNumbersFromAreaCodeRegex } from '../../utils/conditionGroups';
import _ from 'lodash';
import { getSegmentIdTagIdForAPI } from '../../../pages/broadcasts/utils';
import { checkBroadcastSelectedTargetConditions } from '../../components/tagging-gc-content/utils';
const fetchSegmentsApiToUi = (data: FetchSegmentsApiResponse): Segments => {
  const segments: Segments = {};
  let isAdvanced = false;
  data.forEach(({ id, name, userCount, allows, conditions, createdDate, createdBy, lastUpdatedDate, botId, userCountUpdated, description }: FetchSegmentsApiResponse[0]) => {
    if (conditions?.childConditions) {
      conditions.childConditions.forEach((childCondition: condition) => {
        if (childCondition?.childConditions) {
          isAdvanced = true;
        }
      });
    }
    segments[id] = {
      id,
      name,
      totalContacts: userCount,
      conditions: {
        ...conditions,
        childConditions: conditions?.childConditions?.map((childCondition: condition) => ({
          ...{
            logicalOperator: childCondition.logicalOperator,
            ifStatements: childCondition.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
              return { ...ifStatement, id: uuid() };
            }),
            childCondition: childCondition.childConditions,
          },
          id: uuid(),
        })),
      },
      createdBy,
      allows,
      createdDate,
      lastUpdatedDate,
      botId,
      userCountUpdated,
      description,
      isAdvanced,
    };
  });
  return segments;
};
const fetchSegmentTagsApiToUi = (data: FetchSegmentsApiResponse): any => {
  const segmentTags: Segments = {};
  data.forEach((segmentTag: any[0]) => {
    segmentTags[segmentTag.id] = segmentTag;
  });
  return segmentTags;
};

const fetchSegmentApiToUi = (data: FetchSegmentApiResponse, transformSegmentConditions: boolean = false): Segment => {
  let conditions: conditions = {};
  let isAdvanced = false;
  if (data.conditions.childConditions) {
    data.conditions.childConditions.forEach((childCondition: condition) => {
      if (childCondition?.childConditions) {
        isAdvanced = true;
      }
    });
  }
  if (!data.conditions?.childConditions && data.conditions?.ifStatements) {
    conditions = {
      logicalOperator: data.conditions.logicalOperator,
      childConditions: [
        {
          ifStatements: data.conditions.ifStatements.map((ifStatement: childConditionsIfStatement) => {
            const isAreaCodeValue = transformSegmentConditions && ifStatement?.variable?.name === areaCodeVariable?.name && extractNumbersFromAreaCodeRegex(ifStatement?.value);
            const broadcastConditionExistsNonExists =
              transformSegmentConditions &&
              checkBroadcastSelectedTargetConditions(ifStatement?.variable?.macro) &&
              conditionsOpForExists?.includes(ifStatement?.condition) &&
              (ifStatement?.condition === conditionsOpForExists[1] ? broadcastConditionOperator.BROADCAST_NOT_RECEIVED : broadcastConditionOperator.BROADCAST_RECEIVED);

            return {
              ...ifStatement,
              id: uuid(),
              ...(isAreaCodeValue ? { value: isAreaCodeValue } : {}),
              ...(broadcastConditionExistsNonExists ? { condition: broadcastConditionExistsNonExists } : {}),
            };
          }),
          logicalOperator: data.conditions.logicalOperator,
          id: uuid(),
        },
      ],
    };
  } else {
    conditions = {
      ...data.conditions,
      childConditions: data.conditions?.childConditions?.map((childCondition: condition) => ({
        ...{
          logicalOperator: childCondition.logicalOperator,
          ifStatements: childCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
            const isAreaCodeValue = transformSegmentConditions && ifStatement?.variable?.name === areaCodeVariable?.name && extractNumbersFromAreaCodeRegex(ifStatement?.value);
            const broadcastConditionExistsNonExists =
              transformSegmentConditions &&
              checkBroadcastSelectedTargetConditions(ifStatement?.variable?.macro) &&
              conditionsOpForExists?.includes(ifStatement?.condition) &&
              (ifStatement?.condition === conditionsOpForExists[1] ? broadcastConditionOperator.BROADCAST_NOT_RECEIVED : broadcastConditionOperator.BROADCAST_RECEIVED);

            return {
              ...ifStatement,
              id: uuid(),
              ...(isAreaCodeValue ? { value: isAreaCodeValue } : {}),
              ...(broadcastConditionExistsNonExists ? { condition: broadcastConditionExistsNonExists } : {}),
            };
          }),
          childConditions: childCondition.childConditions,
        },
        id: uuid(),
      })),
    };
  }
  const segment: Segment = {
    id: data.id,
    name: data.name,
    totalContacts: data.userCount,
    conditions,
    createdBy: data.createdBy,
    allows: data.allows,
    createdDate: data.createdDate,
    lastUpdatedDate: data.lastUpdatedDate,
    botId: data.botId,
    userCountUpdated: data.userCountUpdated,
    description: data.description,
    isAdvanced: data.isAdvanced || isAdvanced,
  };
  return segment;
};

const segmentConditionUiToApi = (conditions?: any, transformSegmentConditions: boolean = false) => {
  const allChildConditions = conditions?.childConditions?.map((childCondition: any) => {
    let newChildCondition = _.cloneDeep(childCondition);
    const newIfStatements: any = childCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
      const broadcastConditionExistsNonExists =
        operatorsForBroadcasts?.includes(ifStatement?.condition) &&
        (ifStatement?.condition === broadcastConditionOperator.BROADCAST_NOT_RECEIVED ? conditionsOpForExists[1] : conditionsOpForExists[0]);
      const value =
        transformSegmentConditions && ifStatement?.variable?.name === areaCodeVariable?.name ? convertAreaCodeValToRegex(ifStatement?.value)?.regexPattern : ifStatement?.value;
      return { ...ifStatement, value, ...(broadcastConditionExistsNonExists ? { condition: broadcastConditionExistsNonExists } : {}) };
    });

    const newSubChildConditions = (childCondition as any).childConditions?.map((subChildCondition: any) => {
      const isSegmentCondition = subChildCondition.segmentId === '';
      if (isSegmentCondition) {
        const childConditionsWithSegmentId = (subChildCondition as any).childConditions?.map((subSubChildCondition: any) => {
          const segmentInfoForAPI = getSegmentIdTagIdForAPI(subSubChildCondition.segmentId);
          delete subSubChildCondition.segmentId;
          return {
            ...subSubChildCondition,
            ...segmentInfoForAPI,
          };
        });
        return {
          ...subChildCondition,
          childConditions: childConditionsWithSegmentId,
        };
      }

      const subChildCNewIf = subChildCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
        const broadcastConditionExistsNonExists =
          operatorsForBroadcasts?.includes(ifStatement?.condition) &&
          (ifStatement?.condition === broadcastConditionOperator.BROADCAST_NOT_RECEIVED ? conditionsOpForExists[1] : conditionsOpForExists[0]);
        const value =
          transformSegmentConditions && ifStatement?.variable?.name === areaCodeVariable?.name ? convertAreaCodeValToRegex(ifStatement?.value)?.regexPattern : ifStatement?.value;
        return { ...ifStatement, value, ...(broadcastConditionExistsNonExists ? { condition: broadcastConditionExistsNonExists } : {}) };
      });

      return { ...subChildCondition, ifStatements: subChildCNewIf };
    });

    newChildCondition = { ...newChildCondition, childConditions: newSubChildConditions, ifStatements: newIfStatements };
    return newChildCondition;
  });

  return { ...conditions, childConditions: allChildConditions };
};

/*eslint-disable max-lines-per-function*/
const segmentUiToApi = (recommendation: Segment, createMode: boolean, transformSegmentConditions: boolean = false) => {
  const updatedRecommendation: { [key: string]: unknown } = createMode
    ? {
        ...(recommendation.id && { id: recommendation.id }),
        name: (recommendation.name || '').trim(),
        ...(recommendation.conditions?.childConditions && {
          conditions: {
            ...recommendation.conditions,
            childConditions: recommendation.conditions?.childConditions?.map((childCondition: condition) => {
              const childConditionWithIdsRemoved = {
                ...{
                  logicalOperator: childCondition.logicalOperator,
                  ifStatements: childCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
                    const updatedIfStatement = { ...ifStatement };
                    const hasDistanceOperators = operatorsForDistanceValueUnit.includes(ifStatement.condition);
                    updatedIfStatement.valueUnit = operatorsSupportingValueUnit.includes(ifStatement.condition) || hasDistanceOperators ? ifStatement.valueUnit : undefined;
                    if (!hasDistanceOperators && updatedIfStatement.metadata && updatedIfStatement.metadata.distanceFrom) {
                      const metaData = { ...updatedIfStatement.metadata };
                      delete metaData.distanceFrom;
                      updatedIfStatement.metadata = metaData;
                    }
                    if (transformSegmentConditions && updatedIfStatement.variable?.name === areaCodeVariable.name) {
                      updatedIfStatement.value = convertAreaCodeValToRegex(updatedIfStatement.value?.trim())?.regexPattern;
                    }
                    if (operatorsForBroadcasts?.includes(ifStatement?.condition)) {
                      updatedIfStatement.condition =
                        ifStatement?.condition === broadcastConditionOperator.BROADCAST_NOT_RECEIVED ? conditionsOpForExists[1] : conditionsOpForExists[0];
                    }

                    delete updatedIfStatement.id;
                    return updatedIfStatement;
                  }),
                  childConditions: childCondition?.childConditions,
                },
              };
              return childConditionWithIdsRemoved;
            }),
          },
        }),
        ...(recommendation.description && { description: recommendation.description || '' }),
      }
    : {
        ...(recommendation.id && { id: recommendation.id }),
        name: recommendation.name,
        ...(recommendation.conditions?.childConditions && {
          conditions: {
            ...recommendation.conditions,
            childConditions: recommendation.conditions?.childConditions?.map((childCondition: condition) => {
              const childConditionWithIdsRemoved = {
                ...{
                  logicalOperator: childCondition.logicalOperator,
                  ifStatements: childCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
                    const updatedIfStatement = { ...ifStatement };
                    const hasDistanceOperators = operatorsForDistanceValueUnit.includes(ifStatement.condition);
                    updatedIfStatement.valueUnit = operatorsSupportingValueUnit.includes(ifStatement.condition) || hasDistanceOperators ? ifStatement.valueUnit : undefined;
                    if (!hasDistanceOperators && updatedIfStatement.metadata && updatedIfStatement.metadata.distanceFrom) {
                      const metaData = { ...updatedIfStatement.metadata };
                      delete metaData.distanceFrom;
                      updatedIfStatement.metadata = metaData;
                    }
                    if (transformSegmentConditions && updatedIfStatement.variable?.name === areaCodeVariable.name) {
                      updatedIfStatement.value = convertAreaCodeValToRegex(updatedIfStatement.value?.trim())?.regexPattern;
                    }
                    if (operatorsForBroadcasts?.includes(ifStatement?.condition)) {
                      updatedIfStatement.condition =
                        ifStatement?.condition === broadcastConditionOperator.BROADCAST_NOT_RECEIVED ? conditionsOpForExists[1] : conditionsOpForExists[0];
                    }
                    delete updatedIfStatement.id;
                    return updatedIfStatement;
                  }),
                  childConditions: childCondition.childConditions,
                },
              };
              return childConditionWithIdsRemoved;
            }),
          },
        }),
        userCount: recommendation.totalContacts,
        ...(recommendation.description && { description: recommendation.description || '' }),
      };
  return updatedRecommendation;
};

const transformations = {
  fetchSegmentsApiToUi,
  fetchSegmentApiToUi,
  segmentUiToApi,
  segmentConditionUiToApi,
  fetchSegmentTagsApiToUi,
};
export default transformations;
