import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { User } from '../../pages/login/login.types';
import { useCurrentUser } from '../../hooks';

const useAuthVerification = () => {
  const user = useSelector((state: RootState): User | null => state.auth && state.auth.user);
  const token = useAuthToken();

  let isUserLoggedIn = false;
  if (user?.id && token) {
    isUserLoggedIn = true;
  }
  return isUserLoggedIn;
};

export const useTempAuthVerification = () => {
  const tempAuthDetails = useSelector((state: RootState) => state.auth?.tempAuthDetails);
  return !!(tempAuthDetails && tempAuthDetails?.authToken && tempAuthDetails?.email);
};

export const useAuthToken = () => {
  return useSelector((state: RootState): string | null => state.auth?.authToken);
};

export const useCheckIfEmailBelongsToCurrentUser = (email: string) => {
  let isCurrentUserLoggedIn = false;
  const user = useCurrentUser();
  if (user?.email === email) {
    isCurrentUserLoggedIn = true;
  }
  return isCurrentUserLoggedIn;
};

export default useAuthVerification;
